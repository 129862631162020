import { compose, both, prop, filter, reject } from "ramda";
import { isNotNil } from "src/main_app/utils/common";

const filterValidMarker = compose(
  filter(
    both(
      compose(isNotNil, prop("latitude")),
      compose(isNotNil, prop("longitude"))
    )
  ),
  reject(item => prop("latitude", item) === -1 || prop("longitude", item) === -1)
);

export default filterValidMarker;
