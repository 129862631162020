import React from "react";
import { pathOr } from "ramda";
import { useTenantConfig } from "src/main_app/actions";
import { getTargetChecked } from "src/main_app/utils/react_utils";
import Select from '@bhunjadi/react-select-v3';

const CustomerVisualization = ({
  visible,
  onChange,
  visualizationType,
  isDisplayElevation,
  onElevationLayerChange,
}) => {
  const tenantConfig = useTenantConfig();
  let listVisualizationType = [
    {value: 'usage_heatmap', label: 'Usage heatmap'},
    {value: 'customer_address', label: 'Customer address'},
  ];

  if ((tenantConfig.slug == 'hunterwater') || (tenantConfig.slug == 'demo-tenant')) { // view map
    listVisualizationType.push({value: 'current_water_outages', label: 'Current water outages'},
    {value: 'trade_waste_discharge', label: 'Trade waste discharge'},
    {value: 'recycled_water_demand', label: 'Recycled water demand'})
  }

  if (
    !visible ||
    !pathOr(
      true,
      ["page_settings", "map", "heat_map_visualisation", "visible"],
      tenantConfig
    )
  )
    return null;

  return (
    <div className="map__filter map__filter__customer_visualization" style={{zIndex: "3"}}>
      <div className="map__layer">
        <div className="btn btn-default block-center-between extended">
          <span className="ml-2 mr-2 mr-sm-4">Customer visualization</span>
        </div>
      </div>
      <div className="map__filter-box active">
        <div className="map__filter-box-inner">
          <div className="map__filter-content">
            <div className="map__filter-option">
            <Select
              value={visualizationType}
              onChange={onChange}
              options={listVisualizationType}
              menuPortalTarget={document.body} 
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
            </div>
            <div className="map__filter-option">
              <div className="form-check d-flex align-items-center checkbox">
                <label
                  className="form-check-label"
                  htmlFor="display_elevation_layer"
                  style={{
                    paddingLeft: "1rem",
                    marginTop: 0,
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="display_elevation_layer"
                    defaultChecked={isDisplayElevation}
                    onChange={(event) =>
                      onElevationLayerChange &&
                      onElevationLayerChange(getTargetChecked(event))
                    }
                  />
                  <span className="ck"></span>
                  Elevation layer
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerVisualization;
