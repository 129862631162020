import { prop } from 'ramda'
import {
  queryCustomerSiteContact,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const queryCustomerSiteContactData = siteId => async (page, limit, search, searchIn, sort) => {
  const result = await queryCustomerSiteContact(siteId)
    ({
      page,
      limit,
      search,
      searchIn,
      ...sort
    })
  return isServerResponseError(result) || hasError(result)
    ? {
      total: 0,
      data: []
    }
    : prop('data', result)
}

export default queryCustomerSiteContactData