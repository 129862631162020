import {getAlerts} from '../../services/apis'
import {hasError, isServerResponseError} from '../common'
import {prop} from 'ramda'

const getAlertsData = async (page, limit, searchData, orderBy) => {
  const result = await getAlerts({page, limit, orderBy, ...searchData})
  return isServerResponseError(result) || hasError(result)
    ? {
      total: 0,
      data: []
    }
    : prop('data', result)
}

export default getAlertsData
