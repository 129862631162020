import { pathOr } from "ramda";
import React, { useState, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";

import { inputChangeHandler, useProgressing } from "../../utils/react_utils";
import {
  FormContainer,
  SpinnerButton,
  Textbox,
} from "src/main_app/components/form_elements";
import { PageHeading } from "../../components";
import addUser from "../../services/apis/add-user";
import {
  hasError,
  isNotEmpty,
  isServerResponseError,
  filterByPropEquals,
} from "../../utils/common";

import { track } from "src/main_app/mixpanel";
import UserRoles from "./user-roles";
import { checkPermission, useUserPermissions } from "src/main_app/actions";

const UserAddPage = (props) => {
  useEffect(() => {
    track("Add user page");
  }, []);
  const { addToast } = useToasts();
  const history = useHistory();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    roles: [],
  });
  const [isProgressing, startProgress, endProgress] = useProgressing(600);
  const onChange = inputChangeHandler(formData, setFormData);

  const [errorMessage, setErrorMessage] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "users-page", "canView"),
    [userPermissions]
  );
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const submitHandler = async (event) => {
    event.preventDefault();

    if (
      formData.roles.filter((i) => i.role_id === 0).length ===
      formData.roles.length
    ) {
      setErrorMessage("Role is mandatory field");
      return;
    }

    if (
      formData.roles.filter((i) => i.tenant_id === 0).length ===
      formData.roles.length
    ) {
      setErrorMessage("Tenant is mandatory field");
      return;
    }

    startProgress();
    const response = await addUser(formData);
    endProgress();

    if (isServerResponseError(response)) {
      setErrorMessage("Server error, please retry later.");
    } else if (hasError(response)) {
      const messages = pathOr([], ["data", "error", "messages"], response);
      const errorMessage = filterByPropEquals("", "email", "field")(messages);
      if (isNotEmpty(errorMessage))
        setEmailError(pathOr("", [0, "message"], errorMessage));
      else setErrorMessage(pathOr("", [0, "message"], messages));
    } else {
      setEmailError(null);
      setErrorMessage(null);
      addToast("Add successfully.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setTimeout(() => {
        history.push("/users");
      }, 1500);
    }
  };

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      <PageHeading title="Add a user" />
      <div className="container-fluid content">
        <div className="row">
          <div className="col">
            <h3>User details</h3>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormContainer
              disabled={isProgressing}
              className="add-user"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="col-md-6">
                  <Textbox
                    controlId="firstName"
                    name="first_name"
                    label="First name"
                    placeholder="Enter first name"
                    value={formData.first_name}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <Textbox
                    controlId="lastName"
                    name="last_name"
                    label="Last name"
                    placeholder="Enter last name"
                    value={formData.last_name}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Textbox
                    controlId="email"
                    name="email"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={onChange}
                  >
                    <Form.Text className="add-user-error">
                      {emailError}
                    </Form.Text>
                  </Textbox>
                </div>
              </div>
              <UserRoles
                currentRoles={formData.roles}
                onChange={(roles) =>
                  setFormData({
                    ...formData,
                    roles,
                  })
                }
              />
              <SpinnerButton
                type="submit"
                isProgressing={isProgressing}
                className="btn btn-primary btn--save"
              >
                ADD USERS
              </SpinnerButton>
            </FormContainer>
            {isNotEmpty(errorMessage) ? (
              <div className="add-user-error">
                <small>{errorMessage}</small>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAddPage;
