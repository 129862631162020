import { map, tap } from "ramda";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { ExportWemps, ExportGresb, ExportWaterUsageByZone, ExportNotes } from "src/main_app/components/report-export";
import { useTenantConfig } from "src/main_app/actions";
import { useUserPermissions, checkPermission, userRoleReducer } from "src/main_app/actions";
import axios from 'axios';
import { getApiUrl } from 'src/main_app/utils/config-utility';
import { v4 as uuidv4 } from 'uuid';
import { authService } from "src/base/services";

const ReportPageExport = () => {
  const [displayModalReportActions, setDisplayModaUpReportActions] = useState(false);
  const [displayModalReportGresb, setDisplayModaUpReportGresb] = useState(false);
  const [displayModalReportWaterUsageByZone, setDisplayModalReportWaterUsageByZone] = useState(false);
  const [displayModalExportNotes, setDisplayModalExportNotes] = useState(false);
  const tenantConfig = useTenantConfig();
  const userPermissions = useUserPermissions();

  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "report-page", "canView"),
    [userPermissions]
  );

  const showModalReportActions = useCallback(
    () => setDisplayModaUpReportActions(true),
    [setDisplayModaUpReportActions]
  );
  const handleReportActionsClose = useCallback(
    () => setDisplayModaUpReportActions(false),
    [setDisplayModaUpReportActions]
  );

  const showModalReportGresb = useCallback(
    () => setDisplayModaUpReportGresb(true),
    [setDisplayModaUpReportGresb]
  );
  const handleReportGresbClose = useCallback(
    () => setDisplayModaUpReportGresb(false),
    [setDisplayModaUpReportGresb]
  );

  const showModalReportWaterByZone = useCallback(
    () => setDisplayModalReportWaterUsageByZone(true),
    [setDisplayModalReportWaterUsageByZone]
  );
  const handleReportWaterUsageByZoneClose = useCallback(
    () => setDisplayModalReportWaterUsageByZone(false),
    [setDisplayModalReportWaterUsageByZone]
  );

  const showModaExportNotes = useCallback(
    () => setDisplayModalExportNotes(true),
    [setDisplayModalExportNotes]
  );
  const handleExportNotesClose = useCallback(
    () => setDisplayModalExportNotes(false),
    [setDisplayModalExportNotes]
  );


  const exportTradeWaste  = async () => {
    axios.get(`${getApiUrl()}/export/report-trade-waste-discharge-factor/csv?&token=${authService.getAccessToken()}`, {
      responseType: 'blob'
    }).then(
        res => saveAs(res.data, "Discharge factors.csv")
    )
  }


  const history = useHistory();

  return userPermissions.length === 0 || !canViewThisPage ? null : (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">Reports</h2>
        </div>
      </div>
      <div className="container-fluid content">
        <>
            <div className="table-responsive">
                <table className="table" id="user-list-table">
                <thead>
                    <tr>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                
                {
                  (tenantConfig.listReportField || []).map( field => {
                      if (field === 'GRESB') return (
                        <tr key={uuidv4()}>
                          <td onClick={showModalReportGresb}>{field}</td>
                        </tr>
                      )
                      if (field === 'Actions / WEMPS') return (
                        <tr key={uuidv4()}>
                          <td onClick={showModalReportActions}>{field}</td>
                        </tr>
                      )
                      if (field === 'Discharge factors') return (
                        <tr key={uuidv4()}>
                          <td onClick={exportTradeWaste}>{field}</td>
                        </tr>
                      )
                      if (field === 'Water usage by zone') return (
                        <tr key={uuidv4()}>
                          <td onClick={showModalReportWaterByZone}>{field}</td>
                        </tr>
                      )
                      if (field === 'Export notes') return (
                        <tr key={uuidv4()}>
                          <td onClick={showModaExportNotes}>{field}</td>
                        </tr>
                      )
                  })
                }
                </tbody>
                </table>
            </div>
            <ExportWemps
                showModal={displayModalReportActions}
                handleActionsClose={handleReportActionsClose}
            />

            <ExportGresb
                showModal={displayModalReportGresb}
                handleActionsClose={handleReportGresbClose}
            />

            <ExportWaterUsageByZone
                showModal={displayModalReportWaterUsageByZone}
                handleActionsClose={handleReportWaterUsageByZoneClose}
            />

            <ExportNotes
                showModal={displayModalExportNotes}
                handleExportClose={handleExportNotesClose}
            />
        </>
      </div>
    </div>
  );
};

export default ReportPageExport;


