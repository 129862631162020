import React from 'react'
import {
  VerificationForm,
} from '../components/user'

const VerificationPage = (props) => {
  return (
    <div className="col-sm-6 sign-in__body">
      <VerificationForm/>
    </div>
  )
}

export default VerificationPage
