import React, { useEffect, useState } from "react";
import ReactMapGL, {
  Popup,
  WebMercatorViewport,
  FlyToInterpolator,
  NavigationControl,
  Source,
  Layer,
} from "react-map-gl";

import { getMapboxAccessKey } from "../utils/config-utility";

const mapboxAccessKey = getMapboxAccessKey();

const TestPage = (props) => {
  const [viewport, setViewport] = useState({
    width: 600,
    height: 600,
    mapStyle: "mapbox://styles/mapbox/light-v10",
    zoom: 13,
    longitude: 152.45032825036722,
    latitude: -32.3103038609632,
  });
  const geojson = {
    type: "vector",
    // Use any Mapbox-hosted tileset using its tileset id.
    // Learn more about where to find a tileset id:
    // https://docs.mapbox.com/help/glossary/tileset-id/
    url: "mapbox://mapbox.mapbox-terrain-v2",
  };

  const layerStyle = {
    id: "terrain-data",
    type: "line",
    source: "mapbox-terrain",
    "source-layer": "contour",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#ff69b4",
      "line-width": 1,
    },
  };

  return (
    <div
      className="content"
      style={{
        height: "800px",
        width: "600px",
      }}
    >
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={mapboxAccessKey}
        maxZoom={20}
      >
        <Source
          id="my-data"
          type="vector"
          url={"mapbox://mapbox.mapbox-terrain-v2"}
        >
          <Layer {...layerStyle} />
        </Source>
      </ReactMapGL>
    </div>
  );
};

export default TestPage;
