import { userAuthHeader } from 'src/main_app/utils/user_utils/user-auth-header'
import apiPostRequest from './api-post-request'

const postWithToken = apiPath => (data, config = {}) => apiPostRequest(apiPath)(
    data,
    {
        ...config,
        headers: userAuthHeader,
    },
)

export default postWithToken