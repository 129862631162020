import React, {
  useMemo,
} from 'react'
import { propOr } from 'ramda'
import { useTenantConfig } from "src/main_app/actions";
import { v4 as uuidv4 } from 'uuid';

const CustomerCategories = ({ customerTaxonomies = [] }) => {

  const newArrayIndustry = customerTaxonomies.filter(function (el) {
    return el.parent_taxonomy_name === "industry";
  });
  const style = useMemo(
    () => ({
      textTransform: 'capitalize',
      fontWeight: '700',
    }),
    [],
  )

  const tenantConfig = useTenantConfig();

  return (
    <>
      <h3 className="sub-title">Categories</h3>
      <div className="box">
        <table border="0" cellPadding="0" cellSpacing="0">
          <tbody>
          {
            (tenantConfig.customerTaxonomyFieldCategories || []).map( field => {
                if (field === 'Type') return (
                  <tr key={uuidv4()}>
                    <td className="pr-4" style={style}>{field}</td>
                    {customerTaxonomies.map((taxonomy, index) => {
                      if (taxonomy.parent_taxonomy_name === "type") return (
                        <td key={uuidv4()}>{taxonomy.taxonomy_label}</td>
                        )
                      }
                    )}
                  </tr>
                )
                if (field === 'Industry') return (
                  <tr key={uuidv4()}>
                    <td className="pr-4" style={style}>{field}</td>
                    <td key={uuidv4()}>
                    {newArrayIndustry.map((taxonomy, index) => {
                      if (taxonomy.parent_taxonomy_name === "industry") return (
                        `${taxonomy.taxonomy_label}${index+1 == newArrayIndustry.length ? '' : ','} `
                        )
                      }
                    )}
                    </td>
                  </tr>
                )
                if (field === 'LGA') return (
                  <tr key={uuidv4()}>
                    <td className="pr-4" style={style}>{field}</td>
                    {customerTaxonomies.map((taxonomy, index) => {
                      if (taxonomy.parent_taxonomy_name === "lga") return (
                        <td key={uuidv4()}>{taxonomy.taxonomy_label !== "Other" ? taxonomy.taxonomy_label : ''}</td>
                        )
                      }
                    )}
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CustomerCategories
