import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "../../components/header";
import Footer from "../../components/footer";

import { authService } from "../../../base/services";
import { track } from "src/main_app/mixpanel";

const DefaultLayout = (props) => {
  const {
    routes = {},
    component: ComponentPage,
    fetchTenantConfig,
    getUserPermissionAsync,
    ...rest
  } = props;

  if (!authService.isAuthenticated()) {
    const isRedirectToLoginPage =
      props.requireLogin && window.location.pathname !== "/sign-in";
    if (isRedirectToLoginPage) {
      return <Redirect to={`/sign-in?url=${location.pathname}`} />;
    }
  }

  useEffect(() => {
    if (authService.isAuthenticated()) {
      fetchTenantConfig();
      getUserPermissionAsync();
    }
  }, []);

  useEffect(() => {
    const tenantInfo = authService.getTenantInfo();
    track("Viewed page", {
      tenantId: tenantInfo.id,
      tenantName: tenantInfo.name,
    });
  }, []);

  return (
    <div className="app">
      <Header />
      <div className="app__main">
        <Route
          {...rest}
          render={(routeProps) => (
            <ComponentPage routes={routes} {...routeProps} />
          )}
        />
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
