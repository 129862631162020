import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTenantConfig } from "src/main_app/actions";
import { v4 as uuidv4 } from 'uuid';
import { authService } from "../../../base/services";
const InsightsNav = () => {
  const tenantConfig = useTenantConfig();
  return (
    <nav className="customer-nav">
      <ul className="nav">
          {
            (tenantConfig.insightsNav || []).map( field => {
                // if (field === 'Real-time water usage') return (
                //   <li key={uuidv4()} className="nav-item">
                //     <NavLink className="nav-link " to={`/insights/real-time-water-usage`} activeClassName="active" exact={true}>{field}</NavLink>
                //   </li>
                // )
                // if (field === 'Metered water usage') return (
                //   <li key={uuidv4()} className="nav-item">
                //     <NavLink className="nav-link" to={`/insights/metered-water-usage`} activeClassName="active">{field}</NavLink>
                //   </li>
                // )
                if (field === 'Data validation') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/insights/data-validation`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                // if (field === 'Water balance') return (
                //   <li key={uuidv4()} className="nav-item">
                //     <NavLink className="nav-link" to={`/insights/water-balance`} activeClassName="active">{field}</NavLink>
                //   </li>
                // )
                // if (field === 'TV app') return (
                //   <li key={uuidv4()} className="nav-item">
                //     <NavLink className="nav-link" to={`/insights/tv-app`} activeClassName="active">{field}</NavLink>
                //   </li>
                // )
            })
          }
      </ul>
    </nav>
  )
}

export default InsightsNav
