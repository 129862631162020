import React, { useState } from 'react'

const useError = (
  defaultMessage = ''
) => {
  const [error, setError] = useState(defaultMessage)
  return [
    error
      ? (
        <span
          className="invalid-feedback"
        >
          {error}
        </span>
      )
      : null,
    setError
  ]
}

export default useError