import { map, tap } from "ramda";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { compose } from "redux";
import { isNotEmpty } from "src/main_app/utils/common";

import { Pager, UsersListTable } from "../../components";
import { getUsersListData } from "../../utils/user_utils";
import UsersPageSkeleton from "./users-page-skeleton";

import { track } from "src/main_app/mixpanel";
import { checkPermission, useUserPermissions } from "src/main_app/actions";

const UsersPage = ({ userRoles, getUserRolesAsync }) => {
  useEffect(() => {
    track("User list page");
  }, []);
  const history = useHistory();
  const userTableHeaders = ["Name", "Email", "Role", "Date added"];
  const [total, setTotal] = useState(0);
  const [limit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [userTableData, setTableData] = useState([]);
  const [isLoadingUserDone, setIsLoadingUserDone] = useState(false);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "users-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const canEdit = useMemo(
    () => checkPermission(userPermissions, "users-page", "canEdit"),
    [userPermissions]
  );

  const canDelete = useMemo(
    () => checkPermission(userPermissions, "users-page", "canDelete"),
    [userPermissions]
  );

  useEffect(() => {
    getUserRolesAsync();
  }, []);

  useEffect(() => {
    if (isNotEmpty(userRoles))
      onPageChange(1).then(() => setIsLoadingUserDone(true));
  }, [userRoles]);

  const onPageChange = async (pageNo = 1) => {
    const { total, data } = await getUsersListData(pageNo, limit);

    setCurrentPage(pageNo);
    setTotal(total);

    compose(
      setTableData,
      // tap(console.log),
      map((user) => {
        const role = userRoles.find((i) => i.id == user.role_id);
        return {
          ...user,
          role_name: role ? role.name : "",
        };
      })
    )(data);
  };

  const refreshData = () => onPageChange(currentPage);

  const addUser = useCallback((event) => {
    history.push("/user/add");
  }, []);

  return (userPermissions.length === 0 || !canViewThisPage) ? null : isLoadingUserDone ? (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">Users</h2>
          {canEdit && (
            <button className="btn-text f-14 secondary-color" onClick={addUser}>
              <i className="far fa-plus-circle mr-2" />
              Add user
            </button>
          )}
        </div>
      </div>
      <div className="container-fluid content">
        <UsersListTable
          id="user-list-table"
          headers={userTableHeaders}
          items={userTableData}
          refreshData={refreshData}
          canEdit={canEdit}
          canDelete={canDelete}
        />
        <Pager
          onPageChange={onPageChange}
          total={total}
          limit={limit}
          currentPage={currentPage}
        />
      </div>
    </div>
  ) : (
    <UsersPageSkeleton headers={userTableHeaders} numberOfItems={limit} />
  );
};

export default UsersPage;
