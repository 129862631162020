import bootstrap from "../base/bootstrap";

import "./styles/main.scss";
import store from "./store";
import App from "./app";

import logoutOn401 from "./logout-on-401";
import startMixpanel from "./mixpanel";

logoutOn401();
startMixpanel();
bootstrap(App, store);
