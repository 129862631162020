import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const OtherRecipientsTooltip = ({
  itemID,
  recipients,
}) => {

  function renderTooltip(recipients) {

    const tooltipRecipients = recipients.slice(2)
    return (
      <Tooltip
        style={{
          textAlign: 'center',
        }}
        id={`recipients-tooltip-${itemID}`}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {
            tooltipRecipients.map((tooltipRecipient, key) => {
              return (
                <div key={key}>
                  <div>{`${tooltipRecipient.name}`}</div>
                  <div>{`(${tooltipRecipient.email}) `}</div>
                </div>
              )
            })
          }
        </div>
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(recipients)}
    >
      <span style={{
        textDecoration: 'underline',
        cursor: 'pointer'
      }}>
        {`and ${recipients.length - 2} others`}
      </span>
    </OverlayTrigger>
  )
}

export default OtherRecipientsTooltip
