import React from 'react'
import { NavLink } from 'react-router-dom';
import { useTenantConfig } from "src/main_app/actions";
import { v4 as uuidv4 } from 'uuid';
import { authService } from "../../../base/services";
const SettingNav = () => {
  const tenantConfig = useTenantConfig();
  const user = authService.getUser();
  return (
    <nav className="customer-nav">
      <ul className="nav">
          {
            (tenantConfig.settingsNav || []).map( field => {
                if (field === 'Notifications') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link " to={`/settings/notifications`} activeClassName="active" exact={true}>{field}</NavLink>
                  </li>
                )
                if (field === 'Customer Login') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/settings/customer-login`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Contact Address') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/settings/contact-address`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Roles') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/settings/roles`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Configuration') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/settings/configuration`} activeClassName="active">{field}</NavLink>
                  </li>
                )
                if (field === 'Provide Feedback') return (
                  <li key={uuidv4()} className="nav-item">
                    <NavLink className="nav-link" to={`/settings/provide-feedback`} activeClassName="active">{field}</NavLink>
                  </li>
                )
            })
          }
      </ul>
    </nav>
  )
}

export default SettingNav
