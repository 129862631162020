import {
  customerUpdateNote
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const updateNote = async (id, data) => {
  const response = await customerUpdateNote(id, data)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default updateNote