import defaultRoutes from './default-routes'
import normalUserRoutes from './normal-user-routes'
import managementRoutes from './management-routes'

export const getRoutes = roleId => {
    const routesByRole = [
        // config logged routes here
    ]
    const allRoutes = [
        ...defaultRoutes,
        ...normalUserRoutes,
        ...managementRoutes,
        ...routesByRole
    ]
    return allRoutes

    // const routes = roleId
    //   ? allRoutes
    //   : allRoutes.filter(i => i.path === '/sign-in' || !i.requireLogin)
    // return routes
}