import React from "react";
import { isEmpty, propOr } from "ramda";
import { isNilOrEmpty } from "src/main_app/utils/common";

const IndustrySection = ({ section }) => {
  const sectionRows = (section) => {
    let count = 0;
    let sectionRows = [];
    let sectionRow = [];
    propOr([], "fields", section).forEach((field) => {
      if (count + field.layout_column > 12) {
        sectionRows.push(sectionRow);
        sectionRow = [];
        count = field.layout_column;
      } else {
        count += field.layout_column;
      }
      sectionRow.push(field);
    });
    if (!isEmpty(sectionRow)) sectionRows.push(sectionRow);
    return sectionRows;
  };

  return (
    <>
      <h3 className="sub-title mt-4">{section.sector_label}</h3>
      {sectionRows(section).map((row, rowIndex) => {
        return (
          <div className="row" key={rowIndex}>
            {row.map((field, fieldIndex) => {
              let val = field.data.customerName || field.data.value || "";
              if (!isNilOrEmpty(val) && !isNilOrEmpty(field.unit))
                val += field.unit;
              return (
                <div
                  className={`col-md-${field.layout_column}`}
                  key={fieldIndex}
                >
                  <div className="form-group">
                    <label>{field.label}</label>
                    <input
                      className="form-control"
                      value={val}
                      disabled={true}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default IndustrySection;
