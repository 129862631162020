import React, {
  useState,
} from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete'
import {Form} from 'react-bootstrap'
import {upperCaseFirstChar} from '../../utils/string_utils'

const AddressAutoComplete = ({
  controlId,
  name,
  label,
  currentAddress,
  onAddressUpdate,
  roleName
}) => {
  const [address, setAddress] = useState(currentAddress)
  const handleChange = address => {
    setAddress(address)
  }

  const handleSelect = address => {
    setAddress(address)
    geocodeByAddress(address).
      then(async results => {
        const geocode = results[0]
        const latLng = await getLatLng(geocode)
        return {
          addressObj: geocode,
          latLng: latLng
        }
      }).
      then(geoInfo => {
        onAddressUpdate({
          address,
          ...geoInfo
        })
      }).
      catch(error => console.error('Error', error))
  }

  return (
    <Form.Group
      className="address-autocomplete"
      controlId={controlId}
    >
      <Form.Label>{label || upperCaseFirstChar(name)}</Form.Label>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <div>
            {roleName === "Super Administrator" ?
              <input
                className="form-control"
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
              />
            : 
            <input
                className="form-control"
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
                disabled
              />
            }
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, key) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                  : {backgroundColor: '#ffffff', cursor: 'pointer'}
                return (
                  <div
                    key={key}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Form.Group>
  )
}

export default AddressAutoComplete
