import {
    compose,
    pathOr,
} from "ramda"
import filterValidMarker from "./filter-valid-marker"

const getThenSetMarkersData = (getFunc, setFunc, status) => {
    if (status) {
        getFunc()
            .then(
                compose(
                    setFunc,
                    filterValidMarker,
                    pathOr([], ['data', 'data'])
                )
            )
    }
    else {
        setFunc([])
    }
}

export default getThenSetMarkersData