const MAX_ZOOM_LEVEL = 18

const heatMapRecycleWaterDemandLayer = {
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    // increase weight as diameter breast height increases
    'heatmap-weight': {
      'property': 'wupd',
      'type': 'exponential',
      'stops': [
        [1, 0],
        [62, 1]
      ]
    },
    // increase intensity as zoom level increases
    'heatmap-intensity': {
      'stops': [
        [11, 1],
        [18, 3]
      ]
    },
    // use sequential color palette to use exponentially as the weight increases
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(236,222,239,0)',
      0.1,
      'rgba(241,231,254,30)',
      0.2,
      'rgba(213,184,255,100)',
      0.4,
      'rgba(165,55,253,50)',
      0.6,
      'rgba(191,85,236,100)',
      0.8,
      'rgba(140,20,252,40)',
      1,
      'rgba(90,34,139,100)'
    ],
    // increase radius as zoom increases
    'heatmap-radius': {
      'stops': [
        [11, 18],
        [18, 20]
      ]
    },
    // decrease opacity to transition into the circle layer
    'heatmap-opacity': {
      'default': 1,
      'stops': [
        [14, 1],
        [18, 0]
      ]
    }
  }
}

export default heatMapRecycleWaterDemandLayer
