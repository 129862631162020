import {
  pathOr,
} from 'ramda'
import {
  getCustomersDetail,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getCustomerData = async id => {
  const result = await getCustomersDetail(id)
  return isServerResponseError(result) || hasError(result)
    ? {}
    : pathOr({}, ['data', 'data'], result)
}

export default getCustomerData