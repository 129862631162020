import {
  customerUpdate
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const updateCustomer = async (id, data) => {
  const response = await customerUpdate(id, data)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default updateCustomer