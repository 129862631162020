import {
    compose,
    filter,
    propOr,
} from "ramda"

import { isTrue } from "../common"

const filterRemovedItems = filter(
    compose(
        isTrue,
        propOr(false, 'isRemoved')
    )
)

export default filterRemovedItems