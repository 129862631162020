import { props } from 'ramda'
import React from 'react'
import {
    Form,
} from 'react-bootstrap'

const FormContainer = ({
    disabled,
    children,
    ...props
}) => (
    <Form {...props}>
        <fieldset disabled={disabled}>
            {children}
        </fieldset>
    </Form>
)

export default FormContainer