import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dropdown, Button, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";

import {
  formatNumber,
  isNilOrEmpty,
  isNotNil,
} from "src/main_app/utils/common";
import { getAlertsData } from "src/main_app/utils/customer_utils";
import { updateArchiveAlert } from "src/main_app/utils/customer_utils";

import alertTypes from "./helpers/alert-types";
import { Pager } from "src/main_app/components";
import { AddNote } from "src/main_app/components/customer-right-menu/modals";
import SendMail from "src/main_app/components/customer-right-menu/modals/send_mail/send-mail";
import { isNil, propOr } from "ramda";
import { useTenantConfig } from "src/main_app/actions";
import { authService } from "../../../base/services";
import { ArchiveAllAlert, getRoleName } from 'src/main_app/services/apis';

const AlertList = ({ limit, total, getSearchData, onTabCustomerID, loadData, nightFlowAlert }, ref) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const tenantConfig = useTenantConfig()
  const [roleName, setRoleName] =  useState();

  const allowAlertTypes = useMemo(() => propOr([], 'allowAlertTypes', tenantConfig), [tenantConfig])
  const user = authService.getUser();
  const [alerts, setAlerts] = useState([]);
  const [alertType, setAlertType] = useState(isNil(onTabCustomerID) ? 'base_flow' : null);
  const [orderBy, setOrderBy] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [customerID, setCustomerID] = useState(null);

  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const displaySeverity = useCallback((alert) => {
    return (
      <span className={`alert-severity alert-severity--${alert.severity}`}>
        {alert.severity}
      </span>
    );
  }, []);

  const displayAlertType = useCallback((alert) => {
    return (
      <span className={`alert-type alert-type--${alert.type}`}>
        {(alertTypes.find((item) => item.name === alert.type) ?? {}).label}{" "}
        <OverlayTrigger overlay={<Tooltip>{alert.content}</Tooltip>}>
          <i className="fas fa-info-circle"></i>
        </OverlayTrigger>
      </span>
    );
  }, []);

  const displayAlertValueInPercent = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        return alert.roundedNightUsagePercent + "%";
      case "data_loss":
        return "0%";
      case "peak_flow":
        return alert.valueInPercent + "%";
      case "step_change":
        return alert.valueInPercent + "%";
      default:
        return "-";
    }
  }, []);

  const displayAlertValue = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        const value = alert.nightUsage
          ? (alert.nightUsage * 1000) / (60 * 60 * 2)
          : alert.value;
        return (
          <React.Fragment>
            {isNilOrEmpty(value) ? "" : Math.ceil(value * 10) / 10}{" "}
            <i
              className={[
                "fas fa-arrow-up",
                alert.value < alert.valueOfPreviousDay
                  ? "trending-down rotate-135deg"
                  : alert.value > alert.valueOfPreviousDay
                  ? "trending-up rotate-45deg"
                  : "trending-normal rotate-90deg",
              ].join(" ")}
            ></i>
          </React.Fragment>
        );
      case "peak_flow":
        return (
          <React.Fragment>
            {isNilOrEmpty(alert.value) ? "" : Math.ceil(alert.value * 10) / 10}{" "}
            <i
              className={[
                "fas fa-arrow-up",
                alert.value < alert.valueOfPreviousDay
                  ? "trending-down rotate-135deg"
                  : alert.value > alert.valueOfPreviousDay
                  ? "trending-up rotate-45deg"
                  : "trending-normal rotate-90deg",
              ].join(" ")}
            ></i>
          </React.Fragment>
        );
      case "step_change":
        return (
          <React.Fragment>
            {isNilOrEmpty(alert.value)
              ? ""
              : Math.ceil(((alert.value * 1000) / (7 * 24 * 60 * 60)) * 10) /
                10}{" "}
            <i
              className={[
                "fas fa-arrow-up",
                alert.value < alert.valueOfPreviousDay
                  ? "trending-down rotate-135deg"
                  : alert.value > alert.valueOfPreviousDay
                  ? "trending-up rotate-45deg"
                  : "trending-normal rotate-90deg",
              ].join(" ")}
            ></i>
          </React.Fragment>
        );
      case "water_quality":
        return <React.Fragment>{alert.value}</React.Fragment>;
      case "base_flow":
        return <React.Fragment>{alert.value}</React.Fragment>;
    }
  }, []);

  const displayAlertTime = useCallback((alert) => {
    return moment(alert.date).format("DD MMM yyyy");
  }, []);

  const handleAddNoteShow = (id) => (event) => {
    event.stopPropagation();
    setCustomerID(id);
    setShowAddNoteModal(true);
  };
  const handleAddNoteClose = () => setShowAddNoteModal(false);

  const handleSendMailShow = (id) => (event) => {
    event.stopPropagation();
    setCustomerID(id);
    setShowSendMailModal(true);
  };

  const handleSendMailClose = () => setShowSendMailModal(false);

  const handleRedirect = useCallback(
    (type, id) => (event) => {
      event.stopPropagation();
      switch (type) {
        case "overview":
          if (isNil(onTabCustomerID)) {
            history.push(`customer/${id}/overview`);
          } else {
            history.push(`overview`);
          }
          break;
        case "usage":
          if (isNil(onTabCustomerID)) {
            history.push(`customer/${id}/usage`);
          } else {
            history.push(`usage`);
          }
          break;
      }
    },
    []
  );

  const handleAlertArchive = (id) => async (event) => {
    event.stopPropagation();
    addToast("Alert archived", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    await updateArchiveAlert(id);
    const searchData = getSearchData({
      ...filteredData,
      alertType,
    });
    await loadAlertsData(currentPage, searchData);
    await loadData(filteredData);
  };

  const loadAlertsData = async (currentPage, searchData) => {
    const result = await getAlertsData(currentPage, limit, searchData, orderBy);
     setIsCheck([]);
     setIsCheckAll(false);
    setAlerts(result.data);
  };

  const onAlertTypeChange = (newAlertType) => () => {
    setAlertType(newAlertType);
    nightFlowAlert(newAlertType)
    setCurrentPage(1);
  };

  const onOrderChange = (newOrder) => () => {
    setOrderBy([
      newOrder,
      orderBy[0] !== newOrder ? "asc" : orderBy[1] === "asc" ? "desc" : "asc",
    ]);
    setCurrentPage(1);
  };

  const getTableColClass = (colName) =>
    [
      "allow-order",
      orderBy[0] === colName ? (orderBy[1] === "desc" ? "order-desc" : "") : "",
    ].join(" ");

  useImperativeHandle(ref, () => ({
    setFilteredData(newFilteredData) {
      setFilteredData(newFilteredData);
      setCurrentPage(1);
    },
  }));


  const getRoleNameUser = async () => {
    const roleNameUserLogin = await getRoleName();
    setRoleName(propOr([], 'data', roleNameUserLogin))
  }

  const handleAllChecked = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(alerts.map(alert => alert.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  }

  const deleteNoHandler = () => setShowConfirmDialog(false);
  const adminArchiveAllAlertHandler = (event) => {
    setShowConfirmDialog(true);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    let dataCheckBox = [...isCheck, parseInt(id)].length
    setIsCheck([...isCheck, parseInt(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== parseInt(id)));
      dataCheckBox = isCheck.filter(item => item !== parseInt(id));
    }

    const lengthAlerts = alerts.length;
    if (dataCheckBox == lengthAlerts) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const submit = async (event) => {
    event.stopPropagation();
    addToast("Alert archived", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    const res = await ArchiveAllAlert(isCheck);

    const searchData = getSearchData({
      ...filteredData,
      alertType,
    });
    setIsCheck([]);
    setIsCheckAll(false);
    setShowConfirmDialog(false);
    await loadAlertsData(currentPage, searchData);
    await loadData(filteredData);
  }

  useEffect(() => {
    getRoleNameUser();
  }, []);

  useEffect(() => {
    if (isNilOrEmpty(filteredData)) return;
    if (isNil(alertType)) {
      const searchData = getSearchData({
        ...filteredData,
      });
     loadAlertsData(currentPage, searchData);
    } else {
      const searchData = getSearchData({
        ...filteredData,
        alertType,
      });
      loadAlertsData(currentPage, searchData);
    }

  }, [filteredData, alertType, orderBy, currentPage]);

  return (
    <React.Fragment>
      <div className="mt-5">
        <div className="row">
        <div className="d-flex mb-3 col-md-10">
          {alertTypes
            .filter(alertTypeItem => allowAlertTypes.some(allowAlertType => allowAlertType === alertTypeItem.name))
            .map((alert) => (
            <div>
              {/* check hide water_quality for type user Customer */}
              {roleName == "Customer" && alert.label != "Water quality" &&
                <div
                  key={alert.name}
                  className={[
                    "total-alert",
                    alertType === alert.name ? "active" : "",
                  ].join(" ")}
                  onClick={onAlertTypeChange(alert.name)}
                >
                  {alert.label} <span>{formatNumber(total[alert.name] ?? 0)}</span>
                </div>
              }
              {roleName != "Customer" &&
                <div
                  key={alert.name}
                  className={[
                    "total-alert",
                    alertType === alert.name ? "active" : "",
                  ].join(" ")}
                  onClick={onAlertTypeChange(alert.name)}
                >
                  {alert.label} <span>{formatNumber(total[alert.name] ?? 0)}</span>
                </div>
              }
              
            </div>
          ))}
          <div
            className={["total-alert", alertType === null ? "active" : ""].join(
              " "
            )}
            onClick={onAlertTypeChange(null)}
          >
            All alerts <span>{formatNumber(total.all)}</span>
          </div>
        </div>
        {((roleName == "Super Administrator") || (roleName == "Administrator")) &&
          <div className="col-md-2 text-right">
              <a className="btn btn--save btn-primary" onClick={adminArchiveAllAlertHandler}>Archive alerts</a>
          </div>
        }
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Customer</th>
                <th scope="col">Customer type</th>
                <th
                  scope="col"
                  className={getTableColClass("severity")}
                  onClick={onOrderChange("severity")}
                >
                  Severity{" "}
                  {orderBy[0] === "severity" && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </th>
                <th
                  scope="col"
                  className={getTableColClass("alertType")}
                  onClick={onOrderChange("alertType")}
                >
                  Alert type{" "}
                  {orderBy[0] === "alertType" && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </th>
                <th
                  scope="col"
                  className={getTableColClass("alertValueInPercent")}
                  onClick={onOrderChange("alertValueInPercent")}
                >
                  Value{" "}
                  {orderBy[0] === "alertValueInPercent" && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </th>
                <th
                  scope="col"
                  className={getTableColClass("alertValue")}
                  onClick={onOrderChange("alertValue")}
                >
                  L/s{" "}
                  {orderBy[0] === "alertValue" && (
                    <i className="fas fa-long-arrow-alt-up"></i>
                  )}
                </th>
                <th scope="col">Date</th>
                <th scope="col">&nbsp;</th>
                {((roleName == "Super Administrator") || (roleName == "Administrator")) &&
                  <th scope="col">
                    <input
                      style={{accentColor: "#4d667f"}}
                      type="checkbox"
                      onClick={handleAllChecked}
                      checked={isCheckAll}
                    />
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert, index) => (
                <tr key={alert.id}>
                  <td scope="col" className="customer-info customer-name">
                    <span>{alert.display_name}</span>
                  </td>
                  <td
                    scope="col"
                    className="customer-info customer-critical-class"
                  >
                    {alert.critical_class}
                  </td>
                  <td scope="col">{displaySeverity(alert)}</td>
                  <td scope="col">{displayAlertType(alert)}</td>
                  <td scope="col text-right">
                    {displayAlertValueInPercent(alert)}
                  </td>
                  <td scope="col text-right">{displayAlertValue(alert)}</td>
                  <td scope="col">{displayAlertTime(alert)}</td>
                  <td scope="col">
                    {roleName != "Customer" &&
                      <span className="alert-ellipsis alert-float-right">
                      <Dropdown>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Dropdown.Toggle
                            variant=""
                            className="btn-text dropdown-toggle"
                          >
                            <i
                              style={{
                                width: "10px",
                              }}
                              className="far fa-ellipsis-v"
                            />
                          </Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu align="right">
                          <Dropdown.Item
                            as={Button}
                            onClick={handleRedirect(
                              "overview",
                              alert.customer_id
                            )}
                          >
                            Customer details
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Button}
                            onClick={handleRedirect("usage", alert.customer_id)}
                          >
                            Customer usage
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Button}
                            onClick={handleAddNoteShow(alert.customer_id)}
                          >
                            Add note to customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Button}
                            onClick={handleSendMailShow(alert.customer_id)}
                          >
                            Email customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Button}
                            onClick={handleAlertArchive(alert.id)}
                          >
                            Archive alert
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </span>
                    } 
                    
                  </td>
                  {((roleName == "Super Administrator") || (roleName == "Administrator")) &&
                    <td>
                      <input
                        style={{accentColor: "#4d667f"}}
                        type="checkbox"
                        id={alert.id}
                        onChange={handleClick}
                        checked={isCheck.includes(alert.id)}
                      />
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-lg-end pagination-box">
          <Pager
            onPageChange={setCurrentPage}
            total={isNil(alertType) ? total.all : total[alertType]}
            limit={limit}
            currentPage={currentPage}
          />
        </div>
      </div>
      <AddNote
        showModal={showAddNoteModal}
        handleActionsClose={handleAddNoteClose}
        customer_id={customerID}
      />
      <SendMail
        showSendMailModal={showSendMailModal}
        handleSendMailClose={handleSendMailClose}
        customer_id={customerID}
      />

    {showConfirmDialog && (
        <Modal
          centered={true}
          show={showConfirmDialog}
          onHide={deleteNoHandler}
        >
          <Modal.Header closeButton className="modal-center">
            <Modal.Title>Do you want to archive alerts</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="modal-center">
            <Button variant="secondary" onClick={deleteNoHandler}>
              No
            </Button>
            <Button variant="primary" onClick={submit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default forwardRef(AlertList);
