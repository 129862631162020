import React, {useEffect} from 'react'
import {
  useParams
} from 'react-router-dom'
import AlertsPage from '../../../alerts_page/alerts-page'

import { track } from "src/main_app/mixpanel"

const CustomerAlertsPage = ({

}) => {
  useEffect(() => {
    track('Customer alert page')
  }, [])
  const params = useParams()
  return (
    <AlertsPage
      onTabCustomerID={params.id}
    />
  )
}

export default CustomerAlertsPage
