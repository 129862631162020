import {
  pathOr, prop,
} from 'ramda'
import {
  getCustomerActions,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getActionsData = async (id, page, limit) => {
  const result = await getCustomerActions(id, page, limit)
  return isServerResponseError(result) || hasError(result)
    ? {
      total: 0,
      data: []
    }
    : prop('data', result)
}

export default getActionsData