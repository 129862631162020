import React, { useState, useEffect, useContext } from "react";
import { useError } from "../../../form_elements";
import { useHistory } from "react-router-dom";
import { isEmail } from "../../../../utils/common";
import { SignInContext } from "../../../../scenes/sign-in";

import { userAuthenticate, userAuthVerify } from "../../../../services/apis";

const formStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: "#FFFFFF",
};

const SignInEmail = (props) => {
  const signInContext = useContext(SignInContext);

  const [formOpened, setFormOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);

  const openForm = () => {
    setFormOpened(true);
  };

  const sendVerificationEmail = async () => {
    if (!isEmail(email)) {
      return setError("Please enter a valid email");
    }

    setError(null);
    setLoading(true);

    try {
      await userAuthenticate(email, props.tenantConfig.slug);
    } catch (e) {
      signInContext.setAfterLogin({
        status: "failed",
        message: "Something went wrong. Please try again",
      });
      return;
    }

    setLoading(false);
    setStep(2);
  };

  const verifyCode = async () => {
    setLoading(true);

    try {
      const result = await userAuthVerify(
        email,
        verificationCode,
        props.tenantConfig.slug
      );
      signInContext.setAfterLogin({ status: "ok", data: result.data });
    } catch (e) {
      signInContext.setAfterLogin({
        status: "failed",
        message: e.response.data.error.messages,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-default"
        onClick={() => openForm()}
        style={{ width: "100%" }}
      >
        CONTINUE WITH EMAIL
      </button>
      <div
        style={{ ...formStyle, display: formOpened ? "inherit" : "none" }}
        className="sign-in__body"
      >
        {step == 1 && (
          <>
            <h2 className="sign-in__tile">
              Enter your email address to login.
            </h2>
            <p className="sign-in__text f-14">Sign in to your account</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendVerificationEmail();
              }}
              formNoValidate
            >
              <div className="form-group">
                <label>Email</label>
                <input
                  name={"email"}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  placeholder="example@email.com"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                {error && <span className="invalid-feedback">{error}</span>}
              </div>
              <div className="sign-in__action-button text-sm-right mt-5">
                <button
                  className="sign-in__btn btn btn-primary"
                  disabled={loading}
                >
                  Next
                </button>
              </div>
            </form>
            <div style={{ clear: "both" }}></div>
          </>
        )}

        {step == 2 && (
          <>
            <h2 className="sign-in__tile">Verification</h2>
            <p className="sign-in__text f-14">
              We emailed your verification code to {email}.
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verifyCode();
              }}
              formNoValidate
            >
              <div className="form-group">
                <label>Verification code</label>
                <input
                  name={"verification-code"}
                  className={`form-control ${error ? "is-invalid" : ""}`}
                  placeholder="------"
                  value={verificationCode}
                  onChange={(event) => setVerificationCode(event.target.value)}
                />
                {error && <span className="invalid-feedback">{error}</span>}
              </div>
              <div className="sign-in__action-button block-center-between mt-5">
                <button
                  className="btn-text"
                  onClick={() => setStep(1)}
                  disabled={loading}
                >
                  Back
                </button>
                <button
                  className="sign-in__btn btn btn-primary"
                  disabled={loading}
                >
                  SIGN IN
                </button>
              </div>
            </form>
            <div style={{ clear: "both" }}></div>
          </>
        )}
      </div>
    </>
  );
};

export default SignInEmail;
