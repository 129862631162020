import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

export default withRouter((props) => {
  const runScrollToTop = () => {
    if (window.scrollY <= 0) return
    setTimeout(() => {
      window.scrollTo(0, window.scrollY - 30)
      runScrollToTop()
    }, 22)
  }

  useEffect(() => {
    runScrollToTop()
  }, [props.location.pathname])

  return props.children
})
