import { getUserPermissions } from "src/main_app/services/apis";
import { GET_USER_PERMISSIONS } from "../constants";

const getUserPermissionSuccess =
  (dispatch, getState) =>
  ({ data: userPermissions }) =>
    dispatch({
      type: GET_USER_PERMISSIONS,
      data: userPermissions,
    });

const getUserPermissionAsync = () => (dispatch, getState) =>
  getUserPermissions().then(getUserPermissionSuccess(dispatch, getState));

export default getUserPermissionAsync;
