import React,
{
  lazy,
  Suspense,
} from 'react'
import clsx from 'clsx'
import * as uuid from 'uuid'

const defaultSetting = {
  timePicker: true,
  startDate: new Date(),
  endDate: new Date(),
  locate: {format: 'M/D hh:mm A'},
}

const BootstrapDateRangePicker = lazy(() => {
  import('bootstrap-daterangepicker/daterangepicker.css')
  return import('react-bootstrap-daterangepicker')
})

const DateRangePicker = ({
  className,
  setting = {},
  onApply,
  dateDisplayFormat = 'DD MMM YYYY',
  children,
}) => {
  
  const id = uuid.v4()
  
  return (
    <div id={ id } className={clsx('date-picker', className)}>
      <Suspense
        fallback={children}
      >
        <BootstrapDateRangePicker
          initialSettings={{
            ...defaultSetting,
            ...setting,
            locale: {
              format: 'DD MMM YYYY',
            },
            parentEl: `#${id}`,
          }}
          dateDisplayFormat={dateDisplayFormat}
          onApply={onApply}
        >
          {children}
        </BootstrapDateRangePicker>
      </Suspense>
    </div>
  )
}

export default DateRangePicker
