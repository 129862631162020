import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { authService } from '../../base/services'

const EmptyLayout = (props) => {
  if (!authService.isAuthenticated()) {
    const isRedirectToLoginPage =
      props.requireLogin && window.location.pathname !== '/login'
    if (isRedirectToLoginPage)
      return <Redirect to={`/login?url=${document.location.href}`} />
  }

  const { component: ComponentPage, ...rest } = props
  return (
    <div className="wapper-container">
      <div className="main-content">
        <Route
          {...rest}
          render={routeProps => (
            <ComponentPage {...routeProps} />
          )}
        />
      </div>
    </div>
  )
}

export default EmptyLayout
