import React from 'react'
import moment from 'moment'

const DatetimeDisplay = ({
  data,
  format = 'YYYY-MM-DD HH:mm:ss',
  displayAs = 'MMM D, YYYY - HH:mm a'
}) => (
  <>
    {moment(data, format)
      .add(new Date().getTimezoneOffset() * -1, 'minutes')
      .format(displayAs)}
  </>
)

export default DatetimeDisplay