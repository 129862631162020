import { getUserRoles } from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'
import {
  propOr,
} from 'ramda'

const getUserRoleData = async () => {
  const response = await getUserRoles()
  const defaultResult = {
    total: 0,
    data: []
  }
  return isServerResponseError(response) || hasError(response)
    ? defaultResult
    : propOr(defaultResult, 'data', response)
}

export default getUserRoleData