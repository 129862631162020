import React, { useState, useEffect, useMemo, useCallback } from "react";
import { compose, prop, sortBy } from "ramda";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getTaxonomyUsageData } from "../../utils/customer_utils";
import {
  convertDate,
  firstDateOfMonthsAgo,
  formatNumber,
  lastDateOfMonthsAgo,
  convertDateMYWithShotMonthName,
  roundNumber,
} from "../../utils/common";
import { saveAs } from 'file-saver'; 
import { useCurrentPng } from "recharts-to-png";
import * as htmlToImage from 'html-to-image';

const yFormatter = (tickItem) => formatNumber(tickItem) + " ";

const renderTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div
      className="custom-line-tooltip"
      style={{
        padding: "5px",
        backgroundColor: "#fff",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 5%)",
      }}
    >
      <h5 className="mb-0">{payload[0].payload.date}</h5>
      <br />
      Usage: {formatNumber(payload[0].payload.usage)} ML
    </div>
  );
};

const UsageSection = ({ taxonomyID }) => {
  const [startDate, setStartDate] = useState(firstDateOfMonthsAgo(13));
  const [endDate, setEndDate] = useState(lastDateOfMonthsAgo(1));
  const [usageData, setUsageData] = useState([]);

  const loadData = async () => {
    getTaxonomyUsageData(
      taxonomyID,
      convertDate(startDate),
      convertDate(endDate)
    ).then((results) =>
      setUsageData(
        sortBy(prop("log_date"))(results).map((result) => {
          return {
            usage: roundNumber(result.consumption),
            date: convertDateMYWithShotMonthName(new Date(result.arr_end_date[0])),
          };
        })
      )
    );
  };

  useEffect(() => {
    loadData();
  }, [startDate, endDate]);

  const yLabel = useMemo(
    () => ({
      value: "Usage (ML)",
      angle: -90,
      position: "top",
      dy: 170,
      dx: -14,
    }),
    []
  );

//Download image chart
  const onCapture = () =>{
    htmlToImage.toPng(document.getElementById("downloadImageUsageChart"))
     .then(function (dataUrl) {
       saveAs(dataUrl, 'Usage-chart.png');
     });
   }

  return (
    <>
      <h3 className="sub-title mt-4">Usage</h3>
      <div className="row">
        <div className="form-group">
          <div className="month-range-label">
            <label>Month range: </label>
          </div>
          <div className="usage-month-range-picker">
            <span>from</span>
            <div className="month-range-picker start-month">
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
            <span>to</span>
            <div className="month-range-picker end-month">
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
        </div>
      </div>
      <a className="btn btn-primary btn-sm" onClick={onCapture}>Download as image</a>
      <div className="card" id="downloadImageUsageChart">
        <div className="card-body">
          <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
            <BarChart
              data={usageData}
              margin={{
                top: 20,
                right: 5,
                left: -15,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke="#e9ebf1" />
              <XAxis dataKey="date" stroke="#e9ebf1" />
              <YAxis
                dataKey={"usage"}
                stroke="#e9ebf1"
                tickFormatter={yFormatter}
                label={yLabel}
              />
              <Tooltip
                contentStyle={{
                  boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                  borderRadius: "4px",
                  border: "none",
                }}
                itemStyle={{
                  color: "#05d6f",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                content={renderTooltip}
              />
              <Bar
                dataKey={"usage"}
                fill="#09A3B3"
                radius={[5, 5, 0, 0]}
                barSize={24}
                unit=" ML"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default UsageSection;
