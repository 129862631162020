import {
  customerDeleteAction,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const deleteAction = async (id, data) => {
  const response = await customerDeleteAction(id, data)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default deleteAction