import { hasPath, path, when, isEmpty, symmetricDifference, tap, propOr } from "ramda";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { checkSettingUrlFeedBack } from "src/main_app/services/apis";

const SecondaryNav = ({ items = [], tenantConfig, roleName, urlFeedBack, ...props }) => {
  let removeInsightsTab = [];
  if (tenantConfig.slug !== "hunterwater") {
    removeInsightsTab = items.filter((item) => (item.label !== "Insights"));
  } else {
    removeInsightsTab = items;
  }
  
  return (
    <nav className="secondary-nav">
      <div className="container-fluid">
        <div className="block-center-between secondary-nav__row">
        {roleName != "Customer" && (
          <ul className="nav">
            {removeInsightsTab.map((item, index) => (
              <li key={index + ""} className="nav-item">
                <NavLink
                  className="nav-link"
                  to={item.to}
                  activeClassName="active"
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
        {roleName == "Customer" && (
          <ul className="nav"></ul>
        )}
          {/* {tenantConfig.provideFeedback == true && ( */}
          {urlFeedBack != '' && (
            <a
              className="support color-inherit m-3"
              target="_blank"
              // href="https://543zv6tu40z.typeform.com/to/WRnuXrwR"
              href={urlFeedBack}
            >
              <i className="far fa-megaphone" />{" "}
              <span className="hide-xs ml-2">Provide feedback</span>
            </a>
          )}
        </div>
      </div>
    </nav>
  );
};

export default SecondaryNav;
