import { getLinksData } from 'src/main_app/utils/customer_utils'
import { REFRESH_CUSTOMER_LINKS } from '../constants'

const refreshCustomerLinkSuccess = (dispatch, getState) => (data) =>
  dispatch({
    type: REFRESH_CUSTOMER_LINKS,
    data,
  })

const refreshCustomerLinkAsync = () => (dispatch, getState) => {
  const {
    customerLinkReducer: {
      queryOptions: { id, currentPage, limit },
    },
  } = getState()
  return getLinksData(id, currentPage, limit).then(
    refreshCustomerLinkSuccess(dispatch, getState),
  )
}

export default refreshCustomerLinkAsync
