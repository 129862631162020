import {
  customerDeleteTimelineItem,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const deleteTimelineItem = async (id) => {
  const response = await customerDeleteTimelineItem(id)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default deleteTimelineItem