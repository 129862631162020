import { getSubTenants } from "src/main_app/services/apis";
import { FETCH_SUB_TENANTS } from "../constants";

let isFetched = false;
const fetchSubTenantsAsync = () => (dispatch, getState) => {
  if (isFetched) return;
  isFetched = true;
  getSubTenants().then(({ data: { data } }) =>
    dispatch({
      type: FETCH_SUB_TENANTS,
      data,
    })
  );
};

export default fetchSubTenantsAsync;
