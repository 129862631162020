import mixpanel from "mixpanel-browser"
import { authService } from "src/base/services"
import { isNilOrEmpty } from "./utils/common"
import { getMixpanelToken } from "./utils/config-utility"

const startMixpanel = () => {
  try {
    mixpanel.init(getMixpanelToken())
  } catch (error) {
    console.error('startMixpanel', error)
  }
}

export const track = (name, data) => {
  try {
    const userId = authService.getUserId()
    // if (isNilOrEmpty(userId)) mixpanel.identify(userId)
    mixpanel.track(name, data)
    // console.log('track', name, data)
  } catch (error) {
    console.error('track', error)
  }
}

export const identifyUser = () => {
  const user = authService.getUser();
  const full_name = user.first_name + ' ' + user.last_name
  const userId = authService.getUserId()
  mixpanel.identify(userId)

  mixpanel.people.set({
    name: full_name,
    $email: user.email
  });
}

export default startMixpanel
