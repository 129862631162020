import {
    FETCH_CUSTOMER_ACTIOHS,
    REFRESH_CUSTOMER_ACTIONS,
} from "../constants";

const customerActionReducer = (state = {
    total: 0,
    data: [],
    queryOptions: {

    }
}, action) => {
    switch (action.type) {
        case FETCH_CUSTOMER_ACTIOHS:
            return {
                ...state,
                total: action.data.total,
                data: [...action.data.data],
                queryOptions: { ...action.queryOptions },
            }
        case REFRESH_CUSTOMER_ACTIONS:
            return {
                ...state,
                total: action.data.total,
                data: [...action.data.data],
            }
        default:
            return state
    }
}

export default customerActionReducer