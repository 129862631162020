import React from 'react'

import BaseChart from '../base-chart'

const StackedChart = ({
  id: elementId,
  data = [],
  info = {},
  style={}
}) => {
  const onInit = (
    am4core,
    am4charts,
  ) => {
    let chart = am4core.create(elementId, am4charts.XYChart)
    chart.numberFormatter.numberFormat = "#.#'ML'"
    //add data
    chart.data = data

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = info.category
    categoryAxis.renderer.grid.template.location = 0

    categoryAxis.renderer.grid.template.strokeOpacity = 1;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#e9ebf1");
    categoryAxis.renderer.grid.template.strokeWidth = 1;
    categoryAxis.renderer.labels.template.fill = am4core.color("#333333");
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.fontWeight = 500;
    // categoryAxis.dateFormats.setKey("day", "MMMM dt");
    // categoryAxis.periodChangeDateFormats.setKey("day", "MMMM dt");

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.inside = false
    valueAxis.min = 0


    valueAxis.renderer.grid.template.strokeOpacity = 1;
    valueAxis.renderer.grid.template.stroke = am4core.color("#e9ebf1");
    valueAxis.renderer.grid.template.strokeWidth = 1;
    valueAxis.renderer.baseGrid.stroke = "#e9ebf1";
    valueAxis.renderer.labels.template.fill = am4core.color("#333333");
    valueAxis.renderer.labels.template.fontSize = 10;
    valueAxis.renderer.labels.template.fontWeight = 500;
    // Create series
    const createStackedSeries = (field, name,color,radius) => {

      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries())
      series.name = name
      series.dataFields.valueY = field
      series.dataFields.categoryX =  info.category
      series.sequencedInterpolation = true
      series.columns.template.column.cornerRadiusTopLeft = radius
      series.columns.template.column.cornerRadiusTopRight = radius

      // Make it stacked
      series.stacked = true
      series.fill = color
      series.stroke = color
      // Configure columns
      series.columns.template.width = am4core.percent(55)

      // series.columns.template.tooltipText = '[medium][font-size:12px;]{categoryX} - {name} \n {valueY}[/]'
      series.columns.template.tooltipHTML = '<div style="text-align: center;font-weight: 500;font-size: 12px;color:#505D6F">{categoryX} - {name} <br/>{valueY}</div>'
      // SET pointer orientation tooltip
      series.tooltip.pointerOrientation = "down"
      series.tooltip.background.strokeOpacity = "0"
      // SET background tooltip
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#ffffff")
      // SET box-shadow tooltip
      let shadow = series.tooltip.background.filters.getIndex(0)
      shadow.dx = 0
      shadow.dy = 0
      shadow.blur = 0

      return series
    }
    info.series.forEach((serie, index) => {
      const radius = index === info.series.length -1 ? 10: 0
      createStackedSeries(serie.value, serie.name,serie.color, radius)
    })


    // chart.cursor = new am4charts.XYCursor();

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fill = '#505D6F';
    chart.legend.labels.template.fontSize = 12;
    chart.legend.contentAlign = "right";
    var marker = chart.legend.markers.template.children.getIndex(0);
    // for (let ii in marker) console.log('iii', ii, marker[ii])
    marker.cornerRadius(12, 12, 12, 12);
    marker.strokeWidth = 0;
    marker.strokeOpacity = 0;
    marker.width = 20;
    marker.height = 20;
    // marker.markerLabelGap = 100;
    // marker.verticalCenter = 'center';

    return chart
  }

  return (
    <BaseChart
      style={{...style}}
      id={elementId}
      onInit={onInit}
    />
  )
}

export default StackedChart
