import React, { useMemo } from "react";
import { times } from "ramda";
import { Pager } from "../../components";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from 'uuid';

const CustomersTableSkeleton = ({ tenantConfig, line = 1 }) => {
  const skeletonLines = times(
    (i) => (
      <tr key={i}>
        <React.Fragment>
          {(tenantConfig.customerFieldsData || []).map( field => {
              if (field === 'name') return (
                <td key={uuidv4()} className="customer-info customer-name">
                  <Skeleton />
                </td>
              )
              if (field === 'site_name') return (
                <td key={uuidv4()} className="customer-info customer-name">
                  <Skeleton />
                </td>
              )
              if (field === 'trading_name') return (
                <td key={uuidv4()} className="customer-info customer-trading-name">
                  <Skeleton />
                </td>
              )
              if (field === 'owner_name') return (
                <td key={uuidv4()} className="customer-info customer-owner-name">
                  <Skeleton />
                </td>
              )
              if (field === 'critical_class') return (
                <td key={uuidv4()} className="customer-info customer-critical-class">
                  <Skeleton />
                </td>
              )
              if (field === 'taxonomy_label') return (
                <td key={uuidv4()} className="customer-info customer-taxonomy-label">
                  <Skeleton />
                </td>
              )
              if (field === 'sewer_zone') return (
                <td key={uuidv4()} className="customer-info customer-sewer-zone">
                <Skeleton />
              </td>
              )
              if (field === 'water_zone') return (
                <td key={uuidv4()} className="customer-info customer-supply-zone">
                <Skeleton />
              </td>
              )
              if (field === 'install_number') return (
                <td key={uuidv4()} className="customer-info customer-account-no">
                  <Skeleton />
                </td>
              )
              if (field === 'email') return (
                <td key={uuidv4()} className="customer-info customer-contact-email">
                  <Skeleton />
                </td>
              )
            })
          }
        </React.Fragment>

        <td className="customer-info customer-contact-name">
          <Skeleton />
        </td>
        <td className="customer-info customer-contact-phone">
          <Skeleton />
        </td>
        {/* <td className="customer-info customer-contact-email">
          <Skeleton />
        </td> */}
        {(tenantConfig.customerFieldsData || []).map( field => {
              if (field === 'has_sums_data') return (
                <td key={uuidv4()} className="customer-info customer-realtime-data">
                  <Skeleton />
                </td>
              )
            })
          }
      </tr>
    ),
    line
  );

  const columnStyle = useMemo(() => ({ padding: "10px" }), []);

  return !!tenantConfig.slug && (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr style={{ height: "40px" }}>
              {/* <th scope="col" width={"25%"} style={columnStyle}>
                Name
              </th> */}
              <>
                {(tenantConfig.customerFieldsHeading || []).map(
                  columnName => <th key={uuidv4()} scope="col" width={"10%"} style={columnStyle}>{columnName}</th>
                )}
              </>
            </tr>
          </thead>
          <tbody>{skeletonLines}</tbody>
        </table>
      </div>
      <div className="d-flex justify-content-lg-end pagination-box">
        <Pager />
      </div>
    </>
  );
};

export default CustomersTableSkeleton;
