import React, {useState} from 'react'
import {
  AddAction,
  AddNote,
  SendMail,
} from './modals'

const CustomerRightMenu = ({
  refreshWhenUpdateAction,
  tenantConfig,
}) => {
  const [showAddActionsModal, setShowAddActionsModal] = useState(false)
  const [showAddNoteModal, setShowAddNoteModal] = useState(false)
  const [showSendMailModal, setShowSendMailModal] = useState(false)

  const handleAddActionsClose = () => setShowAddActionsModal(false)
  const handleAddActionsShow = () => setShowAddActionsModal(true)

  const handleAddNoteShow = () => setShowAddNoteModal(true)
  const handleAddNoteClose = () => setShowAddNoteModal(false)

  const handleSendMailShow = () => setShowSendMailModal(true)
  const handleSendMailClose = () => setShowSendMailModal(false)

  return (

    <div className="customer-nav-right block-center">
      <button className="btn-text " onClick={handleAddNoteShow}><i className="far fa-sticky-note mr-2"/>Add
        note
      </button>
      {tenantConfig.slug !== 'intellihub' && (
        <>
          <button className="btn-text  ml-3" onClick={handleSendMailShow}><i className="far fa-envelope mr-2"/>Send
          email
          </button>
          <button className="btn-text  ml-3" onClick={handleAddActionsShow}><i
            className="far fa-plus-circle mr-2"/>Actions
          </button>
        </>
      )}
      
      <AddAction
        showAddActionsModal={showAddActionsModal}
        handleAddActionsClose={handleAddActionsClose}
        afterSuccess={refreshWhenUpdateAction}
      />
      <AddNote
        showModal={showAddNoteModal}
        handleActionsClose={handleAddNoteClose}
      />
      <SendMail
        showSendMailModal={showSendMailModal}
        handleSendMailClose={handleSendMailClose}
      />
    </div>

  )
}

export default CustomerRightMenu
