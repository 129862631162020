import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import timelineTypes from '../../../../enums/timeline-types.json'

import { DatetimeDisplay } from '../../../../components'
import { isEmpty } from 'ramda'
import OtherRecipientsTooltip from './other-recipients-tooltip'
import CustomerTimelineSkeleton from './customer-timeline-skeleton'
import NoteItem from './note-item'
import CustomerTimelineFilter from './customer-timeline-filter'

import { track } from "src/main_app/mixpanel"

const CustomerTimelinePage = ({ timelineData, fetchCustomerTimeline }) => {
  useEffect(() => {
    track('Customer timeline page')
  }, [])
   const [loadTimelineDone, setLoadTimelineDone] = useState(false)

  const params = useParams()
  const MaxRecipientsDisplay = 2
  // const [timelineData, setTimelineData] = useState([])

  const renderTypeIcon = (type) => {
    switch (type - '') {
      case timelineTypes.note:
        return <i className="far fa-sticky-note " />
      case timelineTypes.action:
        return <i className="far fa-bell" />
      case timelineTypes.email:
        return <i className="far fa-envelope " />
    }
  }

  const renderContent = ({ id, type, content, label }) => {
    switch (type - '') {
      case timelineTypes.note:
        return <NoteItem id={id} itemData={content} label={label} customerId={params.id} />
      case timelineTypes.action:
        return <div>{content.name}</div>
      case timelineTypes.email:
        let recipientMessage = `Email was sent to`
        for (
          let i = 0;
          i < MaxRecipientsDisplay && i < content.recipients.length;
          i++
        ) {
          recipientMessage += content.recipients[i]
            ? `${i > 0 ? ', ' : ''} ${content.recipients[i].name} (${
                content.recipients[i].email
              }) `
            : ''
        }
        return (
          <>
            {content.recipients.length < 3 ? (
              <div className="recipients">{recipientMessage}</div>
            ) : (
              <div className="recipients">
                {recipientMessage}
                <OtherRecipientsTooltip recipients={content.recipients} />
              </div>
            )}
            <div
              className="mail-content"
              dangerouslySetInnerHTML={{ __html: content.content }}
            ></div>
          </>
        )
    }
  }

  const onFilter = (type) => {
    if (loadTimelineDone) setLoadTimelineDone(false)
    fetchCustomerTimeline(params.id, type).then(() =>
      setTimeout(() => {
        setLoadTimelineDone(true)
      }, 666),
    )
  }

  return (
    <div className="container-fluid content">
      <div className="row">
        <div className="col-8">
          {loadTimelineDone ? (
            isEmpty(timelineData) ? (
              <div className="no-item-message">
                There are currently no events on this customer's timeline. The
                customer timeline is a chronological list of events consisting
                of notes, emails and alerts. You can add a note using the "Add
                note" button from the menu bar top right.
              </div>
            ) : (
              <div className="timeline">
                {timelineData.map((data, index) => {
                  return (
                    <div key={index} className="timeline__item">
                      <div className="timeline__type">
                        {renderTypeIcon(data.type)}
                      </div>
                      <div className="timeline__content">
                        <div className="timeline__date font-weight-bold">
                          <DatetimeDisplay data={data.updated_at} />
                        </div>
                        <p style={{marginBottom: "0px"}} className="font-weight-bold">{data.label}</p>
                        {/* <p style={{marginBottom: "0px"}} className="font-weight-bold">{data.parent_id != null ? data.label : ''}</p> */}
                        {renderContent(data)}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          ) : (
            <CustomerTimelineSkeleton line={10} />
          )}
        </div>
        <div className="col">
          <CustomerTimelineFilter onFilter={onFilter} />
        </div>
      </div>
    </div>
  )
}

export default CustomerTimelinePage
