import clsx from "clsx";
import { pathOr } from "ramda";
import React from "react";
import { useTenantConfig } from "src/main_app/actions";
import {
  inputChangeHandler,
  useToggleValue,
} from "src/main_app/utils/react_utils";
import { layerNames } from "../layers/layer-config";
import MapCustomerFilter from "./map_customer_filter";

const MapFilter = ({ layersVisible, setLayersVisible, applyFilterData, displayCurrentMap }) => {
  const tenantConfig = useTenantConfig();

  // layer switcher state
  const [layerOnChecked, toggleLayerOnChecked] = useToggleValue(true);

  const toggleLayer = inputChangeHandler(layersVisible, setLayersVisible);

  return (
    <div className="map__filter map__filter__left" style={{zIndex: "3", display: displayCurrentMap}}>
      <div className="map__layer">
        <div
          className={`btn btn-default block-center-between ${
            layerOnChecked ? "extended" : "collapsed"
          }`}
          onClick={toggleLayerOnChecked}
        >
          <span className="ml-2 mr-2 mr-sm-4">Layers</span>
          <button className="btn-icon">
            <i
              className={clsx(
                "far",
                layerOnChecked ? "fa-angle-up" : "fa-angle-down"
              )}
            />
          </button>
        </div>
      </div>
      <div className={clsx("map__filter-box", layerOnChecked && "active")}>
        <div className="map__filter-box-inner">
          <div className="map__filter-content">
            <div className="map__filter-option">
              <label className="checkbox ">
                <input
                  type="checkbox"
                  name={layerNames.customers}
                  onClick={toggleLayer}
                  defaultChecked={layersVisible[layerNames.customers]}
                />
                <span className="ck" />
                Customers
              </label>
            </div>
            <MapCustomerFilter
              onFiltersApply={applyFilterData}
              visible={layersVisible[layerNames.customers]}
            />
            {pathOr([], ["page_settings", "map", "layersConfig"], tenantConfig)
              .filter((layerConfig) => layerConfig.visible)
              .map((layerConfig, index) => (
                <div key={index + ""} className="map__filter-option">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      name={layerConfig.name}
                      onClick={toggleLayer}
                      defaultChecked={layersVisible[layerConfig.name]}
                    />
                    <span className="ck" />
                    {layerConfig.label}
                  </label>
                </div>
              ))}
              {(tenantConfig.layersConfigMap || []).map((item, index) => (
                <div key={index + ""} className="map__filter-option">
                  <label className="checkbox ">
                    <input type="checkbox" name={item.name} onClick={toggleLayer}/>
                    <span className="ck" />
                    {item.label}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapFilter;
