import React, { useState, useEffect, useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { andThen, propOr, compose } from "ramda";
import { useParams, Route } from "react-router-dom";

import { getCustomerData } from "../../utils/customer_utils";

import { track } from "src/main_app/mixpanel";
import { useUserPermissions, checkPermission } from "src/main_app/actions";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { getImportDashboardDetails } from "../../utils/customer_utils";

const ImportDetailPage = (props) => {
  useEffect(() => {
    track("Import detail page");
  }, []);
  const tenantConfig = useTenantConfig();
  const params = useParams();
  const [fileName, setFileName] = useState([]);
  const [dataAlertImportList, setDataAlertImportList] = useState([]);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "import-page", "canView"),
    [userPermissions]
  );

const loadData = async () => {
  const response = await getImportDashboardDetails(params.id)
  setDataAlertImportList(propOr([], 'dataAlertImport', response))
  setFileName(propOr([], 'detailImportDashboard', response))
}

useEffect(() => {
  if (!canViewThisPage) {
    alert("You don't have permission to view this page");
    document.location.href = "/";
  }
}, [canViewThisPage]);

  useEffect(() => {
    loadData(params.id);
  }, []);


  return !canViewThisPage ? null : (
    <div className="the-content">
      <div className="page-heading">
        <div className="container-fluid ">
          {fileName.map((item, index) => (
            <h2 key={index} className="title mb-0">
              {item.created_at} - {item.type} - {item.name_file}
            </h2>
          ))}
          <div className="block-center-between">
            <div className="left-action-menu">
              <nav className="customer-nav">
                <ul className="nav">
                  <li className="nav-item">
                    <a aria-current="page" className="nav-link  active" style={{fontSize: "14px"}}>Log</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid content" >
        <div style={{backgroundColor:"white", padding:"20px", maxHeight:"700px", overflow:"auto"}}>
          {dataAlertImportList.map((item, index) => (
            <p style={{fontSize: "13px"}} className={item.status == 'error' ? "statusErrorDetail" : item.status == 'added' ? "statusAddNew" : "statusUpdated"} key={index}>
              {item['data']['ACCTNO'] ? `${item['data']['ACCTNO']} -`  : ''} {item['data']['TRADINGNAME'] ? item['data']['TRADINGNAME'] : item['data']['OWNERNAME']}
              {item['data']['NAME'] ? item['data']['NAME'] : ''}
              {item['data']['TITLE'] ? item['data']['TITLE'] : ''}
              {item['data']['SOURCE'] ? item['data']['SOURCE'] : ''}
              {item['data']['anzsic_code'] ? item['data']['anzsic_code'] : ''}
              {item.status == 'error'
                ? ` - (${item.label})` 
                : item.status == 'added'
                ? ` (added)`
                : item.status == 'updated'
                ? ` (updated)`
                : ''
              }
             </p>
          ))}
        </div>
      </div>
      
    </div>
  );

};

export default ImportDetailPage;
