import { propOr, pathOr, isEmpty, find, propEq } from "ramda";
import React from "react";
import {
  Textbox,
  Checkbox,
  Select1,
} from "src/main_app/components/form_elements";
import {useTenantConfig} from "src/main_app/actions";

const DeedReporting = ({
  reporting,
  onRemove,
  onChange,
  section_config,
}) => {
  const tenantConfig = useTenantConfig();

  const process_fields = (fields, reporting) => {
    if (isEmpty(fields)) return [];

    let rows = [];
    let row_fields = [];
    let columnsCount = 0;
    fields.forEach((field) => {
      columnsCount += pathOr(0, ["field_data", "layout_columns"], field);
      if (columnsCount > 12) {
        rows.push(row_fields);
        columnsCount = pathOr(0, ["field_data", "layout_columns"], field);
        row_fields = [];
      }
      row_fields.push({
        field,
        currentValue: propOr("",pathOr("", ["field_data", "name"], field),reporting),
      });
    });
    if (!isEmpty(row_fields)) rows.push(row_fields);
    return rows;
  };

  const render_field = ({ field, currentValue }, index) => {
    switch (propOr("", "field_type", field)) {
      case "text_input":
        return (
          <div key={index + ""} className={`col-md-${pathOr("", ["field_data", "layout_columns"], field)} `}>
            <Textbox
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              value={currentValue}
              onChange={onChange}
              disabled={pathOr(false, ["field_data", "readOnly"], field)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const render_row = (row, index) => (
    <div key={index + ""} className="row align-items-center">
      {row.map(render_field)}
    </div>
  );

  const render_reporting = (fields_config, reporting) => {
    const rows = process_fields(
      pathOr([], ["field_data", "fields"], fields_config),
      reporting
    );
    return rows.map(render_row);
  };
  return (
    <>
      <div className="flex-grow-1">
        {render_reporting(section_config[0], reporting)}
      </div>
        <div className="action" style={{textAlign: "right"}}>
          <button style={{position: "relative", bottom: "50px", paddingRight: "5%"}} className="btn-text" type="button" onClick={onRemove}>
            <i className="far fa-trash f-16" />
          </button>
        </div>
     </>
  );
};

export default DeedReporting;
