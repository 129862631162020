
import React from 'react'

const LoadingComponent = () => (
    <div id="loader-wrapper">
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
        <div id="loader-img"></div>
        <div id="loader-text">
            Loading, please wait...
        </div>
    </div>
)

export default LoadingComponent