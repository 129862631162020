import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { addNote } from '../../../../utils/customer_utils'
import { isEmpty, compose, andThen, pathOr, prop, find } from 'ramda'
import { setTargetValue } from 'src/main_app/utils/react_utils'
import { getCustomersTimelineNoteTypes } from 'src/main_app/services/apis'
import { isNilOrEmpty } from 'src/main_app/utils/common'
import Select from '@bhunjadi/react-select-v3';

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList'],
}

import { track } from "src/main_app/mixpanel";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const AddNote = ({
  showModal,
  handleActionsClose,
  fetchCustomerTimeline,
  customer_id = null,
}) => {
  if (!showModal) return null
  const tenantInfo = useRef(getTenantInfoFromLocal());
  
  useEffect(() => {
    track("Add note modal", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [])
  const params = useParams()
  const id = customer_id ?? params.id
  const { addToast } = useToasts()
  const [noteType, setNoteType] = useState('')
  const [noteContent, setNoteContent] = useState('')
  const [error, setError] = useState(false)
  const [noteTypes, setNoteTypes] = useState([])

  const resetStates = () => {
    setNoteType('1')
    setNoteContent('')
    setError(false)
  }

  const noteSubmitHandler = async () => {
    if (isEmpty(noteContent)) {
      addToast('Note content must not be empty', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      setError(true)
      return
    }
    const response = await addNote(id, {
      type: noteType,
      content: noteContent,
    })
    addToast(
      response === 'success' ? 'Note added successfully' : 'Adding note failed',
      {
        appearance: response === 'success' ? 'success' : 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      },
    )
    if (response === 'success' && fetchCustomerTimeline) {
      fetchCustomerTimeline(id)
    }
    resetStates()
    handleActionsClose()
  }

  const loadData = compose(andThen(setNoteTypes), getCustomersTimelineNoteTypes)

  useEffect(() => {
    loadData()
    const listNoteType = noteTypes.map(item => ({
      value: item.id,
      label: item.label
    }));
    setNoteTypes(listNoteType);
  }, [])

  useEffect(() => {
    if (isNilOrEmpty(noteTypes)) {
      setNoteType('')
    } else {
      compose(
        setNoteType,
        prop('id'),
        find(pathOr(true, ['meta', 'selectable'])),
      )(noteTypes)
    }
  }, [noteTypes])

  return id ? (
    <Modal show={showModal} onHide={handleActionsClose} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Add a note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-note-form" className="">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Note type</label>
                <Select
                      defaultValue={{label: "Agreements"}}
                      getOptionLabel ={(option)=>option.label}
                      getOptionValue ={(option)=>option.id}
                      onChange={(e) =>
                        setNoteType(e.id)
                      }
                      options={noteTypes}
                  />
              </div>
            </div>
          </div>
          <div className={`form-group ${error ? 'has-error' : ''}`}>
            <label>Note</label>
            <CKEditor
              editor={Editor}
              config={editorConfiguration}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    'height',
                    '200px',
                    editor.editing.view.document.getRoot(),
                  )
                  writer.setStyle(
                    'color',
                    '#000000',
                    editor.editing.view.document.getRoot(),
                  )
                })
                // console.log('Editor is ready to use!', editor)
              }}
              onChange={(event, editor) => {
                setNoteContent(editor.getData())
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            />
          </div>
          <Button variant="primary" onClick={noteSubmitHandler}>
            ADD NOTE
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  ) : null
}

export default AddNote
