import { useRef, useState } from "react"

const useProgressing = (delay) => {
    const refSetTimeout = useRef(null)
    const [state, setState] = useState(false)

    const startProgress = () => refSetTimeout.current = setTimeout(() => setState(true), delay)

    const endProgress = () => {
        clearTimeout(refSetTimeout.current)
        refSetTimeout.current = null
        setState(false)
    }

    return [state, startProgress, endProgress]
}

export default useProgressing