import {
  userDelete,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'
import {pathOr} from 'ramda'

const deleteUser = async (id, data) => {
  const response = await userDelete(id, data)
  return isServerResponseError(response) || hasError(response)
    ? 0
    : pathOr(0, ['data', 'affectedRows'], response)
}

export default deleteUser