import { getUserRolesData } from 'src/main_app/utils/user_utils'
import GET_USER_ROLES from '../constants'

const getUserRolesSuccess = (dispatch, getState) => userRoles => dispatch({
    type: GET_USER_ROLES,
    data: userRoles
})

const getUserRolesAsync = () => (dispatch, getState) => getUserRolesData().then(getUserRolesSuccess(dispatch, getState))

export default getUserRolesAsync