
import { pathOr } from 'ramda'
import getWithToken from 'src/main_app/services/apis/base/get-with-token'
import { hasError, isServerResponseError } from '../common'

const getImportDashboardDetails = async (id) => {
  const result = await getWithToken(`/customer-list-import-dashboard/${id}`)()

  return isServerResponseError(result) || hasError(result)
    ? {}
    : {
      dataAlertImport: pathOr([], ['data', 'dataAlertImport'], result),
      detailImportDashboard: pathOr([], ['data', 'detailImportDashboard'], result),
    }
}

export default getImportDashboardDetails
