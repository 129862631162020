import {
  customerDeleteLinkItem,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const deleteLinkItem = async (id) => {
  const response = await customerDeleteLinkItem(id)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default deleteLinkItem