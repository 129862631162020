import React, { useState, useEffect, useCallback, useMemo } from "react";
import { andThen, propOr, compose, isNil } from "ramda";
import { useParams, Route } from "react-router-dom";
import {
  FormContainer,
  SpinnerButton,
  Textbox,
} from "src/main_app/components/form_elements";
import { TagInput } from "src/main_app/components";

import { useToasts } from "react-toast-notifications";
import { track } from "src/main_app/mixpanel";
import { PageHeading } from "../../components";
import { getSettingReceiveMailAlert, addSettingReceiveMail } from "src/main_app/services/apis";
import SettingNav from "src/main_app/components/settings-nav/settings-nav";
import { useUserPermissions, checkPermission } from "src/main_app/actions";

import "react-tagsinput/react-tagsinput.css";

const SettingReceiveMailPage = (props) => {
  useEffect(() => {
    track("Setting email alert page");
  }, []);
  const { addToast } = useToasts();
  const [emailValue, setEmailValue] = useState([]);
  const [load, setReload] = ([])

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(
    () => checkPermission(userPermissions, "settings-mail-alert", "canView"),
    [userPermissions]
  );

  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const submitEmailHandler = async (event) => {
    event.preventDefault();
    //Update email
    const email = emailValue;
    await addSettingReceiveMail(emailValue)
    addToast("Update successfully.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setReload(emailValue);
  };

  const loadData = async () => {
    const response = await getSettingReceiveMailAlert();
    if (!isNil(response.data)) {
      setEmailValue(response.data);
    }
  }

  useEffect(() => {
    loadData();
  }, [load]);

  const { routes = [] } = props;

  return userPermissions.length === 0 || !canViewThisPage ? null : (
    <div className="the-content">
      <div className="page-heading pb-0">
        <div className="container-fluid ">
          <h2 className="title mb-0">
            Settings
          </h2>
          <div className="block-center-between">
            <div className="left-action-menu">
            <SettingNav />
            </div>
          </div>
        </div>
      </div>
      {routes.map(({ component: ComponentPage, routes, path, ...rest }, i) => (
        <Route
          key={i + ""}
          path={`${props.match.path}${path}`}
          {...rest}
          render={(routeProps) => (
            <ComponentPage
              routes={routes}
              {...routeProps}
              reloadInfo={loadData}
            />
          )}
        />
      ))}
    </div>
  );
};

export default SettingReceiveMailPage;
