import { customerTimelineData } from 'src/main_app/utils/customer_utils'
import FETCH_CUSTOMER_TIMELINE from '../constants'

const fetchCustomerTimelineSuccess = (dispatch, getState) => data => dispatch({
    type: FETCH_CUSTOMER_TIMELINE,
    data
})

const fetchCustomerTimelineAsync = (id, type) => (dispatch, getState) => customerTimelineData(id, type).then(fetchCustomerTimelineSuccess(dispatch, getState))

export default fetchCustomerTimelineAsync