import React from 'react'

const PageHeading = ({
    title
}) => (
    <div className="page-heading ">
        <div className="container-fluid">
            <h2 className="title mb-0">
                {title}
            </h2>
        </div>
    </div>
)

export default PageHeading