import { connect } from 'react-redux';

const { fetchCustomerLinks } = require("src/main_app/actions");

const mapStateToProps = null
const mapActionToProps = {
    fetchCustomerLinks
}

const withRedux = component => connect(
    mapStateToProps,
    mapActionToProps
)(component)

export default withRedux
