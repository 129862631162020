import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Dropdown } from "react-bootstrap";
import { hasPath, isEmpty, isNil, propOr } from "ramda";
import {
  getFilteredDataFromFilter,
  getFiltersConfig,
} from "./helpers/filterHelper";
import {
  clearCustomerFiltersOnStorage,
  getCustomerFiltersFromStorage,
  saveCustomerFiltersToStorage,
} from "../../services/local_storage/customer-filters-storage";
import { useTenantConfig } from "src/main_app/actions";
import Select from '@bhunjadi/react-select-v3';

const renderInput = (
  index,
  filter,
  filterData,
  setFilterData,
  onApply,
  filterConfig
) => {
  const fieldName = filter.field;
  switch (filterConfig[fieldName].type) {
    case "text_input":
    case "number_input":
      return (
        <div className="filter__footer" key={`filter_input_${index}`}>
          <input
            type={
              filterConfig[fieldName].type === "text_input" ? "text" : "number"
            }
            className="form-control"
            value={filterData[fieldName] || ""}
            onChange={(event) =>
              setFilterData({
                ...filterData,
                [fieldName]: event.target.value,
              })
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onApply();
              }
            }}
          />
        </div>
      );
    case "taxonomy":
     const taxonomy_id = propOr(filterConfig[fieldName].data.options[0].id, fieldName, filterData);
     const searchTaxonomy = filterConfig[fieldName].data.options.filter((item) => {
        if (item.id == taxonomy_id) {
          return item;
        }
      })
    
     const nameTaxonomy = searchTaxonomy[0].taxonomy_label;
     const data = {
      id: propOr(filterConfig[fieldName].data.options[0].id, fieldName, filterData),
      taxonomy_label: nameTaxonomy,
     };
      return (
        <div className="filter__footer" key={`filter_input_${index}`}>
           <Select
                defaultValue={data}
                onChange={(event) => {
                  setFilterData({
                    ...filterData,
                    [filter.field]: event.id,
                  });
                }}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                getOptionLabel ={(option)=>option.taxonomy_label}
                getOptionValue ={(option)=>option.id}
                options={filterConfig[fieldName].data.options}
            />
        </div>
      );
    case "select":
      const valueDataFiltered = propOr(filterConfig[fieldName].data.options, fieldName, filterData);
      const searchData = filterConfig[fieldName].data.options.filter((item) => {
        if (item.value == valueDataFiltered) {
          return item;
        }
      })
    
     const dataSelected = searchData[0];
      
      return (
        <div className="filter__footer" key={`filter_input_${index}`}>
           <Select
              defaultValue={dataSelected}
              onChange={(event) => {
                setFilterData({
                  ...filterData,
                  [filter.field]: event.value,
                });
              }}
              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              options={filterConfig[fieldName].data.options}
            />
        </div>
      );
    default:
      return null;
  }
};

const getFilterWithFromStorage = (filteredData) => {
  let filterWith = {};
  Object.keys(filteredData).forEach((filterKey) => {
    if (!isEmpty(filteredData[filterKey])) {
      filterWith[filterKey] = true;
    }
  });
  return filterWith;
};

const FilterButton = ({ onFiltersApply, filterCount, disabled }, ref) => {
  const tenantConfig = useTenantConfig();
  const [filterWith, setFilterWith] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [filterConfig, setFilterConfig] = useState({});
  const [tenantFilterConfig, setTenantFilterConfig] = useState([]);
  const filtersReady = () => {
    return (
      !isEmpty(tenantFilterConfig) &&
      !isEmpty(filterData) &&
      !isEmpty(filterConfig) &&
      !isEmpty(tenantFilterConfig)
    );
  };

  const onApply = (event) => {
    const nextFilteredData = getFilteredDataFromFilter(filterData, filterWith);
    setFilteredData(nextFilteredData);
    saveCustomerFiltersToStorage(nextFilteredData);
    setTimeout(toggleClick, 200);
  };

  const toggleRef = useRef(null);
  const applyButtonRef = useRef(null);
  const toggleClick = () => {
    toggleRef.current && toggleRef.current.click();
  };

  useImperativeHandle(ref, () => ({
    getFilteredData: () => filteredData,
    getFilterWith: () => filterWith,
  }));

  const filtersClear = (event) => {
    setFilterWith(getFilterWithDefault(filterConfig));
    setFilterData(getFilterDataDefault(filterConfig));
    setFilteredData({});
    clearCustomerFiltersOnStorage();
    toggleClick();
  };

  const getFilterWithDefault = (config) => {
    const filterWithDefault = {};
    Object.keys(config).forEach((key) => {
      filterWithDefault[config[key].data.name] = false;
    });
    return filterWithDefault;
  };

  const getFilterDataDefault = (config) => {
    const filterDataDefault = {};
    Object.keys(config).forEach((key) => {
      switch (config[key].type) {
        case "text_input":
        case "number_input":
          filterDataDefault[config[key].data.name] = "";
          break;
        case "taxonomy":
          filterDataDefault[config[key].data.name] = hasPath(
            [key, "data", "options", 0, "id"],
            config
          )
            ? config[key].data.options[0].id
            : undefined;
          break;
        case "select":
          filterDataDefault[config[key].data.name] = hasPath(
            [key, "data", "options", 0, "value"],
            config
          )
            ? config[key].data.options[0].value
            : undefined;
          break;
      }
    });
    return filterDataDefault;
  };

  useEffect(() => {
    if (!disabled && onFiltersApply) {
      onFiltersApply();
    }
  }, [filteredData]);

  const loadData = async () => {
    setTenantFilterConfig(propOr([], "customer_filter", tenantConfig));

    const filtersConfig = await getFiltersConfig(tenantConfig);

    setFilterConfig(filtersConfig);
    const customerFiltersFromStorage = getCustomerFiltersFromStorage();
    const filterDataDefault = getFilterDataDefault(filtersConfig);
    setFilterData(filterDataDefault);
    if (isNil(customerFiltersFromStorage)) {
      setFilterWith(getFilterWithDefault(filtersConfig));
      setFilterData(filterDataDefault);
      setFilteredData(filterDataDefault);
    } else {
      setFilterWith(getFilterWithFromStorage(customerFiltersFromStorage));
      setFilterData({ ...filterDataDefault, ...customerFiltersFromStorage });
      setFilteredData(customerFiltersFromStorage);
      setTimeout(() => {
        const { id, name, ...restOfFilters } = customerFiltersFromStorage;
        saveCustomerFiltersToStorage({
          ...restOfFilters,
        });
      }, 500);
    }
  };

  const onFilterWithChange = (filter) => (event) => {
    const fieldName = filter.field;
    const nextVal = !filterWith[fieldName];
    setFilterWith({
      ...filterWith,
      [fieldName]: nextVal,
    });
    if ((filterConfig[fieldName].type) && (filterConfig[fieldName].type != 'checkbox')) return;
    setFilterData({
      ...filterData,
      [fieldName]: nextVal ? true : undefined,
    });
  };

  const renderFilterOption = (filter, index) => {
    // console.log('filter', filter, index);
    const fieldName = filter.field;
    if (!filterConfig[fieldName]) return null;
    return (
      <React.Fragment key={`frag_${index}`}>
        <div className="filter__option" key={`filter_check_${index}`}>
          <label className="checkbox">
            <input
              checked={filterWith[fieldName]}
              type="checkbox"
              className=""
              name="option-filter"
              onChange={onFilterWithChange(filter)}
            />
            <span className="ck" />
            {filterConfig[fieldName].data.title}
          </label>
        </div>
        {filterWith[fieldName] &&
          renderInput(
            index,
            filter,
            filterData,
            setFilterData,
            onApply,
            filterConfig
          )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (isEmpty(tenantConfig)) return;
    loadData();
    // clearCustomerFiltersOnStorage();
  }, [tenantConfig]);

  return (
    <div className="filter-button filter">
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="filter-button__btn p-0"
          ref={toggleRef}
        >
          <div className="btn-group ">
            <span className="btn btn-default">
              <i className="far fa-filter mr-2" /> Filter{" "}
            </span>
            {filterCount > 0 && (
              <span className="btn btn-default">{filterCount}</span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="filter__header block-center-between">
            <button
              className="filter__clear btn btn-default"
              onClick={filtersClear}
            >
              Clear
            </button>
            <span className="filter__label ">Filters</span>
            <button
              className="filter__apply btn btn-default"
              onClick={onApply}
              ref={applyButtonRef}
            >
              apply
            </button>
          </div>
          <div className="filter__body">
            <div className="filter__options f-14">
              <div className="filter__option">
                {filtersReady() && tenantFilterConfig.map(renderFilterOption)}
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default forwardRef(FilterButton);
