import React from 'react'
import {
    Form,
} from 'react-bootstrap'
import { upperCaseFirstChar } from 'src/main_app/utils/string_utils'

const Checkbox = ({
    controlId,
    name,
    label,
    checked,
    onChange,
}) => (
    <Form.Group controlId={controlId}>
        <label className="checkbox checkbox--large mt-4">
            <input
                name={name}
                type="checkbox"
                checked={checked}
                onChange={onChange}
            /><span className="ck" /> {label || upperCaseFirstChar(name)}
        </label>
    </Form.Group>
)

export default Checkbox