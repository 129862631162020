import { useSelector } from "react-redux";

const useUserPermissions = () => {
  const permissions = useSelector(
    (state) => state.userPermissionReducer.permissions
  );
  return permissions;
};

export default useUserPermissions;
