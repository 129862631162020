import {
    getActionsData,
} from 'src/main_app/utils/customer_utils'
import { REFRESH_CUSTOMER_ACTIONS } from '../constants'

const refreshCustomerActionSuccess = (dispatch, getState) => (data) => dispatch({
    type: REFRESH_CUSTOMER_ACTIONS,
    data,
})

const refreshCustomerActionAsync = () => (dispatch, getState) => {
    const {
        customerActionReducer: {
            queryOptions: {
                id,
                currentPage,
                limit,
            }
        }
    } = getState()
    return getActionsData(id, currentPage, limit).then(refreshCustomerActionSuccess(dispatch, getState))
}

export default refreshCustomerActionAsync
