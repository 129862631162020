import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { pathOr, filter, reject, compose, pathEq } from "ramda";

import { fetchCustomerLinks } from "src/main_app/actions";
import { AddLink } from "src/main_app/components/customer-right-menu/modals";
import { deleteLinkItem, pinLink } from "src/main_app/utils/customer_utils";
import { authService } from "../../../../../base/services";

const linkStyle = {
  fontSize: "1.5rem",
};

const clickableLinkStyle = {
  ...linkStyle,
  cursor: "pointer",
};

const CustomerHelpfulLinks = ({
  canEditCustomer,
  links = [],
  fetchCustomerLinks,
  customerId,
  tenantConfig,
}) => {
  const { addToast } = useToasts();
  const [displayModalAddLink, setDisplayModalAddLink] = useState(false);

  const showModalAddLink = useCallback(
    () => setDisplayModalAddLink(true),
    [setDisplayModalAddLink]
  );

  const handleAddLinkClose = useCallback(
    () => setDisplayModalAddLink(false),
    [setDisplayModalAddLink]
  );

  const responseProcess = (response, successMess, failMessage) => {
    addToast(response === "success" ? successMess : failMessage, {
      appearance: response === "success" ? "success" : "error",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    if (response === "success" && fetchCustomerLinks) {
      fetchCustomerLinks(customerId);
    }
  };

  const onRemoveLink = (link) => async () => {
    if (!confirm("Are you sure you want to delete this item?")) return;
    const response = await deleteLinkItem(link.id);
    responseProcess(
      response,
      "Link removed successfully",
      "Remove link failed"
    );
  };

  // const onPinLink = (link) => async () => {
  //   const response = await pinLink(link.id);
  //   responseProcess(response, "Link update successfully", "Update link failed");
  // };
  const user = authService.getUser();

  useEffect(() => {
    import("./customer-helpful-links-style.scss");
    fetchCustomerLinks(customerId);
  }, []);

  const pinedLinks = filter(pathOr(false, ["meta", "priority"]), links);
  const normalLinks = reject(pathOr(false, ["meta", "priority"]), links);

  const isDefaultLink = pathEq(["meta", "isDefault"], true);

  return (
    <>
      <h3 className="sub-title d-flex justify-content-between">
        <span>Resources</span>
        {canEditCustomer && tenantConfig.slug != 'maitland' && (
          <i
            className="fal fa-plus-circle"
            style={{
              cursor: "pointer",
            }}
            onClick={showModalAddLink}
          ></i>
        )}
      </h3>
      {links.length > 0 && (
        <div className="box">
          <a className="underline mt-4 color-inherit d-none">
            Open content manager
          </a>
          {pinedLinks.map((link, index) => (
            <div
              key={index}
              className="helpful-link pt-2 pb-3 d-flex justify-content-between align-items-baseline"
            >
              <a
                className="underline mt-4 color-inherit"
                href={link.type == 'true' ? `https://awsbuckets3.blob.core.windows.net/aquo-link-files/${link.url}` : link.url}
                target="_blank"
              >
                {link.display_text}
              </a>
              <div className="link-actions-container">
                <div className="btn-unpin-link">
                  <i className="far fa-thumbtack" style={linkStyle}></i>
                </div>
                {canEditCustomer && !isDefaultLink(link) && (
                  <div className="btn-remove-link">
                    <i
                      className="fal fa-minus-circle"
                      style={clickableLinkStyle}
                      onClick={onRemoveLink(link)}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          ))}
          {pinedLinks.length > 0 && normalLinks.length > 0 && <hr />}
          {(normalLinks || []).map((link, index) => {
            if ((tenantConfig.slug !== 'maitland') && (user.role != "Customer")) {
              return (
                <div
                  key={index}
                  className="helpful-link pt-2 pb-3 d-flex justify-content-between align-items-baseline"
                >
                  <a
                    className="underline mt-4 color-inherit"
                    href={link.type == 'true' ? `https://awsbuckets3.blob.core.windows.net/aquo-link-files/${link.url}` : link.url}
                    target="_blank"
                  >
                    {link.display_text}
                  </a>
                  <div className="link-actions-container">
                    {canEditCustomer && !isDefaultLink(link) && (
                      <div className="btn-remove-link">
                        <i
                          className="fal fa-minus-circle"
                          style={clickableLinkStyle}
                          onClick={onRemoveLink(link)}
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
              )
              } else if((user.role == "Customer") && (link.display_text != 'Velocity' && link.display_text != 'Content Manager')) {
                return (
                  <div
                    key={index}
                    className="helpful-link pt-2 pb-3 d-flex justify-content-between align-items-baseline"
                  >
                    <a
                      className="underline mt-4 color-inherit"
                      href={link.type == 'true' ? `https://awsbuckets3.blob.core.windows.net/aquo-link-files/${link.url}` : link.url}
                      target="_blank"
                    >
                      {link.display_text}
                    </a>
                    <div className="link-actions-container">
                      {canEditCustomer && !isDefaultLink(link) && (
                        <div className="btn-remove-link">
                          <i
                            className="fal fa-minus-circle"
                            style={clickableLinkStyle}
                            onClick={onRemoveLink(link)}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                )
              }
              if (tenantConfig.slug === 'maitland' && link.display_text !== 'Velocity')
              return (
                <div
                  key={index}
                  className="helpful-link pt-2 pb-3 d-flex justify-content-between align-items-baseline"
                >
                  <a
                    className="underline mt-4 color-inherit"
                    href={link.type == 'true' ? `https://awsbuckets3.blob.core.windows.net/aquo-link-files/${link.url}` : link.url}
                    target="_blank"
                  >
                    {link.display_text}
                  </a>
                </div>
              )
          })}
        </div>
      )}
      <AddLink
        showModal={displayModalAddLink}
        handleActionsClose={handleAddLinkClose}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  total: state.customerLinkReducer.total,
  links: state.customerLinkReducer.data,
});
const mapActionToProps = {
  fetchCustomerLinks,
};

export default connect(mapStateToProps, mapActionToProps)(CustomerHelpfulLinks);
