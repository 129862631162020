import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { track } from "src/main_app/mixpanel";
import { sendMailUpdateAddress, customerAddOrUpdateMeta} from 'src/main_app/services/apis';
import update from "immutability-helper";
import { useParams } from "react-router-dom";
import { find, isEmpty, pathOr, propEq, propOr, indexOf } from "ramda";
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { isNilOrEmpty, jsonStringToValue, formatDateDDMMMYYYY } from "src/main_app/utils/common.js";
import { 
    filterRemovedItems,
    getTargetChecked,
    getTargetValue,
    inputChangeHandler,
    targetIsCheckbox,
 } from "src/main_app/utils/react_utils";
import {
  AddressAutoComplete,
  Checkbox,
  Select1,
  Textbox,
} from "src/main_app/components/form_elements";
import DeedTreatment from "./deed-treatment";
import DeedSubstances from "./deed-substances";
import DeedReporting from "./deed-reporting";
import { getCustomerTradeWasteDataDetail } from "src/main_app/utils/customer_utils";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
import moment from 'moment';

const CustomerTradeWasteDeed = ({
  tenantConfig,
  showModal,
  handleTradeWasteDeedClose,
  user,
  tradeWasteDeedData,
  onCustomerTradeWasteDeedPdfChange,
  onCustomerTradeWasteDeedPdfFieldChange,
  // handleSubmitPopupDeed
}) => {
  if (!showModal) return null
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const { addToast } = useToasts();
  const params = useParams();
  const customerTreatmentDefault = useRef({});
  const [customerTreatmentData, setCustomerTreatmentData] = useState([]);
  const customerSubstancesDefault = useRef({});
  const [customerSubstancesData, setCustomerSubstancesData] = useState([]);
  const customerReportingDefault = useRef({});
  const [customerReportingData, setCustomerReportingData] = useState([]);
  const customer_id = params.id;

  const process_rows = (customer_section, tradeWasteDeedData) => {
    let layout_columns = 0;
    let fields = [];
    let rows = [];
    propOr([], "fields", customer_section).forEach((field, index) => {
      layout_columns += pathOr(0, ["field_data", "layout_columns"], field);
      if (layout_columns > 12) {
        rows.push(fields);
        layout_columns = pathOr(0, ["field_data", "layout_columns"], field);
        fields = [];
      }
      fields.push({
        field,
        index,
        currentValue: tradeWasteDeedData[pathOr("", ["field_data", "name"], field)],
      });
    });
    if (!isEmpty(fields)) {
      rows.push(fields);
    }
    return rows;
  };

  const render_row = (fields, rowIndex) => {
    if (isEmpty(fields)) return null;

    return (
      <div key={rowIndex + ""} className="row align-items-center">
        {fields
          .filter(pathOr(true, ["field", "field_data", "visible"]))
          .map(({ field, index, currentValue }) => {
            switch (propOr(null, "field_type", field)) {
              case "text_input":
                return (
                  <div key={index} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} `}>
                    <Textbox
                      controlId={pathOr("", ["field_data", "name"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      label={pathOr("", ["field_data", "title"], field)}
                      defaultValue={jsonStringToValue(currentValue)}
                      onChange={onCustomerTradeWasteDeedPdfChange}
                      disabled={pathOr(false,["field_data", "readOnly"],field)}
                    />
                  </div>
                );
              case "number_input":
                return (
                  <div key={index} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} `}>
                    <Textbox
                      controlId={pathOr("", ["field_data", "name"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      label={pathOr("", ["field_data", "title"], field)}
                      type="number"
                      defaultValue={pathOr("", ["field_data", "default_value"], field) ? 0.85 : jsonStringToValue(currentValue)}
                      onChange={onCustomerTradeWasteDeedPdfChange}
                      disabled={pathOr(false,["field_data", "readOnly"],field)}
                    />
                  </div>
              );
              case "select":
                return (
                  <div key={index + ""} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} `}>
                    <Select1
                      label={pathOr("", ["field_data", "title"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      items={pathOr("", ["field_data", "name"], field) == 'anzsic_code' ? listAnzsicCode : pathOr("", ["field_data", "options"], field)}
                      hasEmptyOption={true}
                      value={isNilOrEmpty(currentValue) ? "" : jsonStringToValue(currentValue)}
                      onChange={ e => onCustomerTradeWasteDeedPdfFieldChange(pathOr("", ["field_data", "name"], field))(e.value) }
                      disabled={pathOr(
                        false,
                        ["field_data", "readOnly"],
                        field
                      )}
                    />
                  </div>
                );
              case "checkbox":
                return (
                  <div key={index + ""} className={`col-md-${pathOr("", ["field_data", "layout_columns"], field)} `}>
                    <Checkbox
                      label={pathOr("", ["field_data", "title"], field)}
                      name={pathOr("", ["field_data", "name"], field)}
                      controlId={pathOr("", ["field_data", "name"], field)}
                      checked={!isEmpty(currentValue) ? currentValue : false}
                      onChange={ e => onCustomerTradeWasteDeedPdfFieldChange(pathOr("", ["field_data", "name"], field))(e.target.checked) }
                    />
                  </div>
                );
                case "date_input":
                  const valueDate = currentValue ? formatDateDDMMMYYYY(currentValue) : false;
                  return (
                    <div key={index + ""} className={`col-md-${pathOr("",["field_data", "layout_columns"],field)} mb-4`}>
                      <label>{pathOr("", ["field_data", "title"], field)}</label>
                      <DateRangePicker
                        setting={{
                          singleDatePicker: true,
                          autoUpdateInput: !!valueDate,
                          startDate: valueDate,
                        }}
                        onApply={(event, picker) => {
                          const nDate = picker.startDate.utcOffset(0, true);
                          picker.element.val(nDate.format("DD MMM YYYY"));
                          onCustomerTradeWasteDeedPdfFieldChange(pathOr("", ["field_data", "name"], field))(nDate.toDate());
                        }}
                      >
                    <input type="text" className="form-control" />
                </DateRangePicker>
                    </div>
                  )
              default:
                return null;
            }
          })}
      </div>
    );
  };


  const render_section_fields = (customer_section, tradeWasteDeedData) => {
    const rows = process_rows(customer_section, tradeWasteDeedData);
    return rows.map(render_row);
  };

  const render_each_form = () => {
    return (
      <>
        <div className="row align-items-center">
          <div className="col-md-12">
            <label>Fees and payments</label>
            <textarea className="form-control" value={pathOr("", ["deed_fees_and_payments"], tradeWasteDeedData)} onChange={ e => onCustomerTradeWasteDeedPdfFieldChange('deed_fees_and_payments')(e.target.value) }/>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-md-12">
            <Checkbox
              label="Backflow prevention compliant"
              name="backflow_prevention_compliant"
              controlId="backflow_prevention_compliant"
              checked={pathOr(false, ["backflow_prevention_compliant"], tradeWasteDeedData)}
              onChange={ e => onCustomerTradeWasteDeedPdfFieldChange('backflow_prevention_compliant')(e.target.checked) }
            />
          </div>
        </div>

        <div className="row align-items-center mt-3">
          <div className="col-md-12">
            <label>Backflow prevention compliance notes</label>
            <textarea className="form-control" value={pathOr("", ["deed_backflow_revention_compliance_notes"], tradeWasteDeedData)} onChange={ e => onCustomerTradeWasteDeedPdfFieldChange('deed_backflow_revention_compliance_notes')(e.target.value) }/>
          </div>
        </div>
        <div className="row align-items-center mt-4">
          <div className="col-md-12">
            <label>Special conditions</label>
            <textarea className="form-control" value={pathOr("", ["deed_special_conditions"], tradeWasteDeedData)} onChange={ e => onCustomerTradeWasteDeedPdfFieldChange('deed_special_conditions')(e.target.value) }/>
          </div>
        </div>
      </>
    )
  }

   //Start render reporting obligations
   const render_section_reporting = (customer_section, customerReportingData) => {
    setReportingDefaultValue(propOr(null, "fields", customer_section));
    return (
    <>
      {customerReportingData.map(
        (reporting, reporting_index) =>
          !reporting.isRemoved && (
            <DeedReporting
              key={reporting_index + ""}
              onRemove={onRemoveReporting(reporting)}
              onChange={onReportingChange(reporting)}
              section_config={pathOr([], ["fields"], customer_section)}
              reporting={reporting}
              setReportingDefault={setReportingDefault}
            />
          )
      )}
      <div className="text-right mb-4" style={{paddingRight: "8%"}}>
        <a className="underline" onClick={addNewReporting}>
          Add another
        </a>
      </div>
    </>
    )
  };
    //each section
    const addNewReporting = () => {
      setCustomerReportingData([
        ...customerReportingData,
        customerReportingDefault.current,
      ]);
    };
  
    const setReportingDefaultValue = (section_config) => {
      pathOr([], ["field_data", "fields"], section_config[0]).map((field) => {
        if (pathOr(null, ["field_data", "default_value"], field)) {
          setReportingDefault(
            pathOr(false, ["field_data", "name"], field),
            pathOr(false, ["field_data", "default_value"], field)
          );
        } else {
          setReportingDefault(pathOr(false, ["field_data", "name"], field), "");
        }
      });
    };
  
    const setReportingDefault = (name, value) => {
      customerReportingDefault.current = {
        ...customerReportingDefault.current,
        [name]: value,
      };
    };
  
    const onRemoveReporting = (reporting) => (event) => {
      const index = indexOf(reporting, customerReportingData);
      const updateCondition = reporting.id
        ? {
            [index]: {
              isRemoved: {
                $set: true,
              },
            },
          }
        : {
            $splice: [[index, 1]],
          };
      const newCustomerReportingData = update(
        customerReportingData,
        updateCondition
      );
      setCustomerReportingData(newCustomerReportingData);
    };
  
    const onReportingChange = (reporting) => (event) => {
      const index = indexOf(reporting, customerReportingData);
      let newCustomerReportingData  = [];
      if (event.value) {
        newCustomerReportingData = update(customerReportingData, {
        [index]: {
          ['reporting']: {
            $set: targetIsCheckbox(event)
              ? getTargetChecked(event)
              : event.value,
          },
        },
      });
      } else {
        newCustomerReportingData = update(customerReportingData, {
        [index]: {
          [event.target.name]: {
            $set: targetIsCheckbox(event)
              ? getTargetChecked(event)
              : getTargetValue(event),
          },
        },
      });
      }
      setCustomerReportingData(newCustomerReportingData);
    };
    //each section
  //End render reporting obligations

  //Start render substances
  const render_section_substances = (customer_section, customerSubstancesData) => {
    setSubstancesDefaultValue(propOr(null, "fields", customer_section));
    return (
    <>
      {customerSubstancesData.map(
        (substances, substances_index) =>
          !substances.isRemoved && (
            <DeedSubstances
              key={substances_index + ""}
              onRemove={onRemoveSubstances(substances)}
              onChange={onSubstancesChange(substances)}
              section_config={pathOr([], ["fields"], customer_section)}
              substances={substances}
              setSubstancesDefault={setSubstancesDefault}
            />
          )
      )}
      <div className="text-right mb-4" style={{paddingRight: "8%"}}>
        <a className="underline" onClick={addNewSubstances}>
          Add another
        </a>
      </div>
    </>
    )
  };
    //each section
    const addNewSubstances = () => {
      setCustomerSubstancesData([
        ...customerSubstancesData,
        customerSubstancesDefault.current,
      ]);
    };
  
    const setSubstancesDefaultValue = (section_config) => {
      pathOr([], ["field_data", "fields"], section_config[0]).map((field) => {
        if (pathOr(null, ["field_data", "default_value"], field)) {
          setSubstancesDefault(
            pathOr(false, ["field_data", "name"], field),
            pathOr(false, ["field_data", "default_value"], field)
          );
        } else {
          setSubstancesDefault(pathOr(false, ["field_data", "name"], field), "");
        }
      });
    };
  
    const setSubstancesDefault = (name, value) => {
      customerSubstancesDefault.current = {
        ...customerSubstancesDefault.current,
        [name]: value,
      };
    };
  
    const onRemoveSubstances = (substances) => (event) => {
      const index = indexOf(substances, customerSubstancesData);
      const updateCondition = substances.id
        ? {
            [index]: {
              isRemoved: {
                $set: true,
              },
            },
          }
        : {
            $splice: [[index, 1]],
          };
      const newCustomerSubstancesData = update(
        customerSubstancesData,
        updateCondition
      );
      setCustomerSubstancesData(newCustomerSubstancesData);
    };
  
    const onSubstancesChange = (substances) => (event) => {
      const index = indexOf(substances, customerSubstancesData);
      let newCustomerSubstancesData  = [];
      if (event.value) {
        newCustomerSubstancesData = update(customerSubstancesData, {
        [index]: {
          ['substances']: {
            $set: targetIsCheckbox(event)
              ? getTargetChecked(event)
              : event.value,
          },
        },
      });
      } else {
        newCustomerSubstancesData = update(customerSubstancesData, {
        [index]: {
          [event.target.name]: {
            $set: targetIsCheckbox(event)
              ? getTargetChecked(event)
              : getTargetValue(event),
          },
        },
      });
      }
      setCustomerSubstancesData(newCustomerSubstancesData);
    };
    //each section
  //End render substances

  //Start render treatment 
  const render_section_treatment = (customer_section, customerTreatmentData) => {
    setTreatmentDefaultValue(propOr(null, "fields", customer_section));
    return (
    <>
      {customerTreatmentData.map(
        (treatment, treatment_index) =>
          !treatment.isRemoved && (
            <DeedTreatment
              key={treatment_index + ""}
              onRemove={onRemoveTreatment(treatment)}
              onChange={onTreatmentChange(treatment)}
              section_config={pathOr([], ["fields"], customer_section)}
              treatment={treatment}
              setTreatmentDefault={setTreatmentDefault}
            />
          )
      )}
      <div className="text-right mb-4" style={{paddingRight: "8%"}}>
        <a className="underline" onClick={addNewTreatment}>
          Add another
        </a>
      </div>
    </>
    )
  };

  //each section
  const addNewTreatment = () => {
    setCustomerTreatmentData([
      ...customerTreatmentData,
      customerTreatmentDefault.current,
    ]);
  };

  const setTreatmentDefaultValue = (section_config) => {
    pathOr([], ["field_data", "fields"], section_config[0]).map((field) => {
      if (pathOr(null, ["field_data", "default_value"], field)) {
        setTreatmentDefault(
          pathOr(false, ["field_data", "name"], field),
          pathOr(false, ["field_data", "default_value"], field)
        );
      } else {
        setTreatmentDefault(pathOr(false, ["field_data", "name"], field), "");
      }
    });
  };

  const setTreatmentDefault = (name, value) => {
    customerTreatmentDefault.current = {
      ...customerTreatmentDefault.current,
      [name]: value,
    };
  };

  const onRemoveTreatment = (treatment) => (event) => {
    const index = indexOf(treatment, customerTreatmentData);
    const updateCondition = treatment.id
      ? {
          [index]: {
            isRemoved: {
              $set: true,
            },
          },
        }
      : {
          $splice: [[index, 1]],
        };
    const newCustomerTreatmentData = update(
      customerTreatmentData,
      updateCondition
    );
    setCustomerTreatmentData(newCustomerTreatmentData);
  };

  const onTreatmentChange = (treatment) => (event) => {
    const index = indexOf(treatment, customerTreatmentData);
    let newCustomerTreatmentData  = [];
    if (event.value) {
      newCustomerTreatmentData = update(customerTreatmentData, {
      [index]: {
        ['treatment']: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : event.value,
        },
      },
    });
    } else {
      newCustomerTreatmentData = update(customerTreatmentData, {
      [index]: {
        [event.target.name]: {
          $set: targetIsCheckbox(event)
            ? getTargetChecked(event)
            : getTargetValue(event),
        },
      },
    });
    }
    setCustomerTreatmentData(newCustomerTreatmentData);
  };
  //each section
  //End render treatment 

  const validateTimeTradeWasteDeed = () => {
    let allValidated = true;
    const fromDate = tradeWasteDeedData['deed_date'];
    const toDate = tradeWasteDeedData['deed_expiry'];
    // check fromDate valid
    if ((fromDate != undefined) && (toDate != undefined)) {
      const startDate = formatDateDDMMMYYYY(fromDate);
      const endDate = formatDateDDMMMYYYY(toDate);
      const isFromDateBeforeToDate = moment(startDate).isSameOrBefore(moment(endDate))// boolean
      // const isFromDateBeforeToDate = moment(formatDateDDMMMYYYY(fromDate)).isBefore(moment(formatDateDDMMMYYYY(toDate)))// boolean
      if (!isFromDateBeforeToDate) {
        allValidated = false;
        addToast("Deed date must be before the Deed expiry", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    }

    const commencementDate = tradeWasteDeedData['deed_hw_commencement_date'];
    const commencementExpiryDate = tradeWasteDeedData['deed_hw_expiry_date'];
    // check fromDate valid
    if ((commencementDate != undefined) && (commencementExpiryDate != undefined)) {
      const isFromCommencementDateBeforeToDate = moment(formatDateDDMMMYYYY(commencementDate)).isSameOrBefore(moment(formatDateDDMMMYYYY(commencementExpiryDate)))// boolean
      if (!isFromCommencementDateBeforeToDate) {
        allValidated = false;
        addToast("Commencement date must be before the Expiry date", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    }
   
    return allValidated;
  };

  const updateCustomerTradeWasteDeed = async (customerID) => {
    const type_deed_form_popup = 'customer_deed_form_popup';
    const type_deed_treatment = 'customer_deed_treatment';
    const type_deed_substances = 'customer_deed_substances';
    const type_reporting = 'customer_deed_reporting';
    const resTreatment = await customerAddOrUpdateMeta(customerID, type_deed_treatment, customerTreatmentData)
    const resSubstances = await customerAddOrUpdateMeta(customerID, type_deed_substances, customerSubstancesData)
    const resReporting = await customerAddOrUpdateMeta(customerID, type_reporting, customerReportingData)
    const resPopup = await customerAddOrUpdateMeta(customerID, type_deed_form_popup, tradeWasteDeedData, 'generate_pdf')
    const file_name_pdf =  propOr("", 'data', resPopup);
    window.open(`https://awsbuckets3.blob.core.windows.net/aquo-link-files/${file_name_pdf}`,'_blank')

  };

  const handleSubmit = async (event) => {
    const tradeWasteDeedValidated = validateTimeTradeWasteDeed();
    if (!tradeWasteDeedValidated) return;
    await updateCustomerTradeWasteDeed(params.id);
    await loadData();
    addToast("Update successfully.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    handleTradeWasteDeedClose();
    window.location.reload()
  };

  const loadData = async () => {
    const dataTreatment = await getCustomerTradeWasteDataDetail(params.id, 'customer_deed_treatment');
    const dataSubstances = await getCustomerTradeWasteDataDetail(params.id, 'customer_deed_substances');
    const dataReportingData = await getCustomerTradeWasteDataDetail(params.id, 'customer_deed_reporting');
    setCustomerTreatmentData(dataTreatment)
    setCustomerSubstancesData(dataSubstances)
    setCustomerReportingData(dataReportingData)
    // setTradeWasteDeedData({
    //   deed_name: "THE EXECUTIVE INN PTY LTD",
    // })
  }
  
  useEffect(() => {
    if (!showModal) return;
    loadData();
    track("Trade waste deed", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [showModal])

  return (
    <Modal show={showModal} onHide={handleTradeWasteDeedClose} size={"lg"}>
      <Modal.Header closeButton>
            <Modal.Title>{tradeWasteDeedData['deed_status_update'] ? 'Update' : 'Create'} trade waste deed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-link-form" className="">
            {propOr([], "customer_trade_waste_deed_fields", tenantConfig).map((customer_trade_waste_section, index) => (
                <>
                <div className="modal-title h4 mt-4">{propOr("", "section_name", customer_trade_waste_section)}</div>
                  {propOr("", "section_name", customer_trade_waste_section) != '' ? <hr></hr> : ''}
                  {
                    propOr("", "field_type", customer_trade_waste_section.fields[0]) === "treatment" ?  render_section_treatment(customer_trade_waste_section, customerTreatmentData)
                    : propOr("", "field_type", customer_trade_waste_section.fields[0]) === "substances" ?  render_section_substances(customer_trade_waste_section, customerSubstancesData)
                    : propOr("", "field_type", customer_trade_waste_section.fields[0]) === "reporting" ?  render_section_reporting(customer_trade_waste_section, customerReportingData)
                    : render_section_fields(customer_trade_waste_section, tradeWasteDeedData)}
                </>
              )
            )}
            {render_each_form()}
            <br></br>

            {/* <a className="btn btn--save btn-primary" variant="primary" href="https://aquo-link-files.s3.ap-southeast-2.amazonaws.com/Deed_Agreement (003).pdf"
                      target="_blank" onClick={handleSubmit}>
                {customer_id == 866 ? 'UPDATE' : 'CREATE'} DEED
            </a> */}
            <a className="btn btn--save btn-primary" variant="primary"
                      target="_blank" onClick={handleSubmit}>
                {tradeWasteDeedData['deed_status_update'] ? 'UPDATE' : 'CREATE'} DEED
            </a>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerTradeWasteDeed;

