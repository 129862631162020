import React from 'react'
import Skeleton from 'react-loading-skeleton'

const AlertsPageChartRealTimeSkeleton = () => {
  
  return (
    <div className="=mt-5">
      <div className="mb-5">
        <Skeleton width="100%" height="400px" />
      </div>
    </div>
  )
}

export default AlertsPageChartRealTimeSkeleton
