import {
  prop,
  pathOr,
} from 'ramda'
import {
  getDataImportDashboard,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getImportDashBoard = async (page, limit, search) => {
  const result = await getDataImportDashboard({
    page,
    limit,
    search
  })
  return isServerResponseError(result) || hasError(result)
    ? {
      total: 0,
      data: []
    }
    : {
      total: pathOr([], ['data', 'total'], result),
      data: pathOr([], ['data', 'data'], result),
    }
}

export default getImportDashBoard
