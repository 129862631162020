import React from "react";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css"; 
import "./style.scss"; 

export const TagInput = ({ label, value = [], onChange, disabled }) => (
  <React.Fragment>
    {label && (
      <label className="form-label" for="address">
        {label}
      </label>
    )}
    <div className="form-group">
      <TagsInput
        disabled={disabled}
        value={value}
        onChange={onChange}
        className="react-tagsinput w-100"
      />
    </div>
  </React.Fragment>
);
