import {
  prop,
  pathOr,
} from 'ramda'
import {
  getTaxonomyBenchmark
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getTaxonomyBenchmarkInfo = async (id) => {
  const result = await getTaxonomyBenchmark(id)

  // hard code for aged care
  // if (!(isServerResponseError(result) || hasError(result)) && id == 31) {
  //   return {
  //     "sector_label": "Benchmark",
  //     "fields": [
  //       {
  //         "label": "Benchmark Usage",
  //         "data": "kL/occupied bed pa",
  //         "layout_column": 3
  //       },
  //       {
  //         "label": "Sector Range",
  //         "data": "0- 200",
  //         "layout_column": 3
  //       },
  //       {
  //         "label": "Sector Mean",
  //         "data": "70",
  //         "layout_column": 3
  //       },
  //       {
  //         "label": "Sector Best Practice",
  //         "data": "50",
  //         "layout_column": 3
  //       }
  //     ]
  //   }
  // }

  return isServerResponseError(result) || hasError(result)
    ? null
    : pathOr([], ['data', 'data'], result)
}

export default getTaxonomyBenchmarkInfo
