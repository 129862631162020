import React from 'react'
import Skeleton from 'react-loading-skeleton'
import {propOr} from 'ramda'

const CustomerOverviewSkeleton = (props) => {

  return (
    <>
      <div className="container-fluid content">
        <div className="row">
          <div className="col-md-9 mb-5">
            <form>
              <h3 className="sub-title mt-4"><Skeleton /></h3>
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <h3 className="sub-title mt-4"><Skeleton /></h3>
              <div className="row align-items-center">
                <div className="col-md-6 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <h3 className="sub-title mt-4"><Skeleton /></h3>
              <div className="row align-items-center">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="form-group">
                    <Skeleton />
                    <Skeleton height={'calc(1.45em + 2rem + 2px)'}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-3 secondary-color sidebar-right">
            <h3 className="sub-title"><Skeleton /></h3>
            <div className="box">
              <table border="0" cellPadding="0" cellSpacing="0">
                <tbody>
                <tr>
                  <td className="pr-4" style={{
                    textTransform: 'capitalize',
                    fontWeight: '700',
                  }}>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td className="pr-4" style={{
                    textTransform: 'capitalize',
                    fontWeight: '700',
                  }}>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <h3 className="sub-title"><Skeleton /></h3>
            <div className="box">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerOverviewSkeleton
