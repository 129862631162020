import {
  prop,
  pathOr,
} from 'ramda'
import {
  getCustomersFilterOptions,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getFilterOptions = async (filterType) => {
  const result = await getCustomersFilterOptions({
    searchIn: filterType
  })
  return isServerResponseError(result) || hasError(result)
    ? []
    : pathOr([], ['data', 'data'], result)
}

export default getFilterOptions