import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReportsList = ({ title = "", items = [], allowEdit }) => {
  return (
    <ListGroup>
      <ListGroup.Item className="list-title">{title}</ListGroup.Item>
      {items.map((item, key) => {
        return (
          <ListGroup.Item key={key} className="list-item">
            <div className="d-flex justify-content-between">
              <Link className="flex-grow-1" to={`/report-details/${item.id}`}>
                {item.value}
              </Link>
              {allowEdit && (
                <Link to={`/report-details/${item.id}/edit`}>Edit</Link>
              )}
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};

export default ReportsList;
