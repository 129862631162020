import {
    compose,
    propOr,
    reject,
} from "ramda"

import { isTrue } from "../common"

const rejectRemovedItems = reject(
    compose(
        isTrue,
        propOr(false, 'isRemoved')
    )
)

export default rejectRemovedItems