import GET_USER_ROLES from "../constants";

const userRoleReducer = (state = {
    total: 0,
    data: []
}, action) => {
    switch (action.type) {
        case GET_USER_ROLES:
            return {
                ...action.data
            }
        default:
            return state
    }
}

export default userRoleReducer