import { connect } from 'react-redux';

const { fetchCustomerTimeline } = require("src/main_app/actions");

const mapStateToProps = state => ({
    timelineData: state.customerTimelineReducer.data
})

const mapActionToProps = {
    fetchCustomerTimeline
}

const withRedux = component => connect(
    mapStateToProps,
    mapActionToProps
)(component)

export default withRedux
