import {
  prop,
} from 'ramda'
import {
  getUserInfo,
} from '../../services/apis'
import {
  isServerResponseError,
} from '../common'

const getUserData = async id => {
  const result = await getUserInfo(id)
  const data = isServerResponseError(result)
    ? {}
    : prop('data', result)
  return data
}

export default getUserData