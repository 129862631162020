import React from 'react'
import {Dropdown} from 'react-bootstrap';


const PlansSetting = props => {
  return (
      <div className="filter-button filter">
        <Dropdown>
          <Dropdown.Toggle variant="" className="filter-button__btn p-0">
            <span className="btn btn-default" ><i className="far fa-sliders-v mr-1  f-16"></i> Settings </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="filter__header block-center-between">
              <button className="filter__clear btn btn-default">Clear</button>
              <span className="filter__label ">Settings</span>
              <button className="filter__apply btn btn-default">apply</button>
            </div>
            <div className="filter__body">
              <div className="form-groups pt-4 pb-3">
                <div className="form-group">
                  <label>
                    Climate and Weather (percentile)
                  </label>
                  <select className="form-control">
                    <option>Average</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Restriction Level
                  </label>
                  <select className="form-control">
                    <option>Level 0</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Customer Demand
                  </label>
                  <select className="form-control">
                    <option>Medium</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Covid Impact
                  </label>
                  <select className="form-control">
                    <option>Medium</option>
                  </select>
                </div>

              </div>
            </div>

          </Dropdown.Menu>
        </Dropdown>
      </div>
  )
}

export default PlansSetting
