import axios from "axios";

let willLogout = false;

export default () => {
  const responseSuccessHandler = (response) => {
    return response;
  };

  const responseErrorHandler = (error) => {
    if ([
      401,
      // 403,
      // 502,
    ].includes(error.response.status) && !willLogout) {
      willLogout = true;
      document.location.href = "/sign-out";
    }

    return Promise.reject(error);
  };

  axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  );
};
