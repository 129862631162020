import { isEmpty, propOr } from "ramda";
import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";

import { DateRangePicker } from "src/main_app/components/date_range_picker";
import actionStatus from "src/main_app/enums/action-status.json";
import actionSavingUnit from "src/main_app/enums/action-saving-unit.json";
import { addAction, editAction } from "src/main_app/utils/customer_utils";
import { setTargetValue } from "src/main_app/utils/react_utils";
import Select from '@bhunjadi/react-select-v3';
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

import { track } from "src/main_app/mixpanel";

const AddAction = ({
  showAddActionsModal,
  handleAddActionsClose,
  refeshCustomerActions,
  isUpdate = false,
  actionId = null,
  actionData = null,
}) => {
  useEffect(() => {
    if (showAddActionsModal) track("Add action modal",  {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [showAddActionsModal]);
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const params = useParams();
  const { addToast } = useToasts();
  const [actionName, setActionName] = useState("");
  const [saving, setSaving] = useState("");
  const [savingUnit, setSavingUnit] = useState(actionSavingUnit["ML / Annum"]);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [implementedDate, setImplementedDate] = useState(null);
  const [status, setStatus] = useState({value: 1, label: 'Pending'});
  const [actionContent, setActionContent] = useState("");
  const [isEmptyName, setIsEmptyName] = useState(false);
  const actionItemRef = useRef(null);

  const resetStates = () => {
    setActionName("");
    setSaving("");
    setSavingUnit(actionSavingUnit["ML / Annum"]);
    setStatus({value: 1, label: 'Pending'});
    setActionContent("");
    setIsEmptyName(false);
  };

  const listActionStatus = [
    {value: 1, label: 'Pending'},
    {value: 3, label: 'Cancel'},
    {value: 4, label: 'Implemented'},
    {value: 5, label: 'Scheduled'},
  ];

  const actionAddHandler = async (event) => {
    if (isEmpty(actionName)) {
      addToast("Action item field must not be empty", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyName(true);
      return;
    }
    const response = await addAction(params.id, {
      name: actionName,
      savings: saving,
      savings_units: savingUnit,
      scheduled_date: scheduledDate,
      implemented_date: implementedDate,
      status: status.value,
      notes: actionContent,
    });
    addToast(
      response === "success"
        ? "Action added successfully"
        : "Adding action failed",
      {
        appearance: response === "success" ? "success" : "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      }
    );
    if (response === "success" && refeshCustomerActions) {
      refeshCustomerActions();
    }
    handleAddActionsClose();
    resetStates();
  };

  const actionUpdateHandler = async (event) => {
    if (isEmpty(actionName)) {
      addToast("Action item field must not be empty", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyName(true);
      return;
    }
    const response = await editAction(actionId, {
      name: actionName,
      savings: saving,
      savings_units: savingUnit,
      scheduled_date: scheduledDate,
      implemented_date: implementedDate,
      status: status.value,
      notes: actionContent,
    });
    addToast(
      response === "success"
        ? "Action updated successfully"
        : "Updating action failed",
      {
        appearance: response === "success" ? "success" : "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      }
    );
    if (response === "success" && refeshCustomerActions) {
      refeshCustomerActions();
    }
    handleAddActionsClose();
    resetStates();
  };

  useEffect(() => {
    if (!actionData) return;
    if (isUpdate) {
      setActionName(propOr("", "name", actionData));
      setSaving(propOr("", "savings", actionData));
      setSavingUnit(
        propOr(actionSavingUnit["ML / Annum"], "savings_units", actionData)
      );

      // check case to binding data dropdown status
      switch(actionData['status']) {
        case "1":
          setStatus({value: 1, label: 'Pending'});
          break;
        case "3":
          setStatus({value: 3, label: 'Cancel'});
          break;
        case "4":
          setStatus({value: 4, label: 'Implemented'});
          break;
        case "5":
          setStatus({value: 5, label: 'Scheduled'});
          break;
        default:
          setStatus({value: 1, label: 'Pending'});
      }

      const scheduled_date = propOr(null, "scheduled_date", actionData);
      if (scheduled_date) {
        setScheduledDate(new Date(scheduled_date));
      }

      const implemented_date = propOr(null, "implemented_date", actionData);
      if (implemented_date) {
        setImplementedDate(new Date(implemented_date));
      }
      setActionContent(propOr("", "notes", actionData));
    }
  }, [actionData]);

  useEffect(() => {
    if (!actionItemRef.current) return;
    actionItemRef.current.focus();
  }, [showAddActionsModal]);

  return (
    <Modal
      show={showAddActionsModal}
      onHide={handleAddActionsClose}
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isUpdate ? "Action Edit" : "Add an action"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="">
          <div className={`form-group ${isEmptyName ? "has-error" : ""}`}>
            <label>Action item</label>
            <input
              ref={actionItemRef}
              type="text"
              className="form-control"
              value={actionName}
              onChange={(event) => setActionName(event.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Savings</label>
                <input
                  type="number"
                  className="form-control"
                  value={saving}
                  onChange={setTargetValue(setSaving)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Savings unit</label>
                <select
                  className="form-control"
                  value={savingUnit}
                  onChange={setTargetValue(setSavingUnit)}
                >
                  <option value={actionSavingUnit["ML / Annum"]}>
                    ML / Annum
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Scheduled date</label>

                <DateRangePicker
                  setting={{
                    singleDatePicker: true,
                    autoUpdateInput: !!scheduledDate,
                    startDate: scheduledDate ? scheduledDate : undefined,
                  }}
                  onApply={(event, picker) => {
                    const nDate = picker.startDate.utcOffset(0, true);
                    picker.element.val(nDate.format("MM/DD/yyyy"));
                    setScheduledDate(nDate.toDate());
                  }}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Implemented date</label>
                <DateRangePicker
                  setting={{
                    singleDatePicker: true,
                    autoUpdateInput: !!implementedDate,
                    startDate: implementedDate ? implementedDate : undefined,
                  }}
                  onApply={(event, picker) => {
                    const nDate = picker.startDate.utcOffset(0, true);
                    picker.element.val(nDate.format("MM/DD/yyyy"));
                    setImplementedDate(nDate.toDate());
                  }}
                >
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Status</label>
                <Select
                    value={ status }
                    onChange={(e) => setStatus(e)}
                    options={listActionStatus}
                  />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Implementation notes</label>
            <textarea
              className="form-control"
              value={actionContent}
              onChange={setTargetValue(setActionContent)}
            />
          </div>
        </form>
        <Button
          variant="primary"
          onClick={isUpdate ? actionUpdateHandler : actionAddHandler}
        >
          {isUpdate ? "SAVE CHANGE" : "ADD ACTION"}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddAction;
