import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ContentWithTooltip = ({
  cid,
  type,
}) => {
  function renderTooltip(tooltipType) {
    return (
      <Tooltip id={`tooltip-${tooltipType}-${cid}`}>
        Headers don't match the required
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(type)}
    >
     <i style={{color: "red"}} className="fas fa-exclamation-triangle"></i>
    </OverlayTrigger>
  )
}

export default ContentWithTooltip
