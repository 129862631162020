import {
  prop,
  pathOr,
} from 'ramda'
import {getTenantConfig} from '../../services/apis'
import {isServerResponseError} from '../common'
import {authService} from '../../../base/services'
import {getTenantInfoFromLocal} from '../../services/local_storage/tenant-info'

const getTenantConfigData = async () => {
  const tenant_data = authService.getTenantInfo()
  const result = await getTenantConfig(tenant_data.id)
  const tenantInfoFromLocal = getTenantInfoFromLocal()
  if (pathOr(null, ['data', 'data', 'name'], result) && pathOr(null, ['data', 'data', 'name'], result) !== tenantInfoFromLocal.name) {
    localStorage.setItem('tenantInfo', JSON.stringify({...tenantInfoFromLocal, name: pathOr(null, ['data', 'data', 'name'], result)}))
  }
  return isServerResponseError(result)
    ? {}
    : pathOr(null, ['data', 'data'], result)
}

export default getTenantConfigData
