import React, {
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react'
import {
  useHistory
} from 'react-router-dom'
import {
  Pager
} from '../../components'
import {
  getTaxonomies
} from '../../utils/customer_utils'
import {
  isEmpty,
  propOr
} from 'ramda'
import NotesPageSkeleton from './notes-page-skeleton'

import { track } from "src/main_app/mixpanel"
import { useUserPermissions, checkPermission } from 'src/main_app/actions'
import NotesFilter from "../../components/notes_filter/notes-filter";
import { Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";

const NotesPage = () => {
  useEffect(() => {
    track('Industry list page')
  }, [])
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [taxonomiesData, setTaxonomiesData] = useState([])
  const [limit] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoaded, setIsLoaded] = useState(false)

  const filterRef = useRef(null);
  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "industry-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const loadData = async (pageNo) => {
    const response = await getTaxonomies(pageNo, limit, null, null, 'industry')
    setTaxonomiesData(propOr([], 'data', response))
    setCurrentPage(pageNo)
    setTotal(propOr([], 'total', response))
  }

  const gotoDetail = taxonomyName => {
    history.push(`/industry/${taxonomyName}`)
  }

  useEffect(() => {
    loadData(1).then(() => setIsLoaded(true))
  }, [])

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (isLoaded
      ? <div className="the-content">
        <div className="page-heading ">
          <div className="container-fluid ">
            <h2 className="title mb-0">
              Notes
            </h2>
          </div>
        </div>
        <div className="container-fluid content">
        {/* Start-notes filter */}
        <div className="action-buttons block-center-between">
          <div className="block-center">
            <NotesFilter
              ref={filterRef}
              onFiltersApply={loadData}
            />
          </div>
          <div className="d-flex">
            <Button
              className="btn btn-default"
              // href={exportUrl}
            >
              Export
            </Button>
          </div>
        </div>
        {/* End-notes filter */}

          {/* <div className="table-responsive">
            <table className="table">
              <thead>
              <tr>
                <th scope="col" width="50%">
                  <div className="d-flex">
                    Name
                  </div>
                </th>
                <th scope="col" width="50%" style={{textAlign: 'center'}}>Num of companies</th>
              </tr>
              </thead>
              <tbody>
              {!isEmpty(taxonomiesData) && <>
                {
                  taxonomiesData.map((taxonomyData, index) => {
                    if (taxonomyData.total_customer > 2)
                    return (
                      <tr
                        key={index}
                        onClick={event => gotoDetail(
                          taxonomyData.id)}
                      >
                        <td>
                          <div className="d-flex">
                            {taxonomyData.taxonomy_label}
                          </div>
                        </td>
                        <td style={{textAlign: 'center'}}>{taxonomyData.total_customer}</td>
                      </tr>
                    )
                  })
                }
              </>
              }
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-lg-end pagination-box">
            <Pager
              onPageChange={loadData}
              total={total}
              limit={limit}
              currentPage={currentPage}
            />
          </div> */}
        </div>
      </div>
      : <NotesPageSkeleton
        numberOfItems={limit}
      />
  )
}

export default NotesPage
