import {
    compose,
    pathOr,
} from "ramda"
import React, {
    useState,
    useLayoutEffect,
} from "react"
import {
    Source,
    Layer,
} from 'react-map-gl'
import {
    getMapDataSewerZones,
} from "src/main_app/services/apis"
import {
    isNilOrEmpty,
} from "src/main_app/utils/common"

const layerStyle = {
    type: 'line',
    source: 'sewer-zones-data',
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': 'rgba(0, 0, 0, 1)',
        'line-width': 3,
    }
}

const SewerZonesLayer = ({
    visible,
}) => {
    const [data, setData] = useState(null)

    useLayoutEffect(() => {
        if (visible) {
            getMapDataSewerZones()
                .then(
                    compose(
                        setData,
                        pathOr([], ['data', 'data'])
                    )
                )
        }
        else {
            setData([])
        }
    }, [
        visible
    ])

    return (visible && !isNilOrEmpty(data))
        ? (
            <Source
                id='sewerZonesLayer'
                type='geojson'
                data={data}
            >
                <Layer {...layerStyle} />
            </Source>
        )
        : null
}

export default SewerZonesLayer