import { isNil } from 'ramda'

const authServiceKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  user: 'user',
  userId: 'userId',
  tenantInfo: 'tenantInfo'
}

class AuthService {
  getLoginUrl() {
    return '/sign-in'
  }

  isAuthenticated() {
    return !isNil(this.getUserId()) &&
      !isNil(this.getAccessToken())
  }

  getAccessToken() {
    return localStorage.getItem(authServiceKeys.accessToken)
  }

  getTenantInfo() {
    return JSON.parse(localStorage.getItem(authServiceKeys.tenantInfo))
  }

  getRefreshToken() {
    return localStorage.getItem(authServiceKeys.refreshToken)
  }

  getUserId() {
    return localStorage.getItem(authServiceKeys.userId)
  }

  getUser() {
    const userData = localStorage.getItem(authServiceKeys.user)
    return JSON.parse(userData)
  }

  authenticate(data) {
    const {
      account,
      tenant_info,
      accessToken,
      refreshToken,
    } = data

    localStorage.setItem(authServiceKeys.userId, account.id)
    localStorage.setItem(authServiceKeys.tenantInfo, JSON.stringify(tenant_info))
    localStorage.setItem(authServiceKeys.user, JSON.stringify(account))
    localStorage.setItem(authServiceKeys.accessToken, accessToken)
    localStorage.setItem(authServiceKeys.refreshToken, refreshToken)
  }

  async signout() {
    localStorage.removeItem(authServiceKeys.userId)
    localStorage.removeItem(authServiceKeys.user)
    localStorage.removeItem(authServiceKeys.accessToken)
    localStorage.removeItem(authServiceKeys.refreshToken)
  }
}

export const authService = new AuthService()
