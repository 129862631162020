import React from "react";
import { propOr, times } from "ramda";
import Skeleton from "react-loading-skeleton";
import { keyLookUp } from "../../../../utils/common";
import actionStatus from "../../../../enums/action-status.json";
import actionSavingUnit from "../../../../enums/action-saving-unit.json";
import { Button, Dropdown } from "react-bootstrap";

const CustomerActionsSkeleton = ({ line = 1 }) => {
  const actionsList = times(
    (i) => (
      <tr key={i}>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td style={{ width: "10px" }} className="text-right">
          <Skeleton />
        </td>
      </tr>
    ),
    line
  );

  return (
    <div className="container-fluid content">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" width="300">
                Action item
              </th>
              <th scope="col" width="100">
                Status
              </th>
              <th scope="col" className="text-right" width="200">
                Savings (ML/annum)
              </th>
              <th scope="col" width="200">
                Scheduled date
              </th>
              <th scope="col" width="200">
                Implemented date
              </th>
              <th scope="col">Implementation notes</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>{actionsList}</tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerActionsSkeleton;
