import { connect } from "react-redux";

import {
  fetchTenantConfig,
  getUserPermissionAsync,
} from "src/main_app/actions";

const mapStateToProps = null;
const mapActionToProps = {
  fetchTenantConfig,
  getUserPermissionAsync,
};

const withRedux = (component) =>
  connect(mapStateToProps, mapActionToProps)(component);

export default withRedux;
