import React from 'react'
import { times } from 'ramda'
import Skeleton from 'react-loading-skeleton'

const CustomerTimelineSkeleton = ({ line = 1 }) => {
  const timelineList = times(
    (i) => (
      <div key={i} className="timeline__item">
        <div className="timeline__type">
          <Skeleton circle={true} height={16} width={16} />
        </div>
        <div className="timeline__content">
          <div className="timeline__date font-weight-bold">
            <Skeleton />
          </div>
          <Skeleton height={60} />
        </div>
      </div>
    ),
    line,
  )

  return <div className="timeline">{timelineList}</div>
}

export default CustomerTimelineSkeleton
