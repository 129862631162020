import React, { useEffect } from 'react'
import { authService } from '../../base/services'

const SignoutPage = (props) => {
  useEffect(() => {
    authService.signout().then(() => {
      setTimeout(() => (document.location.href = '/'), 333)
    })
  }, [])

  return (
    <div>
      <h1>Signout.</h1>
      <p>You will be redirected to the home page shortly.</p>
    </div>
  )
}

export default SignoutPage
