import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { isEmpty } from "ramda";
import { setTargetValue } from "src/main_app/utils/react_utils";

import { getApiUrl } from 'src/main_app/utils/config-utility';
import { authService } from 'src/base/services';

import { track } from "src/main_app/mixpanel";
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Select from '@bhunjadi/react-select-v3';

const UploadCsv = ({
  tenantConfig,
  showModal,
  handleActionsClose,
  onUploaded,
}) => {
  if (!showModal) return null

  const { addToast } = useToasts();
  const [typeImport, setTypeImport] = useState({ value: 'Billing', label: 'Billing' });
  const [fileContent, setFileContent] = useState(null)
  const [fileName, setFileName] = useState(null);

  const handleChangeTypeImport = typeImport => {
    setTypeImport(typeImport);
  };

  const upload = () => {
    //validate file type csv
    const type = typeImport['value']

    if (fileContent == null) {
      addToast("Please chose file to upload", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }

    if (fileContent.type !== 'text/csv') {
      addToast("Only csv file will be accepted", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }
    
    const formData = new FormData();
    formData.append('file',fileContent);
    const url = `${getApiUrl()}/import/customer-hunter-water-dashboard?import_type=${type}&file_name=${fileContent.name}`;

    axios({
      method: "post",
      url: url,
      data: formData,
      headers: { 
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${authService.getAccessToken()}`
      },
    })
      .then(function (response) {
        //handle success
        addToast(response.data.status, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        handleActionsClose();
        setTimeout(() => {
          location.reload();
        }, 2000);
        onUploaded && onUploaded();
      })
      .catch(function (response) {
        //handle error
        addToast("Import failed", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        return;
      });
  }
  
  useEffect(() => {
    track("Manually upload data");
  }, [])

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={"lg"}>
      <Modal.Header closeButton>
            <Modal.Title>Manually upload data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-link-form" className="">
            <div className="col-md-5" style={{padding: "0"}}>
                <div className="form-group">
                    <label>Type</label>
                    <Select
                      defaultValue={typeImport}
                      onChange={handleChangeTypeImport}
                      options={tenantConfig.importDashboard.importDashboardType}
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Upload CSV</label>
                <Dropzone 
                  onDrop={ (file) => {
                        setFileContent(file[0]), 
                        setFileName(file[0]['name'])
                  } }
                >
                  {({getRootProps, getInputProps}) => (
                    <section className="container" style={{padding: "0"}}>
                    <div {...getRootProps({className: 'dropzone'})} style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                      borderWidth: "2px",
                      borderRadius: "2px",
                      borderColor: "#eeeeee",
                      borderStyle: "dashed",
                      backgroundColor: "#fafafa",
                      color: "#bdbdbd",
                      outline: "none",
                    }}>
                      <input {...getInputProps()} />
                      <p>Click here to upload a CSV</p>
                      <p>{fileName}</p>
                    </div>
                  </section>
                  )}
                </Dropzone>
            </div>
            <Button variant="primary" onClick={upload}>
                UPLOAD
            </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UploadCsv;

