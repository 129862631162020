import React, { useState, useEffect, useRef } from "react";
import { map, path, __ } from "ramda";
import { gsap } from "gsap";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getMapboxAccessKey } from "../utils/config-utility";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getPublicDataHeatMap } from "src/main_app/services/apis";

import heatMapStyleAppForTv from "../components/map/layers/customer_layer/heat-map-style-app-for-tv";
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
const mapboxAccessKey = getMapboxAccessKey();
mapboxgl.accessToken = mapboxAccessKey;
 
const TvAppPageTest = () => {
  const mapContainer = useRef(null);
  const mapGl = useRef(null);

  const start = {
    center: [80, 36],
    zoom: 1.5,
    pitch: 0,
    bearing: 0
  };
  const end = {
    center: [151.750000, -32.916668],
    zoom: 10,
    bearing: 100,
    pitch: 75
  };
    
  const newWYork = {
    center: [-74.00565054938768, 40.73123288832318],
    zoom: 2,
    bearing: 0,
    pitch: 4,
  }

  const southNewcastle = {
    center: [151.49418254127747, -33.142449772483005],
    zoom: 12,
    bearing: 100,
    pitch: 60
  };

  const northNewcastle = {
    center: [152.0845402680649, -32.77633188242049],
    zoom: 11,
    bearing: 450,
    pitch: 75
  };

  const fly = (duration, target) => {
    mapGl.current.flyTo({
      ...target, // Fly to the selected target
      duration: duration, // Animate over 12 seconds
      essential: true // This animation is considered essential with
      });
  }

  useEffect(() => { 
    //Get data heat-map
      const queryWaterUsagePerDaOfLastYear = async () => {
        const { data: { data } } = await getPublicDataHeatMap();
        await mapGl.current.once('load');
        mapGl.current.setFog({
          'color': 'rgb(220, 159, 159)', // Pink fog
          'high-color': 'rgb(36, 92, 223)', // Blue sky
          'horizon-blend': 0.4
        });

        setTimeout(() => {
          fly(10000, newWYork);
        }, 6000);

        setTimeout(() => {
          fly(20000, end);
          setTimeout(() => {
            mapGl.current.addSource('trees', {
              'type': 'geojson',
              'data': data
            }); 
            mapGl.current.addLayer({...heatMapStyleAppForTv});
          }, 10000);
        }, 14000);

        setTimeout(() => {
          fly(12000, southNewcastle);
        }, 33000);

        setTimeout(() => {
          fly(30000, northNewcastle);
        }, 40000);
        
      }; 
      queryWaterUsagePerDaOfLastYear();
  },[]);

  const repeatGlobe = () => {
    setTimeout(() => {
      fly(1000, start);
    }, 3000);
    
    setTimeout(() => {
      fly(10000, newWYork);
    }, 6000);
    setTimeout(() => {
      fly(20000, end);
    }, 14000);

    
    setTimeout(() => {
      fly(12000, southNewcastle);
    }, 33000);

    setTimeout(() => {
      fly(30000, northNewcastle);
    }, 40000);
  }

  useEffect(() => {
      let tl = gsap.timeline({repeat: -1, onRepeat: repeatGlobe});
      tl.from(".logoAquo", {duration: 0, opacity:0, ease: "back"});
      tl.addLabel("logoAquoOut", "+=1")
      tl.to(".logoAquo", {duration: 2, opacity: 1, x: 200, ease: "power4.out"}, "logoAquoOut");

      tl.from(".map-container", {duration: 2, opacity:0, ease: "back", delay: 3 }, "-=1");
      tl.to(".map-container", {duration: 1, delay: 61, opacity:0, ease: "power3.out"});

      tl.from(".superset", {duration: 0.5, opacity: 0, ease: "back" }, "-=1");
      tl.to(".superset-scroll-top", { duration: 1, y: -200}, "-=3");
      tl.addLabel("logoAquoOut2", "+=1")
      tl.to(".logo", {duration: 4, opacity:0, ease: "power4.out", x: 600 }, 'logoAquoOut2');
      tl.to(".intro", {duration: 0, opacity:0});
      tl.to(".superset", { duration: 40, y: -2650});
      mapGl.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/loweblue0510/cku3lxvq10s2q17lfcixvqyan',
        ...start,
        projection: 'globe',
      });
  }, [])

  return (
    <div className="wrapper">
      <div className="intro">
        
        <video className='video' autoPlay loop muted
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        >
          <source src='https://screenshot-alert-page.s3.ap-southeast-2.amazonaws.com/aquo-video-tv-app.mp4' type='video/mp4'/>
        </video>
        <div style={{height: "200px", width: '350px'}}>
          <div className="logo" style={{backgroundColor: 'rgba(227, 227, 227, 0.3)', zIndex: '1', height: "150px", width: "350px", position: "absolute", borderRadius: "0px 0px 0px 70px", right: '0'}}>
            <img className="logoHunterWater" src='/assets/tv-app/hunter-water-logo.png' style={{width: '130px', position: 'absolute', paddingLeft: "7%",  paddingTop: "5%"}}/>
            <img className="logoAquo" src='/assets/tv-app/aquo-logo.png' style={{width: '130px', position: 'absolute', paddingLeft: "7%", paddingTop: "5%"}}/>
          </div>
        </div>
       
      </div> 
  
        <div ref={mapContainer} id="map" className="map-container" style={{ position: "absolute", top: "0", bottom: "0", width: "100%"}}/>
        <div className="superset-scroll-top">
          <iframe
              className="superset"
              style={{ width: "100%", height: "3550px", position: 'absolute'}}
              frameBorder="0"
              src="https://superset-prv.aquo.com.au/superset/dashboard/30/?standalone=true"
          />
        </div>

   </div>
  );
}

export default TvAppPageTest



