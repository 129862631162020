import {
  compose,
  map,
  propOr,
} from 'ramda'
import {
  getUsersList,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError, toString,
} from '../common'

const getUsersListData = async (page, limit, search, searchIn) => {
  const result = await getUsersList(
    {
      page,
      limit,
      search,
      searchIn
    })
  if (isServerResponseError(result) || hasError(result)) {
    return {
      total: 0,
      data: []
    }
  } else {
    const { data: resultData } = result
    return {
      total: propOr(0, 'total', resultData),
      data: compose(
        map(user => (
          {
            name: `${toString(user.first_name)} ${toString(user.last_name)}`.trim(),
            ...user,
          }
        )),
        propOr([], 'data')
      )(resultData)
    }
  }
}

export default getUsersListData