export * from "./constants";
export * from "./actions";
export * from "./hooks";
export { default as userPermissionReducer } from "./reducer";

export const checkPermission = (
  userPermissions,
  permissionSlug,
  permissionNameDetail
) => {
  const permission = userPermissions.find(
    (item) => item.slug === permissionSlug
  );
  return userPermissions.length === 0 || (!!permission && !!permission.meta[permissionNameDetail]);
};
