import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  Tooltip,
} from "recharts";

import { Bar } from "react-chartjs-2";

import { isNilOrEmpty, addDays, formatNumber, convertDateWithShotMonthName, convertDate, isNotNil } from "src/main_app/utils/common";

import { PageHeading } from "../../components";
import AlertsFilter from "../../components/alerts_filter/alerts-filter";
import { getAlertsOverview, getCustomersDetail, getSixCustomerTheMostAlert } from "src/main_app/services/apis";

import { isEmpty, isNil, propOr, compose, find, propEq, reduce, map, last } from "ramda";
import { getApiUrl } from "../../utils/config-utility";
import { authService } from "../../../base/services";
import AlertsPageSkeleton from "./alerts-page-skeleton";
import AlertCustomerRealTimeUsagePage from "./alert-customers-real-time-usage";
import { getCustomerWaterUsage } from "src/main_app/services/apis";
import alertTypes from "./helpers/alert-types";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const limit = 20;

const ticksFormatter = (value, index) => moment(value).format("MMM DD");

const yLabel = {
  value: "Count",
  angle: -90,
  position: "top",
  dy: 170,
  dx: 0,
};

import { track } from "src/main_app/mixpanel";
import AlertList from "./alert-list";
import getSearchData from "./helpers/get-search-data";
import { useTenantConfig, useUserPermissions, checkPermission } from "src/main_app/actions";
import AlertsPageChartRealTimeSkeleton from "./alerts-page-chart-realtime-skeleton";

const tooltipContentStyle = {
  boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
  borderRadius: "4px",
  border: "none",
  textAlign: "center",
};

const tooltipItemStyle = {
  color: "#05d6f",
  fontSize: "12px",
  fontWeight: "500",
};

const renderTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div
      className="custom-line-tooltip"
      style={{
        padding: "5px",
        backgroundColor: "#fff",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 7%)",
        borderRadius: '4px'
      }}
    >
      <h5 className="mb-0">{payload[0].payload.displayDate}</h5>
      <br />
      {payload.map((payloadItem, index) => (
        <div
          key={index + ""}
          style={{
            color: payloadItem.stroke,
          }}
        >
          {payloadItem.name}: {formatNumber(payloadItem.value)}{" "}
        </div>
      ))}
    </div>
  );
};

const AlertsPage = ({ onTabCustomerID = null }) => {
  const tenantInfo = useRef(getTenantInfoFromLocal());
  useEffect(() => {
    track("Alert page", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, []);

  const tenantConfig = useTenantConfig()

  const allowAlertTypes = useMemo(() => propOr([], 'allowAlertTypes', tenantConfig), [tenantConfig])

  const [isLoaded, setIsLoaded] = useState(false);
  const filterRef = useRef(null);
  const alertListRef = useRef(null);

  const [total, setTotal] = useState({
    all: 0,
  });

  const [dataOverview, setDataOverview] = useState([]);
  const [alertsChartData, setAlertsChartData] = useState([]);
  const [dataBarchart, setDataBarChart] = useState([]);
  const [ticks, setTicks] = useState([]);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "alerts-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const getBaseExportUrl = () => `${getApiUrl()}/export/alert/csv?&token=${authService.getAccessToken()}${
      onTabCustomerID ? `search[customer_id]=${onTabCustomerID}` : ""
    }`

  const [exportUrl, setExportUrl] = useState(getBaseExportUrl());
  const hasAny = useRef(false);

  const updateExportUrl = (searchData) => {
    let newExportUrl = getBaseExportUrl();
    Object.keys(searchData).forEach((filter) => {
      if (searchData[filter]) {
        newExportUrl += `&${filter}=${searchData[filter]}`;
      }
    });
    setExportUrl(newExportUrl)
  };

  const loadAlertsOverview = async (searchData) => {
    const { data: result } = await getAlertsOverview(searchData);
    setDataOverview(result.overviewBySeverity);

    hasAny.current =
      reduce(
        (sumItems, item) => {
          return sumItems + (item.count - "");
        },
        0,
        result.overviewBySeverity
      ) > 0;

    const nTotal = {
      all: reduce(
        (sumItems, item) => {
          return sumItems + (item.count - "");
        },
        0,
        result.overviewByType
      ),
    };
    for (const alertType of alertTypes) {
      nTotal[alertType.name] =
        (
          result.overviewByType.find((i) => i.type === alertType.name) ?? {
            count: 0,
          }
        ).count - "";
    }
    setTotal(nTotal);

    const { from, to } = searchData;

    const formDate = new Date(from);
    const toDate = new Date(to);

    const combinedData = [];
    const nTicks = [];
    for (
      let currentDate = formDate;
      currentDate <= toDate;
      currentDate = addDays(currentDate, 1)
    ) {
      const currentDateString = moment(currentDate).format(
        "yyyy-MM-DD 00:00:00"
      );
      const dataAtTheDate = {
        date: currentDate,
        daeString: currentDateString,
      };
      for (const alertType of alertTypes) {
        if (!allowAlertTypes.some(allowAlertType => allowAlertType === alertType.name)) continue
        const alertsByType = result.data.find(
          (i) => i.type === alertType.name && i.created_at === currentDateString
        );
        if (alertsByType) {
          dataAtTheDate[alertType.name] = alertsByType.count - 0;
        } else {
          dataAtTheDate[alertType.name] = 0;
        }
      }
      combinedData.push(dataAtTheDate);
      nTicks.push(currentDate);
    }

    setAlertsChartData(combinedData);
    setTicks(nTicks);

    const arr_date = [];
    const arr_data_loss = [];
    const arr_night_flow = [];
    const arr_peak_flow = [];
    const arr_step_change = [];
    const arr_water_quality = [];
    const arr_base_flow = [];

    combinedData.forEach((item) => {
      arr_date.push(convertDateWithShotMonthName(item['date']))
      arr_data_loss.push(item['data_loss'])
      arr_night_flow.push(item['night_flow'])
      arr_peak_flow.push(item['peak_flow'])
      arr_step_change.push(item['step_change'])
      arr_water_quality.push(item['water_quality'])
      arr_base_flow.push(item['base_flow'])
    })

    const dataSet = [];

    allowAlertTypes.forEach((item) => {
      switch(item) {
        case 'night_flow':
          dataSet.push({
            label: 'Night flow',
            // backgroundColor: 'rgb(194,53,49)',
            backgroundColor: '#54513f',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#54513fb5',
            data: arr_night_flow
          });
          break;
        case 'data_loss':
          dataSet.push({
            label: 'Data loss',
            // backgroundColor: 'rgb(47,69,84)',
            backgroundColor: '#d6a189',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#d6a1899c',
            data: arr_data_loss
          },);
          break;
        case 'peak_flow':
          dataSet.push({
            label: 'Peak flow',
            backgroundColor: '#97c5cc',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#97c5ccbd',
            data: arr_peak_flow
          });
          break;
        case 'water_quality':
          dataSet.push({
            label: 'Water quality',
            backgroundColor: '#dca727',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#dca72799',
            data: arr_water_quality
          });
        break;
        case 'step_change':
          dataSet.push({
            label: 'Step change',
            backgroundColor: '#307981',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#3079819e',
            data: arr_step_change
          });
          break;
        case 'base_flow':
          dataSet.push({
            label: 'Base flow',
            backgroundColor: '#48b49b',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#48b49ba3',
            data: arr_base_flow
          });
          break;
        default:
          // code block
      }
    })

    //Maping data linechart to barchart
    const dataMapingBarchart = {
      labels: arr_date,
      datasets: dataSet
    }
    setDataBarChart(dataMapingBarchart);
  };

  const loadData = async (filteredData) => {
    const searchData = getSearchData(onTabCustomerID)(filteredData);

    //
    await loadAlertsOverview(searchData);
    alertListRef.current.setFilteredData(filteredData);

    setIsLoaded(true);

    //
    if (isEmpty(searchData)) return;
    updateExportUrl(searchData);
  };

  const displayDataOverview = useCallback(
    (severity) =>
      compose(
        formatNumber,
        propOr(0, "count"),
        find(propEq("severity", severity))
      ),
    []
  );

const options={
  responsive: true,
  legend: {
      display: false,
  },
  type:'bar',
}


/////////////////////Chart Real time data (Sums data)////////////////////////// 
const ticksRef = useRef([]);
const [isNightFlowAlert, setIsNightFlowAlert] = useState(false);
const [isLoadedRealTimeChart, setIsLoadRealTimeChart] = useState(true);
const [nameCustomer, setNameCustomer1] = useState(null);
const [nameCustomer2, setNameCustomer2] = useState(null);
const [nameCustomer3, setNameCustomer3] = useState(null);
const [nameCustomer4, setNameCustomer4] = useState(null);
const [nameCustomer5, setNameCustomer5] = useState(null);
const [nameCustomer6, setNameCustomer6] = useState(null);
const [nameCustomer7, setNameCustomer7] = useState(null);
const [nameCustomer8, setNameCustomer8] = useState(null);
const [nameCustomer9, setNameCustomer9] = useState(null);

const [dataWeekly, setDataWeekly1] = useState(null);
const [meterNames, setMeterNames1] = useState([]);

const [dataWeekly2, setDataWeekly2] = useState(null);
const [meterNames2, setMeterNames2] = useState([]);

const [dataWeekly3, setDataWeekly3] = useState(null);
const [meterNames3, setMeterNames3] = useState([]);

const [dataWeekly4, setDataWeekly4] = useState(null);
const [meterNames4, setMeterNames4] = useState([]);

const [dataWeekly5, setDataWeekly5] = useState(null);
const [meterNames5, setMeterNames5] = useState([]);

const [dataWeekly6, setDataWeekly6] = useState(null);
const [meterNames6, setMeterNames6] = useState([]);

const [dataWeekly7, setDataWeekly7] = useState(null);
const [meterNames7, setMeterNames7] = useState([]);

const [dataWeekly8, setDataWeekly8] = useState(null);
const [meterNames8, setMeterNames8] = useState([]);

const [dataWeekly9, setDataWeekly9] = useState(null);
const [meterNames9, setMeterNames9] = useState([]);


const getDataWeekly = async (type) => {
  setNameCustomer1(null); setNameCustomer2(null); setNameCustomer3(null); setNameCustomer4(null); setNameCustomer5(null);
  setNameCustomer6(null); setNameCustomer7(null); setNameCustomer8(null); setNameCustomer9(null);

  setDataWeekly1(null); setMeterNames1([]);
  setDataWeekly2(null); setMeterNames2([]);

  setDataWeekly3(null); setMeterNames3([]);
  setDataWeekly4(null); setMeterNames4([]);

  setDataWeekly5(null); setMeterNames5([]);
  setDataWeekly6(null); setMeterNames6([]);

  setDataWeekly7(null); setMeterNames7([]);
  setDataWeekly8(null); setMeterNames8([]);
  setDataWeekly9(null); setMeterNames9([]);

  const res = await getSixCustomerTheMostAlert(type);
  const customer_ids = propOr([], "data", res);
  if (!isEmpty(customer_ids)) {
    for (let i = 0; i < customer_ids.length; i++) {
      const item = customer_ids[i];
      const dataRealTime = await mapDataSums(item['customer_id']);
      const {data: data} = await getCustomersDetail(item['customer_id']);
      const nameOfCustomer = propOr(null, "name", data.data);
      switch (i) {
        case 0:
          setDataWeekly1(dataRealTime['arrDataWeekly']);
          setMeterNames1(dataRealTime['setOfMeterNames']);
          setNameCustomer1(nameOfCustomer)
          break;
        case 1:
          setDataWeekly2(dataRealTime['arrDataWeekly']);
          setMeterNames2(dataRealTime['setOfMeterNames']);
          setNameCustomer2(nameOfCustomer)
          break;
        case 2:
          setDataWeekly3(dataRealTime['arrDataWeekly']);
          setMeterNames3(dataRealTime['setOfMeterNames']);
          setNameCustomer3(nameOfCustomer)
          break;
        case 3:
          setDataWeekly4(dataRealTime['arrDataWeekly']);
          setMeterNames4(dataRealTime['setOfMeterNames']);
          setNameCustomer4(nameOfCustomer)
          break;
        case 4:
          setDataWeekly5(dataRealTime['arrDataWeekly']);
          setMeterNames5(dataRealTime['setOfMeterNames']);
          setNameCustomer5(nameOfCustomer)
          break;
        case 5:
          setDataWeekly6(dataRealTime['arrDataWeekly']);
          setMeterNames6(dataRealTime['setOfMeterNames']);
          setNameCustomer6(nameOfCustomer)
          break;
        case 6:
          setDataWeekly7(dataRealTime['arrDataWeekly']);
          setMeterNames7(dataRealTime['setOfMeterNames']);
          setNameCustomer7(nameOfCustomer)
          break;   
        case 7:
          setDataWeekly8(dataRealTime['arrDataWeekly']);
          setMeterNames8(dataRealTime['setOfMeterNames']);
          setNameCustomer8(nameOfCustomer)
          break;
        case 8:
          setDataWeekly9(dataRealTime['arrDataWeekly']);
          setMeterNames9(dataRealTime['setOfMeterNames']);
          setNameCustomer9(nameOfCustomer)
          break;
      }

    }
  }
};


const mapDataSums = async (customer_id) => {
  const ticks = []
  const today = new Date();
  const to = addDays(today, -1);
  const from = addDays(today, -3);
  const {
    data: { data },
  } = await getCustomerWaterUsage(customer_id, {
    from: convertDate(from),
    to: convertDate(to),
  });

  const allMeterNames = [];

  let arrDataWeekly = map((item) => {
    const { time, ...metersData } = item;

    if (isEmpty(metersData)) return { time };

    const utcTime = moment(time).utcOffset(0, true);

    const utcUnix = utcTime.unix();

    if (
      isEmpty(ticks) ||
      utcUnix - last(ticks) >= 86400
    ) {
      ticks.push(utcUnix);
    }

    const combinedData = {
      date: utcUnix,
      displayDate: utcTime.format("ddd MMM DD hh:mm a"),
    };

    for (const meterName in metersData) {
      if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
      allMeterNames.push(meterName);
      const sumUsage = metersData[meterName];
      combinedData[meterName] = sumUsage
        ? Math.round(sumUsage * 10) / 10
        : null;
    }

    ticksRef.current  = ticks

    return combinedData;
  }, data);

  const setOfMeterNames = [...new Set(allMeterNames)];
  arrDataWeekly = map((item) => {
    for (const meterName of setOfMeterNames) {
      if (isNotNil(item[meterName])) continue;
      item[meterName] = 0;
    }

    return item;
  }, arrDataWeekly);

  return {setOfMeterNames, arrDataWeekly}
}

const nightFlowAlert = async (type) => {
  if ((type === 'night_flow') || (type === 'base_flow')) {
    setIsLoadRealTimeChart(false);
    await getDataWeekly(type);
    setIsNightFlowAlert(true);
    setIsLoadRealTimeChart(true);
  } else {
    setIsNightFlowAlert(false);
  }
}

useEffect(() => {
  setIsLoadRealTimeChart(false)
  getDataWeekly('base_flow');
  setIsNightFlowAlert(true);
  setIsLoadRealTimeChart(true);
}, []);
///////////////////////////////////////////////

  useLayoutEffect(() => {
    import("./style.scss");
  }, []);

  const renderContent = () => {
    return isNilOrEmpty(dataWeekly) && isNilOrEmpty(dataWeekly2) && isNilOrEmpty(dataWeekly3) && isNilOrEmpty(dataWeekly4) && isNilOrEmpty(dataWeekly5) && isNilOrEmpty(dataWeekly6) ? (
      <div className="no-item-message">
        {/* There is currently no usage data available. */}
      </div>
    ) : (
      <>
        {isNightFlowAlert && (
          <div className="card">
            <div className="card-body">

            {/* <Bar data={dataBarchart} options={options} height={100} /> */}

              <div className="row"> 
              {!isNilOrEmpty(dataWeekly) && (
                <>
                  <div className="col-md-4">
                    <AlertCustomerRealTimeUsagePage
                      dataWeekly={dataWeekly}
                      meterNames={meterNames}
                      ticks={ticksRef.current}
                    />
                     <h5 className="text-center">{nameCustomer}</h5>
                  </div>
                 
                </>
              )}
              {!isNilOrEmpty(dataWeekly2) && (
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly2}
                    meterNames={meterNames2}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer2}</h5>
                </div>
              )} 
              {!isNilOrEmpty(dataWeekly3) && ( 
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly3}
                    meterNames={meterNames3}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer3}</h5>
                </div>
              )}
              </div>

              <div className="row">
              {!isNilOrEmpty(dataWeekly4) && (  
                <div className="col-md-4">
                  <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly4}
                    meterNames={meterNames4}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer4}</h5>
                </div>
              )}
              {!isNilOrEmpty(dataWeekly5) && ( 
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly5}
                    meterNames={meterNames5}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer5}</h5>
                </div>
              )}
              {!isNilOrEmpty(dataWeekly6) && ( 
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly6}
                    meterNames={meterNames6}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer6}</h5>
                </div>
              )}
              </div>


              <div className="row">
              {!isNilOrEmpty(dataWeekly7) && (  
                <div className="col-md-4">
                  <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly7}
                    meterNames={meterNames7}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer7}</h5>
                </div>
              )}
              {!isNilOrEmpty(dataWeekly8) && ( 
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly8}
                    meterNames={meterNames8}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer8}</h5>
                </div>
              )}
              {!isNilOrEmpty(dataWeekly9) && ( 
                <div className="col-md-4">
                <AlertCustomerRealTimeUsagePage
                    dataWeekly={dataWeekly9}
                    meterNames={meterNames9}
                    ticks={ticksRef.current}
                  />
                  <h5 className="text-center">{nameCustomer9}</h5>
                </div>
              )}
              </div>

            </div>
          </div>
        )}
      </>
      
    );
  };

  const renderNoContent = useCallback(() => {
    return onTabCustomerID
      ? "There are currently no alerts for this customer."
      : "There are currently no alerts.";
  }, [onTabCustomerID]);

  const renderContentBarChart = () => {
    return isNilOrEmpty(alertsChartData) ? (
      <div className="no-item-message">
        There is currently no usage data available.
      </div>
    ) : (
      <div className="card">
        <div className="card-body">
        <Bar data={dataBarchart} options={options} height={100} />
        </div>
      </div>
    );
  };

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      {isNil(onTabCustomerID) && <PageHeading title="Alerts" />}
      <div className="container-fluid content">
        <div className="action-buttons block-center-between">
          <div className="block-center">
            <AlertsFilter
              ref={filterRef}
              onFiltersApply={loadData}
              onTabCustomerID={onTabCustomerID}
            />
          </div>
          <div className="d-flex">
            {isLoaded && (
              <div className="alert-statistic">
                <span className="alert-statistic_high">
                  {displayDataOverview("high")(dataOverview)}
                </span>
                <span className="alert-statistic_medium">
                  {displayDataOverview("medium")(dataOverview)}
                </span>
                <span className="alert-statistic_low">
                  {displayDataOverview("low")(dataOverview)}
                </span>
              </div>
            )}
            <Button
              className="btn btn-default"
              href={exportUrl}
              disabled={!isLoaded}
            >
              Export
            </Button>
          </div>
        </div>

        {isNil(onTabCustomerID) && 
          <>
          {!isLoadedRealTimeChart ? (
            <AlertsPageChartRealTimeSkeleton />
            ) : hasAny.current ? (
              renderContent()
            ) : (
              renderNoContent()
            )}
          </>
        }

        <AlertList
          ref={alertListRef}
          limit={limit}
          total={total}
          getSearchData={getSearchData(onTabCustomerID)}
          onTabCustomerID={onTabCustomerID}
          loadData={loadData}
          nightFlowAlert={nightFlowAlert}
        />

        {!isLoaded ? (
          <AlertsPageSkeleton numberOfItems={limit} />
        ) : renderContentBarChart()}

        {/* {!isLoaded ? (
          <AlertsPageSkeleton numberOfItems={limit} />
        ) : hasAny.current ? (
          renderContentBarChart()
        ) : (
          renderNoContent()
        )} */}
      </div>
    </div>
  );
};

export default AlertsPage;
