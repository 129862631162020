import React from 'react'
import {
  isEmpty,
  times,
} from 'ramda'
import {Pager} from '../../components'
import {Button, Dropdown} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

const UsersPageSkeleton = ({
  headers = [],
  numberOfItems = 1
}) => {

  const usersList = times(i =>
      <tr key={i}>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td><Skeleton /></td>
        <td className="text-right"/>
      </tr>, numberOfItems)

  return (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">
            Users
          </h2>
          <button className="btn-text f-14 secondary-color" disabled={true} >
            <i className="far fa-plus-circle mr-2" />Add user
          </button>
        </div>
      </div>
      <div className="container-fluid content">
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
              {
                isEmpty(headers)
                  ? null
                  : headers.map((header, index) => (
                    <th key={index + ''} scope="col">{header}</th>
                  ))
              }
              <th scope="col" />
            </tr>
            </thead>
            <tbody>
            {
              usersList
            }
            </tbody>
          </table>
        </div>
        <Pager />
      </div>
    </div>
  )
}

export default UsersPageSkeleton
