const MAX_ZOOM_LEVEL = 18

const heatMapTradeWasteLayer = {
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    // increase weight as diameter breast height increases
    'heatmap-weight': {
      'property': 'wupd',
      'type': 'exponential',
      'stops': [
        [1, 0],
        [62, 1]
      ]
    },
    // increase intensity as zoom level increases
    'heatmap-intensity': {
      'stops': [
        [11, 1],
        [18, 3]
      ]
    },
    // use sequential color palette to use exponentially as the weight increases
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(236,222,239,0)',
      0.1,
      'rgba(193,199,161,30)',
      0.2,
      'rgba(193,199,161,100)',
      0.4,
      'rgba(160,202,178,50)',
      0.6,
      'rgba(160,202,178,100)',
      0.8,
      'rgba(164,175,161,40)',
      1,
      'rgba(164,175,161,100)'
    ],
    // increase radius as zoom increases
    'heatmap-radius': {
      'stops': [
        [11, 18],
        [18, 20]
      ]
    },
    // decrease opacity to transition into the circle layer
    'heatmap-opacity': {
      'default': 1,
      'stops': [
        [14, 1],
        [18, 0]
      ]
    }
  }
}

export default heatMapTradeWasteLayer
