import React, { useState, useEffect, useMemo } from "react";
import { andThen, propOr, compose } from "ramda";
import { useToasts } from "react-toast-notifications";
import {
  getSettingReceiveMailAlert,
  addSettingReceiveMail,
} from "src/main_app/services/apis";
import { track } from "src/main_app/mixpanel";
import {
  checkPermission,
  useUserPermissions,
} from "src/main_app/actions";
import {
  FormContainer,
  SpinnerButton,
} from "src/main_app/components/form_elements";
import { TagInput } from "src/main_app/components";

const ContactAddressPage = (props) => {
  useEffect(() => {
    track("Setting contact address page");
  }, []);
  const { addToast } = useToasts();
  const [emailValueContactAddress, setEmailValueContactAddress] = useState([]);
  const [loadMailContactAddress, setReloadMailContactAddress] = [];
  const userPermissions = useUserPermissions();
  const canEdit = useMemo(
    () => checkPermission(userPermissions, "settings-mail-alert", "canEdit"),
    [userPermissions]
  );

  const submitEmailImportHandler = async (event) => {
    event.preventDefault();
    //Update email
    const email = emailValueContactAddress;
    const type = 'contact_address';
    await addSettingReceiveMail(type, emailValueContactAddress);
    addToast("Update successful.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setReloadMailContactAddress(emailValueContactAddress);
  };

  const loadData = async () => {
    const response = await getSettingReceiveMailAlert();
    setEmailValueContactAddress(propOr([], 'emailContactAddress', response.data));
  };

  useEffect(() => {
    loadData();
  }, [loadMailContactAddress]);

  return (
    <>
    <div className="container-fluid content">
      <div className="row">
        <div className="col">
          <h3>Contact address notifications</h3>
          <hr />
        </div>
      </div>
      <p>
        Enter the email addresses to receive customer address change requests
      </p>
      <div className="row">
        <div className="col-md-12">
          <FormContainer onSubmit={submitEmailImportHandler}>
            <TagInput
              label={"Email address"}
              value={emailValueContactAddress}
              onChange={(event) => {
                setEmailValueContactAddress(event);
              }}
              disabled={false}
            />
            {canEdit && (
            <SpinnerButton type="submit" className="btn btn-primary btn--save">
              SAVE
            </SpinnerButton>
            )}
          </FormContainer>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactAddressPage;
