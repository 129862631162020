import axios from 'axios'

import {
  getApiUrl,
} from '../../../utils/config-utility'

const apiGetRequest = apiPath => config => axios.get(
  getApiUrl() + apiPath,
  config,
)

export default apiGetRequest