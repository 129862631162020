import {Button, Modal} from 'react-bootstrap'
import React from 'react'

const DeleteConfirmModal = ({
  showDeleteConfirmDialog,
  deleteNoHandler,
  deleteYesHandler,
}) => {
  return (
    <Modal
      centered={true}
      show={showDeleteConfirmDialog}
      onHide={deleteNoHandler}
    >
      <Modal.Header closeButton className="modal-center">
        <Modal.Title>Are you sure you want to remove this action?</Modal.Title>
      </Modal.Header>
      <Modal.Footer className="modal-center">
        <Button variant="primary" onClick={deleteYesHandler}>
          Yes
        </Button>
        <Button variant="secondary" onClick={deleteNoHandler}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteConfirmModal