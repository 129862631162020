import React, {
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react'

const BaseChart = ({
  id: elementId,
  style = {},
  onInit,
}) => {
  const chartRef = useRef(null)

  const loadRequiredLibs = useCallback(() => ([
    import('@amcharts/amcharts4/core'),
    import('@amcharts/amcharts4/charts'),
  ]), [])

  useLayoutEffect(() => {
    Promise.all(
      loadRequiredLibs(),
    ).then(([
      am4core,
      am4charts,
    ]) => {
      const chart = onInit && onInit(
        am4core,
        am4charts,
      )
      chartRef.current = chart
    })

    return () => {
      chartRef.current
        && chartRef.current.dispose
        && chartRef.current.dispose()
    }
  }, [])

  useEffect(() => {
    Promise.all([
      ...loadRequiredLibs(),
      import('@amcharts/amcharts4/themes/animated'),
    ]).then(([
      am4core,
      am4charts,
      { default: am4themes_animated }
    ]) => {
      am4core.useTheme(am4themes_animated)
    })
  }, [])

  return (
    <div
      className="chart"
      id={elementId}
      style={{
        width: "100%",
        height: "300px",
        ...style
      }}
    />
  )
}

export default BaseChart;
