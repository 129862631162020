import React, {
  useEffect,
  useState,
  useRef
} from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  useToasts
} from 'react-toast-notifications'
import {
  Button,
  Modal
} from 'react-bootstrap'
import { TailSpin } from "react-loader-spinner"

import {
  CKEditor,
} from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import {
  queryCustomerSiteContactData,
  sendMail
} from '../../../../utils/customer_utils'
import {isEmpty, propOr, remove, trim, update, reject} from 'ramda'

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList'],
}

import { track } from "src/main_app/mixpanel";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

  
const SendMail = ({
  showSendMailModal,
  handleSendMailClose,
  fetchCustomerTimeline,
  customer_id = null
}) => {
  useEffect(() => {
    if (showSendMailModal)
      track("Send email modal", {
        tenant_id: tenantInfo.current.id,
        tenant_name: tenantInfo.current.name,
      });
  }, [showSendMailModal])

  const tenantInfo = useRef(getTenantInfoFromLocal());
  const { addToast } = useToasts()
  const params = useParams()
  const id = customer_id ?? params.id
  const [recipients, setRecipients] = useState([])
  const [loading, setLoading] = useState(false)
  const [validSubject, setValidSubject] = useState(true)
  const [validContent, setValidContent] = useState(true)
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
    id && showSendMailModal && addContactsToRecipients()
  }, [id, showSendMailModal])

  const resetInfo = () => {
    setSubject('')
    setContent('')
    addContactsToRecipients()
  }

  const addContactsToRecipients = async () => {
    let recipientsList = []
    const allContacts = await queryCustomerSiteContactData(id)(1, 1000, null, null, { 'sort[0][0]': 'id', 'sort[0][1]': 'asc' })
    allContacts.data.forEach(contact => {
      if (contact.email && contact.email !== '')
        recipientsList.push({
          name: `${propOr('', 'first_name', contact)} ${propOr('', 'last_name', contact)}`,
          email: contact.email,
          confirmed: true,
        })
    })
    setRecipients(recipientsList)
  }

  const addNewRecipient = event => {
    event.preventDefault()
    setRecipients([
      ...recipients, {
        name: {
          value: '',
          hasError: false
        },
        email: {
          value: '',
          hasError: false
        },
        confirmed: false,
      },
    ])
  }

  const confirmRecipient = r_index => {
    const recipient = recipients[r_index]
    const hasValidName = !isEmpty(trim(recipient.name.value))
    const hasValidEmail = !isEmpty(trim(recipient.email.value)) && (/^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(recipient.email.value))
    if (hasValidName && hasValidEmail) {
      setRecipients(update(r_index, {
        name: recipients[r_index].name.value,
        email: recipients[r_index].email.value,
        confirmed: true
      }, recipients))
    } else {
     setRecipients(update(r_index, {
        name: {
          ...recipients[r_index].name,
          hasError: !hasValidName,
        },
        email: {
          ...recipients[r_index].email,
          hasError: !hasValidEmail,
        },
       confirmed: false
      }, recipients))
    }
  }

  const sendMailHandler = async () => {
    if (!isEmpty(trim(subject)) && !isEmpty(trim(content))) {
      setValidSubject(true)
      setValidContent(true)
      setLoading(true)
      const response = await sendMail(id, {
        subject: subject,
        content: content,
        recipients: reject(i => !i.confirmed, recipients).map(recipient => {
          return {
            name: recipient.name,
            email: recipient.email,
          }
        })
      })
      if (response === 'success') {
        setLoading(false)
        resetInfo()
        handleSendMailClose()
        addToast('Emails sent successfully', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
        fetchCustomerTimeline(id)
      } else {
        setLoading(false)
        addToast('Sending emails failed', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      }
    } else {
      setValidSubject(!isEmpty(trim(subject)))
      setValidContent(!isEmpty(trim(content)))
    }
  }

  return (
    id ?
      <Modal
        show={showSendMailModal}
        onHide={() => {
          resetInfo()
          handleSendMailClose()
        }}
        size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title>Send an email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="customer-send-mail-form" className="">
            <div className="form-group">
              <table className="table">
                <thead>
                <tr>
                  <th style={{backgroundColor: '#F3F3F3'}}>Recipient</th>
                  <th style={{backgroundColor: '#F3F3F3'}}>Email</th>
                  <th style={{backgroundColor: '#F3F3F3'}}/>
                  <th style={{backgroundColor: '#F3F3F3'}}/>
                </tr>
                </thead>
                <tbody>
                {
                  recipients.map((recipient, r_index) => {
                    return (
                      recipient.confirmed
                        ? <tr key={r_index}>
                          <td>{recipient.name}</td>
                          <td>{recipient.email}</td>
                          <td/>
                          <td>
                            <button className="btn-text" type="button"><i
                              className="far fa-trash f-16"
                              onClick={e => setRecipients(
                                remove(r_index, 1, recipients))}/></button>
                          </td>
                        </tr>
                        : <tr key={r_index}>
                          <td>
                            <input
                              type="text"
                              value={recipients[r_index].name.value}
                              onChange={e => setRecipients(update(r_index, {
                                ...recipients[r_index],
                                name: {
                                  ...recipients[r_index].name,
                                  value: e.target.value,
                                },
                              }, recipients))}
                              className={`form-control ${recipients[r_index].name.hasError ?
                                'has-error' :
                                ''}`}/>
                            {
                              recipients[r_index].name.hasError &&
                              <small style={{color: '#bd1923'}}>Please insert
                                valid name</small>
                            }
                          </td>
                          <td>
                            <input
                              type="email"
                              value={recipients[r_index].email.value}
                              onChange={e => setRecipients(update(r_index, {
                                ...recipients[r_index],
                                email: {
                                  ...recipients[r_index].email,
                                  value: e.target.value,
                                },
                              }, recipients))}
                              className={`form-control ${recipients[r_index].email.hasError ?
                                'has-error' :
                                ''}`}/>
                            {
                              recipients[r_index].email.hasError &&
                              <small style={{color: '#bd1923'}}>Please insert
                                valid email</small>
                            }
                          </td>
                          <td className="need-confirmation">
                            <button className="btn-text" type="button"><i
                              className="fa fa-check f-16"
                              onClick={e => confirmRecipient(r_index)}/></button>
                          </td>
                          <td className="need-confirmation">
                            <button className="btn-text" type="button"><i
                              className="far fa-trash f-16"
                              onClick={e => setRecipients(
                                remove(r_index, 1, recipients))}/></button>
                          </td>
                        </tr>
                    )
                  })
                }
                </tbody>
              </table>
              <div style={{textAlign: 'right'}}>
                <a href="" onClick={addNewRecipient}>Add another recipient</a>
              </div>
            </div>
            <div className={`form-group`}>
              <label>Subject</label>
              <input type="text" value={subject}
                     onChange={e => setSubject(e.target.value)}
                     className={`form-control ${!validSubject ?
                       'has-error' :
                       ''}`}/>
              {
                !validSubject &&
                <small style={{color: '#bd1923'}}>Subject can not be
                  empty</small>
              }
            </div>
            <div className={`form-group ${!validContent ? 'has-error' : ''}`}>
              <label>Email body</label>
              <CKEditor
                editor={Editor}
                config={editorConfiguration}
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  editor.editing.view.change(writer => {
                    writer.setStyle(
                      'height',
                      '200px',
                      editor.editing.view.document.getRoot(),
                    )
                    writer.setStyle(
                      'color',
                      '#000000',
                      editor.editing.view.document.getRoot(),
                    )
                  })
                  // console.log('Editor is ready to use!', editor)
                }}
                onChange={(event, editor) => {
                  setContent(editor.getData())
                }}
                onBlur={(event, editor) => {
                  // console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  // console.log( 'Focus.', editor );
                }}
              />
              {
                !validContent &&
                <small style={{color: '#bd1923'}}>Mail content can not be
                  empty</small>
              }
            </div>
            <Button variant="primary" onClick={sendMailHandler}
                    disabled={loading}>
              SEND EMAIL
            </Button>
            {loading && (
              <TailSpin
                style={{
                  display: 'inline-block',
                  marginLeft: '5px',
                }}
                color="#00BFFF"
                height={80}
                width={80}
              />
            )}
          </form>
        </Modal.Body>
      </Modal>
      : null
  )
}

export default SendMail
