// layouts
import {
    ManagementLayout,
} from '../layouts'

// management
import { TenantsPage } from '../scenes/tenants_page'

const managementRoutes = [
    {
        exact: true,
        path: '/management/tenants',
        requireLogin: true,
        component: TenantsPage,
        layout: ManagementLayout
    },
]

export default managementRoutes