import React, { useMemo } from 'react'
import {
  isNil,
} from 'ramda'
import {
  useHistory
} from 'react-router'
import { Dropdown } from 'react-bootstrap';

import {
  authService
} from '../../../base/services'
import { checkPermission, useUserPermissions } from 'src/main_app/actions';


const Profile = props => {
  const history = useHistory()
  const userPermissions = useUserPermissions();

  const canViewUserPage = useMemo(() => checkPermission(userPermissions, "users-page", "canView"), [userPermissions]);
  const canViewImportPage = useMemo(() => checkPermission(userPermissions, "import-page", "canView"), [userPermissions]);
  const canViewSettingMailAlertPage = useMemo(() => checkPermission(userPermissions, "settings-mail-alert", "canView"), [userPermissions]);

  const gotoUserPage = event => {
    history.push('/users')
  }

  const gotoImportDashBoard = event => {
    history.push('/import-dashboard')
  }

  const gotoSettingMailAlert = event => {
    history.push('/settings/notifications')
  }

  const logoutClickHandler = event => {
    event.preventDefault()

    authService.signout()
    document.location.href ='/'
    window.location.reload();
  }

  const user = props.user

  return (
    <div className="profile ml-3">
      <div className="profile__box">
        <Dropdown>
          <Dropdown.Toggle variant="" className="profile__btn dropdown-toggle">
            <span className="profile__name ">
              {
                isNil(user.first_name) || isNil(user.last_name)
                  ?
                  user.email.slice(0, 2).toUpperCase()
                  :
                  user.first_name.slice(0, 1).toUpperCase() +
                  user.last_name.slice(0, 1).toUpperCase()
              }
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu align="right">
            {canViewUserPage && <Dropdown.Item onClick={gotoUserPage}>Users</Dropdown.Item>}
            {canViewImportPage && <Dropdown.Item onClick={gotoImportDashBoard}>Import dashboard</Dropdown.Item>}
            {canViewSettingMailAlertPage && <Dropdown.Item onClick={gotoSettingMailAlert}>Settings</Dropdown.Item>}
            <Dropdown.Item href="/sign-out" onClick={logoutClickHandler}>Sign
              out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default Profile
