import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";

import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import { deleteAction } from "../../../../utils/customer_utils";
import { isEmpty, propOr } from "ramda";
import { Pager } from "../../../../components";
import actionStatus from "../../../../enums/action-status.json";
// import actionSavingUnit from "../../../../enums/action-saving-unit.json";
import {
  formatNumber,
  isNilOrEmpty,
  keyLookUp,
} from "../../../../utils/common";
import DeleteConfirmModal from "./modals/delete-confirm-modal";
import { AddAction } from "../../../../components/customer-right-menu/modals";
import CustomerActionsSkeleton from "./customer-actions-skeleton";
import { authService } from "../../../../../base/services";

import { track } from "src/main_app/mixpanel";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
const DisplayDate = ({ time }) => {
  return isNilOrEmpty(time) ? "" : moment(time).format("MM/DD/YYYY");
};

const CustomerActionsPage = ({ total, actionsData, fetchCustomerActions }) => {
  useEffect(() => {
    track("Customer action page", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, []);
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const params = useParams();
  const { addToast } = useToasts();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [getActionsDone, setGetActionsDone] = useState(false);
  const getActions = () => fetchCustomerActions(params.id, currentPage, limit);
  const user = authService.getUser();

  useEffect(() => {
    getActions().then(() => setGetActionsDone(true));
  }, []);

  const onPageChange = async (pageNo) => {
    setCurrentPage(pageNo);

    fetchCustomerActions(params.id, pageNo, limit);
  };

  const editActionClickHandler = (event) => {
    setSelectedAction(event.currentTarget.id);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const deleteActionClickHandler = (event) => {
    setSelectedAction(event.target.id);
    setShowDeleteConfirmDialog(true);
  };

  const deleteNoHandler = () => setShowDeleteConfirmDialog(false);
  const deleteYesHandler = async () => {
    const response = await deleteAction(selectedAction, {});
    if (response === "failed") {
      addToast("Delete failed. You can re-try later.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    } else if (response === "success") {
      addToast("Delete action successfully.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    }
    getActions();
    setShowDeleteConfirmDialog(false);
  };

  return getActionsDone ? (
    <>
      <div className="container-fluid content">
        {!isEmpty(actionsData) ? (
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" width="300">
                      Action item
                    </th>
                    <th scope="col" width="100">
                      Status
                    </th>
                    <th scope="col" className="text-right" width="200">
                      Savings (ML/annum)
                    </th>
                    <th scope="col" width="200">
                      Scheduled date
                    </th>
                    <th scope="col" width="200">
                      Implemented date
                    </th>
                    <th scope="col">Implementation notes</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {actionsData.map((data) => {
                    //Check for type customer login just view data
                    if (user.role != "Customer") {
                      return (
                        <tr
                          key={data.id}
                          id={data.id}
                          onClick={editActionClickHandler}
                        >
                          <td>{propOr("", "name", data)}</td>
                          <td>
                            {keyLookUp(actionStatus, +propOr("", "status", data))}
                          </td>
                          <td className="text-right">{`${formatNumber(
                            propOr(0, "savings", data)
                          )}`}</td>
                          <td>
                            <DisplayDate
                              time={propOr("", "scheduled_date", data)}
                            />
                          </td>
                          <td>
                            <DisplayDate
                              time={propOr("", "implemented_date", data)}
                            />
                          </td>
                          <td>{propOr("", "notes", data)}</td>
                          <td
                            style={{ width: "10px" }}
                            className="text-right"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="btn-text dropdown-toggle"
                              >
                                <i className="far fa-ellipsis-v" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="right">
                                <Dropdown.Item
                                  as={Button}
                                  id={data.id}
                                  onClick={editActionClickHandler}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as={Button}
                                  id={data.id}
                                  onClick={deleteActionClickHandler}
                                >
                                  Remove
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={data.id}>
                          <td>{propOr("", "name", data)}</td>
                          <td>{keyLookUp(actionStatus, +propOr("", "status", data))}</td>
                          <td className="text-right">{`${formatNumber(
                            propOr(0, "savings", data)
                          )}`}</td>
                          <td>
                            <DisplayDate
                              time={propOr("", "scheduled_date", data)}
                            />
                          </td>
                          <td>
                            <DisplayDate
                              time={propOr("", "implemented_date", data)}
                            />
                          </td>
                          <td>{propOr("", "notes", data)}</td>
                          <td></td>
                        </tr>
                      );
                    }
                   
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-lg-end pagination-box">
              <Pager
                onPageChange={onPageChange}
                total={total}
                limit={limit}
                currentPage={currentPage}
              />
            </div>
          </>
        ) : (
          <div className="no-item-message">
            There are currently no actions for this customer. Actions, or WEMPS
            are a list of recommendations that this customer can implement to
            reduce their water usage. You can add an action using the “Add
            action” button from the menu bar top right.
          </div>
        )}
      </div>
      <DeleteConfirmModal
        showDeleteConfirmDialog={showDeleteConfirmDialog}
        deleteNoHandler={deleteNoHandler}
        deleteYesHandler={deleteYesHandler}
      />
      <AddAction
        showAddActionsModal={showEditModal}
        handleAddActionsClose={closeEditModal}
        isUpdate={true}
        actionId={selectedAction}
        actionData={actionsData.find((e) => e.id == selectedAction)}
      />
    </>
  ) : (
    <CustomerActionsSkeleton line={limit} />
  );
};

export default CustomerActionsPage;
