import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CustomEase)
 
const TvAppPage = () => {
  const url = 'https://screenshot-alert-page.s3.ap-southeast-2.amazonaws.com/aquo-video-tv-app.mp4#t=1';
  // const repeat = () => {
  //   const curVid = document.getElementById('videoPlayer');
  //   curVid.play();
  // }

  useEffect(() => {
    let tl = gsap.timeline({repeat: -1});
    tl.to(".logoAquo", {duration: 0, opacity: 1, x: '135%', ease: "power4.out"}, "-=2");
    tl.to(".xLogo", {duration: 0, opacity: 1, x: '300% ', ease: "power4.out"}, "-=2");

    tl.from(".map-container", {duration: 0.5, opacity:0, ease: "back", delay: 6 }, "-=1");
    tl.to(".map-container", { duration: 1, y: -200}, "-=3");
    tl.to(".map-container", {duration: 1, delay: 10, opacity:0, ease: "power3.out"});
    tl.to(".intro", {duration: 0, opacity:0, ease: "power4.out"}, "-=2");
    tl.from(".superset", {duration: 0.5, opacity: 0, ease: "back" }, "-=2");
    tl.to(".superset-scroll-top", { duration: 1, y: -200}, "-=3");
    tl.to(".superset", { duration: 55, delay: 6, ease: CustomEase.create("custom", "M0,0 C0.402,0.02 0.537,0.391 0.666,0.512 0.71,0.553 0.92,0.834 1,0.988 "), y: -2500});
    // tl.call(repeat, ["param1", "param2"], "-=1")
  }, [])

  return (
    <div className="wrapper">
      <div className="intro">
        <img className="video" src='/assets/tv-app/DroneFlyingOverChichesterDam.JPG' style={{ position: 'absolute' }}/>
        <div style={{height: "200px", width: '300px'}}>
        <div className="logo" style={{backgroundColor: 'rgba(227, 227, 227, 0.5)', zIndex: '1', height: "15vh", width: "23vw", position: "absolute", borderRadius: "0px 50px 0px 0px", bottom: '0'}}>
            <img className="logoHunterWater" src='/assets/tv-app/hunter-water-logo.png' style={{width: '15vh', position: 'absolute', paddingLeft: "7%",  paddingTop: "5%"}}/>
            <img className="xLogo" src='/assets/tv-app/x-icon.png' style={{width: '5vh', position: 'absolute', paddingLeft: "7%",  paddingTop: "16%"}}/>
            <img className="logoAquo" src='/assets/tv-app/aquo-logo.png' style={{width: '15vh', position: 'absolute', paddingLeft: "7%", paddingTop: "5%"}}/>
          </div>
        </div>
      </div> 

      <div className="map-scroll-top">
          <iframe
              className="map-container"
              style={{ width: "100%", height:'100%', position: 'absolute'}}
              frameBorder="0"
              src="https://staging-hunterwater.aquo.com.au/aquo.html"
          />
      </div>
      
        <div className="superset-scroll-top">
          <iframe
              className="superset"
              style={{ width: "100%", height: "3550px", position: 'absolute'}}
              frameBorder="0"
              src="https://superset-public.aquo.com.au/superset/dashboard/30/?standalone=true"
          />
        </div>

   </div>
  );
}

export default TvAppPage