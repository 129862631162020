import React, { useState, useEffect, useRef, useMemo } from "react";
import { getWaterSupply, getWaterUsage } from "../../services/apis";
import { useHistory } from "react-router";
import {
  pathOr,
  find,
  map,
  propOr,
  prop,
  tap,
  reduce,
  add,
  filter,
  isEmpty,
  isNil,
} from "ramda";
import { compose } from "redux";
import moment from "moment";
import { formatNumber, toNumber } from "../../utils/common";
import { authService } from "src/base/services";
import { getApiUrl } from "../../utils/config-utility";
import {
  checkPermission,
  useTenantConfig,
  useUserPermissions,
} from "../../actions";

const getTotalAmountByMonth = (month, data) =>
  compose(
    (_) => Math.round(_),
    reduce(add, 0),
    map(propOr(0, "total_amount")),
    filter((i) => i.month === month)
  )(data);

import { track } from "src/main_app/mixpanel";
import { Link } from "react-router-dom";
import InsighstNav from "../../components/insights-nav/insights-nav";
import { useParams, Route } from "react-router-dom";

const InsightsPage = (props) => {
  useEffect(() => {
    track("Insights page");
  }, []);

  const tenantConfig = useTenantConfig();
  const history = useHistory();

  const [waterSupply, setWaterSupply] = useState([]);
  const [storageLevels, setStorageLevels] = useState([]);
  const [waterUsage, setWaterUsage] = useState([]);
  const [nonRevenueWater, setNonRevenueWater] = useState([]);
  const [iframeUrl, setIframeUrl] = useState("");
  const [iframeHeight, setIframeHeight] = useState(2430);
  const [displayWaterSupplyUsage, setDisplayWaterSupplyUsage] = useState(false);
  const exportUrlRef = useRef(
    `${getApiUrl()}/export/water-supply/csv?&token=${authService.getAccessToken()}`
  );

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "insights-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const loadData = async () => {
    const resultWaterSupply = await getWaterSupply();
    setWaterSupply(pathOr([], ["data", "data"], resultWaterSupply));
    setStorageLevels(pathOr([], ["data", "storageLevels"], resultWaterSupply));

    const resultWaterUsage = await getWaterUsage();
    setWaterUsage(pathOr([], ["data", "data"], resultWaterUsage));
  };

  useEffect(() => {
    loadData();
  }, []);

  const { routes = [] } = props;

  useEffect(() => {
    setDisplayWaterSupplyUsage(pathOr(false, ["insightsPage", "displayeWaterSupplyUsageSection"], tenantConfig));
    setIframeUrl(pathOr("", ["insightsPage", "iframeUrl"], tenantConfig));
    setIframeHeight(
      pathOr(2430, ["insightsPage", "iframeHeight"], tenantConfig)
    );
    if ((tenantConfig.slug === 'hunterwater') && (window.location.pathname == "/insights")) { //check for tab insighs page 
      document.location.href = `insights/data-validation`
      // document.location.href = `insights/real-time-water-usage`
    }
  }, [tenantConfig]);

  useEffect(() => {
    if (isEmpty(waterSupply) || isEmpty(waterUsage)) return;
    const months = [...new Set(map(prop("month"), waterSupply))];

    const data = months.map((month) => ({
      month,
      val: Math.round(
        getTotalAmountByMonth(month, waterSupply) -
          getTotalAmountByMonth(month, waterUsage)
      ),
    }));
    setNonRevenueWater(data);
  }, [waterSupply, waterUsage]);

  const renderWaterSupply = () => {
    const dams = [...new Set(map(prop("source"), waterSupply))];
    const months = [...new Set(map(prop("month"), waterSupply))];

    const renderAmount = (dam, month) =>
      compose(
        formatNumber,
        toNumber,
        propOr(0, "total_amount"),
        find((i) => i.source === dam && i.month === month)
      )(waterSupply);

    const renderTotalAmountByMonth = (month) =>
      compose(formatNumber, (data) => getTotalAmountByMonth(month, data))(
        waterSupply
      );

    const displayStorageLevels = (dam) => {
      const storageLevel = storageLevels.find((i) => i.name === dam);
      return storageLevel ? storageLevel["storage-levels"] + "%" : "";
    };

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="300">&nbsp;</th>
              {months.map((month, monthIndex) => {
                const monthObj = moment(month);
                const today = new Date();
                const isCurrentMonth =
                  monthObj.month() === today.getMonth() &&
                  monthObj.year() === today.getFullYear();
                return (
                  <th
                    key={monthIndex + ""}
                    className="text-right align-middle"
                    width="15%"
                  >
                    {isCurrentMonth
                      ? "Year to date"
                      : monthObj.format("MMM YYYY")}
                  </th>
                );
              })}
              <th className="text-right align-middle" width="15%">
                Storage levels
              </th>
              <th className="text-right">
                <a className="btn btn-link d-none" href={exportUrlRef.current}>
                  Download CSV
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6" className="p-0">
                <h3 className="sub-title mt-4 pl-4 mb-0 pb-4">SUPPLY (ML)</h3>
              </td>
            </tr>
            {dams.map((dam, damIndex) => (
              <tr key={damIndex + ""}>
                <td>{dam}</td>
                {months.map((month, monthIndex) => (
                  <td key={monthIndex + ""} className="text-right">
                    {renderAmount(dam, month)}
                  </td>
                ))}
                <td className="text-right">{displayStorageLevels(dam)}</td>
                <td>&nbsp;</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>TOTAL SUPPLY</th>
              {months.map((month, monthIndex) => {
                return (
                  <th key={monthIndex + ""} className="text-right align-middle">
                    {renderTotalAmountByMonth(month)}
                  </th>
                );
              })}
              <th>&nbsp;</th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  const renderWaterUsage = () => {
    const industries = [...new Set(map(prop("source"), waterUsage))];
    const months = [...new Set(map(prop("month"), waterUsage))];

    const renderAmount = (industry, month) =>
      compose(
        formatNumber,
        (_) => Math.round(_),
        toNumber,
        propOr(0, "total_amount"),
        find((i) => i.source === industry && i.month === month)
      )(waterUsage);

    const renderTotalAmountByMonth = (month) =>
      compose(formatNumber, (data) => getTotalAmountByMonth(month, data))(
        waterUsage
      );

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="300">&nbsp;</th>
              {months.map((month, monthIndex) => {
                const monthObj = moment(month);
                const today = new Date();
                const isCurrentMonth =
                  monthObj.month() === today.getMonth() &&
                  monthObj.year() === today.getFullYear();
                return (
                  <th
                    key={monthIndex + ""}
                    className="text-right align-middle"
                    width="15%"
                  >
                    {isCurrentMonth
                      ? "Year to date"
                      : monthObj.format("MMM YYYY")}
                  </th>
                );
              })}
              <th className="text-right">
                <a className="btn btn-link d-none" href={exportUrlRef.current}>
                  Download CSV
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6" className="p-0">
                <h3 className="sub-title mt-4 pl-4 mb-0 pb-4">
                  WATER USAGE (ML)
                </h3>
              </td>
            </tr>
            {industries.map((industry, industryIndex) => (
              <tr key={industryIndex + ""}>
                <td>{industry}</td>
                {months.map((month, monthIndex) => (
                  <td key={monthIndex + ""} className="text-right">
                    {renderAmount(industry, month)}
                  </td>
                ))}
                <td>&nbsp;</td>
              </tr>
            ))}
            <tr>
              <td>Non Revenue water</td>
              {nonRevenueWater.map((item, index) => (
                <td key={index + ""} className="text-right">
                  {/* {formatNumber(item.val)} */}
                </td>
              ))}
              <td>&nbsp;</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>TOTAL USAGE/ DEMAND</th>
              {months.map((month, monthIndex) => {
                return (
                  <th key={monthIndex + ""} className="text-right align-middle">
                    {renderTotalAmountByMonth(month)}
                  </th>
                );
              })}
              <th>&nbsp;</th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };
  <>{console.log('tenantConfig.insightsPageTabRealTime', tenantConfig.insightsPageTabRealTime)}</>
 if ((tenantConfig.slug === 'hunterwater') && (tenantConfig.insightsPageTabRealTime != undefined)) { //check for tab insighs page
  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      <div className="page-heading pb-0">
        <div className="container-fluid ">
          <h2 className="title mb-0">
          Insights dashboard
          </h2>
          <div className="block-center-between">
            <div className="left-action-menu">
            <InsighstNav />
            </div>
          </div>
        </div>
      </div>
      {routes.map(({ component: ComponentPage, routes, path, ...rest }, i) => (
        <Route
          key={i + ""}
          path={`${props.match.path}${path}`}
          {...rest}
          render={(routeProps) => (
            <ComponentPage
              routes={routes}
              {...routeProps}
              reloadInfo={loadData}
            />
          )}
        />
      ))}
    </div>
  );
 } else {
  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">Insights dashboard</h2>
          <Link className="d-none" to="/reports">
            View all reports <i className="far fa-arrow-alt-circle-right"></i>
          </Link>
        </div>
      </div>
      <div className="container-fluid content">
        <iframe
          style={{
            width: "100%",
            height: iframeHeight + "px",
          }}
          scrolling="no"
          frameBorder="0"
          src={iframeUrl}
          title="Superset for report"
        />
        {displayWaterSupplyUsage && renderWaterSupply()}
        {displayWaterSupplyUsage && renderWaterUsage()}
      </div>
    </div>
  );
 }

};

export default InsightsPage;
