import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Line,
  ComposedChart,
  Dot
} from "recharts";
import { propOr, pathOr, prop, map, isNil, isEmpty } from "ramda";
import { firstDateOfMonthsAgo, formatNumber, lastDateOfMonthsAgo, roundNumber } from "src/main_app/utils/common";
import { getTaxonomyBenchmarkSewerChartData } from "src/main_app/services/apis";
import { Link } from "react-router-dom";
import { useTenantConfig } from "src/main_app/actions";
import { saveAs } from 'file-saver'; 
import { useCurrentPng } from "recharts-to-png";
import * as htmlToImage from 'html-to-image';

const xFormatter = (tickItem) => formatNumber(tickItem) + " ";
const yFormatter = (tickItem) => formatNumber(tickItem) + " ";

const renderTooltip =
  (isShow) =>
  ({ active, payload, label }) => {
    if (!isShow || !active || !payload || !payload.length) return null;

    return (
      <div
        className="custom-line-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "#fff",
          boxShadow: "0 0 8px 8px rgb(0 0 0 / 5%)",
        }}
      >
        <h5
          className="mb-0"
          style={{
            fontWeight: 700,
            maxWidth: "300px",
          }}
        >
          {payload[0].payload.customer_name}
        </h5>
      </div>
    );
  };

const Popup = (props) => {
  if (!props.visible) return null;
  return (
    <div className="mapboxgl-popup-content" style={props.style}>
      <button
        className="mapboxgl-popup-close-button"
        type="button"
        onClick={props.onClose}
      >
        ×
      </button>
      <div className="map-marker-info customer" style={{ width: "300px" }}>
        <div className="marker-info-name customer-name">
          <Link
            className="marker-info-customer-link"
            to={`/customer/${props.data.id}/overview`}
          >
            <span className="info-name">{props.data.name}</span>
          </Link>
          <hr></hr>
          <div className="marker-info-detail">
            {props.data.annual_revenue > 0 && (
              <div>
                <span className="info-attribute">Annual revenue: </span>
                <span style={{}}>${formatNumber(Math.round(props.data.annual_revenue))}</span>
              </div>
            )}
        </div>
        </div>
      </div>
    </div>
  );
};

const BenchmarkSewerSection = ({ taxonomyID = null, taxonomyData }) => {
  const tenantConfig = useTenantConfig();
  const [isDisplayPopup, setIsDisplayPopup] = useState(false);
  const [popupPayload, setPopupPayload] = useState({
    name: "",
    payload: [],
  });
  const [popStyle, setPopStyle] = useState({
    position: "absolute",
    width: "fit-content",
  });

  const [totalCustomerBelowIndustryRate, setTotalCustomerBelowIndustryRate] = useState(0);
  const [totalAnnualRevenue, setTotalAnnualRevenue] = useState(0);
  const [pointColor, setPointColor] = useState("#000");
  const [chartData, setChartData] = useState(null);
  const [xDomain, setXDomain] = useState([0, 0]);
  const [yDomain, setYDomain] = useState([0, 0]);

  const { addToast } = useToasts();

  const loadBenchmarkChartData = async () => {
    const {
      data: { data: result, totalCustomerBelowIndustryRate: totalBelowIndustryRate, totalAnnualRevenue: totalAnnualRevenue},
    } = await getTaxonomyBenchmarkSewerChartData(taxonomyID);
    setChartData(result);
    setTotalCustomerBelowIndustryRate(totalBelowIndustryRate.length);
    setTotalAnnualRevenue(totalAnnualRevenue);
  };

  const loadTenantConfigData = async () => {
    const color = pathOr(
      "#000",
      ["config", "tenantDisplay", "color"],
      tenantConfig
    );
    setPointColor(color);
  };

  useEffect(() => {
    if (isEmpty(tenantConfig)) return;
    loadTenantConfigData();
  }, [tenantConfig]);

  useEffect(() => {
    loadBenchmarkChartData();
  }, []);

  useEffect(() => {
    if (isNil(chartData)) return;
    const index = map(prop("index_x"), chartData);
    const maxX = Math.max(...index);
    setXDomain([0, maxX]);
    setYDomain([0, 100]);  
  }, [chartData]);

  const yLabel = useMemo(
    () => ({
      value: "Sewer discharge factor (%)",
      angle: -90,
      position: "top",
      dy: 200,
      dx: -20,
    }),
    []
  );


  const handleScatterClick = (e) => {
    setPopupPayload({
      id: e.id,
      name: e.customer_name,
      annual_revenue: e.annual_revenue
    });

    setIsDisplayPopup(true);
    setTimeout(() => {
      setPopStyle({
        ...popStyle,
        left: e.tooltipPosition.x - 120,
        top:
          e.tooltipPosition.y -
          document.querySelector(".map-marker-info").clientHeight +
          8,
      });
    }, 0);
  };

  const RenderDot = (props) => {
    const { cx, cy, stroke, payload, sewer_discharge_factor, avg_daily_usage } = props;
    // return (<Dot cx={cx} cy={cy} fill="red" r={15} />)
    if (avg_daily_usage > 100) {
      return (
        <Dot cx={cx} cy={cy} fill="#d6a189" r={12} />
      );
    } else if ((avg_daily_usage > 50) && (avg_daily_usage <= 100)) {
      return (
        <Dot cx={cx} cy={cy} fill="#d6a189" r={10} />
      );
    } else if ((avg_daily_usage > 40) && (avg_daily_usage <= 50)) {
      return (
        <Dot cx={cx} cy={cy} fill="#d6a189" r={9} />
      );
    } else if ((avg_daily_usage > 30) && (avg_daily_usage <= 40)) {
      return (
        <Dot cx={cx} cy={cy} fill="#d6a189" r={8} />
      );
    } else if ((avg_daily_usage > 20) && (avg_daily_usage <= 30)) {
      return (
        <Dot cx={cx} cy={cy} fill="#d6a189" r={7} />
      );
    } else if ((avg_daily_usage > 10) && (avg_daily_usage <= 20)) {
      return (
        <Dot cx={cx} cy={cy} fill="#4a8c93" r={6} />
      );
    } else if ((avg_daily_usage > 5) && (avg_daily_usage <= 10)) {
      return (
        <Dot cx={cx} cy={cy} fill="#4a8c93" r={5} />
      );
    } else if ((avg_daily_usage > 3) && (avg_daily_usage <= 5)) {
      return (
        <Dot cx={cx} cy={cy} fill="black" r={4} />
      );
    } else if ((avg_daily_usage > 2) && (avg_daily_usage <= 3)) {
      return (
        <Dot cx={cx} cy={cy} fill="black" r={4} />
      );
    } else {
      return (
        <Dot cx={cx} cy={cy} fill="black" r={4} />
      );
    }
  };

//Download image chart
const handleBenchmarkDownload = () =>{
  htmlToImage.toPng(document.getElementById("benchmark-sewer-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'benchmark-sewer-chart.png');
   });
 }
  return (
    <>
      <h3 className="sub-title mt-4">Discharge factor</h3>
      
      {isNil(chartData) && <Skeleton height={400} />}
        <>
          <a className="btn btn-primary btn-sm" onClick={handleBenchmarkDownload}>Download as image</a>
          <div className="card" id="benchmark-sewer-chart">
            <div className="card-body" style={{ position: "relative" }}>
              <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                <ComposedChart data={chartData}>
                  <CartesianGrid stroke="#e9ebf1" />
                  <XAxis
                    dataKey="index_x"
                    type="number"
                    stroke="#e9ebf1"
                    domain={xDomain}
                    tickFormatter={xFormatter}
                    tick={false} 
                  />
                  <YAxis
                    dataKey="sewer_discharge_factor"
                    type="number"
                    stroke="#e9ebf1"
                    domain={yDomain}
                    tickFormatter={yFormatter}
                    label={yLabel}
                  />
                  <Scatter
                    name="A customer"
                    fill={pointColor}
                    data={chartData}
                    onClick={handleScatterClick}
                    shape={<RenderDot/>}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                      borderRadius: "4px",
                      border: "none",
                    }}
                    itemStyle={{
                      color: "#05d6f",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    content={renderTooltip(!isDisplayPopup)}
                  />
                  <Line 
                    dataKey="anzsic_percent" 
                    stroke="#28a2b4" 
                    dot={false}
                    activeDot={false}
                    legendType="none"
                    />
              </ComposedChart>
                {/* </ScatterChart> */}
              </ResponsiveContainer>
              <div className="row" style={{position: "relative", left: "25%", textAlign: "center"}}>
                <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <label><strong>Customers below industry rate:</strong> {totalCustomerBelowIndustryRate}</label>
                  </div>
                  </div>
                </div>
                <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <label><strong>Potential revenue gains:</strong> ${formatNumber(Math.round(totalAnnualRevenue))}</label>
                  </div>
                  </div>
                </div>
              </div>
              
              <br></br>
              <Popup
                visible={isDisplayPopup}
                onClose={() => setIsDisplayPopup(false)}
                style={popStyle}
                data={popupPayload}
              />
            </div>
          </div>
        </>
    </>
  );
};

export default BenchmarkSewerSection;

