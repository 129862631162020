import 'core-js/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

const bootstrap = ((win, doc) => (App, store) => {
    const domContentLoaded = 'DOMContentLoaded'
    const render = () => {
        win.timeoutToReload && clearTimeout(win.timeoutToReload)

        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
            doc.getElementById('root-app')
        )
    }

    const _domReadyHandler = () => {
        doc.removeEventListener(domContentLoaded, _domReadyHandler, false)
        render()
    }

    switch (doc.readyState) {
        case 'loading':
            doc.addEventListener(domContentLoaded, _domReadyHandler, false)
            break
        case 'interactive':
        case 'complete':
        default:
            render()
    }
})(window, document)

export default bootstrap
