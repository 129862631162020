import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { track } from "src/main_app/mixpanel";

const CustomerModalQRCode = ({
  showModal,
  handleActionsClose,
}) => {
  if (!showModal) return null
  
  useEffect(() => {
  }, [])

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={"sm"}>
      <Modal.Header closeButton>
            <Modal.Title>QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-link-form" className="">
          <div className="form-group text-center">
            <img className="logoHunterWater" src='https://screenshot-alert-page.s3.ap-southeast-2.amazonaws.com/qrcode.png' style={{width: "200px", height: "200px"}}/>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerModalQRCode;

