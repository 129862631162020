import React from 'react'
import {
  propEq,
  propOr,
  compose,
  find,
} from 'ramda'
import { layerNames } from '../layers/layer-config'
import { convertDateMYWithShotMonthName, roundNumber } from "src/main_app/utils/common";

const getCustomerType =
  compose(
    propOr('', 'taxonomy_label'),
    find(
      propEq('parent_taxonomy_name', 'type')
    ),
    propOr([], 'customerTaxonomies')
  )

const MarkerInfo = ({
  info,
  type,
}) => {
  if (type === 'tooltip') {
    return (
      <div
        className="map-marker-tooltip"
        style={{
          width: "fit-content",
          height: "auto",
          fontWeight: "bold"
        }}>
        <div>
          {propOr('', 'name', info)}
        </div>
      </div>
    )
  }

  switch (info.type) {
    case layerNames.customers:
      return (
        <div
          className={`map-marker-info customer`}
          style={{
            width: "300px",
          }}
        >
          <div className="marker-info-name customer-name" style={{marginRight:"10px"}}>
            <a className="marker-info-customer-link" href={`/customer/${info.id}/overview`}><span className="info-name">{propOr('', 'display_name', info)}</span></a>
          </div>
          <div className="marker-info-detail">
            {
              info.address
              && <div className="customer-info-address">
                <span className="info-attribute">Address: </span>{info.address}
              </div>
            }
            {
              !!info.avg_daily_usage
              && <div className="customer-info-avg-usage">
                <span className="info-attribute">AVG Usage: </span>{`${info.avg_daily_usage} ML / Year`}
              </div>
            }
            {
              !!info.critical_class
              && <div className="customer-info-critical-class">
                <span className="info-attribute">Critical class: </span>{info.critical_class}
              </div>
            }
            {
              (info.water_zone && info.water_zone !== '')
              && <div className="customer-info-water-zone">
                <span
                  className="info-attribute">Water zone: </span>{info.water_zone}
              </div>
            }
            {
              (info.sewer_zone && info.sewer_zone !== '')
              && <div className="customer-info-sewer-zone">
                <span
                  className="info-attribute">Sewer zone: </span>{info.sewer_zone}
              </div>
            }
            {
              getCustomerType(info)
              && <div className="customer-info-type">
                <span className="info-attribute">Type: </span>{getCustomerType(info)}
              </div>
            }
          </div>
        </div>
      )
    case layerNames.bom:
      return (
        <div
          className={`map-marker-info bom`}
          style={{
            minWidth: "260px"
          }}
        >
          <div className="marker-info-name bom-name" style={{textAlign: "center", marginRight:"10px"}}>
            <span className="info-name">{propOr('', 'name', info)}</span>
          </div>
          <div className="marker-info-detail">
            {/* <div className="bom-info-rainfall">
              <span className="info-attribute">Rainfall: </span>{`${propOr('', 'rainfall', info)} mm / Year`}
            </div> */}
            <div style={{paddingBottom: "15px"}}>
              <table style={{fontSize: "13px"}}>
                <tr>
                  <th style={{padding: "5px 0px 5px 20px"}}>Month</th>
                  <th>Rainfall</th>
                </tr>
                {info.month.map((item, index) => (
                  <tr key={index + ""}>
                    <td style={{padding:" 5px 0 5px 20px", color: "#727f94"}}>{convertDateMYWithShotMonthName(new Date(item))}</td>
                    <td style={{color: "#727f94"}}> {info.rainfall[index] >= 0 ? roundNumber(info.rainfall[index]) : 0} mm</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      )
    case layerNames.wasteWater:
      return (
        <div
          className={`map-marker-info waste-water`}
          style={{
            width: "300px",
          }}
        >
          <div className="marker-info-name waste-water-name">
            <span className="info-name">{propOr('', 'name', info)}</span>
          </div>
          <div className="marker-info-detail">
            <div className="waste-water-info-address">
              <span className="info-attribute">Address: </span>{propOr('', 'address', info)}
            </div>
            <div className="waste-water-info-capacity">
              <span className="info-attribute">Capacity: </span>{`${propOr('', 'capacity', info)}ML / Year`}
            </div>
            <div className="waste-water-info-treatment-level">
              <span className="info-attribute">Treatment level: </span>{propOr('', 'treatment_level', info)}
            </div>
            {
              (info.sewer_zone && info.sewer_zone !== '')
              && <div className="waste-water-info-sewer-zone">
                <span className="info-attribute">Sewer zone: </span>{propOr('', 'sewer_zone', info)}
              </div>
            }
          </div>
        </div>
      )
    case layerNames.dams:
      return (
        <div
          className={`map-marker-info dam`}
          style={{
            width: "300px",
          }}
        >
          <div className="marker-info-name dam-name" style={{marginRight:"10px"}}>
            <span className="info-name">{propOr('', 'name', info)}</span>
          </div>
          <div className="marker-info-detail">
            <div className="dam-info-location">
              <span className="info-attribute">Location: </span>{propOr('', 'location', info)}
            </div>
            <div className="dam-info-storage-percent">
              <span className="info-attribute">Current storage level: </span>{`${propOr('', 'current_storage_level', info)} %`}
            </div>
          </div>
        </div>
      )
  }
}

export default MarkerInfo
