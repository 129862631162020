import {
  compose,
  keys,
  map,
  propOr,
  pathOr,
  isNil,
  hasPath,
  reject,
} from "ramda";
import React, {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { isNilOrEmpty, addDays } from "src/main_app/utils/common";
import Select from '@bhunjadi/react-select-v3';

import './notes-filter.style.scss'

const AllValue = "-All-";
const filterWithDefault = {
  noteType: false,
  customerList: false,
  dateRange: false,
};

const customerList = [
  {'id': "1", 'name': "Customer 1"}, 
  {'id': "2", 'name': "Customer 2"}, 
  {'id': "3", 'name': "Customer 3"}, 
  {'id': "4", 'name': "Customer 4"},
  {'id': "5", 'name': "Customer 5"}
];

const noteType = [
  {'id': "1", 'label': "Note 1"}, 
  {'id': "2", 'label': "Note 2"}, 
  {'id': "3", 'label': "Note 3"}, 
  {'id': "4", 'label': "Note 4"},
  {'id': "5", 'label': "Note 5"}
];

const NotesFilter = (
  { onFiltersApply, disabled },
  ref
) => {
  const tenantConfig = useTenantConfig();
  const toggleRef = useRef(null);
  const maxDateRef = useRef(addDays(new Date(), -1));
  const [filterWith, setFilterWith] = useState(filterWithDefault);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [notesTypeFilter, setNotesTypeFilter] = useState();

  const [defaultValueMultiCustomer, setDefaultValueMultiCustomer] = useState();

  const toggleClick = () => {
    toggleRef.current && toggleRef.current.click();
  };

  const onApply = () => {
    onFiltersApply();
  };

  const onApplyClick = (event) => {
    onApply();
    setTimeout(toggleClick, 200);
  };

  const filtersClear = (event) => {
    toggleClick();
    setStartDate();
    setEndDate();
    setNotesTypeFilter();
    setFilterWith(filterWithDefault)
  };


  const handleMultiCustomerChange = (option) => {
    setDefaultValueMultiCustomer(option)
  }


  useEffect(() => {
    if (isNilOrEmpty(tenantConfig)) return;
  }, [tenantConfig]);

  return (
    <div className="filter-button filter alert--filter-button">
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="filter-button__btn p-0"
          ref={toggleRef}
        >
          <div className="btn-group ">
            <span className="btn btn-default">
              <i className="far fa-filter mr-2" /> Filter{" "}
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="filter__header block-center-between">
            <button
              className="filter__clear btn btn-default"
              onClick={filtersClear}
            >
              Clear
            </button>
            <span className="filter__label ">Filters</span>
            <button
              className="filter__apply btn btn-default"
              onClick={onApplyClick}
            >
              Apply
            </button>
          </div>
          <div className="filter__body">
            <div className="filter__options f-14">

            <>
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.customerList}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        customerList: !filterWith.customerList,
                      })
                    }
                  />
                  <span className="ck" />
                  Customers
                </label>
              </div>
              {filterWith.customerList && (
                <div className="filter__footer">
                  <Select
                      defaultValue={defaultValueMultiCustomer}
                      isMulti
                      onChange={handleMultiCustomerChange}
                      getOptionLabel ={(option)=>option.name}
                      getOptionValue ={(option)=>option.id}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      options={customerList}
                  />
                </div>
              )}
            </>

            <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.dateRange}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        dateRange: !filterWith.dateRange,
                      })
                    }
                  />
                  <span className="ck" />
                  Date range
                </label>
              </div>
              {filterWith.dateRange && (
                <div className="filter__footer">
                  <>
                    <div>from: </div>
                    <div className="month-range-picker start-date">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                    <div>to: </div>
                    <div className="month-range-picker end-date">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        // maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                  </>
                </div>
              )}
            
                <>
                  <div className="filter__option">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        className=""
                        name="option-filter"
                        checked={filterWith.noteType}
                        onChange={(e) =>
                          setFilterWith({
                            ...filterWith,
                            noteType: !filterWith.noteType,
                          })
                        }
                      />
                      <span className="ck" />
                      Notes type
                    </label>
                  </div>
                  {filterWith.noteType && (
                    <div className="filter__footer">
                      <Select
                          defaultValue={{ id: '-All-', label: 'All' }}
                          onChange={(e) => {
                            setNotesTypeFilter(e.id)
                          }}
                          getOptionLabel ={(option)=>option.label}
                          getOptionValue ={(option)=>option.id}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          options={noteType}
                      />
                    </div>
                  )}
                </>
                
              
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default forwardRef(NotesFilter);
