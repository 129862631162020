import React from 'react'
import {
    Button,
    Spinner,
} from "react-bootstrap"

const SpinnerButton = ({
    children,
    isProgressing,
    ...props
}) => (
    <Button {...props}>
        {children}
        {isProgressing && (
            <>
                {' '}
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
            </>
        )}
    </Button>
)

export default SpinnerButton