export const saveMenuToLocal = menu => {
  localStorage.setItem('tenant_menu', JSON.stringify(menu))
}

export const getMenuFromLocal = () => {
  return JSON.parse(localStorage.getItem('tenant_menu')) ?? []
}

export const getTenantInfoFromLocal = () => {
  return JSON.parse(localStorage.getItem('tenantInfo')) ?? {}
}
