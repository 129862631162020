import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const TvAppLayout = (props) => {
  const { component: ComponentPage, ...rest } = props
  return (
    // <div className="container">
    //   <div className="row sign-in">
    //     <div className="col no-gutters p-0">
    //       <div className="row m-0">
    //         <Route
    //           {...rest}
    //           render={routeProps => (
    //             <ComponentPage {...routeProps} />
    //           )}
    //         />
    //           </div>
    //     </div>
    //   </div>
    // </div>
    // <div className="wapper-container">
    //   <div className="main-content">
    //     <Route
    //       {...rest}
    //       render={routeProps => (
    //         <ComponentPage {...routeProps} />
    //       )}
    //     />
    //   </div>
    // </div>
      <div>
        <Route
          {...rest}
          render={routeProps => (
            <ComponentPage {...routeProps} />
          )}
        />
      </div>
  )
}

export default TvAppLayout
