import { isEmpty } from "ramda"

const getMixpanelToken = () => process.env.MIXPANEL_TOKEN

const getMapboxAccessKey = () => process.env.REACT_APP_MAPBOX_ACCESS_KEY

const getApiUrl = () => process.env.REACT_APP_API_URL

const getUrlParameter = (name, defaultValue) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(location.search)
  return results === null ? (defaultValue ?? '') : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

const getTenantSlugFromCurrentUrl = () => {
  /* webpack-strip-block:removed */
  return document.location.hostname.split('.')[0].split('-')[0]
}

const getVerificationUrl = (email, url) => {
  /* webpack-strip-block:removed */
  return `/verification?email=${encodeURIComponent(email)}${isEmpty(url) ? '' : '&url=' + url}`
}

export {
  getMixpanelToken,
  getMapboxAccessKey,
  getApiUrl,
  getUrlParameter,
  getTenantSlugFromCurrentUrl,
  getVerificationUrl,
}