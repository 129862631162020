import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import Header from '../../components/header'
import Footer from '../../components/footer'

import { authService } from '../../../base/services'

const ManagementLayout = (props) => {
  if (!authService.isAuthenticated()) {
    const isRedirectToLoginPage =
      props.requireLogin && window.location.pathname !== '/sign-in'
    if (isRedirectToLoginPage) {
      return <Redirect to={`/sign-in?url=${location.pathname}`} />
    }
  }

  useEffect(() => {
    const tenantInfo = authService.getTenantInfo();
    track("Viewed page", {
      tenantId: tenantInfo.id,
      tenantName: tenantInfo.name,
    });
  }, []);

  const { component: ComponentPage, ...rest } = props
  return (
    <div className="app">
      <Header />
      <div className="app__main">
        <Route
          {...rest}
          render={routeProps => (
            <ComponentPage {...routeProps} />
          )}
        />
      </div>
      <Footer />
    </div>
  )
}

export default ManagementLayout
