import { prop } from "ramda";
import { getCustomersList } from "../../services/apis";
import { isServerResponseError, hasError } from "../common";

const getCustomersListData = async (
  page,
  limit,
  search,
  searchIn,
  searchData,
  selectedFields
) => {
  const result = await getCustomersList(
    {
      page,
      limit,
      search,
      searchIn,
      ...selectedFields,
      ...searchData,
    },
  );
  return isServerResponseError(result) || hasError(result)
    ? {
        total: 0,
        data: [],
      }
    : prop("data", result);
};

export default getCustomersListData;
