import React, { useState, useEffect } from "react";
import FilterButton from "../components/filter-button/filter-button";
import { Pager, ReportsList } from "../components";
import { queryReport } from "../services/apis/report";
import { map } from "ramda";
import { useParams } from "react-router";

const ReportsListPage = (props) => {
  const params = useParams();
  const [total, setTotal] = useState(0);
  const [limit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);

  const onPageChange = async (pageNo) => {
    const {
      data: { total, data },
    } = await queryReport({
      page: pageNo,
      limit: 20,
    });
    setCurrentPage(pageNo);
    setTotal(total);
    setItems(
      map(
        (item) => ({
          id: item.id,
          value: item.name,
          link: item.url,
        }),
        data
      )
    );
  };

  useEffect(() => {
    onPageChange(1);
  }, []);

  return (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">Reports</h2>
        </div>
      </div>
      <div className="container-fluid content">
        {/* <div className="action-buttons">
          <FilterButton />
        </div> */}
        <ReportsList
          title="Name"
          items={items}
          allowEdit={params.action === "management"}
        />
        <Pager
          onPageChange={onPageChange}
          total={total}
          limit={limit}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default ReportsListPage;
