import { connect } from 'react-redux';

const { getUserRolesAsync, fetchSubTenants } = require("src/main_app/actions");

const mapStateToProps = state => ({
    userRoles: state.userRoleReducer.data
})

const mapActionToProps = {
    getUserRolesAsync,
    fetchSubTenants
}

const withRedux = component => connect(
    mapStateToProps,
    mapActionToProps
)(component)

export default withRedux
