import {
  pathOr,
  compose,
  map,
  pickAll,
} from 'ramda'
import {
  getCustomersList,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const getAllPositions = async (page, limit = -1, search, searchIn, searchData) => {
  const result = await getCustomersList({
    page,
    limit,
    search,
    searchIn,
    ...searchData,
  })
  return isServerResponseError(result) || hasError(result)
    ? {
      total: 0,
      data: []
    }
    : compose(
      map(
        ({
          id,
          name,
          latitude,
          longitude,
        }) => ({
          id,
          name,
          latitude,
          longitude,
        })
      ),
      pathOr([], ['data', 'data'])
    )(result)
}

export default getAllPositions
