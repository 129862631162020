import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CustomersUsageSkeleton = (props) => (
  <div className="container-fluid content">
    <h3 className="sub-title"><Skeleton height={30} width={'40%'} /></h3>
    <div className="form-group">
      <label>Date range</label>
      <br />
      <Skeleton height={30} width={'20%'} />
    </div>
    <Skeleton height={400} />
    <br />
    <br />
    <h3 className="sub-title"><Skeleton height={30} width={'40%'} /></h3>
    <div className="form-group">
      <label>Date range</label>
      <br />
      <Skeleton height={30} width={'20%'} />
    </div>
    <Skeleton height={400} />
  </div>
)

export default CustomersUsageSkeleton
