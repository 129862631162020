import {
    getTargetChecked,
    targetIsCheckbox
} from './index'

const inputChangeHandler = (currentData, setter) => event => {
    const { name, value } = event.target
    setter({
        ...currentData,
        [name]: targetIsCheckbox(event) ? getTargetChecked(event) : value
    })
}

export default inputChangeHandler