import FETCH_CUSTOMER_TIMELINE from "../constants";

const customerTimelineReducer = (state = {
    total: 0,
    data: []
}, action) => {
    switch (action.type) {
        case FETCH_CUSTOMER_TIMELINE:
            return {
                ...state,
                data: [...action.data],
            }
        default:
            return state
    }
}

export default customerTimelineReducer