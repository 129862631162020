import { propOr, pathOr, isEmpty, find, propEq } from "ramda";
import React from "react";
import {
  Textbox,
  Checkbox,
  Select1,
} from "src/main_app/components/form_elements";
import {useTenantConfig} from "src/main_app/actions";
import { authService } from "../../../../../base/services";

const CustomerContact = ({
  canEditCustomer,
  contact,
  onRemove,
  onChange,
  addCustomerContactValidate,
  customerContactErrors,
  section_config,
}) => {
  const tenantConfig = useTenantConfig();
  const user = authService.getUser();

  const process_fields = (fields, contact) => {
    if (isEmpty(fields)) return [];

    let rows = [];
    let row_fields = [];
    let columnsCount = 0;
    fields.forEach((field) => {
      columnsCount += pathOr(0, ["field_data", "layout_columns"], field);
      if (columnsCount > 12) {
        rows.push(row_fields);
        columnsCount = pathOr(0, ["field_data", "layout_columns"], field);
        row_fields = [];
      }
      row_fields.push({
        field,
        currentValue: propOr(
          "",
          pathOr("", ["field_data", "name"], field),
          contact
        ),
      });
    });
    if (!isEmpty(row_fields)) rows.push(row_fields);
    return rows;
  };

  const render_field = ({ field, currentValue }, index) => {
    addCustomerContactValidate(
      pathOr("", ["field_data", "name"], field),
      pathOr(null, ["field_data", "validation"], field),
      pathOr("", ["field_data", "title"], field)
    );
    const validate =
      !customerContactErrors ||
      find(propEq("name", pathOr("", ["field_data", "name"], field)))(
        customerContactErrors
      );
    switch (propOr("", "field_type", field)) {
      case "text_input":
        return (
          <div
            key={index + ""}
            className={
              `col-md-${pathOr("", ["field_data", "layout_columns"], field)} ` +
              `${
                propOr(true, "validate", validate) !== true ? "has-error" : ""
              }`
            }
          >
            <Textbox
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              value={currentValue}
              onChange={onChange}
              disabled={pathOr(false, ["field_data", "readOnly"], field)}
            />
          </div>
        );
      case "email_input":
        return (
          <div
            key={index + ""}
            className={
              `col-md-${pathOr("", ["field_data", "layout_columns"], field)} ` +
              `${
                propOr(true, "validate", validate) !== true ? "has-error" : ""
              }`
            }
          >
            <Textbox
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              type="email"
              value={currentValue}
              onChange={onChange}
              disabled={pathOr(false, ["field_data", "readOnly"], field)}
            />
          </div>
        );
      case "select":
        return (
          <div
            key={index + ""}
            className={
              `col-md-${pathOr("", ["field_data", "layout_columns"], field)} ` +
              `${
                propOr(true, "validate", validate) !== true ? "has-error" : ""
              }`
            }
          >
            <Select1
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              items={pathOr("", ["field_data", "options"], field)}
              hasEmptyOption={true}
              value={!isEmpty(currentValue) ? currentValue : ""}
              onChange={onChange}
              disabled={pathOr(false, ["field_data", "readOnly"], field)}
            />
          </div>
        );
      case "checkbox":
        if (user.role != 'Customer')
          return (
            <div
              key={index + ""}
              className={
                `col-md-${pathOr("", ["field_data", "layout_columns"], field)} ` +
                `${
                  propOr(true, "validate", validate) !== true ? "has-error" : ""
                }`
              }
            >
              <Checkbox
                label={pathOr("", ["field_data", "title"], field)}
                name={pathOr("", ["field_data", "name"], field)}
                controlId={pathOr("", ["field_data", "name"], field)}
                checked={!isEmpty(currentValue) ? currentValue : false}
                onChange={onChange}
              />
            </div>
          );
      default:
        return null;
    }
  };

  const render_row = (row, index) => (
    <div key={index + ""} className="row align-items-center">
      {row.map(render_field)}
    </div>
  );

  const render_contact = (fields_config, contact) => {
    const rows = process_fields(
      pathOr([], ["field_data", "fields"], fields_config),
      contact
    );
    return rows.map(render_row);
  };
  return (
    <div className="form-group-block mb-3 card">
      <div className="card-body d-flex">
        <div className="flex-grow-1">
          {render_contact(section_config[0], contact)}
        </div>
        {canEditCustomer && tenantConfig.slug != 'maitland' && (
          <div className="action">
            <button className="btn-text" type="button" onClick={onRemove}>
              <i className="far fa-trash f-16" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerContact;
