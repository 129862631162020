import React from 'react'

import BaseChart from '../base-chart'

const ClusteredColumnChart = ({
  id: elementId,
  style = {},
  data = [],
  info = {},
}) => {
  const onInit = (
    am4core,
    am4charts,
  ) => {
    let chart = am4core.create(elementId, am4charts.XYChart)
    chart.numberFormatter.numberFormat = '#.#\'mm\''
    chart.data = data

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = info.category
    xAxis.renderer.cellStartLocation = 0.4
    xAxis.renderer.cellEndLocation = 0.6
    xAxis.renderer.grid.template.location = 0

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis())
    yAxis.min = 0

    const createSeries = (value, name, color) => {
      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = info.category
      series.name = name
      series.columns.template.column.cornerRadiusTopLeft = info.radius
      series.columns.template.column.cornerRadiusTopRight = info.radius
      series.fill = color
      series.stroke = color
      series.columns.template.width = am4core.percent(75)

      series.columns.template.tooltipHTML = '<div style="text-align: center;font-weight: 500;font-size: 12px;color:#505D6F">{name} <br/>{valueY}</div>'
      // SET pointer orientation tooltip
      series.tooltip.pointerOrientation = 'down'
      // SET background tooltip
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = am4core.color('#ffffff')

      return series
    }

    info.series.forEach((item) => {
      createSeries(item.value, item.name, item.color)
    })

    return chart
  }

  return (
    <BaseChart
      style={{...style}}
      id={elementId}
      onInit={onInit}
    />
  )
}

export default ClusteredColumnChart