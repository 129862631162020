import { connect } from 'react-redux';

const { fetchCustomerActions } = require("src/main_app/actions");

const mapStateToProps = state => ({
    total: state.customerActionReducer.total,
    actionsData: state.customerActionReducer.data
})

const mapActionToProps = {
    fetchCustomerActions
}

const withRedux = component => connect(
    mapStateToProps,
    mapActionToProps
)(component)

export default withRedux
