import { propOr, pathOr, isEmpty, find, propEq } from "ramda";
import React from "react";
import { Textbox, Checkbox } from "src/main_app/components/form_elements";
import { formatDateDDMMMYYYY } from "src/main_app/utils/common";
import { DateRangePicker } from "src/main_app/components/date_range_picker";

const CustomerBackFlowDevices = ({
  devices,
  onRemove,
  onChange,
  section_config,
}) => {
  const process_fields = (fields, devices) => {
    if (isEmpty(fields)) return [];

    let rows = [];
    let row_fields = [];
    let columnsCount = 0;
    fields.forEach((field) => {
      columnsCount += pathOr(0, ["field_data", "layout_columns"], field);
      if (columnsCount > 12) {
        rows.push(row_fields);
        columnsCount = pathOr(0, ["field_data", "layout_columns"], field);
        row_fields = [];
      }
      row_fields.push({
        field,
        currentValue: propOr(
          "",
          pathOr("", ["field_data", "name"], field),
          devices
        ),
      });
    });
    if (!isEmpty(row_fields)) rows.push(row_fields);
    return rows;
  };

  const render_field = ({ field, currentValue }, index) => {
    switch (propOr("", "field_type", field)) {
      case "text_input":
        return (
          <div key={index + ""} className={`col-md-${pathOr("", ["field_data", "layout_columns"], field)} `}>
            <Textbox
              label={pathOr("", ["field_data", "title"], field)}
              name={pathOr("", ["field_data", "name"], field)}
              value={currentValue}
              onChange={onChange}
              disabled={pathOr(false, ["field_data", "readOnly"], field)}
            />
          </div>
        );
      case "date_input":
        const valueDate = currentValue ? formatDateDDMMMYYYY(currentValue) : false;
        return (
          <div key={index + ""} className={`col-md-${pathOr("", ["field_data", "layout_columns"], field)} `}>
            <div className="form-group">
              <label>{pathOr("", ["field_data", "title"], field)}</label>
              <DateRangePicker
                setting={{
                  singleDatePicker: true,
                  autoUpdateInput: !!valueDate,
                  startDate: valueDate,
                }}
                onApply={(event, picker) => {
                  const nDate = picker.startDate.utcOffset(0, true);
                  picker.element.val(nDate.format("DD MMM YYYY"));
                  onChange(
                    pathOr("", ["field_data", "name"], field),
                    nDate.toDate()
                  );
                }}
              >
                <input type="text" className="form-control" />
              </DateRangePicker>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const render_row = (row, index) => (
    <div key={index + ""} className="row align-items-center">
      {row.map(render_field)}
    </div>
  );

  const render_devices = (fields_config, devices) => {
    const rows = process_fields(
      pathOr([], ["field_data", "fields"], fields_config),
      devices
    );
    return rows.map(render_row);
  };
  return (
    <>
      <div className="flex-grow-1">
        {render_devices(section_config[0], devices)}
      </div>
      <div className="action" style={{ textAlign: "right" }}>
        <button
          style={{ position: "relative", bottom: "50px" }}
          className="btn-text"
          type="button"
          onClick={onRemove}
        >
          <i className="far fa-trash f-16" />
        </button>
      </div>
    </>
  );
};

export default CustomerBackFlowDevices;
