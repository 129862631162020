import React, { useState } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import { isEmpty } from "ramda";

import { deleteUser } from "../../utils/user_utils";

const UsersListTable = ({
  id = "table-list",
  headers = [],
  items = [],
  refreshData,
  canEdit,
  canDelete,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { addToast } = useToasts();

  const userDeleteClickHandler = (event) => {
    setSelectedUserId(event.target.id);
    setShowConfirmDialog(true);
  };
  const deleteNoHandler = () => setShowConfirmDialog(false);
  const deleteYesHandler = async () => {
    const response = await deleteUser(selectedUserId, {});
    if (response === 0) {
      addToast("Delete failed. You can re-try later.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
    } else if (response === 1) {
      addToast("Delete user successfully.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 1500,
      });
      refreshData && refreshData();
    }
    setShowConfirmDialog(false);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table" id={id}>
          <thead>
            <tr>
              {isEmpty(headers)
                ? null
                : headers.map((header, index) => (
                    <th key={index + ""} scope="col">
                      {header}
                    </th>
                  ))}
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index + ""}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.role_name}</td>
                <td>{item.created_at}</td>
                <td className="text-right">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn-text dropdown-toggle"
                    >
                      <i className="far fa-ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right">
                      {canEdit && (
                        <Dropdown.Item as={Link} to={`/user/${item.id}/edit`}>
                          Edit
                        </Dropdown.Item>
                      )}
                      {canDelete && (
                        <Dropdown.Item
                          as={Button}
                          id={item.id}
                          onClick={userDeleteClickHandler}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showConfirmDialog && (
        <Modal
          centered={true}
          show={showConfirmDialog}
          onHide={deleteNoHandler}
        >
          <Modal.Header closeButton className="modal-center">
            <Modal.Title>Do you want to delete this user</Modal.Title>
          </Modal.Header>
          {/*<Modal.Body>User id {selectedUserId}</Modal.Body>*/}
          <Modal.Footer className="modal-center">
            <Button variant="secondary" onClick={deleteNoHandler}>
              No
            </Button>
            <Button variant="primary" onClick={deleteYesHandler}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default UsersListTable;
