import React, { useState, useEffect, useMemo, useCallback } from "react";
import clsx from "clsx";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";
import {
  getTaxonomyBenchmarkInfo,
  updateTaxonomyBenchmarkData,
} from "../../utils/customer_utils";
import { propOr, pathOr, prop, map, isNil, isEmpty } from "ramda";
import {
  convertDate,
  firstDateOfMonthsAgo,
  formatNumber,
  isNilOrEmpty,
  isNotNil,
  lastDateOfMonthsAgo,
  toNumber,
} from "src/main_app/utils/common";
import { getTaxonomyBenchmarkChartData } from "src/main_app/services/apis";
import { Link } from "react-router-dom";
import { useTenantConfig } from "src/main_app/actions";
import { saveAs } from 'file-saver'; 
import { useCurrentPng } from "recharts-to-png";
import * as htmlToImage from 'html-to-image';

const xFormatter = (tickItem) => formatNumber(tickItem) + " ";
const yFormatter = (tickItem) => formatNumber(tickItem) + " ";

const renderTooltip =
  (isShow) =>
  ({ active, payload, label }) => {
    if (!isShow || !active || !payload || !payload.length) return null;

    return (
      <div
        className="custom-line-tooltip"
        style={{
          padding: "5px",
          backgroundColor: "#fff",
          boxShadow: "0 0 8px 8px rgb(0 0 0 / 5%)",
        }}
      >
        <h5
          className="mb-0"
          style={{
            fontWeight: 700,
            maxWidth: "300px",
          }}
        >
          {payload[0].payload.customer_name}
        </h5>
      </div>
    );
  };

const Popup = (props) => {
  if (!props.visible) return null;
  return (
    <div className="mapboxgl-popup-content" style={props.style}>
      <button
        className="mapboxgl-popup-close-button"
        type="button"
        onClick={props.onClose}
      >
        ×
      </button>
      <div className="map-marker-info customer" style={{ width: "300px" }}>
        <div className="marker-info-name customer-name">
          <Link
            className="marker-info-customer-link"
            to={`/customer/${props.data.id}/overview`}
          >
            <span className="info-name">{props.data.name}</span>
          </Link>
        </div>
        <div className="marker-info-detail">
          {props.data.payload.map((payloadItem, index) => {
            return (
              <div key={index + ""}>
                <span className="info-attribute">{payloadItem.name}: </span>
                {formatNumber(payloadItem.value)} {payloadItem.unit}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const BenchmarkSection = ({ taxonomyID = null, taxonomyData }) => {
  const tenantConfig = useTenantConfig();
  const [isDisplayPopup, setIsDisplayPopup] = useState(false);
  const [popupPayload, setPopupPayload] = useState({
    name: "",
    payload: [],
  });
  const [popStyle, setPopStyle] = useState({
    position: "absolute",
    width: "fit-content",
  });
  const [benchmarkData, setBenchmarkData] = useState({
    sector_label: "Benchmark",
    fields: [
      {
        label: "Benchmark Usage",
        data: "",
        layout_column: 3,
      },
      {
        label: "Sector Range",
        data: "",
        layout_column: 3,
      },
      {
        label: "Sector Mean",
        data: "",
        layout_column: 3,
      },
      {
        label: "Sector Best Practice",
        data: "",
        layout_column: 3,
      },
    ],
  });
  const [totalCustomer, setTotalCustomer] = useState(0);
  const [pointColor, setPointColor] = useState("#000");
  const [startDate, setStartDate] = useState(firstDateOfMonthsAgo(13));
  const [endDate, setEndDate] = useState(lastDateOfMonthsAgo(1));
  const [chartData, setChartData] = useState(null);
  const [xDomain, setXDomain] = useState([0, 0]);
  const [yDomain, setYDomain] = useState([0, 0]);

  const { addToast } = useToasts();

  const loadTaxonomyBenchmarkInfo = async () => {
    const result = await getTaxonomyBenchmarkInfo(taxonomyID);
    console.log('getTaxonomyBenchmarkInfo', result)

    if (isNilOrEmpty(result)) return;
    setBenchmarkData(result);
  };

  const loadBenchmarkChartData = async (from, to) => {
    const {
      data: { totalCustomer: total, data: result },
    } = await getTaxonomyBenchmarkChartData(taxonomyID, {
      from: convertDate(from || startDate),
      to: convertDate(to || endDate),
    });
    const arrResult = [];
    for (const item of result) {
      if (item.waterUsage > 0) {
        arrResult.push(item);
      }
    }

    setTotalCustomer(total);
    setChartData(arrResult);
    // setChartData(result);
  };

  const updateBenchmarkData = (event, index) => {
    setBenchmarkData({
      ...benchmarkData,
      fields: benchmarkData.fields.map((field, fieldIndex) => {
        if (fieldIndex === index) {
          return {
            ...field,
            data: event.target.value,
          };
        } else {
          return field;
        }
      }),
    });
  };

  const submitBenchmarkData = async (event) => {
    event.preventDefault();
    const response = await updateTaxonomyBenchmarkData(
      taxonomyID,
      benchmarkData
    );
    if (response === "success") {
      addToast("Benchmark data updated successfully.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    } else {
      addToast("Benchmark data update failed.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
    loadTaxonomyBenchmarkInfo();
  };

  const loadTenantConfigData = async () => {
    const color = pathOr(
      "#000",
      ["config", "tenantDisplay", "color"],
      tenantConfig
    );
    setPointColor(color);
  };

  useEffect(() => {
    if (isEmpty(tenantConfig)) return;
    loadTenantConfigData();
    loadTaxonomyBenchmarkInfo();
  }, [tenantConfig]);

  useEffect(() => {
    loadBenchmarkChartData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (isNil(chartData)) return;
    const allUnit = map(prop("unit"), chartData);
    const allWaterUsage = map(prop("waterUsage"), chartData);
    // setXDomain([Math.min(...allUnit), Math.max(...allUnit)]);
    // setYDomain([Math.min(...allWaterUsage), Math.max(...allWaterUsage)]);
    const maxX = Math.max(...allUnit);
    const maxY = Math.max(...allWaterUsage);
    setXDomain([0, Math.ceil(maxX/100)*100]);
    setYDomain([0, Math.ceil(maxY/100)*100]);  
  }, [chartData]);

  const xUnitMetric = useMemo(
    () =>
      pathOr(
        "Lot area",
        ["taxonomy_info", "meta", "unit_metric"],
        taxonomyData
      ),
    [taxonomyData]
  );

  const xLabel = useMemo(
    () =>
      xUnitMetric !== "Lot area"
        ? {
            value: xUnitMetric,
            position: "insideBottomRight",
            offset: 0,
            dx: -600,
            dy: 10,
          }
        : null,
    [xUnitMetric]
  );
  const yLabel = useMemo(
    () => ({
      value: "Usage (kL)",
      angle: -90,
      position: "top",
      dy: 170,
      dx: -40,
    }),
    []
  );

  const percentCustomer = useMemo(
    () =>
      isNotNil(chartData) && toNumber(totalCustomer) !== 0
        ? Math.round((chartData.length / toNumber(totalCustomer)) * 100 * 100) /
          100
        : 0,
    [totalCustomer, chartData]
  );

  const handleScatterClick = (e) => {
    const unitItem = e.tooltipPayload.find((i) => i.dataKey === "unit");
    const usageItem = e.tooltipPayload.find((i) => i.dataKey === "waterUsage");
    usageItem.name = "Total usage (kL/ annum)";
    usageItem.dataKey = "waterUsagePerUnit";
    const usagePerUnit = {
      ...usageItem,
      name: "Benchmark",
      value: Math.round((usageItem.value * 1000) / unitItem.value) / 1000,
    };
    setPopupPayload({
      id: e.id,
      name: e.customer_name,
      payload: [...e.tooltipPayload, usagePerUnit],
    });

    setIsDisplayPopup(true);
    setTimeout(() => {
      setPopStyle({
        ...popStyle,
        left: e.tooltipPosition.x - 120,
        top:
          e.tooltipPosition.y -
          document.querySelector(".map-marker-info").clientHeight +
          8,
      });
    }, 0);
  };

//Download image chart
const handleBenchmarkDownload = () =>{
  htmlToImage.toPng(document.getElementById("benchmark-chart"))
   .then(function (dataUrl) {
     saveAs(dataUrl, 'benchmark-chart.png');
   });
 }

  return (
    <>
      {benchmarkData && (
        <>
          <h3 className="sub-title mt-4">
            {propOr(" ", "sector_label", benchmarkData)}
          </h3>
          <div className="row">
            {propOr([], "fields", benchmarkData).map((field, index) => (
              <div
                className={`col-md-${propOr("", "layout_column", field)}`}
                key={index}
              >
                <div className="form-group">
                  <label>{propOr("", "label", field)}</label>
                  <input
                    className="form-control"
                    value={pathOr("", ["fields", index, "data"], benchmarkData)}
                    onChange={(event) => updateBenchmarkData(event, index)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            className="row"
            style={{
              marginBottom: "15px",
            }}
          >
            <div className="col-md-3">
              <button
                className="btn btn--save btn-primary benchmark-submit"
                onClick={submitBenchmarkData}
              >
                SAVE
              </button>
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="form-group">
          <div className="month-range-label">
            <label>Month range: </label>
          </div>
          <div className="usage-month-range-picker">
            <span>from</span>
            <div className="month-range-picker start-month">
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
            <span>to</span>
            <div className="month-range-picker end-month">
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>
          </div>
        </div>
      </div>
      {isNil(chartData) && <Skeleton height={400} />}
      {isNotNil(chartData) && chartData.length === 0 && (
        <div className="no-item-message">
          {" "}
          There is currently no usage data available.
        </div>
      )}
      {isNotNil(chartData) && chartData.length > 0 && (
        <>
          <label>
            The chart below is based on data provided for{" "}
            {Math.ceil(percentCustomer)}% of customers in this industry group.
          </label>
          <br></br>
          <a className="btn btn-primary btn-sm" onClick={handleBenchmarkDownload}>Download as image</a>
          <div className="card" id="benchmark-chart">
            <div className="card-body" style={{ position: "relative" }}>
              <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                <ScatterChart
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <CartesianGrid stroke="#e9ebf1" />
                  <XAxis
                    name={xUnitMetric}
                    dataKey="unit"
                    type="number"
                    stroke="#e9ebf1"
                    domain={xDomain}
                    // interval={0}
                    tickFormatter={xFormatter}
                    label={xLabel}
                  />
                  <YAxis
                    name={"Usage"}
                    dataKey="waterUsage"
                    type="number"
                    stroke="#e9ebf1"
                    domain={yDomain}
                    // interval={0}
                    tickFormatter={yFormatter}
                    label={yLabel}
                  />
                  <Scatter
                    name="A customer"
                    fill={pointColor}
                    data={chartData}
                    onClick={handleScatterClick}
                  />
                  <Tooltip
                    cursor={false}
                    contentStyle={{
                      boxShadow: "0 10px 24px rgba(29,42,68,0.12)",
                      borderRadius: "4px",
                      border: "none",
                    }}
                    itemStyle={{
                      color: "#05d6f",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                    content={renderTooltip(!isDisplayPopup)}
                  />
                </ScatterChart>
              </ResponsiveContainer>
              <Popup
                visible={isDisplayPopup}
                onClose={() => setIsDisplayPopup(false)}
                style={popStyle}
                data={popupPayload}
              />
              {xUnitMetric === "Lot area" && (
                <label className="x-label">
                  Lot area ( m<sup>2</sup> )
                </label>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BenchmarkSection;

