import React, { useEffect, useMemo, useRef } from "react";
import { Map } from "../components";

import { track } from "src/main_app/mixpanel";
import { checkPermission, useUserPermissions } from "../actions";
import { authService } from "../../base/services";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const MapPage = (props) => {
  useEffect(() => {
    track("Map page", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [])
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "map-page", "canView"), [userPermissions]) 
  const user = authService.getUser();
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="content map-page-content">
      { user.role != "Customer" &&
        <div className="container p-0">
          <Map
            zoom={8}
            style={{
              height: "100%",
              width: "100vw",
            }}
          />
        </div>
      }
    </div>
  );
};

export default MapPage;
