import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { isEmpty, compose, propOr } from "ramda";

import { saveCustomerFiltersToStorage } from "src/main_app/services/local_storage/customer-filters-storage";
import { getTargetValue } from "src/main_app/utils/react_utils";
import { getFiltersConfig } from "../../../filter-button/helpers/filterHelper";
import { isNilOrEmpty } from "src/main_app/utils/common";
import { useTenantConfig } from "src/main_app/actions";
import Select from '@bhunjadi/react-select-v3';

const getFilteredDataFromFilter = (filterData) => {
  const filteredData = {};
  if (filterData.id && filterData.id !== "")
    return {
      id: filterData.id,
      name: filterData.name,
    };
  Object.keys(filterData).forEach((filter) => {
    if (filterData[filter]) {
      filteredData[filter] = filterData[filter];
    }
  });
  return filteredData;
};

const MapCustomerFilter = ({ visible, onFiltersApply }, ref) => {
  if (!visible) return null;

  const tenantConfig = useTenantConfig();
  const didMountRef = useRef(false);
  const [filteredData, setFilteredData] = useState({});
  const [filterWith, setFilterWith] = useState({});
  const [filterData, setFilterData] = useState({});
  const [filterConfig, setFilterConfig] = useState({});
  const [tenantFilterConfig, setTenantFilterConfig] = useState([]);
  const history = useHistory();

  const isFiltersReady =
    !isEmpty(tenantFilterConfig) &&
    !isEmpty(filterData) &&
    !isEmpty(filterConfig) &&
    !isEmpty(tenantFilterConfig);

  const onClearField = useCallback(
    (fieldName) => () => {
      delete filterData.id;
      setFilterData({
        ...filterData,
        [fieldName]: "",
      });
      delete filteredData.id;
      setFilteredData({
        ...filteredData,
        [fieldName]: "",
      });
    },
    [filterData, setFilterData, setFilteredData, setFilteredData]
  );

  const renderInput = (index, filter) => {
    switch (filterConfig[filter.field].type) {
      case "text_input":
      case "number_input":
        return (
          <div className="filter__footer input-group" key={`filter_input_${index}`}>
            <input
              type={
                filterConfig[filter.field].type === "text_input"
                  ? "text"
                  : "number"
              }
              className="form-control"
              value={filterData[filter.field] == true ? "" : propOr("", filter.field, filterData)}
              onChange={(event) =>
                setFilterData({
                  ...filterData,
                  [filter.field]: event.target.value,
                })
              }
            />
            {!isNilOrEmpty(filteredData[filter.field]) && (
              <button
                className="btn btn-outline-secondary rounded-end"
                type="button"
                onClick={onClearField(filter.field)}
              >
                <i className="fas fa-times"></i>
              </button>
            )}
          </div>
        );
      case "taxonomy":
        return (
          <div className="filter__footer" key={`filter_input_${index}`}>
            <Select
                defaultValue={{ id: '-All-', taxonomy_label: 'All' }}
                onChange={(event) => {
                  setFilterData({
                    ...filterData,
                    [filter.field]: event.id,
                  });
                  setFilteredData({
                    ...filteredData,
                    [filter.field]: event.id,
                  });
                }}
                getOptionLabel ={(option)=>option.taxonomy_label}
                getOptionValue ={(option)=>option.id}
                options={filterConfig[filter.field].data.options}
            />
          </div>
        );
      case "select":
        return (
          <div className="filter__footer" key={`filter_input_${index}`}>
            <Select
                defaultValue={{ value: '-All-', label: 'All' }}
                onChange={(event) => {
                  setFilterData({
                    ...filterData,
                    [filter.field]: event.value,
                  });
                  setFilteredData({
                    ...filteredData,
                    [filter.field]: event.value,
                  });
                }}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={filterConfig[filter.field].data.options}
            />
          </div>
        );
      default:
        return null;
    }
  };

  useImperativeHandle(ref, () => ({
    getFilterData: () => filteredData,
  }));

  const onApply = (event) => {
    event && event.preventDefault();
    compose(setFilteredData, getFilteredDataFromFilter)(filterData);
  };

  const getFilterDataDefault = (config) => {
    const filterDataDefault = {};
    Object.keys(config).forEach((key) => {
      switch (config[key].type) {
        case "text_input":
        case "number_input":
          filterDataDefault[config[key].data.name] = "";
          break;
        case "taxonomy":
        case "select":
          filterDataDefault[config[key].data.name] = "";
          break;
      }
    });
    const customerId = sessionStorage.getItem("customerId");
    const name = sessionStorage.getItem("name");
    if (customerId && name) {
      sessionStorage.removeItem("customerId");
      sessionStorage.removeItem("name");
      filterDataDefault.id = customerId;
      filterDataDefault.name = name;
    }
    return filterDataDefault;
  };

  const goToCustomerListPage = (event) => {
    event.preventDefault();
    saveCustomerFiltersToStorage(filteredData);
    setTimeout(() => history.push("/customers"), 200);
  };

  const onFilterWithChange = (filter) => (event) => {
    const fieldName = filter.field;
    const nextVal = !filterWith[fieldName];
    setFilterWith({
      ...filterWith,
      [fieldName]: nextVal,
    });
    setFilterData({
      ...filterData,
      [fieldName]: nextVal ? true : '',
    });
    setFilteredData({
      ...filterData,
      [fieldName]: nextVal ? true : '',
    });
    
  };

  const getFilterWithDefault = (config) => {
    const filterWithDefault = {};
    Object.keys(config).forEach((key) => {
      filterWithDefault[config[key].data.name] = false;
    });
    return filterWithDefault;
  };

  useEffect(() => {
    if (isEmpty(tenantConfig)) return;
    getFiltersConfig(tenantConfig).then((config) => {
      setFilterConfig(config);
      setFilterWith(getFilterWithDefault(config));
      const defaultFilter = getFilterDataDefault(config);
      setFilteredData(getFilteredDataFromFilter(defaultFilter));
      setFilterData({
        ...defaultFilter,
        ...filterData,
        id: null,
      });
    });
    setTenantFilterConfig(propOr([], "customer_filter", tenantConfig));
  }, [tenantConfig]);

  useEffect(() => {
    if (didMountRef.current)
      onFiltersApply &&
        onFiltersApply(
          filteredData.id ? { id: filteredData.id } : filteredData
        );
    else didMountRef.current = true;
  }, [filteredData]);

  return (
    <form className="map__filter-select" onSubmit={onApply}>
      {isFiltersReady &&
        tenantFilterConfig
          .filter(
            (filter) =>
              filterConfig[filter.field]
          )
          .map((filter, index) => (
            <React.Fragment key={`frag_${index}`}>
              <div className="filter__option" key={`filter_check_${index}`}>
                <label className="checkbox">
                  <input
                    checked={filterWith[filter.field]}
                    type="checkbox"
                    name="option-filter"
                    onChange={onFilterWithChange(filter)}
                  />
                  <span className="ck" />
                  {filterConfig[filter.field].data.title}
                </label>
              </div>
              {filterWith[filter.field] &&
                renderInput(index, filter)
              }
            </React.Fragment>
          ))}
      <div className="map__filtered-customers-list">
        <button
          id="to-customer-list-button"
          type="button"
          onClick={goToCustomerListPage}
        >
          View customer list
        </button>
      </div>
    </form>
  );
};

export default forwardRef(MapCustomerFilter);
