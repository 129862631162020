import { map, path, __ } from "ramda";
import React, {
  useState,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Source, Layer } from "react-map-gl";
import { compose } from "redux";
import { getWaterUsagePerDaOfLastYear } from "src/main_app/services/apis";
import { getAllPositions } from "src/main_app/utils/customer_utils";
import filterValidMarker from "../../helpers/filter-valid-marker";
import MapClusters from "../../map-clusters";
import { layerNames } from "../layer-config";
import heatMapRecycleWaterDemandLayer from "./heat-map-recycled-water-demand-style";
import heatMapStyle from "./heat-map-style";
import heatMapTradeWasteStyle from "./heat-map-trade-waste-style";
import visualizationTypes from "./visualization-types.json";

const CustomerLayer = (props, ref) => {
  if (!props.visible) return null;

  const [filteredData, setFilteredData] = useState({});
  const [customerPosition, setCustomerPosition] = useState([]);
  const [waterUsagePerDaOfLastYear, setWaterUsagePerDaOfLastYear] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const getSearchData = () => {
    const searchData = {};

    Object.keys(filteredData).forEach((filterKey) => {
      searchData[`search[${filterKey}]`] = filteredData[filterKey];
    });

    return searchData;
  };

  const queryCustomerAddress = async () => {
    const allPositions = await getAllPositions(
      null,
      -1,
      null,
      null,
      getSearchData()
    );

    if (allPositions.length === 0) {
      props.dataLoaded && props.dataLoaded();
    } else {
      compose(setCustomerPosition, filterValidMarker)(allPositions);
    }
  };

  const queryWaterUsagePerDaOfLastYear = async () => {
    const type = props.visualizationType.value
    const {
      data: { data },
    } = await getWaterUsagePerDaOfLastYear(type, getSearchData());

    if (data.length === 0) {
      props.dataLoaded && props.dataLoaded();
    } else {
      setWaterUsagePerDaOfLastYear({
        ...waterUsagePerDaOfLastYear,
        features: map(
          (customer) => ({
            type: "Feature",
            properties: {
              wupd: customer.water_usages_per_day,
            },
            geometry: {
              type: "Point",
              coordinates: [customer.longitude, customer.latitude],
            },
          }),
          data
        ),
      });
    }
  };

  const applyFilterData = () => {
    switch (props.visualizationType.value) {
      case visualizationTypes.customer_address:
        queryCustomerAddress();
        break;
      case visualizationTypes.usage_heatmap:
        queryWaterUsagePerDaOfLastYear();
        break;

      case visualizationTypes.trade_waste_discharge:
        queryWaterUsagePerDaOfLastYear();
        break;
      case visualizationTypes.recycled_water_demand:
        queryWaterUsagePerDaOfLastYear();
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    applyFilterData(newFilteredData) {
      setFilteredData(newFilteredData);
    },
    getData() {
      switch (props.visualizationType.value) {
        case visualizationTypes.customer_address:
          return customerPosition;
        case visualizationTypes.usage_heatmap:
          return waterUsagePerDaOfLastYear.features.map((item) => ({
            longitude: path(["geometry", "coordinates", 0], item),
            latitude: path(["geometry", "coordinates", 1], item),
          }));
      }
      return [];
    },
  }));

  useLayoutEffect(() => {
    (customerPosition.length > 0 ||
      waterUsagePerDaOfLastYear.features.length > 0) &&
      props.fitBounds &&
      props.fitBounds();
  }, [customerPosition, waterUsagePerDaOfLastYear]);

  useLayoutEffect(() => {
    applyFilterData();
  }, [props.visualizationType.value, filteredData]);

  const displayCurrentMap = props.visualizationType.value === visualizationTypes.current_water_outages ? 100 : 0;

  return (
    <>
      {props.visualizationType.value === visualizationTypes.current_water_outages && (
        <iframe
            style={{
              width: "100%",
              height: "100%",
              // opacity: displayCurrentMap,
              zIndex: 2,
              position: "relative"
            }}
            scrolling="no"
            frameBorder="0"
            src="https://experience.arcgis.com/experience/5ccebe454c3b444e94577df3341766ad/"
            title="Hunter water map"
          />
       )}
      {props.visualizationType.value === visualizationTypes.customer_address &&
        customerPosition.length > 0 && (
          <MapClusters
            type={layerNames.customers}
            data={customerPosition}
            {...props}
          />
        )}
      {props.visualizationType.value === visualizationTypes.usage_heatmap &&
        waterUsagePerDaOfLastYear.features.length > 0 && (
          <Source type="geojson" data={waterUsagePerDaOfLastYear}>
            <Layer {...heatMapStyle} />
          </Source>
        )}

      {props.visualizationType.value === visualizationTypes.trade_waste_discharge &&
        waterUsagePerDaOfLastYear.features.length > 0 && (
          <Source type="geojson" data={waterUsagePerDaOfLastYear}>
            <Layer {...heatMapTradeWasteStyle} />
          </Source>
        )}    

      {props.visualizationType.value === visualizationTypes.recycled_water_demand &&
        waterUsagePerDaOfLastYear.features.length > 0 && (
          <Source type="geojson" data={waterUsagePerDaOfLastYear}>
            <Layer {...heatMapRecycleWaterDemandLayer} />
          </Source>
        )}      
      
    </>
  );
};

export default forwardRef(CustomerLayer);
