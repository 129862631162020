import { compose, andThen, pathOr } from 'ramda'
import React, { useState, useEffect } from 'react'
import { getCustomersTimelineNoteTypes } from 'src/main_app/services/apis'
import { setTargetValue } from 'src/main_app/utils/react_utils'
import Select from '@bhunjadi/react-select-v3';

const CustomerTimelineFilter = ({ onFilter }) => {
  const [noteType, setNoteType] = useState('')
  const [noteTypes, setNoteTypes] = useState([])

  // const loadData = compose(andThen(setNoteTypes), getCustomersTimelineNoteTypes)
  // const data = await getCustomersTimelineNoteTypes
  const loadData = async () => {
    const response = await getCustomersTimelineNoteTypes();
    const listNoteType = response.map(item => ({
      value: 'note-'+item.id,
      label: item.label
    }));
    listNoteType.unshift({value: "", label: "All"})
    setNoteTypes(listNoteType);
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    onFilter && onFilter(noteType)
  }, [noteType])

  return (
    <div className="card">
      <div className="card-body">
        <div className="form-group">
          <label>Filter by type</label>
          <Select
              defaultValue={{ value: '', label: 'All' }}
              onChange={(e) =>
                setNoteType(e.value)
              }
              options={noteTypes}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerTimelineFilter
