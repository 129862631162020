import { andThen, compose, pathOr, tap, pipe } from "ramda";
import React, { useEffect, useState, useMemo } from "react";
import { Route } from "react-router-dom";
import { getPublicTenantConfig } from "src/main_app/services/apis";
import { getTenantSlugFromCurrentUrl } from "src/main_app/utils/config-utility";

const StartLayout = (props) => {
  const [loginImage, setLoginImage] = useState(null);
  const { component: ComponentPage, ...rest } = props;
  const [tenantConfig, setTenantConfig] = useState(null);

  useEffect(() => {
    compose(
      andThen(setLoginImage),
      andThen(
        pathOr(loginImage, [
          "data",
          "data",
          "config",
          "tenantDisplay",
          "loginImage",
        ])
      ),
      andThen(tap((response) => setTenantConfig(response.data.data.config))),
      getPublicTenantConfig,
      getTenantSlugFromCurrentUrl
    )();
  }, []);

  const signInImageStyle = useMemo(
    () => ({
      height: "435px",
      backgroundColor: "#cecece",
      backgroundSize: "cover",
      backgroundImage: loginImage ? `url(${loginImage})` : undefined,
    }),
    [loginImage]
  );

  return (
    <div className="container">
      <div className="row sign-in">
        <div className="col sign-in__row no-gutters p-0">
          <div className="row m-0">
            <div
              className="col-sm-6 sign-in__image"
              style={signInImageStyle}
            ></div>
            <Route
              {...rest}
              render={(props) => (
                <ComponentPage {...props} tenantConfig={tenantConfig} />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartLayout;
