import {
  pathOr,
} from 'ramda'
import {
  getCustomerTradeWasteDataDetail,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
  jsonStringToValue,
} from '../common'

const getCustomerTradeWasteDataFormPopup = async (id, type) => {
  const result = await getCustomerTradeWasteDataDetail(id, type)
  return isServerResponseError(result) || hasError(result)
    ? {}
    : pathOr({},['data'], result)
}

export default getCustomerTradeWasteDataFormPopup