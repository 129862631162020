import React, { useMemo, useCallback } from 'react'
import {
  Pagination,
} from 'react-bootstrap'

const Pager = ({
  total = 0,
  limit = 20,
  currentPage = 1,
  onPageChange,
}) => {
  const lastPage = useMemo(
    () => {
      const tp = Math.ceil(total / limit)
      return tp === 0 ? 1 : tp
    },
    [total, limit]
  )

  const onPageChangeEvent = useCallback(
    nextPage => () => onPageChange && onPageChange(nextPage),
    [onPageChange],
  ) 

  return (
    <div className="d-flex justify-content-lg-end pagination-box">
      <Pagination>
        <Pagination.Prev
          className="prev"
          disabled={currentPage <= 1}
          onClick={onPageChangeEvent(currentPage - 1)}
        />
        {currentPage - 2 > 1 && (
          <Pagination.Item
            onClick={onPageChangeEvent(1)}
          >{1}</Pagination.Item>)
        }
        {currentPage - 3 > 1 && <Pagination.Ellipsis className="ellipsis" />}
        {currentPage - 2 > 0 && (
          <Pagination.Item
            onClick={onPageChangeEvent(currentPage - 2)}
          >
            {currentPage - 2}
          </Pagination.Item>
        )}
        {currentPage - 1 > 0 && (
          <Pagination.Item
            onClick={onPageChangeEvent(currentPage - 1)}
          >
            {currentPage - 1}
          </Pagination.Item>
        )}
        <Pagination.Item active>{currentPage}</Pagination.Item>
        {currentPage + 1 <= lastPage && (
          <Pagination.Item
            onClick={onPageChangeEvent(currentPage + 1)}
          >
            {currentPage + 1}
          </Pagination.Item>
        )}
        {currentPage + 2 <= lastPage && (
          <Pagination.Item
            onClick={onPageChangeEvent(currentPage + 2)}
          >
            {currentPage + 2}
          </Pagination.Item>
        )}

        {currentPage + 4 <= lastPage && <Pagination.Ellipsis className="ellipsis" />}
        {currentPage + 3 <= lastPage && (
          <Pagination.Item
            onClick={onPageChangeEvent(lastPage)}
          >
            {lastPage}
          </Pagination.Item>
        )}
        <Pagination.Next
          className="next"
          disabled={currentPage >= lastPage}
          onClick={onPageChangeEvent(currentPage + 1)}
        />
      </Pagination>
    </div>
  )
}

export default Pager