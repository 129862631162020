import { GET_USER_PERMISSIONS } from "../constants";

const userPermissionReducer = (
  state = {
    permissions: [],
  },
  action
) => {
  switch (action.type) {
    case GET_USER_PERMISSIONS:
      return {
        permissions: action.data,
      };
    default:
      return state;
  }
};

export default userPermissionReducer;

