import {
  customerAddAction,
} from '../../services/apis'
import {
  hasError,
  isServerResponseError,
} from '../common'

const addAction = async (id, data) => {
  const response = await customerAddAction(id, data)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default addAction