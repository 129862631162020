import { getTaxonomies } from "../../../utils/customer_utils";
import { pathEq, propEq, propOr } from "ramda";
import { getCustomerFieldsConfig } from "./tenantConfigHelper";

export const getFilteredDataFromFilter = (filterData, filterWith) => {
  let filteredData = {};
  for (const key in filterWith) {
    if (!filterWith[key]) continue;
    filteredData[key] = filterData[key];
  }
  return filteredData;
};

export const getFiltersConfig = async (tenantConfig) => {
  const parentTaxonomies = await getTaxonomies();
  const customerTaxonomies = await Promise.all(
    propOr([], "data", parentTaxonomies).map(async (parentTaxonomy) => {
      const options = await getTaxonomies(
        1, // page
        -1, // limit
        null,
        null,
        parentTaxonomy.taxonomy_name
      );
      return {
        name: parentTaxonomy.taxonomy_name,
        title: parentTaxonomy.taxonomy_label,
        options: propOr([], "data", options),
      };
    })
  );
  const customerFieldsConfig = getCustomerFieldsConfig(
    tenantConfig.customer_fields
  );
  let filterConfig = {};
  propOr([], "customer_filter", tenantConfig).forEach((filter_config) => {
    if (filter_config.is_multiple) {
      filterConfig[[filter_config.field]] = {
        type: filter_config.field_type,
        data: {
          name: filter_config.field,
          title: filter_config.title,
        },
      };
    } else if (filter_config.source === "customer_taxonomies") {
      const field_data = customerTaxonomies.find(
        propEq("name", filter_config.field)
      );
      if (field_data !== undefined) {
        filterConfig[[filter_config.field]] = {
          type: "taxonomy",
          data: field_data,
        };
      }
    } else {
      const field_data = customerFieldsConfig.find(
        pathEq(["field_data", "name"], filter_config.field)
      );
      if (field_data !== undefined) {
        filterConfig[filter_config.field] = {
          type: field_data.field_type,
          data: field_data.field_data,
        };
      } else {
        filterConfig[[filter_config.field]] = {
          data: {
            name: filter_config.field,
            title: filter_config.title,
          },
        };
      }
    }
  });
  return filterConfig;
};
