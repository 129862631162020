import {
  compose,
  keys,
  map,
  propOr,
  pathOr,
  isNil,
  hasPath,
  reject,
} from "ramda";
import React, {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

// import alertTypes from "../../enums/alert-types.json";
import { getFiltersConfig } from "../filter-button/helpers/filterHelper";
import {
  clearAlertFiltersOnStorage,
  getAlertFiltersFromStorage,
  saveAlertFiltersToStorage,
} from "../../services/local_storage/customer-filters-storage";
import { useTenantConfig } from "src/main_app/actions/tenant";
import { isNilOrEmpty, addDays } from "src/main_app/utils/common";
import Select from '@bhunjadi/react-select-v3';

import './alerts-filter.style.scss'

const AllValue = "-All-";
const filterWithDefault = {
  alertType: false,
  alertSeverity: false,
  dateRange: true,
  customerName: false,
  customerType: false,
  customerIndustry: false,
  waterZone: false,
};

const listSeverities = [{'value': "low", 'label': "Low"}, {'value': "medium", 'label': "Medium"}, {'value': "high", 'label': "High"}];
const alertTypes = [
  {'value': "night_flow", 'label': "Night Flow"}, 
  {'value': "data_loss", 'label': "Data Loss"}, 
  {'value': "base_flow", 'label': "Base Flow"}, 
  {'value': "peak_flow", 'label': "Peak Flow"},
  {'value': "water_quality", 'label': "Water Quality"}
];

const AlertsFilter = (
  { onTabCustomerID = null, onFiltersApply, disabled },
  ref
) => {
  const tenantConfig = useTenantConfig();
  const toggleRef = useRef(null);
  const maxDateRef = useRef(addDays(new Date(), -1));
  const [filterWith, setFilterWith] = useState(filterWithDefault);
  const [filterConfig, setFilterConfig] = useState({});
  const [filterData, setFilterData] = useState({});

  const [startDate, setStartDate] = useState(moment().add(-31, "days").toDate());
  const [endDate, setEndDate] = useState(maxDateRef.current);
  const [filterCount, setFilterCount] = useState(1);

  const toggleClick = () => {
    toggleRef.current && toggleRef.current.click();
  };

  const getFilteredData = (filteredWith) => {
    const filteredData = {};
    filteredWith = filteredWith ?? filterWith;
    keys(filteredWith).forEach((value) => {
      if (
        filteredWith[value] &&
        filterData[value] &&
        filterData[value] !== AllValue
      ) {
        filteredData[value] = filterData[value];
      }
    });
    if (filteredWith.dateRange) {
      filteredData.dateRange = {
        startDate,
        endDate,
      };
    }
    return filteredData;
  };

  const countFilter = (filterData) => {
    if (!filterData) return 0;
    return reject(isNil, Object.values(filterData)).length;
  };

  useImperativeHandle(ref, () => ({
    getFilteredData,
  }));

  const onApply = () => {
    const filteredData = getFilteredData();
    console.log(filteredData);
    onFiltersApply(filteredData);
    setFilterCount(countFilter(filteredData));
    saveAlertFiltersToStorage(filteredData);
  };

  const onApplyClick = (event) => {
    onApply();
    setTimeout(toggleClick, 200);
  };

  const filtersClear = (event) => {
    toggleClick();
    setFilterData({});
    setFilterWith(filterWithDefault);
    setTimeout(() => {
      const filteredData = getFilteredData(filterWithDefault);
      onFiltersApply(filteredData);
      setFilterCount(countFilter(filteredData));
    }, 200);
  };

  useEffect(() => {
    if (isNilOrEmpty(tenantConfig)) return;

    getFiltersConfig(tenantConfig).then((config) => {
      setFilterConfig({
        alertTypes: alertTypes,
        alertSeverities: listSeverities,
        customerName: "",
        customerTypes: pathOr([], ["type", "data", "options"], config),
        customerIndustries: pathOr([], ["industry", "data", "options"], config),
        waterZone: tenantConfig.alertWaterZoneFilter,
      });
      const filterFromStorage = getAlertFiltersFromStorage();
      if (!isNil(filterFromStorage)) {
        setFilterWith({
          ...filterWith,
          customerIndustry: hasPath(["industry"], filterFromStorage),
        });
        const newFilterData = {
          ...filterData,
          customerIndustry: propOr(null, "industry", filterFromStorage),
        };
        if (filterWith.dateRange) {
          newFilterData.dateRange = {
            startDate,
            endDate,
          };
        }
        setFilterData(newFilterData);
        onFiltersApply(newFilterData);
        setTimeout(() => clearAlertFiltersOnStorage(), 500);
      } else {
        onApply();
      }
    });
    // clearAlertFiltersOnStorage
  }, [tenantConfig]);

  return (
    <div className="filter-button filter alert--filter-button">
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="filter-button__btn p-0"
          ref={toggleRef}
        >
          <div className="btn-group ">
            <span className="btn btn-default">
              <i className="far fa-filter mr-2" /> Filter{" "}
            </span>
            {filterCount > 0 && (
              <span className="btn btn-default filter-count">{filterCount}</span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="filter__header block-center-between">
            <button
              className="filter__clear btn btn-default"
              onClick={filtersClear}
            >
              Clear
            </button>
            <span className="filter__label ">Filters</span>
            <button
              className="filter__apply btn btn-default"
              onClick={onApplyClick}
            >
              Apply
            </button>
          </div>
          <div className="filter__body">
            <div className="filter__options f-14">
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.alertType}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        alertType: !filterWith.alertType,
                      })
                    }
                  />
                  <span className="ck" />
                  Alert types
                </label>
              </div>
              {filterWith.alertType && (
                <div className="filter__footer">
                  <Select
                    defaultValue={{ value: '-All-', label: 'All' }}
                    onChange={(event) => {
                      setFilterData({
                        ...filterData,
                        alertType: event.value,
                      })
                    }}
                    // menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    options={filterConfig['alertTypes']}
                  />
                </div>
              )}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.alertSeverity}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        alertSeverity: !filterWith.alertSeverity,
                      })
                    }
                  />
                  <span className="ck" />
                  Alert severity
                </label>
              </div>
              {filterWith.alertSeverity && (
                <div className="filter__footer">
                  <Select
                      defaultValue={{ value: '-All-', label: 'All' }}
                      onChange={(event) => {
                        setFilterData({
                          ...filterData,
                          alertSeverity: event.value,
                        })
                      }}
                      // menuPortalTarget={document.body} 
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      options={filterConfig['alertSeverities']}
                  />
                </div>
              )}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.dateRange}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        dateRange: !filterWith.dateRange,
                      })
                    }
                  />
                  <span className="ck" />
                  Date range
                </label>
              </div>
              {filterWith.dateRange && (
                <div className="filter__footer">
                  <>
                    <div>from: </div>
                    <div className="month-range-picker start-date">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                    <div>to: </div>
                    <div className="month-range-picker end-date">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                  </>
                </div>
              )}
              {isNil(onTabCustomerID) && (
                <>
                  {tenantConfig.slug !== 'intellihub' && (
                    <>
                    <div className="filter__option">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className=""
                          name="option-filter"
                          checked={filterWith.customerName}
                          onChange={(e) =>
                            setFilterWith({
                              ...filterWith,
                              customerName: !filterWith.customerName,
                            })
                          }
                        />
                        <span className="ck" />
                        Customer name
                      </label>
                    </div>
                    {filterWith.customerName && (
                      <div className="filter__footer">
                        <input
                          className="form-control"
                          value={filterData.customerName}
                          onChange={(event) =>
                            setFilterData({
                              ...filterData,
                              customerName: event.target.value,
                            })
                          }
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              onApplyClick();
                            }
                          }}
                        />
                      </div>
                    )}

                    <div className="filter__option">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className=""
                          name="option-filter"
                          checked={filterWith.customerIndustry}
                          onChange={(e) =>
                            setFilterWith({
                              ...filterWith,
                              customerIndustry: !filterWith.customerIndustry,
                            })
                          }
                        />
                        <span className="ck" />
                        Customer industry
                      </label>
                    </div>
                  </>
                )}
                  
                  <div className="filter__option">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        className=""
                        name="option-filter"
                        checked={filterWith.customerType}
                        onChange={(e) =>
                          setFilterWith({
                            ...filterWith,
                            customerType: !filterWith.customerType,
                          })
                        }
                      />
                      <span className="ck" />
                      Customer types
                    </label>
                  </div>
                  {filterWith.customerType && (
                    <div className="filter__footer">
                      <Select
                          defaultValue={{ id: '-All-', taxonomy_label: 'All' }}
                          onChange={(event) => {
                            setFilterData({
                              ...filterData,
                              customerType: event.id,
                            })
                          }}
                          getOptionLabel ={(option)=>option.taxonomy_label}
                          getOptionValue ={(option)=>option.id}
                          // menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          options={filterConfig['customerTypes']}
                      />
                    </div>
                  )}
                  
                  {filterWith.customerIndustry && (
                    <div className="filter__footer">
                      <Select
                          defaultValue={{ id: '-All-', taxonomy_label: 'All' }}
                          onChange={(event) => {
                            setFilterData({
                              ...filterData,
                              customerIndustry: event.id,
                            })
                          }}
                          getOptionLabel ={(option)=>option.taxonomy_label}
                          getOptionValue ={(option)=>option.id}
                          // menuPortalTarget={document.body} 
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          options={filterConfig['customerIndustries']}
                      />
                    </div>
                  )}

                  <div className="filter__option">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        className=""
                        name="option-filter"
                        checked={filterWith.waterZone}
                        onChange={(e) =>
                          setFilterWith({
                            ...filterWith,
                            waterZone: !filterWith.waterZone,
                          })
                        }
                      />
                      <span className="ck" />
                      Water zone
                    </label>
                  </div>
                  {filterWith.waterZone && (
                    <div className="filter__footer">
                      <Select
                        defaultValue={{ value: '-All-', label: 'All' }}
                        onChange={(event) => {
                          setFilterData({
                            ...filterData,
                            waterZone: event.value,
                          })
                        }}
                        // menuPortalTarget={document.body} 
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        options={filterConfig['waterZone']}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default forwardRef(AlertsFilter);
