import {
    getActionsData,
} from 'src/main_app/utils/customer_utils'
import {FETCH_CUSTOMER_ACTIOHS} from '../constants'

const fetchCustomerActionSuccess = (dispatch, getState) => (data, queryOptions) => dispatch({
    type: FETCH_CUSTOMER_ACTIOHS,
    data,
    queryOptions
})

const fetchCustomerActionAsync = (id, currentPage, limit) => (dispatch, getState) => getActionsData(id, currentPage, limit)
    .then(data => fetchCustomerActionSuccess(dispatch, getState)
        (
            data,
            {
                id,
                currentPage,
                limit,
            }
        )
    )

export default fetchCustomerActionAsync
