export const saveCustomerFiltersToStorage = filterData => {
  return localStorage.setItem('current_customer_filters', JSON.stringify(filterData))
}

export const getCustomerFiltersFromStorage = () => {
  return JSON.parse(localStorage.getItem('current_customer_filters'))
}

export const clearCustomerFiltersOnStorage = () => {
  localStorage.removeItem('current_customer_filters')
}

export const saveAlertFiltersToStorage = filterData => {
  return localStorage.setItem('current_alert_filters', JSON.stringify(filterData))
}

export const getAlertFiltersFromStorage = () => {
  return JSON.parse(localStorage.getItem('current_alert_filters'))
}

export const clearAlertFiltersOnStorage = () => {
  localStorage.removeItem('current_alert_filters')
}