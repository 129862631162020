import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import { Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { map, path, __ } from "ramda";
import { Bar } from "react-chartjs-2";

import { isNilOrEmpty, formatNumber, addDays, convertDateWithShotMonthName, convertDate, getDisplayName } from "src/main_app/utils/common";

import AlertsFilter from "../components/alerts_filter/alerts-filter";

import { isEmpty, isNil, propOr, compose, find, propEq, reduce } from "ramda";
import { getApiUrl } from "../utils/config-utility";
import axios from 'axios';
const alertTypes = [
  { name: "base_flow", label: "Base flow", color: "#d6a189" },
  { name: "night_flow", label: "Night flow", color: "#4c6972" },
  { name: "data_loss", label: "Data loss", color: "#d6a189" },
];
const ScreenShotAveo = () => {
  const [dataBarchart, setDataBarChart] = useState([]);
  const [dataOverview, setDataOverview] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const filterRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState({
    all: 0,
  });
  const hasAny = useRef(false);
  const options={
    responsive: true,
    legend: {
        display: false,
    },
    type:'bar',
    scales: {
      y: {
        ticks: {
          precision: 0
        }
      }
    }
  }

  const displaySeverity = useCallback((alert) => {
    return (
      <span className={`alert-severity alert-severity--${alert.data.severity}`}>
        {alert.data.severity}
      </span>
    );
  }, []);

  const displayAlertType = useCallback((alert) => {
    return (
      <span className={`alert-type alert-type--${alert.type}`}>
        {(alertTypes.find((item) => item.name === alert.type) ?? {}).label}{" "}
        <OverlayTrigger overlay={<Tooltip>{alert.data.content}</Tooltip>}>
          <i className="fas fa-info-circle"></i>
        </OverlayTrigger>
      </span>
    );
  }, []);

  const displayAlertValueInPercent = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        return alert.data.roundedNightUsagePercent + "%";
      case "data_loss":
        return "0%";
      default:
        return "-";
    }
  }, []);

  const displayAlertValue = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        const value = alert.data.nightUsage
          ? (alert.data.nightUsage * 1000) / (60 * 60 * 2)
          : alert.data.value;
        return (
          <React.Fragment>
            {isNilOrEmpty(value) ? "" : Math.ceil(value * 10) / 10}{" "}
            <i
              className={[
                "fas fa-arrow-up",
                alert.data.value < alert.data.valueOfPreviousDay
                  ? "trending-down rotate-135deg"
                  : alert.data.value > alert.data.valueOfPreviousDay
                  ? "trending-up rotate-45deg"
                  : "trending-normal rotate-90deg",
              ].join(" ")}
            ></i>
          </React.Fragment>
        );
        case "base_flow":
        return <React.Fragment>{alert.data.value}</React.Fragment>;
    }
  }, []);

  const displayAlertTime = useCallback((alert) => {
    return moment(alert.created_at).format("DD MMM yyyy");
  }, []);

  useEffect(() => { 
    const ignore = false;
    if (!ignore)  loadData()
    return () => { ignore = true; }
  },[]);

  const displayDataOverview = useCallback(
    (severity) =>
      compose(
        formatNumber,
        propOr(0, "count"),
        find(propEq("severity", severity))
      ),
    []
  );

  const loadData = async () => {
      const tenant_id = 4;
      const today = new Date();
      const date = addDays(today, -1);
      const from = convertDate(new Date(date.getFullYear(), date.getMonth(), 1));
      const to = convertDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
  
      const { data: result } = await axios.get(`${getApiUrl()}/alerts-screenshot/overview?tenant_id=${tenant_id}&from=${from}&to=${to}`)
      const { data: listCustomer } = await axios.get(`${getApiUrl()}/alerts-screenshot/list-customer?tenant_id=${tenant_id}&from=${from}&to=${to}`)
  
      setAlerts(listCustomer);
      setDataOverview(result.overviewBySeverity);
  
      const formDate = new Date(from);
      const toDate = new Date(to);

    //Set total 
    hasAny.current =
      reduce(
        (sumItems, item) => {
          return sumItems + (item.count - "");
        },
        0,
        result.overviewBySeverity
      ) > 0;

    const nTotal = {
      all: reduce(
        (sumItems, item) => {
          return sumItems + (item.count - "");
        },
        0,
        result.overviewByType
      ),
    };
    for (const alertType of alertTypes) {
      nTotal[alertType.name] =
        (
          result.overviewByType.find((i) => i.type === alertType.name) ?? {
            count: 0,
          }
        ).count - "";
    }
    setTotal(nTotal);

    //End set total

    const combinedData = [];
    for (
      let currentDate = formDate;
      currentDate <= toDate;
      currentDate = addDays(currentDate, 1)
    ) {
      const currentDateString = moment(currentDate).format("yyyy-MM-DD 00:00:00");
      const dataAtTheDate = {
        date: currentDate,
        daeString: currentDateString,
      };
      for (const alertType of alertTypes) {
        const alertsByType = result.data.find(
          (i) => i.type === alertType.name && i.created_at === currentDateString
        );
        if (alertsByType) {
          dataAtTheDate[alertType.name] = alertsByType.count - 0;
        } else {
          dataAtTheDate[alertType.name] = 0;
        }
      }
      combinedData.push(dataAtTheDate);
    }
    //Map data for Bar chart
    const arr_date = [];
    const arr_data_loss = [];
    const arr_night_flow = [];
    const arr_base_flow = [];

    combinedData.forEach((item) => {
      arr_date.push(convertDateWithShotMonthName(item['date']))
      arr_data_loss.push(item['data_loss'])
      arr_night_flow.push(item['night_flow'])
      arr_base_flow.push(item['base_flow'])
    })

    const dataSet = [];

    alertTypes.forEach((item) => {
      switch(item['name']) {
        case 'night_flow':
          dataSet.push({
            label: 'Night flow',
            backgroundColor: '#54513f',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#c235318a',
            data: arr_night_flow
          });
          break;
        case 'data_loss':
          dataSet.push({
            label: 'Data loss',
            backgroundColor: '#d6a189',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#2e4553a1',
            data: arr_data_loss
          },);
          break;
        case 'base_flow':
          dataSet.push({
            label: 'Base flow',
            backgroundColor: '#48b49b',
            borderWidth: 1,
            stack: 1,
            hoverBackgroundColor: '#48b49ba3',
            data: arr_base_flow
          });
          break;
        default:
      }
    })

    //Maping data linechart to barchart
    const dataMapingBarchart = {
      labels: arr_date,
      datasets: dataSet
    }
    setDataBarChart(dataMapingBarchart);
    setIsLoaded(true);
  };

  useLayoutEffect(() => {
    import("./style-screenshot-alert.scss");
  }, []);

  return (
    <div className="wrapper">
     <div className="the-content">
      <div className="container-fluid content">
        <div className="action-buttons block-center-between">
          <div className="block-center" style={{opacity: "0"}}>
            <AlertsFilter
              ref={filterRef}
              onFiltersApply={loadData}
            />
          </div>
          <div className="d-flex">
            {isLoaded && (
              <div className="alert-statistic">
                <span className="alert-statistic_high">
                  {alerts.length}
                  {/* {displayDataOverview("high")(dataOverview)} */}
                </span>
              </div>
            )}
          </div>
        </div>


        <div className="card">
          <div className="card-body">
          {isLoaded && (
            <Bar data={dataBarchart} options={options} height={100} />
          )}
          </div>
        </div>

        {/* List Alert */}
        <div className="mt-5">
          <div className="d-flex mb-3">
          <div className="total-alert">
                All alerts <span>{alerts.length}</span>
          </div>
          {alertTypes.map((item) => (
            <div>
              <div className="total-alert">
                {item.label} <span>{formatNumber(total[item.name] ?? 0)}</span>
              </div>
            </div>
          ))}
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Customer</th>
                  <th scope="col">Customer type</th>
                  <th scope="col">Severity</th>
                  <th scope="col">Alert type</th>
                  <th scope="col">Value</th>
                  <th scope="col">L/s</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {alerts.map((alert, index) => (
                  <tr key={alert.id}>
                    <td scope="col" style={{width: '36%'}}>
                      <span>{getDisplayName(alert)}</span>
                    </td>
                    <td scope="col" className="customer-info customer-critical-class">
                      {alert.critical_class}
                    </td>
                    <td scope="col">{displaySeverity(alert)}</td>
                    <td scope="col">{displayAlertType(alert)}</td>
                    <td scope="col text-right">
                      {displayAlertValueInPercent(alert)}
                    </td>
                    <td scope="col text-right">{displayAlertValue(alert)}</td>
                    <td scope="col">{displayAlertTime(alert)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* List Alert */}

      </div>
    </div>
   </div>
  );
}

export default ScreenShotAveo



