import {
    pathOr,
    filter,
    propEq,
} from 'ramda'
import { propIsNil } from 'src/main_app/utils/common'
import getWithToken from './base/get-with-token'

const getCustomersTimelineNoteTypes = async () => {
    const res = await getWithToken('/customer-timeline/note-type')()
    const data = pathOr([], ['data', 'data'], res)

    const listType = [];
    for (const item of data) {
      if (item.name !== 'type') {
        listType.push(item);
      }
    }
    return listType;
}

export default getCustomersTimelineNoteTypes