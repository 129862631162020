import { getTaxonomies } from "src/main_app/utils/customer_utils";
import { FETCH_CUSTOMER_TAXONOMIES_BY_PARENT_NAME } from "../constants";

const fetchedStatus = {};
const fetchCustomerTaxonomiesByParentNameAsync =
  (parentName) => (dispatch, getState) => {
    if (fetchedStatus[parentName]) return;
    fetchedStatus[parentName] = true;
    getTaxonomies(null, null, null, null, parentName).then(
      (data) =>
        console.log("data", data) ||
        dispatch({
          type: FETCH_CUSTOMER_TAXONOMIES_BY_PARENT_NAME,
          data: {
            [parentName]: data,
          },
        })
    );
  };

export default fetchCustomerTaxonomiesByParentNameAsync;
