import React from 'react'
import {
  isEmpty,
  map,
  propOr,
} from 'ramda'
import useSupercluster from 'use-supercluster'
import MapCluster from './map-cluster'
import { layerNames } from './layers/layer-config'
import { jsonStringToValue } from "src/main_app/utils/common";

const MapClusters = ({
  viewport,
  setViewport,
  data,
  type,
  zoom,
  visible,
  bounds,
  onMouseIn,
  onMouseOut,
  onClick,
  ...restProps
}) => {
  if (!visible || isEmpty(data)) return null

  const customerPoint = (item, index) => ({
    'type': 'Feature',
    'properties': {
      type,
      'cluster': false,
      'pointId': index,
      'name': propOr('', 'name', item),
      'id': propOr('', 'id', item),
    },
    'geometry': {
      'type': 'Point',
      'coordinates': [item.longitude, item.latitude]
    },
  })


  const wasteWaterPoint = (item, index) => ({
    'type': 'Feature',
    'properties': {
      type,
      'cluster': false,
      'pointId': index,
      'name': propOr('', 'name', item),
      'address': propOr('', 'address', item),
      'capacity': propOr('', 'size_ml', item),
      'treatment_level': propOr('', 'type', item),
      'sewer_zone': propOr('', 'sewer_zone', item),
      'latitude': item.latitude,
      'longitude': item.longitude
    },
    'geometry': {
      'type': 'Point',
      'coordinates': [item.longitude, item.latitude]
    },
  })


  const BOMPoint = (item, index) => ({
    'type': 'Feature',
    'properties': {
      type,
      'cluster': false,
      'pointId': index,
      'name': jsonStringToValue(propOr('', 'name', item)),
      'rainfall': propOr('', 'rain_fall', item),
      'month': propOr('', 'month', item),
      'latitude': item.latitude,
      'longitude': item.longitude
    },
    'geometry': {
      'type': 'Point',
      'coordinates': [item.longitude, item.latitude]
    },
  })

  const damPoint = (item, index) => ({
    'type': 'Feature',
    'properties': {
      type,
      'cluster': false,
      'pointId': index,
      'name': propOr('', 'name', item),
      'location': propOr('', 'address', item),
      'current_storage_level': propOr('', 'current_storage_level', item),
      'latitude': item.latitude,
      'longitude': item.longitude
    },
    'geometry': {
      'type': 'Point',
      'coordinates': [item.longitude, item.latitude]
    },
  })

  const points = data.map((item, index) => {
    switch (type) {
      case layerNames.wasteWater:
        return wasteWaterPoint(item, index)
      case layerNames.bom:
        return BOMPoint(item, index)
      case layerNames.customers:
        return customerPoint(item, index)
      case layerNames.dams:
        return damPoint(item, index)
    }
  })

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 30, maxZoom: 20 },
  })

  return map(cluster => (
    <MapCluster
      key={`cluster-${cluster.properties.cluster ? cluster.id : cluster.properties.pointId}`}
      type={type}
      cluster={cluster}
      superCluster={supercluster}
      viewport={viewport}
      setViewport={setViewport}
      onMouseIn={onMouseIn}
      onMouseOut={onMouseOut}
      onClick={onClick}
      {...restProps}
    />
  ))(clusters)
}

export default MapClusters
