import {
  pathOr,
} from 'ramda'
import {
  getCustomersTimeline,
} from '../../services/apis'
import {
  isServerResponseError,
  hasError,
} from '../common'

const customerTimelineData = async (id, type) => {
  const result = await getCustomersTimeline(id, type)
  return isServerResponseError(result) || hasError(result)
    ? []
    : pathOr({}, ['data', 'data'], result)
}

export default customerTimelineData