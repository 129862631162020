import React from 'react'
import { times } from 'ramda'
import Skeleton from 'react-loading-skeleton'
import { Button, Dropdown } from 'react-bootstrap'
import { Pager } from '../../components'

const AlertsPageSkeleton = ({ numberOfItems = 10 }) => {
  const alertsList = times(
    (i) => (
      <div
        className={`alert main-alert clearfix alert-box-shadow`}
        key={i + ''}
      >
        <div className="alert-content alert-float-left">
          <Skeleton />
        </div>
        <span className="alert-date alert-float-right" style={{ width: '10%' }}>
          <Skeleton />
        </span>
      </div>
    ),
    numberOfItems,
  )

  return (
    <div className="=mt-5">
      <div className="mb-5">
        <Skeleton width="100%" height="400px" />
      </div>
      <div className="table-responsive alerts">{alertsList}</div>
      <div className="d-flex justify-content-lg-end pagination-box">
        <Pager />
      </div>
    </div>
  )
}

export default AlertsPageSkeleton
