import React from 'react'
import {isEmpty, times} from 'ramda'
import {Pager} from '../../components'
import Skeleton from 'react-loading-skeleton'

const NotesPageSkeleton = ({
  numberOfItems
}) => {

  const industriesList = times(i =>
    <tr key={i}>
      <td><div className="d-flex"><Skeleton width={200} height={20}/></div></td>
      <td><Skeleton width={40}/></td>
    </tr>, numberOfItems)

  return (
    <div className="the-content">
      <div className="page-heading ">
        <div  className="container-fluid ">
          <h2 className="title mb-0">
            Notes page
          </h2>
        </div>
      </div>
      <div className="container-fluid content">
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
              <th scope="col" width="50%">
                <div className="d-flex">
                  Name
                </div>
              </th>
              <th scope="col" width="50%">Num of companies</th>
            </tr>
            </thead>
            <tbody>
            {
              industriesList
            }
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-lg-end pagination-box">
          <Pager />
        </div>
      </div>
    </div>
  )
}

export default NotesPageSkeleton
