import { pathOr } from "ramda";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { FormContainer, Textbox } from "../components/form_elements";

import { getReport, updateReport } from "../services/apis/report";
import { inputChangeHandler } from "../utils/react_utils";

const ReportSinglePage = (props) => {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});

  const isEditMode = params.action === "edit";

  const backClickHandler = (event) => {
    if (isEditMode) {
      history.push("/reports/management");
    } else {
      history.push("/reports");
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
  };

  const onChange = inputChangeHandler(item, setItem);

  const onOptionsChange = (event) => {
    const { name, value } = event.target;
    setItem({
      ...item,
      options: {
        ...item.options,
        [name]: value,
      },
    });
  };

  useEffect(() => {
    getReport(params.id).then(({ data: { data } }) => {
      setItem(data);
    });
  }, []);

  if (isEditMode) {
    return (
      <div className="the-content">
        <div className="page-heading ">
          <div className="container-fluid block-center-between">
            <h2 className="title mb-0">{item.name}</h2>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="action-buttons block-center-between">
            <button className="btn-text f-14" onClick={backClickHandler}>
              <i className="far fa-arrow-alt-circle-left mr-2  f-16" />
              Back
            </button>
          </div>
          <div className="chart-box__col">
            <div className="card">
              <div className="card-body">
                <FormContainer onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <Textbox
                        controlId="firstName"
                        name="name"
                        label="Name"
                        placeholder="Enter name"
                        value={item.name}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <Textbox
                        controlId="supersetDashboardId"
                        name="supersetDashboardId"
                        label="Superset dashboard id"
                        placeholder="Enter Superset dashboard id"
                        value={pathOr(
                          "",
                          ["options", "supersetDashboardId"],
                          item
                        )}
                        onChange={onOptionsChange}
                      />
                    </div>
                    <div className="col-md-12">
                      <Textbox
                        controlId="url"
                        name="url"
                        label="Url"
                        placeholder="Enter url"
                        value={item.url}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <Textbox
                        controlId="width"
                        name="width"
                        label="Width"
                        value={pathOr("", ["options", "width"], item)}
                        onChange={onOptionsChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <Textbox
                        controlId="height"
                        name="height"
                        label="Height"
                        value={pathOr("", ["options", "height"], item)}
                        onChange={onOptionsChange}
                      />
                    </div>
                  </div>
                </FormContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">{item.name}</h2>
        </div>
      </div>
      <div className="container-fluid content">
        <div className="action-buttons block-center-between">
          <button className="btn-text f-14" onClick={backClickHandler}>
            <i className="far fa-arrow-alt-circle-left mr-2  f-16" />
            Back
          </button>
        </div>
        <div className="chart-box__col">
          <div className="card">
            <div className="card-body">
              <iframe
                style={{
                  width: "100%",
                  height: "100vh",
                }}
                frameBorder="0"
                src={item.url}
                title={item.name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportSinglePage;
