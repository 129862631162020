import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  useToasts
} from 'react-toast-notifications'
import {
  isEmpty,
  path,
} from 'ramda'
import queryString from 'query-string'

import {
  authService
} from '../../../../base/services'
import {
  setTargetValue
} from '../../../utils/react_utils'
import { useError } from '../../form_elements'
import {
  userAuthVerify
} from '../../../services/apis'
import { hasError, isNilOrEmpty, isServerResponseError } from 'src/main_app/utils/common'
import { getTenantSlugFromCurrentUrl } from 'src/main_app/utils/config-utility'
import { identifyUser } from "src/main_app/mixpanel";

const VerificationForm = (props) => {
  const {
    email,
    url = '/'
  } = queryString.parse(location.search)
  const { addToast } = useToasts()
  const [errorComponent, setError] = useError(null)
  const [verify_code, setCode] = useState('')

  const submitHandler = async event => {
    event.preventDefault()

    if (isEmpty(verify_code)) {
      setError('Please enter your verify code.')
    } else {
      const tenant = getTenantSlugFromCurrentUrl()
      const result = await userAuthVerify(email, verify_code, tenant)
      if (isServerResponseError(result)) {
        setError('No response from server, please wait a while and do it again.')
      } else if (hasError(result)) {
        setError('Authentication failed.')
      } else {
        setError(null)
        addToast('Login successful.', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000
        })
        authService.authenticate({
          account: path(['data', 'result','user'], result),
          accessToken: path(['data', 'result', 'token'], result),
          refreshToken: path(['data', 'result', 'refreshToken'], result),
          tenant_info: path(['data', 'result', 'tenant_info'], result),
        })
        identifyUser();
        setTimeout(() => {
          const customer_id = path(['data', 'customer'], result);
          if (customer_id) {
            document.location.href = `customer/${customer_id}/overview`;
          } else {
            document.location.href = isNilOrEmpty(url) || /undefined/.test(url) ? '/': url
          }
          
        }, 1500)
      }
    }
  }

  return (
    <div>
      <h2 className="sign-in__tile">Verification</h2>
      <p
        className="sign-in__text f-14">We emailed your verification code
        to {email}.</p>
      <form onSubmit={submitHandler}>
        <div className="form-group">
          <label>Verification code</label>
          <input
            name='verify_code'
            value={verify_code}
            className={`form-control ${errorComponent ? 'is-invalid' : ''}`}
            placeholder="------"
            onChange={setTargetValue(setCode)}
          />
          {errorComponent}
        </div>
        <div className="sign-in__action-button block-center-between mt-5">
          <Link className="btn-text" to="/sign-in">Back</Link>
          <button className="sign-in__btn btn btn-primary" type="submit">
            SIGN IN
          </button>
        </div>
      </form>
    </div>
  )
}

export default VerificationForm
