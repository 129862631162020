import { compose, max, min, isEmpty, tap } from "ramda";
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactMapGL, {
  Popup,
  WebMercatorViewport,
  FlyToInterpolator,
  NavigationControl,
} from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";

import { getMapboxAccessKey } from "../../utils/config-utility";

import MapClusters from "./map-clusters";
import MarkerInfo from "./map_markers_info/marker-info";

import {
  getTargetValue,
  useBooleanStage,
} from "src/main_app/utils/react_utils";
import {
  getMapDataBom,
  getMapDataWasteWater,
  getMapDataDams,
} from "src/main_app/services/apis";
import getThenSetMarkersData from "./helpers/get-set-markers-data";
import MapUpdating from "./map-updating";
import { isNilOrEmpty } from "src/main_app/utils/common";
import { CustomerLayer, SewerZonesLayer } from "./layers";
import { layerNames } from "./layers/layer-config";
import CustomerVisualization from "./map_visualization/customer-visualization";
import MapFilter from "./map_filter/map-filter";
import { useTenantConfig } from "src/main_app/actions";

const mapboxAccessKey = getMapboxAccessKey();

const navControlStyle = {
  right: 10,
  bottom: 175,
};
const defaultPosition = {
  // longitude: 151.71649999999596,
  // latitude: -32.776694529121364,
  zoom: 7,
};
const normalViewport = {
  mapStyle: "mapbox://styles/loweblue0510/ckkqoydb526lt17s1s5ql84of",
  pitch: undefined,
};
const elevationViewport = {
  mapStyle: "mapbox://styles/loweblue0510/cku3lxvq10s2q17lfcixvqyan",
  pitch: 69,
};

const Map = ({ style = {}, zoom = 15 }) => {
  const mapRef = useRef(null);
  const timeoutRef = useRef(null);
  const customerLayerRef = useRef(null);
  const tenantConfig = useTenantConfig();
  const [isDisplayElevation, setIsDisplayElevation] = useState(false);
  const [viewport, setViewport] = useState({
    width: "100vw",
    height: "100vh",
    ...defaultPosition,
    ...(isDisplayElevation ? elevationViewport : normalViewport),
  });
  // access key
  const [readyForRenderClusterLayers, setReadyForRenderClusterLayers] =
    useState(false);
  const [visualizationType, setVisualizationType] =
    useState({value: 'customer_address', label: 'Customer address'});
    // useState("customer_address");

  // markers and layers data states
  const [wasteWaterMarkersData, setWasteWaterMarkersData] = useState([]);
  const [bomMarkersData, setBomMarkersData] = useState([]);
  const [damsMarkersData, setDamsMarkersData] = useState([]);
  const allMarkersData = useMemo(
    () => [...wasteWaterMarkersData, ...damsMarkersData],
    [wasteWaterMarkersData, damsMarkersData]
  );
  const [namePopupInfo, setNamePopupInfo] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [loading, setIsLoading, setIsLoaded] = useBooleanStage(true);

  const getBoundViewPort = () => {
    const allMarkers = [
      ...allMarkersData,
      ...(customerLayerRef.current ? customerLayerRef.current.getData() : []),
    ];
    if (isEmpty(allMarkers)) return null;
    let topLeftLat = 1000;
    let topLeftLong = -1000;
    let bottomRightLat = -1000;
    let bottomRightLong = 1000;
    allMarkers.forEach((marker) => {
      topLeftLat = min(topLeftLat, marker.latitude);
      topLeftLong = max(topLeftLong, marker.longitude);
      bottomRightLat = max(bottomRightLat, marker.latitude);
      bottomRightLong = min(bottomRightLong, marker.longitude);
    });
    return [
      [topLeftLong, topLeftLat],
      [bottomRightLong, bottomRightLat],
    ];
  };

  const onElevationLayerChange = (isDisplay) => {
    setIsDisplayElevation(isDisplay);
    setViewport({
      ...viewport,
      ...(isDisplay ? elevationViewport : normalViewport),
    });
  };

  const fitBounds = () => {
    const boundViewPort = getBoundViewPort();
    if (!boundViewPort) return;

    const { longitude, latitude, zoom } = new WebMercatorViewport(
      viewport
    ).fitBounds(boundViewPort);

    setViewport({
      ...viewport,
      height:
        window.innerHeight -
        document.querySelector(".app__header").clientHeight,
      width: window.innerWidth,
      longitude,
      latitude,
      zoom: zoom - 1,
      transitionInterpolator: new FlyToInterpolator({
        speed: 3,
      }),
      transitionDuration: "auto",
    });
  };

  const fitBoundsDebounced = useDebouncedCallback((func) => func(), 666);
  const onFitBounds = () => fitBoundsDebounced(fitBounds);

  useLayoutEffect(() => {
    console.log("tenantConfig", tenantConfig);
    if (!tenantConfig.defaultPosition) return;
    
    console.log("set defaultPosition", defaultPosition);
    setViewport({ ...viewport, ...tenantConfig.defaultPosition });
  }, [tenantConfig]);

  useLayoutEffect(() => {
    window.addEventListener("resize", onFitBounds);
    return () => window.removeEventListener("resize", onFitBounds);
  }, [fitBounds]);

  const clearNamePopupInfo = () => setNamePopupInfo(null);

  const onMarkerClick = compose(clearNamePopupInfo, setPopupInfo);

  useLayoutEffect(() => {
    if (!mapRef.current) return;
    if (timeoutRef.current !== null) return;
    timeoutRef.current = setInterval(() => {
      try {
        const bounds = mapRef.current.getMap().getBounds().toArray().flat();
        clearInterval(timeoutRef.current);
        setTimeout(() => {
          setReadyForRenderClusterLayers(bounds);
        }, 1000);
      } catch (error) {
        console.log("error", error);
      }
    }, 666);
    return () => {
      clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, [mapRef.current, setReadyForRenderClusterLayers]);

  // markers and layers visibility states
  const [layersVisible, setLayersVisible] = useState({
    [layerNames.customers]: true,
    [layerNames.bom]: false,
    [layerNames.dams]: false,
    [layerNames.sewerZones]: false,
    [layerNames.wasteWater]: false,
  });

  const clearInfoWindow = () => {
    if (isNilOrEmpty(popupInfo) || layersVisible[popupInfo.type]) return;

    setPopupInfo(null);
  };

  const onCustomerVisualizationChange = (event) => { 
    setVisualizationType(event)
  }

  // const onCustomerVisualizationChange = compose(
  //   setVisualizationType,
  //   getTargetValue
  // );

  const onMapClick = useCallback((event) => {
    console.log("onMapClick", event.lngLat);

    // allowed area of Hunter water
    // [152.45032825036722, -32.3103038609632],
    // [151.21184839564557, -32.30834735250607],
    // [151.1956439863317, -33.29871836465587],
    // [151.55908573809117, -33.29097861356051],
  }, []);

  useLayoutEffect(() => {
    getThenSetMarkersData(
      getMapDataWasteWater,
      setWasteWaterMarkersData,
      layersVisible[layerNames.wasteWater]
    );
  }, [layersVisible[layerNames.wasteWater]]);

  useLayoutEffect(() => {
    getThenSetMarkersData(
      getMapDataBom,
      setBomMarkersData,
      layersVisible[layerNames.bom]
    );
  }, [layersVisible[layerNames.bom]]);

  useLayoutEffect(() => {
    getThenSetMarkersData(
      getMapDataDams,
      setDamsMarkersData,
      layersVisible[layerNames.dams]
    );
  }, [layersVisible[layerNames.dams]]);

  useLayoutEffect(() => {
    clearInfoWindow();
  }, [
    layersVisible[layerNames.customers],
    layersVisible[layerNames.bom],
    layersVisible[layerNames.wasteWater],
    layersVisible[layerNames.dams],
  ]);

  const applyFilterData = (...rest) => {
    setTimeout(() => {
      customerLayerRef.current &&
        customerLayerRef.current.applyFilterData(...rest);
    }, 1000);
  };

  if (isEmpty(tenantConfig)) {
    return <div>loading...</div>;
  }

  const displayCurrentMap = visualizationType.value === "current_water_outages" ? "none" : "block";

  return (
    <div className="mapbox-wrapper">
      <div className="container">
        {customerLayerRef.current ? (
          <>
            <MapFilter
              layersVisible={layersVisible}
              setLayersVisible={setLayersVisible}
              applyFilterData={applyFilterData}
              displayCurrentMap={displayCurrentMap}
            />
            <CustomerVisualization
              onChange={onCustomerVisualizationChange}
              visualizationType={visualizationType}
              visible={layersVisible[layerNames.customers]}
              isDisplayElevation={isDisplayElevation}
              onElevationLayerChange={onElevationLayerChange}
            />
          </>
        ) : null}
      </div>
      <div className="content map-page-content">
        <ReactMapGL
          ref={mapRef}
          {...viewport}
          mapStyle={
            isDisplayElevation
              ? elevationViewport.mapStyle
              : normalViewport.mapStyle
          }
          mapboxApiAccessToken={mapboxAccessKey}
          onViewportChange={setViewport}
          onTransitionStart={setIsLoading}
          onTransitionEnd={setIsLoaded}
          onClick={onMapClick}
          maxZoom={15}
        >
          {readyForRenderClusterLayers ? (
            <>
              <CustomerLayer
                ref={customerLayerRef}
                dataLoaded={setIsLoaded}
                bounds={readyForRenderClusterLayers}
                onMouseIn={setNamePopupInfo}
                onMouseOut={clearNamePopupInfo}
                onClick={onMarkerClick}
                viewport={viewport}
                setViewport={setViewport}
                zoom={viewport.zoom}
                fitBounds={onFitBounds}
                visualizationType={visualizationType}
                visible={layersVisible[layerNames.customers]}
              />
              <MapClusters
                bounds={readyForRenderClusterLayers}
                data={wasteWaterMarkersData}
                onMouseIn={setNamePopupInfo}
                onMouseOut={clearNamePopupInfo}
                onClick={onMarkerClick}
                viewport={viewport}
                setViewport={setViewport}
                zoom={viewport.zoom}
                type={layerNames.wasteWater}
                visible={layersVisible[layerNames.wasteWater]}
              />
              <MapClusters
                bounds={readyForRenderClusterLayers}
                data={bomMarkersData}
                onMouseIn={setNamePopupInfo}
                onMouseOut={clearNamePopupInfo}
                onClick={onMarkerClick}
                viewport={viewport}
                setViewport={setViewport}
                zoom={viewport.zoom}
                type={layerNames.bom}
                visible={layersVisible[layerNames.bom]}
              />
              <MapClusters
                bounds={readyForRenderClusterLayers}
                data={damsMarkersData}
                onMouseIn={setNamePopupInfo}
                onMouseOut={clearNamePopupInfo}
                onClick={onMarkerClick}
                viewport={viewport}
                setViewport={setViewport}
                zoom={viewport.zoom}
                type={layerNames.dams}
                visible={layersVisible[layerNames.dams]}
              />
            </>
          ) : null}
          <SewerZonesLayer visible={layersVisible[layerNames.sewerZones]} />
          {popupInfo && (
            <Popup
              tipSize={5}
              anchor="bottom"
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              onClose={setPopupInfo}
              offsetLeft={0}
              offsetTop={-10}
              closeButton={true}
              closeOnClick={false}
            >
              <MarkerInfo info={popupInfo} type="info-window" />
            </Popup>
          )}
          {namePopupInfo && (
            <Popup
              tipSize={5}
              anchor="bottom"
              longitude={namePopupInfo.longitude}
              latitude={namePopupInfo.latitude}
              onClose={setNamePopupInfo}
              offsetLeft={0}
              offsetTop={-10}
              closeButton={false}
              closeOnClick={true}
            >
              <MarkerInfo info={namePopupInfo} type="tooltip" />
            </Popup>
          )}
          <NavigationControl style={navControlStyle} />
        </ReactMapGL>
        <MapUpdating loading={loading} />
      </div>
    </div>
  );
};

export default Map;
