import React, { useState, useEffect, useMemo } from "react";
import { andThen, propOr, compose, isEmpty } from "ramda";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import {
  addSettingUrlFeedBack,
  getSettingsUrlFeedBack
} from "src/main_app/services/apis";
import { track } from "src/main_app/mixpanel";
import {
  checkPermission,
  useTenantConfig,
  useUserPermissions,
} from "src/main_app/actions";
import {
  FormContainer,
  SpinnerButton,
  Textbox,
} from "src/main_app/components/form_elements";
import { TagInput } from "src/main_app/components";
import { Button } from "react-bootstrap";

const roles = [
  { role_name: "Super Administrator" },
  { role_name: "Administrator" },
  { role_name: "Standard user" },
  { role_name: "Customer" },
];

const ProvideFeedbackPage = (props) => {
  useEffect(() => {
    track("Setting notification");
  }, []);
  const { addToast } = useToasts();
  const [filterData, setFilterData] = useState([]);
  const [emailValueImport, setEmailValueImport] = useState([]);
  const [load, setReload] = [];
  const [loadMailImport, setReloadMailImport] = [];
  const userPermissions = useUserPermissions();
  const canEdit = useMemo(
    () => checkPermission(userPermissions, "settings-mail-alert", "canEdit"),
    [userPermissions]
  );

  const submitEmailImportHandler = async (event) => {
    event.preventDefault();
    //Update email
    const email = emailValueImport;
    const type = "import";
    await addSettingReceiveMail(type, emailValueImport);
    addToast("Update successful.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setReloadMailImport(emailValueImport);
  };

  const loadData = async () => {
    const response = await getSettingsUrlFeedBack();
    setFilterData(propOr([], "data", response.data));
  };

  const submitUrlFeedback = async (event) => {
    // console.log("submitUrlFeedback", filterData);
    if (!isEmpty(filterData)) {
      const dataUrl = [];
      Object.keys(filterData).forEach(key => {
        const value = filterData[key];
        dataUrl.push({
          role_name: key,
          url: value
        })
      })
      await addSettingUrlFeedBack(dataUrl);
      addToast("Update successful.", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  useEffect(() => {
    loadData();
  }, [load]);

  return (
    <>
      <form id="customer-add-note-form" className="">
        <div className="container-fluid content row">
          {roles.map((item, index) => (
            <div className="container-fluid content col-md-6">
              <div className="row">
                <div className="col">
                  <h3>{item.role_name}</h3>
                  <hr />
                </div>
              </div>
              <p>
                Enter the URL that you would like to assign for {item.role_name}
              </p>
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control form-group"
                    label={"Email address"}
                    value={filterData[item.role_name] || ""}
                    onChange={(event) =>
                      setFilterData({
                        ...filterData,
                        [item.role_name]: event.target.value,
                      })
                    }
                    // onChange={(event) => {
                    //   setEmailValueImport(event);
                    // }}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="container-fluid">
        {canEdit && (
          <Button variant="primary" onClick={submitUrlFeedback}>
            SAVE
          </Button>
        )}
        </div>
      </form>
    </>
  );
};

export default ProvideFeedbackPage;
