import { archiveAlertApi } from '../../services/apis'
import { hasError, isServerResponseError } from '../common'

const updateArchiveAlert = async (id) => {
  const response = await archiveAlertApi(id)
  return isServerResponseError(response) || hasError(response)
    ? 'failed'
    : 'success'
}

export default updateArchiveAlert