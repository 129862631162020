import React, { useState, useEffect, useMemo } from "react";
import { andThen, propOr, compose } from "ramda";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router";
import {
  getSettingReceiveMailAlert,
  addSettingReceiveMail,
} from "src/main_app/services/apis";
import { track } from "src/main_app/mixpanel";
import {
  checkPermission,
  useTenantConfig,
  useUserPermissions,
} from "src/main_app/actions";
import {
  FormContainer,
  SpinnerButton,
  Textbox,
} from "src/main_app/components/form_elements";
import { TagInput } from "src/main_app/components";

const NotificationsPage = (props) => {
  useEffect(() => {
    track("Setting notification");
  }, []);
  const { addToast } = useToasts();
  const [emailValueAlert, setEmailValueAlert] = useState([]);
  const [emailValueImport, setEmailValueImport] = useState([]);
  const [load, setReload] = [];
  const [loadMailImport, setReloadMailImport] = [];
  const userPermissions = useUserPermissions();
  const canEdit = useMemo(
    () => checkPermission(userPermissions, "settings-mail-alert", "canEdit"),
    [userPermissions]
  );

  const submitEmailAlertHandler = async (event) => {
    event.preventDefault();
    //Update email
    const email = emailValueAlert;
    const type = 'alert';
    await addSettingReceiveMail(type, emailValueAlert);
    addToast("Update successful.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setReload(emailValueAlert);
  };

  const submitEmailImportHandler = async (event) => {
    event.preventDefault();
    //Update email
    const email = emailValueImport;
    const type = 'import';
    await addSettingReceiveMail(type, emailValueImport);
    addToast("Update successful.", {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 3000,
    });
    setReloadMailImport(emailValueImport);
  };

  const loadData = async () => {
    const response = await getSettingReceiveMailAlert();
    setEmailValueAlert(propOr([], 'emailAlert', response.data));
    setEmailValueImport(propOr([], 'emailImport', response.data));
  };

  useEffect(() => {
    loadData();
  }, [load, loadMailImport]);

  return (
    <>
    <div className="container-fluid content">
      <div className="row">
        <div className="col">
          <h3>Data import/export notifications</h3>
          <hr />
        </div>
      </div>
      <p>
        Enter the email addresses that you would like to receive email
        notifications for this account
      </p>
      <div className="row">
        <div className="col-md-12">
          <FormContainer onSubmit={submitEmailImportHandler}>
            <TagInput
              label={"Email address"}
              value={emailValueImport}
              onChange={(event) => {
                setEmailValueImport(event);
              }}
              disabled={false}
            />
            {canEdit && (
            <SpinnerButton type="submit" className="btn btn-primary btn--save">
              SAVE
            </SpinnerButton>
            )}
          </FormContainer>
        </div>
      </div>
    </div>
    <div className="container-fluid content">
      <div className="row">
        <div className="col">
          <h3>Alert notifications</h3>
          <hr />
        </div>
      </div>
      <p>
        Enter the email addresses that you would like to receive email
        notifications for this account
      </p>
      <div className="row">
        <div className="col-md-12">
          <FormContainer onSubmit={submitEmailAlertHandler}>
            <TagInput
              label={"Email address"}
              value={emailValueAlert}
              onChange={(event) => {
                setEmailValueAlert(event);
              }}
              disabled={false}
            />
            {canEdit && (
            <SpinnerButton type="submit" className="btn btn-primary btn--save">
              SAVE
            </SpinnerButton>
            )}
          </FormContainer>
        </div>
      </div>
    </div>
    </>
  );
};

export default NotificationsPage;
