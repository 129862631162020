import axios from 'axios'

import {
  getApiUrl,
} from '../../../utils/config-utility'

const apiPostRequest = apiPath => (data, config = {}) => axios.post(
  getApiUrl() + apiPath,
  data,
  config,
)

export default apiPostRequest