import { propOr } from "ramda";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import ContentWithTooltip from "./content-with-tooltip";
import { v4 as uuidv4 } from 'uuid';

const linkStyle = { color: "#000" };

const CustomerItem = ({ tenantConfig, customer, onClick }) => {
  const onRowClick = useCallback(
    () => onClick && onClick(customer),
    [onClick, customer]
  );

  return (
    <>
      <tr onClick={onRowClick}>
        {
          (tenantConfig.customerFieldsData || []).map( field => {
            if (field === 'trading_name') return (
              <td key={uuidv4()} className="customer-trading-name">
                <Link to={`/customer/${customer.id}/overview`} style={linkStyle}>  
                  <ContentWithTooltip
                  cid={customer.id}
                  type="name"
                  content={propOr("", field, customer)}
                />
                </Link>
              </td>
            )
              if (field === 'owner_name') return (
                <td key={uuidv4()} className="customer-owner-name">
                  <Link to={`/customer/${customer.id}/overview`} style={linkStyle}>  
                    <ContentWithTooltip
                    cid={customer.id}
                    type="name"
                    content={propOr("", field, customer)}
                  />
                  </Link>
                </td>
              )
              if (field === 'name') return (
                <td key={uuidv4()} className="customer-name">
                  <Link to={`/customer/${customer.id}/overview`} style={linkStyle}>  
                    <ContentWithTooltip
                    cid={customer.id}
                    type="name"
                    content={propOr("", field, customer)}
                  />
                  </Link>
                </td>
              )
              if (field === 'site_name') return (
                <td key={uuidv4()} className="customer-name">
                  <Link to={`/customer/${customer.id}/overview`} style={linkStyle}>
                    <ContentWithTooltip
                    cid={customer.id}
                    type="name"
                    content={propOr("", field, customer)}
                    />
                  </Link>
                </td>
              )
          })
        }
        
          <React.Fragment>
          {
            (tenantConfig.customerFieldsData || []).map( field => {
                if (field === 'critical_class') return (
                  <td key={uuidv4()} className="customer-info customer-critical-class">
                    <ContentWithTooltip
                      cid={customer.id}
                      type="name"
                      content={propOr("", field, customer)}
                    />
                  </td>
                )
                if (field === 'taxonomy_label') return (
                  <td key={uuidv4()} className="customer-info customer-taxonomy-label">
                    <ContentWithTooltip
                      cid={customer.id}
                      type="name"
                      content={propOr("", field, customer)}
                    />
                  </td>
                )
                if (field === 'sewer_zone') return (
                  <td key={uuidv4()} className="customer-info customer-sewer-zone">
                    <span>{propOr("", field, customer)}</span>
                  </td>
                )
                if (field === 'water_zone') return (
                  <td key={uuidv4()} className="customer-info customer-water-zone">
                    <span>{propOr("", field, customer)}</span>
                  </td>
                )
                if (field === 'install_number') return (
                  <td key={uuidv4()} className="customer-info customer-account-no">
                    <span>{propOr("", field, customer)}</span>
                  </td>
                )
            })
          }
          </React.Fragment>

        <td className="customer-info customer-contact-name">
          <span>{`${propOr("", "first_name", customer)} ${propOr(
            "",
            "last_name",
            customer
          )}`}</span>
        </td>
        <td className="customer-info customer-contact-phone">
          {
            (tenantConfig.customerFieldsData || []).map( field => {
                if (field === 'phone') return (
                  <span key={uuidv4()}>{propOr("", field, customer)}</span>
                )
                if (field === 'mobile') return (
                  <span key={uuidv4()}>{propOr("", field, customer)}</span>
                )
            })
          }
        </td>
          {
            (tenantConfig.customerFieldsData || []).map( field => {
                if (field === 'email') return (
                  <td key={uuidv4()} className="customer-info customer-contact-email">
                      <ContentWithTooltip
                        cid={customer.id}
                        type="name"
                        content={propOr("", field, customer)}
                      />
                    </td>
                )
                if (field === 'has_sums_data') return (
                  <td key={uuidv4()} className="customer-info customer-realtime-data">
                    {/* {propOr(false, "has_trade_waste_data", customer) && (
                        <i className="fas fa-flask mr-2"></i>
                    )} */}
                    {propOr(false, field, customer) && (
                      <i className="fas fa-check-circle"></i>
                    )}
                  </td>
                )
            })
          }
      </tr>
    </>
  );
};

export default CustomerItem;
