import React, { useState, useEffect, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useTenantConfig } from "src/main_app/actions";
import { track } from "src/main_app/mixpanel";
import { andThen, pathOr, compose, isNil } from "ramda";
import { getPublicDataValidation } from "src/main_app/services/apis";
import * as d3 from 'd3'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TailSpin } from "react-loader-spinner"

const DataValidation = (props) => {
  useEffect(() => {
    track("Setting notification");
  }, []);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false)
  const tenantConfig = useTenantConfig();
  const [iframeUrl, setIframeUrl] = useState("");
  const [iframeHeight, setIframeHeight] = useState(2430);
  const [dataTableValidation, setDataTableValidation] = useState([]);
  const [dataChartValidation, setDataChartValidation] = useState([]);

  const customerLogin = async () => {
    const response = await getPublicDataValidation()
    const dataRes =  response.data
   
    setDataTableValidation(dataRes?.resTable? dataRes?.resTable  : [])
    setDataChartValidation(dataRes?.resGraph? dataRes?.resGraph  : [])
  }

  const yFormatter = (value, index) =>
    (Math.round(value/1000)).toLocaleString() + ' kL'; // L Convert to kL

  const formatDataKL = (value) => {
    return (Math.round(value/1000)).toLocaleString() + ' kL';
  }

  const yLabel = useMemo(
    () => ({
      value: "Water usage difference",
      angle: -90,
      position: "top",
      dy: 170,
      dx: -20,
    }),
    []
  );


const renderTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;
  console.log("payload", payload);
  return (
    <div
      className="custom-line-tooltip"
      style={{
        padding: "10px",
        backgroundColor: "#fff",
        boxShadow: "0 0 8px 8px rgb(0 0 0 / 7%)",
        borderRadius: "4px",
      }}
    >
      <h5 className="mb-2"><strong>{payload[0].payload.customer_name}</strong></h5>
      <p className="mb-0">Consumption difference: <strong>{Math.round((payload[0].payload['Consumption difference']/1000)).toLocaleString()} kL</strong></p>
    </div>
  );
};

  useEffect(() => {
    import("./style.scss");
    setLoading(true)
    setIframeUrl(pathOr("", ["insightsPageTabDataValidation", "iframeUrl"], tenantConfig));
    setIframeHeight(
      pathOr(2430, ["insightsPageTabDataValidation", "iframeHeight"], tenantConfig)
    );
    customerLogin();
  }, [tenantConfig]);

  return (
    <>

    <div className="container-fluid content">
        {/* <iframe
          style={{
            width: "100%",
            height: iframeHeight + "px",
          }}
          scrolling="no"
          frameBorder="0"
          src={iframeUrl}
        /> */}

        {/* Start Data table validation  */}
        <div className="table-responsive">
        <h3 className="sub-title">Water consumption data validation</h3>
          <div className="overflow-auto" style={{maxHeight: "500px"}}>
            <table className="table" id="user-list-table">
                <thead style={{position: "sticky", top: 0}}>
                    <tr>
                        <th>Customer</th>
                        <th>Log month</th>
                        <th>Real-time consumption</th>
                        <th>Metered consumption</th>
                        <th>Consumption difference</th>
                    </tr>
                </thead>
                <tbody>
                {(dataTableValidation || []).map((item, index) => (
                    <tr key={index}>
                        <td>{item.customer_name}</td>
                        <td>{item.log_month}</td>
                        <td>{formatDataKL(item['Real-time consumption'])}</td>
                        <td>{formatDataKL(item['Metered consumption'])}</td>
                        <td>{formatDataKL(item['Consumption difference'])}</td>
                        {/* <td>{d3.format(".2s")(item['Real-time consumption'])}</td>
                        <td>{d3.format(".2s")(item['Metered consumption'])}</td>
                        <td>{d3.format(".2s")(item['Consumption difference'])}</td> */}
                    </tr>
                ))}
                </tbody>
            </table>
          </div>
        </div>
        {/* End Data table validation  */}

        {/* Start Chart data validation  */}
        <br></br>
        <div className="mt-5">
          <h3 className="sub-title">Top 10 customers with largest cumulative water usage difference</h3>
            <div className="card" id="metered-chart">
              <div className="card-body">
                {dataChartValidation.length > 0 && (
                  <ResponsiveContainer width="100%" aspect={10.0 / 3.0}>
                  <BarChart
                    data={dataChartValidation}
                    margin={{
                      top: 20,
                      right: 5,
                      left: -15,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid stroke="#e9ebf1" />
                    <XAxis dataKey="customer_name" stroke="#e9ebf1" />
                    <YAxis
                      dataKey={"Consumption difference"}
                      stroke="#e9ebf1"
                      tickFormatter={yFormatter}
                      width={110}
                      label={yLabel}
                    />
                    <Tooltip 
                     content={renderTooltip}
                    />
                    <Bar
                      dataKey={"Consumption difference"}
                      fill="#09A3B3"
                      radius={[5, 5, 0, 0]}
                      barSize={24}
                      // unit="ML"
                    />
                  </BarChart>
                </ResponsiveContainer>
                )}
                
              </div>
            </div>
        </div>
        {/* End Chart data validation  */}
      </div>
    </>
  );
};

export default DataValidation;
