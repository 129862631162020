import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet } from "react-helmet"

import LoadingComponent from '../base/components/loading'
import ScrollToTop from '../base/components/scroll-to-top'

import { getRoutes } from './routes'
import { ToastProvider } from 'react-toast-notifications'

const NotFoundPage = lazy(() => import('./scenes/not-found'))

const App = () => {
  const routes = getRoutes()

  return (
    <ToastProvider>
      <Helmet>
        <title>Aquo</title>
      </Helmet>
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={<LoadingComponent />}
          >
            <Switch>
              {routes.map(({ layout: Layout, ...otherParams }, i) => (
                <Layout
                  {...otherParams}
                  key={i + ''}
                />
              ))}
              {/*<Route component={NotFoundPage} />*/}
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Router>
    </ToastProvider>
  )
}

export default App