import {
  compose,
  keys,
  map,
  propOr,
  pathOr,
  isNil,
  hasPath,
  reject,
} from "ramda";
import React, {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import { useTenantConfig } from "src/main_app/actions/tenant";
import { isNilOrEmpty, addDays } from "src/main_app/utils/common";
import { getUsersListDropDown } from "src/main_app/services/apis";
import Select from '@bhunjadi/react-select-v3';


import './alerts-filter.style.scss'

const AllValue = "-All-";
const filterWithDefault = {
  type: false,
  dateRange: false,
  status: false,
  author: false,
};

const ImportFilter = ({
    onFiltersApply,
    disabled
  },
  ref,
) => {
  const tenantConfig = useTenantConfig();
  const toggleRef = useRef(null);
  const maxDateRef = useRef();
  const [filterWith, setFilterWith] = useState(filterWithDefault);
  const [filterData, setFilterData] = useState({});

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterCount, setFilterCount] = useState(0);
  const [listUser, setListUser] = useState([]);

  const getFilteredData = (filteredWith) => {
    const filteredData = {};
    filteredWith = filteredWith ?? filterWith;
    keys(filteredWith).forEach((value) => {
      if (
        filteredWith[value] &&
        filterData[value] &&
        filterData[value] !== AllValue
      ) {
        filteredData[value] = filterData[value];
      }
    });
    if (filteredWith.dateRange) {
      filteredData.dateRange = {
        startDate,
        endDate,
      };
    }
    return filteredData;
  };
 
  // const getListUser = async () => {
  //   const response = await getUsersListDropDown();
  //   setListUser(response);
  // }
  
  const toggleClick = () => {
    toggleRef.current && toggleRef.current.click();
  };

  const countFilter = (filterData) => {
    if (!filterData) return 0;
    return reject(isNil, Object.values(filterData)).length;
  };

  const onApply = () => {
    const filteredData = getFilteredData();
    setFilterCount(countFilter(filteredData));
    onFiltersApply(filteredData);
  };

  const onApplyClick = (event) => {
    onApply();
    setTimeout(toggleClick, 200);
  };

  const filtersClear = (event) => {
    toggleClick();
    setFilterData({});
    setFilterWith(filterWithDefault);
    setTimeout(() => {
      const filteredData = getFilteredData(filterWithDefault);
      onFiltersApply(filteredData);
      setFilterCount(countFilter(filteredData));
    }, 200);
  };

  useEffect(() => {
    getUsersListDropDown().then(({ data: res }) => {
      const dataUser = res.map(item => ({
        value: item.id,
        label: item.first_name + ' ' + item.last_name
      }));
      dataUser.unshift({value: "-All-", label: "All"}, {value: "system", label: "System"})
      setListUser(dataUser);
    });
  }, []);

  return (
    <div className="filter-button filter alert--filter-button">
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="filter-button__btn p-0"
          ref={toggleRef}
        >
          <div className="btn-group ">
            <span className="btn btn-default">
              <i className="far fa-filter mr-2" /> Filter{" "}
            </span>
            {filterCount > 0 && (
              <span className="btn btn-default filter-count">{filterCount}</span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="filter__header block-center-between">
            <button
              className="filter__clear btn btn-default"
              onClick={filtersClear}
            >
              Clear
            </button>
            <span className="filter__label ">Filters</span>
            <button
              className="filter__apply btn btn-default"
              onClick={onApplyClick}
            >
              Apply
            </button>
          </div>
          <div className="filter__body">
            <div className="filter__options f-14">
              {/* Start filter date */}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.dateRange}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        dateRange: !filterWith.dateRange,
                      })
                    }
                  />
                  <span className="ck" />
                  Date range
                </label>
              </div>
              {filterWith.dateRange && (
                <div className="filter__footer">
                  <>
                    <div>from: </div>
                    <div className="month-range-picker start-date">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                    <div>to: </div>
                    <div className="month-range-picker end-date">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={maxDateRef.current}
                        dateFormat="dd MMM yyyy"
                      />
                    </div>
                  </>
                </div>
              )}
              {/* End filter date */}

              {/* Start filter date */}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.type}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        type: !filterWith.type,
                      })
                    }
                  />
                  <span className="ck" />
                  Import types
                </label>
              </div>
              {filterWith.type && (
                <div className="filter__footer">
                  <Select
                      defaultValue={{ value: '-All-', label: 'All' }}
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          type: e.value,
                        })
                      }
                      options={tenantConfig.importDashboard.filterType}
                  />
                </div>
              )}
              {/* End filter date */}

              {/* Start filter Status */}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.status}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        status: !filterWith.status,
                      })
                    }
                  />
                  <span className="ck" />
                  Status
                </label>
              </div>
              {filterWith.status && (
                <div className="filter__footer">
                  <Select
                      defaultValue={{ value: '-All-', label: 'All' }}
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          status: e.value,
                        })
                      }
                      options={tenantConfig.importDashboard.filterStatus}
                  />
                </div>
              )}
              {/* End filter Status */}

              {/* Start filter author */}
              <div className="filter__option">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className=""
                    name="option-filter"
                    checked={filterWith.author}
                    onChange={(e) =>
                      setFilterWith({
                        ...filterWith,
                        author: !filterWith.author,
                      })
                    }
                  />
                  <span className="ck" />
                  Author
                </label>
              </div>
              {filterWith.author && (

                <div className="filter__footer">
                  <Select
                      defaultValue={{ value: '-All-', label: 'All' }}
                      onChange={(e) =>
                        setFilterData({
                          ...filterData,
                          author: e.value,
                        })
                      }
                      options={listUser}
                  />
                </div>
              )}
              {/* End filter author */}


            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default forwardRef(ImportFilter);
