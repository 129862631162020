import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { useToasts } from 'react-toast-notifications'

import { track } from "src/main_app/mixpanel";
import { getApiUrl } from 'src/main_app/utils/config-utility';
import { authService } from 'src/base/services';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import DatePicker from "react-datepicker";
import { getCustomersTimelineNoteTypes } from 'src/main_app/services/apis';
import Select from '@bhunjadi/react-select-v3';
import AsyncSelect from '@bhunjadi/react-select-v3/async';
import { propOr, map } from 'ramda';
import { getCustomersListData } from 'src/main_app/utils/customer_utils';

const ExportNotes = ({
  showModal,
  handleExportClose,
}) => {
  if (!showModal) return null
  
  useEffect(() => {
    track("Export notes");
  }, [])

  const { addToast } = useToasts();
  const [noteType, setNoteType] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isEmptyFromDate, setIsEmptyFromDate] = useState(false);
  const [isEmptyToDate, setIsEmptyTodDate] = useState(false);

  const [noteTypes, setNoteTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerValueMulti, setCustomerValueMulti] = useState([]);

  const exportHandler = async (event) => {
    const fromDateParam = moment(fromDate).format('YYYY-MM-DD')
    const toDateParam = moment(toDate).format('YYYY-MM-DD')

    // check fromDate valid
    const isFromDateValid = moment(fromDate).isValid() // boolean
    const isToDateValid = moment(toDate).isValid()
    // check fromDate is before toDate
    const isFromDateBeforeToDate = moment(fromDate).isBefore(moment(toDate)) // boolean

    if (!isFromDateValid) {
      addToast("From date is required", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyFromDate(true);
      return;
    }

    if (!isToDateValid) {
      addToast("To date is required", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyTodDate(true);
      return;
    }
    
    if (isFromDateValid && isToDateValid && isFromDateBeforeToDate) {
        // download
        axios.get(`${getApiUrl()}/export-notes/csv?&token=${authService.getAccessToken()}&from=${fromDateParam}&to=${toDateParam}&note_type=${noteType}&customers=${customerValueMulti}`, {
            responseType: 'blob'
        }).then(
            res => saveAs(res.data, `Customer_notes_${fromDateParam}_${toDateParam}.csv`)
        )
        handleExportClose();
        // resetStates();
    } else {
      addToast("From date must be before the to date", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  const handleMultiChange = (option) => {
    if (option != null) {
      const listCustomer = option.map(item => (item.value));
      setCustomerValueMulti(listCustomer)
    } else {
      setCustomerValueMulti([])
    }
  }

  const loadData = async () => {
    const response = await getCustomersTimelineNoteTypes();
    const listNoteType = response.map(item => ({
      value: item.id,
      label: item.label
    }));
    listNoteType.unshift({value: "", label: "All"})
    setNoteTypes(listNoteType);

    ///
    const data = await getCustomersListData(1, 20, undefined, undefined, {"search[name]": 'A'})
    const arr_customer = propOr([], "data", data);
    const listCustomer = arr_customer.map(item => ({
      value: item.id,
      label: item.account_number + ' - ' + item.name
    }));
    setCustomers(listCustomer)
  }

  const promiseOptions = async (inputValue) => {
    const data = await getCustomersListData(1, 20, undefined, undefined, {"search[name]": inputValue})
    const arr_customer = propOr([], "data", data);
    const listCustomer = arr_customer.map(item => ({
      value: item.id,
      label: item.account_number + ' - ' + item.name
    }));
    return listCustomer;
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <Modal show={showModal} onHide={handleExportClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Export notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form id="customer-add-link-form" className="">
        <div className='form-group'>
          <label>Notes type</label>
          <Select
              defaultValue={{ value: '', label: 'All' }}
              onChange={(e) =>
                setNoteType(e.value)
              }
              options={noteTypes}
              maxMenuHeight={300}
              maxOptions={5}
          />
          <label className='pt-3'>Customers</label>
          <AsyncSelect placeholder={<div>Empty to all customers or enter characters for search customers</div>}
            isMulti
            cacheOptions
            defaultOptions={customers}
            loadOptions={promiseOptions}
            onChange={handleMultiChange}
          />
        </div>
          <div className="row">
            <div className="col-md-6">
                <div className={`form-group ${isEmptyFromDate ? "has-error" : ""}`}>
                    <label>From date</label>
                    <DatePicker selected={fromDate} dateFormat="dd MMM yyyy" onChange={(date) => setFromDate(date)} />
                </div>
            </div>
            <div className="col-md-6">
                <div className={`form-group ${isEmptyToDate ? "has-error" : ""}`}>
                    <label>To date</label>
                    <DatePicker selected={toDate} dateFormat="dd MMM yyyy" onChange={(date) => setToDate(date)} />
                </div>
            </div>
          </div>
          <Button variant="primary" onClick={exportHandler}>
            GENERATE REPORT
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ExportNotes;

