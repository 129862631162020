import React from 'react'

const Footer = props => {
  
  return (
    <footer className="footer">
    </footer>
  )
}

export default Footer
