import React, {
    useMemo,
    useState,
} from 'react'
import { EditNote } from 'src/main_app/components/customer-right-menu/modals'

const NoteItem = ({
    id,
    label,
    itemData,
    customerId,
  }) => {
    const [showModal, setShowModal] = useState(false)

    const style = useMemo(() => ({
        cursor: 'pointer'
    }), [])

    const refreshWhenUpdateAction = () => {}

    return (
        <>
            <div className="timeline__text"
                style={style}
                dangerouslySetInnerHTML={{ __html: itemData.content }}
                onClick={() => setShowModal(true)}
            >
            </div>
            <EditNote
                id={id}
                label={label}
                itemData={itemData}
                customerId={customerId}
                showModal={showModal}
                handleActionsClose={() => setShowModal(false)}
                afterSuccess={refreshWhenUpdateAction}
            />
        </>
    )
}

export default NoteItem