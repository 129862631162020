import postWithToken from './base/post-with-token'

const userUpdate = (id, {
    first_name,
    last_name,
    email,
    roles,
}) => postWithToken(`/user/${id}`)({
    first_name,
    last_name,
    email,
    roles,
})

export default userUpdate