import { getLinksData } from 'src/main_app/utils/customer_utils'
import { FETCH_CUSTOMER_LINKS } from '../constants'

const fetchCustomerLinkSuccess = (dispatch, getState) => (data, queryOptions) =>
  dispatch({
    type: FETCH_CUSTOMER_LINKS,
    data,
    queryOptions,
  })

const fetchCustomerLinkAsync =
  (id, currentPage = 1, limit = 20) =>
  (dispatch, getState) =>
    getLinksData(id, currentPage, limit).then((data) =>
      fetchCustomerLinkSuccess(dispatch, getState)(data, {
        id,
        currentPage,
        limit,
      }),
    )

export default fetchCustomerLinkAsync
