import React from 'react'

const CustomerExemption = ({
  goToTimeline,
  hasExemption = false,
}) => {
  return hasExemption
    ? (
      <>
        <h3 className="sub-title">Exemptions</h3>
        <div className="box">
          <div>Exemptions apply for this customer.</div>
          <a className="underline color-inherit" onClick={goToTimeline}>Click
            here to find
            out more about them.</a>
        </div>
      </>
    )
    : null
}

export default CustomerExemption
