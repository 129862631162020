import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import { lastAction } from "./reducers";

import {
  customerLinkReducer,
  customerActionReducer,
  customerTimelineReducer,
  userRoleReducer,
  tenantReducer,
  userPermissionReducer,
} from "./actions";

const rootReducer = combineReducers({
  customerLinkReducer,
  customerActionReducer,
  customerTimelineReducer,
  userRoleReducer,
  tenantReducer,
  userPermissionReducer,
  lastAction,
});

const store = createStore(rootReducer, applyMiddleware(thunk, logger));

export default store;
