import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { userAuthMicrosoft } from "../../../../services/apis";
import { SignInContext } from "../../../../scenes/sign-in";

const SignInMicrosoft = (props) => {
  const signInContext = useContext(SignInContext);

  const handleLogin = async () => {
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: props.loginConfig.clientId,
        authority: props.loginConfig.authorityUrl,
        redirectUri: window.location.protocol + "//" + window.location.host,
      },
    });

    signInContext.setLoading(true);

    let authInfo;

    try {
      authInfo = await msalInstance.loginPopup({
        scopes: ["User.Read"],
      });
    } catch (e) {
      signInContext.setLoading(false);
      return;
    }

    userAuthMicrosoft(authInfo.accessToken, props.tenantConfig.slug)
      .then((r) => {
        signInContext.setAfterLogin({ status: "ok", data: r.data });
      })
      .catch((e) => {
        signInContext.setAfterLogin({
          status: "failed",
          message: e.response.data.error.message,
        });
      })
      .finally(() => signInContext.setLoading(false));
  };

  return (
    <button
      className="btn btn-default"
      onClick={() => handleLogin()}
      style={{ width: "100%", position: "relative" }}
    >
      <svg
        fill="#333333"
        data-icon="login-microsoft"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        color="#333333"
        role="img"
        style={{ position: "absolute", left: "2rem" }}
      >
        login-microsoft<path d="M7.6 0H0V7.6H7.6V0Z" fill="#F25022"></path>
        <path d="M16 0H8.40002V7.6H16V0Z" fill="#7FBA00"></path>
        <path d="M7.6 8.40002H0V16H7.6V8.40002Z" fill="#00A4EF"></path>
        <path d="M16 8.40002H8.40002V16H16V8.40002Z" fill="#FFB900"></path>
      </svg>
      CONTINUE WITH MICROSOFT
    </button>
  );
};

export default SignInMicrosoft;
