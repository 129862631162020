import {
  FETCH_TENANT_CONFIG,
  FETCH_CUSTOMER_TAXONOMIES_BY_PARENT_NAME,
  FETCH_SUB_TENANTS,
} from "../constants";

const tenantReducer = (
  state = {
    config: {},
    customerTaxonomies: {},
    subTenants: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_TENANT_CONFIG:
      return {
        ...state,
        config: action.data,
      };
    case FETCH_CUSTOMER_TAXONOMIES_BY_PARENT_NAME:
      return {
        ...state,
        customerTaxonomies: {
          ...state.customerTaxonomies,
          ...action.data,
        },
      };
    case FETCH_SUB_TENANTS:
      return {
        ...state,
        subTenants: action.data,
      };
    default:
      return state;
  }
};

export default tenantReducer;
