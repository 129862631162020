import React, {
  useState,
  useEffect,
  useMemo,
} from 'react'
import {
  useHistory,
  useParams
} from 'react-router-dom'
import { andThen, compose, pathOr, propOr } from 'ramda'
import {
  getTaxonomyDetails
} from '../../utils/customer_utils'

import { saveCustomerFiltersToStorage } from '../../services/local_storage/customer-filters-storage'

import IndustrySection from './industry-section'
import BenchmarkSection from './benchmark-section'
import UsageSection from './usage-section'

import { track } from "src/main_app/mixpanel"
import { checkPermission, useUserPermissions } from 'src/main_app/actions'
import BenchmarkSewerSection from './benchmark-sewer-section'

const IndustryDetailPage = (props) => {
  useEffect(() => {
    track('Industry detail page')
  }, [])
  const params = useParams()
  const history = useHistory()
  const [taxonomyData, setTaxonomyData] = useState({
    "taxonomy_info": {},
    "sections": [
      {
        "sector_label": "Industry overview",
        "fields": [
          {
            "label": "Number of customers in sector",
            "data": '',
            "layout_column": 3
          },
          {
            "label": "Percentage of total water usage",
            "data": '',
            "layout_column": 3
          },
          {
            "label": "Average Daily Usage",
            "data": '',
            "layout_column": 3
          },
          {
            "label": "Usage change in 7 days",
            "data": '',
            "layout_column": 3
          },
          {
            "label": "Highest customer usage in 7 days",
            "data": '',
            "layout_column": 3
          },
          {
            "label": "Lowest customer usage in 7 days",
            "data": '',
            "layout_column": 3
          }
        ]
      }
    ]
  })

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "industry-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  const loadData = () => compose(
    andThen(setTaxonomyData),
    getTaxonomyDetails
  )(params.id)

  const gotoCustomerPage = (event) => {
    event.preventDefault()
    saveCustomerFiltersToStorage({ industry: params.id })
    setTimeout(() => history.push('/customers'), 200)
  }

  const gotoAlertPage = (event) => {
    event.preventDefault()
    saveCustomerFiltersToStorage({ industry: params.id })
    setTimeout(() => history.push('/alerts'), 200)
  }

  useEffect(() => {
    import('./style.scss')
    loadData()
  }, [])

  return (userPermissions.length === 0 || !canViewThisPage) ? null : (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid ">
          <div className="block-center-between">
            <h2 className="title mb-0">
              {
                pathOr(' ', ['taxonomy_info', 'taxonomy_label'], taxonomyData)
              }
            </h2>
            <div className="right-action-menu">
              <div className="customer-nav-right block-center">
                <button className="btn-text " onClick={gotoCustomerPage} ><i className="far fa-eye mr-2" />View customers</button>
                <button className="btn-text  ml-3" onClick={gotoAlertPage} ><i className="far fa-bell mr-2" />View alerts</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid content">
        <form>
          {
            propOr([], 'sections', taxonomyData)
              .map((section, sectionIndex) => (
                <IndustrySection key={sectionIndex} section={section} />
              ))
          }
          <BenchmarkSection
            taxonomyID={params.id}
            taxonomyData={taxonomyData}
          />
          <BenchmarkSewerSection
            taxonomyID={params.id}
            taxonomyData={taxonomyData}
          />
          <UsageSection
            taxonomyID={params.id}
          />
        </form>
      </div>
    </div>
  )
}

export default IndustryDetailPage
