import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { DateRangePicker } from "src/main_app/components/date_range_picker";
import { useToasts } from 'react-toast-notifications'

import { track } from "src/main_app/mixpanel";
import { getApiUrl } from 'src/main_app/utils/config-utility';
import { authService } from 'src/base/services';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import DatePicker from "react-datepicker";

const ExportWaterUsageByZone = ({
  showModal,
  handleActionsClose,
}) => {
  if (!showModal) return null
  
  useEffect(() => {
    track("Generate report - Water usage by zone");
  }, [])

  const { addToast } = useToasts();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isEmptyFromDate, setIsEmptyFromDate] = useState(false);
  const [isEmptyToDate, setIsEmptyTodDate] = useState(false);
  const resetStates = () => {
    setFromDate(null)
    setToDate(null);
    setIsEmptyTodDate(false);
    setIsEmptyFromDate(false);
  };

  const waterExportHandler = async (event) => {
    const fromDateParam = moment(fromDate).format('YYYY-MM-DD')
    const toDateParam = moment(toDate).format('YYYY-MM-DD')

    // check fromDate valid
    const isFromDateValid = moment(fromDate).isValid() // boolean
    const isToDateValid = moment(toDate).isValid()
    // check fromDate is before toDate
    const isFromDateBeforeToDate = moment(fromDate).isBefore(moment(toDate)) // boolean

    if (!isFromDateValid) {
      addToast("Generate Report item field must not be empty", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyFromDate(true);
      return;
    }

    if (!isToDateValid) {
      addToast("Generate Report item field must not be empty", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setIsEmptyTodDate(true);
      return;
    }
    
    if (isFromDateValid && isToDateValid && isFromDateBeforeToDate) {
        // download
        axios.get(`${getApiUrl()}/export/report-water-usage-by-zone/csv?&token=${authService.getAccessToken()}&from=${fromDateParam}&to=${toDateParam}`, {
            responseType: 'blob'
        }).then(
            res => saveAs(res.data, `Water usage by zone ${fromDateParam} to ${toDateParam}.csv`)
        )
        handleActionsClose();
        resetStates();
    } else {
      addToast("From date must be before the to date", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Generate report - Water usage by zone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form id="customer-add-link-form" className="">
          <div className="row">
            <div className="col-md-6">
                <div className={`form-group ${isEmptyFromDate ? "has-error" : ""}`}>
                    <label>From date</label>
                    <DatePicker selected={fromDate} dateFormat="dd MMM yyyy" onChange={(date) => setFromDate(date)} />
                </div>
            </div>
            <div className="col-md-6">
                <div className={`form-group ${isEmptyToDate ? "has-error" : ""}`}>
                    <label>To date</label>
                    <DatePicker selected={toDate} dateFormat="dd MMM yyyy" onChange={(date) => setToDate(date)} />
                </div>
            </div>
          </div>
          <Button variant="primary" onClick={waterExportHandler}>
            GENERATE REPORT
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ExportWaterUsageByZone;

