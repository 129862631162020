import React, { useEffect, useState, useMemo } from "react";
import { useSubTenants } from "src/main_app/actions";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
import { Select1 } from "src/main_app/components/form_elements";

import withRedux from "./with-redux";
import { getTargetValue } from "../../utils/react_utils";
import { identity, isEmpty } from "ramda";

const UserRoles = ({
  currentRoles,
  onChange,
  userRoles,
  getUserRolesAsync,
  fetchSubTenants,
}) => {
  const [tenantInfo, setTenantInfo] = useState({});
  const subTenants = useSubTenants();

  const filteredTenants = useMemo(() => {
    const isParentTenant = subTenants.some(
      (item) => item.id == tenantInfo.id && !item.parent_id
    );
    if (isParentTenant) return subTenants;
    return subTenants.filter((item) => item.hasPermission);
  }, [subTenants, tenantInfo]);

  useEffect(() => {
    getUserRolesAsync();
    fetchSubTenants();
    setTenantInfo(getTenantInfoFromLocal());
  }, []);

  useEffect(() => {
    if (!isEmpty(currentRoles) || isEmpty(tenantInfo) || isEmpty(userRoles))
      return;
    onChange &&
      onChange([
        {
          tenant_id: tenantInfo.id,
          tenant_name: tenantInfo.name,
          role_id: userRoles[0].id,
          role_name: userRoles[0].name,
        },
      ]);
  }, [currentRoles, userRoles, tenantInfo]);

  const onRoleChange = (index, currentRole) => {
    currentRoles[index] = currentRole;
    onChange && onChange(currentRoles.filter(identity));
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <h3>Roles</h3>
          <hr />
        </div>
      </div>
      {currentRoles &&
        currentRoles.map((currentRole, index) => (
          <div key={index} className="row">
            <div className="col-md-6">
              <Select1
                controlId="role_id"
                name="role_id"
                label="Project / Tenant"
                items={filteredTenants}
                value={currentRole.tenant_id}
                title={currentRole.tenant_name}
                hasEmptyOption={true}
                onChange={(event) =>
                  onRoleChange(index, {
                    ...currentRole,
                    // tenant_id: getTargetValue(event) ?? 0,
                    tenant_id: event.id ?? 0,
                    tenant_name: event.name ?? '',
                  })
                }
                itemId="id"
                itemDisplay="name"
              />
            </div>
            <div className="col-md-6 d-flex flex-column align-items-end">
              <Select1
                className="mb-0 w-100"
                controlId="role_id"
                name="role_id"
                label="Role"
                items={userRoles}
                value={currentRole.role_id}
                title={currentRole.role_name}
                hasEmptyOption={true}
                onChange={(event) =>
                  onRoleChange(index, {
                    ...currentRole,
                    // role_id: getTargetValue(event) ?? 0,
                    role_id:  event.id ?? 0,
                    role_name: event.name
                  })
                }
                itemId="id"
                itemDisplay="name"
              />
              {index <= currentRoles.length - 1 && currentRoles.length > 1 && (
                <button
                  type="button"
                  className="btn btn-link text-danger"
                  onClick={() => onRoleChange(index, undefined)}
                >
                  Remove
                </button>
              )}
              {index === currentRoles.length - 1 &&
                currentRoles.length < filteredTenants.length && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() =>
                      onRoleChange(currentRoles.length, {
                        tenant_id: 0,
                        role_id: 0,
                      })
                    }
                  >
                    Add another role
                  </button>
                )}
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};

export default withRedux(UserRoles);
