import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { addLink } from "../../../../utils/customer_utils";
import { empty, isEmpty } from "ramda";
import { setTargetValue } from "src/main_app/utils/react_utils";

import { track } from "src/main_app/mixpanel";
import Dropzone from 'react-dropzone';
import { getApiUrl } from 'src/main_app/utils/config-utility';
import { authService } from 'src/base/services';
import axios from 'axios';
import { isServerResponseError } from 'src/main_app/utils/common';
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const AddLink = ({
  showModal,
  handleActionsClose,
  fetchCustomerLinks,
  customer_id = null,
}) => {
  if (!showModal) return null
  
  useEffect(() => {
    track("Add action modal", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [])

  const tenantInfo = useRef(getTenantInfoFromLocal());
  const params = useParams();
  const id = customer_id ?? params.id;
  if (!id) return null;

  const { addToast } = useToasts();
  const [title, setTitle] = useState("");
  const [urlData, setUrl] = useState("");
  const [pinToTop, setPinToTop] =  useState(false);
  const [optionUrl, setOptionUrl] = useState({type: false});
  const [fileContent, setFileContent] = useState(null)
  const [fileName, setFileName] = useState(null);

  const resetStates = () => {
    setTitle("");
    setUrl("");
  };

  const linkSubmitHandler = async () => {
    if (isEmpty(title)) {
      addToast("Link title must not be empty", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }
    
    let url = urlData;

    if (!optionUrl.type) {
      setFileContent(null);
    } else if(fileContent != null) {
        url = fileContent['name']
        if (fileContent.type !== 'application/pdf') {
          addToast("Only pdf file will be accepted", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          return;
        }
    }

    const formData = new FormData();
    formData.append('display_text',title);
    formData.append('url', url);
    formData.append('pinToTop', pinToTop);
    formData.append('type', optionUrl.type);
    formData.append('file',fileContent);
  
    const link = `${getApiUrl()}/customer/link?id=${id}&display_text=${title}&url=${url}&pinToTop=${pinToTop}&type=${optionUrl.type}`;

    axios({
      method: "post",
      url: link,
      data: formData,
      headers: { 
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${authService.getAccessToken()}`
      },
    })
      .then(function (response) {
        //handle success
      
        addToast(
          response.status == 200 ? "Link added successfully" : "Adding link failed",
          {
            appearance: response.status == 200  ? "success" : "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          }
        );
        if (response.status == 200  && fetchCustomerLinks) {
          fetchCustomerLinks(id);
        }
        resetStates();
        handleActionsClose();
      })
      .catch(function (response) {
        //handle error
        addToast("Adding link failed", {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      });
    
  };

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Add resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-link-form" className="">
          <div className="form-group">
            <label>Title</label>
            <input
              className="form-control"
              value={title}
              onChange={setTargetValue(setTitle)}
            />
          </div>

          <div className="filter__option">
            <label className="checkbox">
                  <input type="checkbox" name="option-filter" checked={optionUrl.type}
                    onChange={(e) =>
                      // setOptionUrl(optionUrl.type)
                      setOptionUrl({
                        ...optionUrl,
                        type: !optionUrl.type,
                      })
                    }
                  />
                  <span className="ck" />
                  File
            </label>
          </div>
          {!optionUrl.type && (
            <div className="form-group">
              <label>URL</label>
              <input
                className="form-control"
                value={urlData}
                onChange={setTargetValue(setUrl)}
              />
            </div>
          )}

          {optionUrl.type && (
            <div className="form-group">
            <label>Upload File</label>
            <Dropzone 
              onDrop={ (file) => {
                    setFileContent(file[0]), 
                    setFileName(file[0]['name'])
              } }
            >
              {({getRootProps, getInputProps}) => (
                <section className="container" style={{padding: "0"}}>
                <div {...getRootProps({className: 'dropzone'})} style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  borderWidth: "2px",
                  borderRadius: "2px",
                  borderColor: "#eeeeee",
                  borderStyle: "dashed",
                  backgroundColor: "#fafafa",
                  color: "#bdbdbd",
                  outline: "none",
                }}>
                  <input {...getInputProps()} />
                  <p>Click here to upload file</p>
                  <p>{fileName}</p>
                </div>
              </section>
              )}
            </Dropzone>
            </div>
          )}
          <div className="form-check mb-5">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxPinToTop"
              checked={pinToTop}
              onChange={() => setPinToTop(!pinToTop)}
            />
            <label className="form-check-label ml-3" for="checkboxPinToTop">
              Pin to top
            </label>
          </div>
          <Button variant="primary" onClick={linkSubmitHandler}>
            ADD RESOURCE
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddLink;
