import { hasPath, path, when, isEmpty, symmetricDifference, tap, propOr } from "ramda";
import React, { useEffect, useState } from "react";

import Search from "../search";
import Account from "../acount-dropdown/account-dropdown";
import Profile from "../profile/profile";
import Notifications from "../notifications/notifications";
import SecondaryNav from "../secondary_nav/secondary-nav";
import { authService } from "../../../base/services";

import compose from "ramda/src/compose";
import {
  getMenuFromLocal,
  saveMenuToLocal,
} from "../../services/local_storage/tenant-info";
import {
  useTenantConfig,
  useUserPermissions,
  checkPermission,
} from "src/main_app/actions";
import { useMemo } from "react";
import { checkSettingUrlFeedBack, getRoleName } from "src/main_app/services/apis";

const pathToSubMenus = ["subMenus"];

const Header = () => {
  const tenantConfig = useTenantConfig();
  const userPermissions = useUserPermissions();
  const [roleName, setRoleName] =  useState();
  const [urlFeedBack, setUrlFeedBack] =  useState();

  const [subMenus, setSubMenus] = useState(
    getMenuFromLocal() ? getMenuFromLocal() : []
  );
  const subMenusWithPermissions = useMemo(() => {
    let results = subMenus;
    if (!checkPermission(userPermissions, "customers-page", "canView")) {
      results = results.filter((item) => item.to !== "/customers");
    }
    if (!checkPermission(userPermissions, "alerts-page", "canView")) {
      results = results.filter((item) => item.to !== "/alerts");
    }
    if (!checkPermission(userPermissions, "industry-page", "canView")) {
      results = results.filter((item) => item.to !== "/industry");
    }
    if (!checkPermission(userPermissions, "map-page", "canView")) {
      results = results.filter((item) => item.to !== "/map");
    }
    if (!checkPermission(userPermissions, "insights-page", "canView")) {
      if (tenantConfig.slug == "hunterwater") {
        results = results.filter((item) => item.to !== "/insights/data-validation");
        // results = results.filter((item) => item.to !== "/insights/real-time-water-usage");
      } else {
        results = results.filter((item) => item.to !== "/insights");
      }
    }
    if (!checkPermission(userPermissions, "report-page", "canView")) {
      results = results.filter((item) => item.to !== "/report");
    }
    return results;
  }, [subMenus, userPermissions]);
  const user = authService.getUser();

  const eqValues = compose(isEmpty, symmetricDifference);

  const updateSubMenus = (newSubMenus) => {
    if (!eqValues(subMenus, newSubMenus)) {
      setSubMenus(newSubMenus);
    }
  };

  const checkAndSetSubMenus = when(
    hasPath(pathToSubMenus),
    compose(saveMenuToLocal, path(pathToSubMenus))
  );

  const getUrlFeedBack = async () => {
    const roleNameUserLogin = await getRoleName();
    const response = await checkSettingUrlFeedBack(propOr([], 'data', roleNameUserLogin))
    setUrlFeedBack(propOr([], 'data', response));
    setRoleName(propOr([], 'data', roleNameUserLogin))
  }

  useEffect(() => {
    getUrlFeedBack();
  }, []);

  useEffect(() => {
    checkAndSetSubMenus(tenantConfig);
    updateSubMenus(getMenuFromLocal());
  }, [tenantConfig, checkAndSetSubMenus]);

  return (
    <header className="app__header">
      <nav className="main-nav">
          <div className="container-fluid">
            <div className="block-center-between main-nav__row">
              <div className="main-nav__left block-center-center">
                <a href="/" className="logo">
                  <img src="assets/images/logo.svg" width="20" />
                </a>
                <Account />
              </div>
              <div className="main-nav__right block-center-between">
                {roleName != "Customer" ?
                  <Search />
                : ''} 
                {authService.isAuthenticated() ? (
                  <Profile user={user} />
                ) : (
                  <a className="sign-in-link" href="/sign-in">
                    Sign in
                  </a>
                )}

                <Notifications />

              </div>
            </div>
          </div>
      </nav>

      <SecondaryNav
          items={subMenusWithPermissions}
          tenantConfig={tenantConfig}
          roleName={roleName}
          urlFeedBack={urlFeedBack}
        />
      {/* {user.role != "Customer" ? 
        <SecondaryNav
          items={subMenusWithPermissions}
          tenantConfig={tenantConfig}
          roleName={roleName}
          urlFeedBack={urlFeedBack}
        />
      : ''} */}

    </header>
  );
};

export default Header;
