import React, { useMemo, useState, useEffect } from "react";
import { isNil, propOr } from "ramda";
import { useHistory } from "react-router";
import { Route, Redirect } from 'react-router-dom'
import { Dropdown } from "react-bootstrap";

import { authService } from "../../../base/services";
import { checkPermission, useUserPermissions } from "src/main_app/actions";
import { getNotificationsRead, getNotificationsUnRead, notificationsUpdate, getRoleName } from "src/main_app/services/apis";
import { formatDateForNotifications } from "src/main_app/utils/common";
import { Link } from "react-router-dom";
import ContentWithTooltip from "src/main_app/scenes/customers_page/content-with-tooltip";

// const Notifications = (active, unActive, handleActive) => {
const Notifications = (props) => {
  const history = useHistory();
  const userPermissions = useUserPermissions();
  const [active, setActive] = useState("nav-link active");
  const [unActive, setUnactive] = useState("nav-link");
  const [is_active, setIsActive] =  useState(false);
  const [dataRead, setDataRead] = useState([]);
  const [dataUnRead, setDataUnRead] = useState([]);
  const [roleName, setRoleName] =  useState();

  const canViewUserPage = useMemo(
    () => checkPermission(userPermissions, "users-page", "canView"),
    [userPermissions]
  );

  const handleActive = () => {
    setActive('nav-link active');
    setUnactive('nav-link');
    setIsActive(false);
  }
  const handleUnActive = () => {
    setActive('nav-link');
    setUnactive('nav-link active');
    setIsActive(true);
  }

  const loadData = async () => {
    const response = await getNotificationsRead();
    const res = await getNotificationsUnRead();
    const roleNameUserLogin = await getRoleName();
    setDataRead(propOr([], "data", response));
    setDataUnRead(propOr([], "data", res));
    setRoleName(propOr([], 'data', roleNameUserLogin))
    console.log('roleName customer login', propOr([], 'data', roleNameUserLogin))
  };

  const updateUnRead = async (e, idUpdate, idLinkTo, type) => {
    document.location.href = `/alert-import/${idLinkTo}`
    // if (type == 'un-read') {
    //   const response = await notificationsUpdate(idUpdate);
    //   loadData();
    //   document.location.href = `/alert-import/${idLinkTo}`
    // } else {
    //   document.location.href = `/alert-import/${idLinkTo}`
    // }
  }

  useEffect(() => {
    loadData();
  },[]);

  const renderData = (data, status) => {
    return (
    <>
    {data.map((item, index) => (
      <div className="bell-notification" key={index}>
        <div className="notifications-list-item__avatar"
          style={{
            height: "40px",
            marginRight: "16px",
            position: "relative",
            width: "40px",
          }}
        >
          <img src="/assets/tv-app/aquo-logo.png"></img>
        </div>
        <div
          className="notifications-list-item__text"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            minWidth: "283px",
          }}
        >
          <p>Source: {item.name_file}</p>
          {status == true ?
            <a onClick={(e) => {
              updateUnRead(e, item.data_alert_import_id, item.id, 'un-read');
            }}
            >  
            <ContentWithTooltip
              cid={item.id}
              type="name"
              content={item.label}
            />
            </a> 
          :
          <a style={{color: "#000"}} onClick={(e) => {
            updateUnRead(e, item.data_alert_import_id, item.id, 'read');
          }}
          >  
              <ContentWithTooltip
                cid={item.id}
                type="name"
                content={item.label}
              />
            </a>
          }
            
          <p>Import type: {item.type}</p>
        </div>
        <div
          className="notifications-list-item__time"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            width: "45px",
            paddingLeft: "5%",
          }}
        >
          <span>{formatDateForNotifications(item.created_at)}</span>
        </div>
      </div>
    ))}
    </>
    )
  }
  const user = props.user;
  {roleName == "Customer" && (
    <ul className="nav"></ul>
  )}
  // return (
  return roleName === "Super Administrator" && (
    <div className="profile ml-3">
      <div className="profile__box notifications">
        <Dropdown>
          <Dropdown.Toggle variant="" className="icon-button dropdown-toggle">
            <i className="far fa-bell"></i>
            {/* {dataUnRead.length > 0 &&
              <span className="icon-button__badge">{dataUnRead.length}</span>
            } */}
          </Dropdown.Toggle>
          <Dropdown.Menu align="right">
            <div style={{ height: "620px", width: "420px" }}>
              <div style={{ position: "relative", height: "620px", overflow: "scroll" }}>
                <div className="block-center-between">
                  <div className="left-action-menu">
                    <nav className="customer-nav" style={{paddingLeft: "20px"}}>
                      <ul className="nav">
                        <li className="nav-item"><a aria-current="page" className={active} style={{fontSize: "15px", fontWeight: "bold"}}>Notifications</a></li>
                        {/* <li className="nav-item"><a aria-current="page" className={active} style={{fontSize: "15px", fontWeight: "bold"}} onClick={handleActive}>UnRead</a></li> */}
                        {/* <li className="nav-item"><a className={unActive} style={{fontSize: "15px", fontWeight: "bold"}} onClick={handleUnActive}>Read</a></li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
                <hr></hr>
                <></>
                {dataUnRead.length > 0 ? 
                  renderData(dataUnRead, true)
                : 
                <>
                  <div style={{alignItems: "center", justifyContent: "center", display: "flex", marginTop: "30%"}}>
                    <svg data-v-a4619fec=""  data-v-7dc7fc7a="" style={{maxHeight: "96px"}} viewBox="0 0 90 100" xmlns="http://www.w3.org/2000/svg" class="blank-slate__image">
                      <path data-v-a4619fec="" data-v-7dc7fc7a="" fill="#FEB51F" d="M44.19 0c11.96-.059 7.404 10.908 5.008 16.68a48.84 48.84 0 00-.818 2.043c-1.676 4.661-5.2 5.242-7.833 1.428C33.629 10.133 32.601.058 44.19 0zm1.246 15.179c.073-.277.178-.623.3-1.017.878-2.872 2.545-8.328-1.834-8.298-4.243.029-3.866 5.04-1.333 10.025.964 1.897 2.254 1.608 2.867-.71zM45.817 99.981c6.129-.52 11.35-6.095 11.318-12.084-.002-.437-20.888-.389-22.795 1.974-4.371 5.42 7.211 10.472 11.477 10.11z"></path>
                      <path data-v-a4619fec="" data-v-7dc7fc7a="" fill="#FFD56A" d="M89.916 81.556c.824-4.677-4.598-10.534-6.328-14.592-7.156-16.773-2.539-26.441-12.407-41.823C65.015 15.53 54.834 10.68 45 10.841c-9.835-.161-20.015 4.689-26.181 14.301-9.867 15.381-5.25 25.05-12.406 41.822C4.682 71.022-.739 76.879.085 81.556c2.477 14.064 87.349 14.098 89.831 0z"></path>
                      <path data-v-a4619fec="" data-v-7dc7fc7a="" fill="#FEB51F" d="M42.076 74.875c-13.639 0-26.379-1.869-35.455-5.606a1.68 1.68 0 01-.924-2.216c.371-.86 1.385-1.264 2.265-.902 17.167 7.069 49.559 7.038 73.743-.07.916-.27 1.879.238 2.155 1.132a1.686 1.686 0 01-1.159 2.107c-12.601 3.704-27.058 5.555-40.625 5.555z"></path>
                      <path data-v-a4619fec="" data-v-7dc7fc7a="" fill="#FFEAB6" d="M71.034 60.59c-.763 0-1.469-.467-1.699-1.191-.686-2.156-1.067-5.068-1.57-9.264-.11-.917.589-1.745 1.563-1.85.974-.103 1.852.557 1.963 1.474.391 3.27.796 6.65 1.443 8.68.282.884-.251 1.815-1.189 2.081-.17.047-.342.07-.511.07zm-3.005-19.211c-.833 0-1.571-.582-1.717-1.408-2.45-13.824-7.363-19.781-15.931-19.314-.962.054-1.785-.663-1.838-1.598-.055-.934.681-1.735 1.643-1.787 13.891-.755 17.816 12.263 19.564 22.123.164.924-.473 1.8-1.423 1.959-.1.017-.199.025-.298.025z"></path>
                    </svg>
                  </div>
                  <div style={{alignItems: "center", justifyContent: "center", display: "flex", marginTop: "1%"}}>
                    <h3 className="mt-4">You're all up to date</h3>
                  </div>
                </>
                }
                {/* { is_active ? renderData(dataRead, false) : renderData(dataUnRead, true)} */}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default Notifications;

