import clsx from "clsx";
import { compose, path, tap } from "ramda";
import React, { useRef, useCallback, useLayoutEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { loadJquery } from "src/main_app/utils/common";

const focusOnOpenSearch = () =>
  setTimeout(() => {
    document.querySelector(".select2-search__field").focus();
  }, 333);

const AutoComplete = ({
  className,
  placeholder,
  onOpen,
  onSearch,
  onSelect,
  onClickItem,
  renderResult,
}) => {
  const [items, setItems] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const refSelect = useRef(null);
  const refOnSelectTimeout = useRef(null);

  const loadRequiredLibs = useCallback(
    () => [
      import("select2"),
      import("select2/dist/css/select2.min.css"),
      import("./style.scss"),
    ],
    []
  );

  const onSelectDelay = (...rest) => {
    refOnSelectTimeout.current = setTimeout(() => {
      onSelect(...rest);
    }, 666);
  };

  const init = async () => {
    await loadJquery();
    await Promise.all(loadRequiredLibs());

    $(refSelect.current)
      .select2({
        closeOnSelect: false,
        minimumInputLength: 2,
        placeholder,
        templateResult: renderResult,
        // templateSelection: render,
        ajax: {
          delay: 250,
          cache: true,
          transport: (params, success, failure) => {
            onSearch(
              params,
              () => setIsSearching(true),
              (resData) => {
                setIsSearching(false);
                setItems(resData.results);
                success(resData);
              },
              failure
            );
          },
        },
      })
      .on("select2:open", () =>{
        onOpen && onOpen()
        focusOnOpenSearch()
      })
      .on("select2:closing", function (e) {
        $(this).val(null).trigger("change");
      })
      .on("select2:select", (event) => {
        setTimeout(() => {
          $(refSelect.current).select2("close");
        }, 0);
        if (!!onSelectDelay)
          compose(onSelectDelay, path(["params", "data"]))(event);
      });
  };

  useLayoutEffect(() => {
    const onKeyPress = (event) => {
      if (event.which !== 13) return;
      event.stopPropagation();
      event.preventDefault();
      clearTimeout(refOnSelectTimeout.current);
    };
    $(document).on("keydown", ".select2-search__field", onKeyPress);

    init();
    return () =>
      $(document).off("keydown", ".select2-search__field", onKeyPress);
  }, []);

  useLayoutEffect(() => {
    const onClickItemCall = (event) => {
      onClickItem(event, items);
    };
    $(document)
      .off("click", ".select2-results__option--selectable", onClickItemCall)
      .on("click", ".select2-results__option--selectable", onClickItemCall);
    return () =>
      $(document).off(
        "click",
        ".select2-results__option--selectable",
        onClickItemCall
      );
  }, [items]);

  return (
    <div className="position-relative">
      <div className={clsx("position-relative", className)}>
        <select
          ref={refSelect}
          className="form-control hide-select"
          name="state"
        />
        <button className="btn-icon search-bar__btn">
          <i className="fal fa-search"></i>
        </button>
      </div>
      {isSearching && (
        <div className="auto-complete-skeleton">
          <div className="item d-flex">
            <div className="flex-fill ml-2 mr-5">
              <Skeleton height={32} />
            </div>
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
          </div>
          <div className="item d-flex">
            <div className="flex-fill ml-2 mr-5">
              <Skeleton height={32} />
            </div>
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
          </div>
          <div className="item d-flex">
            <div className="flex-fill ml-2 mr-5">
              <Skeleton height={32} />
            </div>
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
            <Skeleton height={32} width={32} className="mr-3" />
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
