import React, { useEffect, useMemo, useRef, } from "react";
import { connect } from "react-redux";

import { Dropdown } from "react-bootstrap";
import { useSubTenants, fetchSubTenants } from "src/main_app/actions";
import { switchTenant } from "src/main_app/services/apis";
import { authService } from "src/base/services";
import { path } from "ramda";
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
import { useTenantConfig } from "src/main_app/actions/tenant";

const Account = ({ fetchSubTenants }) => {
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const tenantConfig = useTenantConfig();
  const subTenants = useSubTenants();

  useEffect(() => {
    fetchSubTenants();
  }, []);

  const otherTenants = useMemo(
    () => subTenants.filter((item) => item.id !== tenantInfo.current.id && item.hasPermission),
    [subTenants, tenantInfo.current]
  );

  const tenantNameDisplay = useMemo(() => {
    const tenant = subTenants.find((item) => item.id === tenantInfo.current.id);
    if (!tenant) return tenantInfo.current.name
    if (!tenant.parent_id) return tenant.name;
    const parentTenant = subTenants.find(
      (item) => item.id === tenant.parent_id
    );
    return parentTenant.name + " - " + tenant.name;
  }, [tenantInfo.current, subTenants]);

  if (otherTenants.length === 0)
    return (
      <div className="my-account">
        <button className="btn btn-success"> {tenantNameDisplay}</button>
      </div>
    );

  const onSwitchTenant = (subTenantId) => async () => {
    const result = await switchTenant(subTenantId);
    authService.authenticate({
      account: path(["data", 1, "user"], result),
      accessToken: path(["data", 1, "token"], result),
      refreshToken: path(["data", 1, "refreshToken"], result),
      tenant_info: path(["data", 1, "tenant_info"], result),
    });
    const tenant = result["data"][0].config;
    setTimeout(() => {
      document.location.href = 
          tenant.pathLandingPage 
          ? tenant.pathLandingPage 
          : "/"
      // document.location.href = "/";
    }, 0);
  };

  return (
    <Dropdown className="my-account">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {tenantNameDisplay}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {otherTenants.map((subTenant) => (
          <Dropdown.Item
            key={subTenant.id}
            onClick={onSwitchTenant(subTenant.id)}
          >
            <i className="fas fa-caret-right mr-3"></i> {subTenant.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapActionToProps = {
  fetchSubTenants,
};

export default connect(null, mapActionToProps)(Account);
