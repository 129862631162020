import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import { Dropdown, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

import { isNilOrEmpty,
  formatNumber,
  addDays,
  convertDate,
  getDisplayName,
  isNotNil,
  convertDateWithShotMonthName
} from "src/main_app/utils/common";

import AlertsFilter from "../components/alerts_filter/alerts-filter";

import {
  isEmpty,
  isNil,
  propOr,
  compose,
  find,
  propEq,
  reduce,
  map,
  last,
  __,
} from "ramda";
import { getApiUrl } from "../utils/config-utility";
import axios from "axios";
import {
  getCustomersDetailPublic,
  getCustomerWaterUsage,
  getNineCustomerTheMostAlertScreenshot,
} from "../services/apis";
import AlertCustomerRealTimeUsagePage from "./alerts_page/alert-customers-real-time-usage";
const alertTypes = [
  { name: "base_flow", label: "Base flow", color: "#d6a189" },
  { name: "night_flow", label: "Night flow", color: "#4c6972" },
  { name: "data_loss", label: "Data loss", color: "#d6a189" },
];
const ScreenShotHw = () => {
  const [dataBarchart, setDataBarChart] = useState([]);
  const [dataOverview, setDataOverview] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const filterRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [titleHeader, setTitleHeader] =  useState('');
  const [total, setTotal] = useState({
    all: 0,
  });
  const hasAny = useRef(false);
  const options={
    responsive: true,
    legend: {
        display: false,
    },
    type:'bar',
    scales: {
      y: {
        ticks: {
          precision: 0
        }
      }
    }
  }

  const ticksRef = useRef([]);
  const [nameCustomer, setNameCustomer1] = useState(null);
  const [nameCustomer2, setNameCustomer2] = useState(null);
  const [nameCustomer3, setNameCustomer3] = useState(null);
  const [nameCustomer4, setNameCustomer4] = useState(null);
  const [nameCustomer5, setNameCustomer5] = useState(null);
  const [nameCustomer6, setNameCustomer6] = useState(null);
  const [nameCustomer7, setNameCustomer7] = useState(null);
  const [nameCustomer8, setNameCustomer8] = useState(null);
  const [nameCustomer9, setNameCustomer9] = useState(null);

  const [dataWeekly, setDataWeekly1] = useState(null);
  const [meterNames, setMeterNames1] = useState([]);

  const [dataWeekly2, setDataWeekly2] = useState(null);
  const [meterNames2, setMeterNames2] = useState([]);

  const [dataWeekly3, setDataWeekly3] = useState(null);
  const [meterNames3, setMeterNames3] = useState([]);

  const [dataWeekly4, setDataWeekly4] = useState(null);
  const [meterNames4, setMeterNames4] = useState([]);

  const [dataWeekly5, setDataWeekly5] = useState(null);
  const [meterNames5, setMeterNames5] = useState([]);

  const [dataWeekly6, setDataWeekly6] = useState(null);
  const [meterNames6, setMeterNames6] = useState([]);

  const [dataWeekly7, setDataWeekly7] = useState(null);
  const [meterNames7, setMeterNames7] = useState([]);

  const [dataWeekly8, setDataWeekly8] = useState(null);
  const [meterNames8, setMeterNames8] = useState([]);

  const [dataWeekly9, setDataWeekly9] = useState(null);
  const [meterNames9, setMeterNames9] = useState([]);

  const displaySeverity = useCallback((alert) => {
    return (
      <span className={`alert-severity alert-severity--${alert.data.severity}`}>
        {alert.data.severity}
      </span>
    );
  }, []);

  const displayAlertType = useCallback((alert) => {
    return (
      <span className={`alert-type alert-type--${alert.type}`}>
        {(alertTypes.find((item) => item.name === alert.type) ?? {}).label}{" "}
        <OverlayTrigger overlay={<Tooltip>{alert.data.content}</Tooltip>}>
          <i className="fas fa-info-circle"></i>
        </OverlayTrigger>
      </span>
    );
  }, []);

  const displayAlertValueInPercent = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        return alert.data.roundedNightUsagePercent + "%";
      case "data_loss":
        return "0%";
      default:
        return "-";
    }
  }, []);

  const displayAlertValue = useCallback((alert) => {
    switch (alert.type) {
      case "night_flow":
        const value = alert.data.nightUsage
          ? (alert.data.nightUsage * 1000) / (60 * 60 * 2)
          : alert.data.value;
        return (
          <React.Fragment>
            {isNilOrEmpty(value) ? "" : Math.ceil(value * 10) / 10}{" "}
            <i
              className={[
                "fas fa-arrow-up",
                alert.data.value < alert.data.valueOfPreviousDay
                  ? "trending-down rotate-135deg"
                  : alert.data.value > alert.data.valueOfPreviousDay
                  ? "trending-up rotate-45deg"
                  : "trending-normal rotate-90deg",
              ].join(" ")}
            ></i>
          </React.Fragment>
        );
      case "base_flow":
        return <React.Fragment>{alert.data.value}</React.Fragment>;
    }
  }, []);

  const displayAlertTime = useCallback((alert) => {
    return moment(alert.created_at).format("DD MMM yyyy");
  }, []);

  useEffect(() => {
    const ignore = false;
    if (!ignore) loadData();
    return () => {
      ignore = true;
    };
  }, []);

  const loadData = async () => {
    const tenant_id = 3;
    const today = addDays(new Date(), -1);
    const to = convertDate(today);
    const from = convertDate(addDays(today, -7));
    const { data: result } = await axios.get(
      `${getApiUrl()}/alerts-screenshot/overview?tenant_id=${tenant_id}&from=${from}&to=${to}`
    );
    const { data: listCustomer } = await axios.get(
      `${getApiUrl()}/alerts-screenshot/list-customer?tenant_id=${tenant_id}&from=${from}&to=${to}`
    );

     //Set total 
     hasAny.current =
     reduce(
       (sumItems, item) => {
         return sumItems + (item.count - "");
       },
       0,
       result.overviewBySeverity
     ) > 0;

   const nTotal = {
     all: reduce(
       (sumItems, item) => {
         return sumItems + (item.count - "");
       },
       0,
       result.overviewByType
     ),
   };
   for (const alertType of alertTypes) {
     nTotal[alertType.name] =
       (
         result.overviewByType.find((i) => i.type === alertType.name) ?? {
           count: 0,
         }
       ).count - "";
   }
   setTotal(nTotal);

   //End set total

    setAlerts(listCustomer);
    setDataOverview(result.overviewBySeverity);

    setNameCustomer1(null);
    setNameCustomer2(null);
    setNameCustomer3(null);
    setNameCustomer4(null);
    setNameCustomer5(null);
    setNameCustomer6(null);
    setNameCustomer7(null);
    setNameCustomer8(null);
    setNameCustomer9(null);

    setDataWeekly1(null);
    setMeterNames1([]);
    setDataWeekly2(null);
    setMeterNames2([]);

    setDataWeekly3(null);
    setMeterNames3([]);
    setDataWeekly4(null);
    setMeterNames4([]);

    setDataWeekly5(null);
    setMeterNames5([]);
    setDataWeekly6(null);
    setMeterNames6([]);

    setDataWeekly7(null);
    setMeterNames7([]);
    setDataWeekly8(null);
    setMeterNames8([]);
    setDataWeekly9(null);
    setMeterNames9([]);

    const res = await getNineCustomerTheMostAlertScreenshot(tenant_id);
    setTitleHeader(propOr('', "alertType", res['data']));
    const customer_ids = propOr([], "data", res['data']);
    if (!isEmpty(customer_ids)) {
      for (let i = 0; i < customer_ids.length; i++) {
        const item = customer_ids[i];
        const dataRealTime = await mapDataSums(item["customer_id"]);
        const { data: data } = await getCustomersDetailPublic(
          item["customer_id"]
        );
        const nameOfCustomer = propOr(null, "name", data.data);
        switch (i) {
          case 0:
            setDataWeekly1(dataRealTime["arrDataWeekly"]);
            setMeterNames1(dataRealTime["setOfMeterNames"]);
            setNameCustomer1(nameOfCustomer);
            break;
          case 1:
            setDataWeekly2(dataRealTime["arrDataWeekly"]);
            setMeterNames2(dataRealTime["setOfMeterNames"]);
            setNameCustomer2(nameOfCustomer);
            break;
          case 2:
            setDataWeekly3(dataRealTime["arrDataWeekly"]);
            setMeterNames3(dataRealTime["setOfMeterNames"]);
            setNameCustomer3(nameOfCustomer);
            break;
          case 3:
            setDataWeekly4(dataRealTime["arrDataWeekly"]);
            setMeterNames4(dataRealTime["setOfMeterNames"]);
            setNameCustomer4(nameOfCustomer);
            break;
          case 4:
            setDataWeekly5(dataRealTime["arrDataWeekly"]);
            setMeterNames5(dataRealTime["setOfMeterNames"]);
            setNameCustomer5(nameOfCustomer);
            break;
          case 5:
            setDataWeekly6(dataRealTime["arrDataWeekly"]);
            setMeterNames6(dataRealTime["setOfMeterNames"]);
            setNameCustomer6(nameOfCustomer);
            break;
          case 6:
            setDataWeekly7(dataRealTime["arrDataWeekly"]);
            setMeterNames7(dataRealTime["setOfMeterNames"]);
            setNameCustomer7(nameOfCustomer);
            break;
          case 7:
            setDataWeekly8(dataRealTime["arrDataWeekly"]);
            setMeterNames8(dataRealTime["setOfMeterNames"]);
            setNameCustomer8(nameOfCustomer);
            break;
          case 8:
            setDataWeekly9(dataRealTime["arrDataWeekly"]);
            setMeterNames9(dataRealTime["setOfMeterNames"]);
            setNameCustomer9(nameOfCustomer);
            break;
        }
      }
    }

    setIsLoaded(true);
  };

  useLayoutEffect(() => {
    import("./style-screenshot-alert.scss");
  }, []);

  /////////////////////Chart Real time data (Sums data)//////////////////////////

  const mapDataSums = async (customer_id) => {
    const ticks = [];
    const today = new Date();
    const to = addDays(today, -1);
    const from = addDays(today, -3);
    const {
      data: { data },
    } = await getCustomerWaterUsage(customer_id, {
      from: convertDate(from),
      to: convertDate(to),
    });

    const allMeterNames = [];

    let arrDataWeekly = map((item) => {
      const { time, ...metersData } = item;

      if (isEmpty(metersData)) return { time };

      const utcTime = moment(time).utcOffset(0, true);

      const utcUnix = utcTime.unix();

      if (isEmpty(ticks) || utcUnix - last(ticks) >= 86400) {
        ticks.push(utcUnix);
      }

      const combinedData = {
        date: utcUnix,
        displayDate: utcTime.format("ddd MMM DD hh:mm a"),
      };

      for (const meterName in metersData) {
        if (!Object.hasOwnProperty.call(metersData, meterName)) continue;
        allMeterNames.push(meterName);
        const sumUsage = metersData[meterName];
        combinedData[meterName] = sumUsage
          ? Math.round(sumUsage * 10) / 10
          : null;
      }

      ticksRef.current = ticks;

      return combinedData;
    }, data);

    const setOfMeterNames = [...new Set(allMeterNames)];
    arrDataWeekly = map((item) => {
      for (const meterName of setOfMeterNames) {
        if (isNotNil(item[meterName])) continue;
        item[meterName] = 0;
      }

      return item;
    }, arrDataWeekly);

    return { setOfMeterNames, arrDataWeekly };
  };
  ///////////////////////////////////////////////

  return (
    <div className="wrapper">
      <div className="the-content">
        <div className="container-fluid content">
          <div className="action-buttons block-center-between">
            <div className="block-center" style={{ opacity: "0" }}>
              <AlertsFilter ref={filterRef} onFiltersApply={loadData} />
            </div>
            <div className="d-flex">
              {isLoaded && (
                <div className="alert-statistic">
                  <span className="alert-statistic_high">
                    {alerts.length}
                    {/* {displayDataOverview("high")(dataOverview)} */}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              
              {isLoaded && (
                <>
                <h5 className="text-center">
                    {titleHeader}
                </h5>
                <div className="card">
                  <div className="card-body">
                    
                    <div className="row">
                      {!isNilOrEmpty(dataWeekly) && (
                        <>
                          <div className="col-md-4">
                            <AlertCustomerRealTimeUsagePage
                              dataWeekly={dataWeekly}
                              meterNames={meterNames}
                              ticks={ticksRef.current}
                            />
                            <h5 className="text-center">{nameCustomer}</h5>
                          </div>
                        </>
                      )}
                      {!isNilOrEmpty(dataWeekly2) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly2}
                            meterNames={meterNames2}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer2}</h5>
                        </div>
                      )}
                      {!isNilOrEmpty(dataWeekly3) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly3}
                            meterNames={meterNames3}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer3}</h5>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      {!isNilOrEmpty(dataWeekly4) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly4}
                            meterNames={meterNames4}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer4}</h5>
                        </div>
                      )}
                      {!isNilOrEmpty(dataWeekly5) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly5}
                            meterNames={meterNames5}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer5}</h5>
                        </div>
                      )}
                      {!isNilOrEmpty(dataWeekly6) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly6}
                            meterNames={meterNames6}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer6}</h5>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      {!isNilOrEmpty(dataWeekly7) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly7}
                            meterNames={meterNames7}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer7}</h5>
                        </div>
                      )}
                      {!isNilOrEmpty(dataWeekly8) && (
                        <div className="col-md-4">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly8}
                            meterNames={meterNames8}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer8}</h5>
                        </div>
                      )}
                      {!isNilOrEmpty(dataWeekly9) && (
                        <div className="col-md-4" id="loaded">
                          <AlertCustomerRealTimeUsagePage
                            dataWeekly={dataWeekly9}
                            meterNames={meterNames9}
                            ticks={ticksRef.current}
                          />
                          <h5 className="text-center">{nameCustomer9}</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                </>
              )}
            </div>
          </div>

          {/* List Alert */}
          <div className="mt-5">
            <div className="d-flex mb-3">
              <div className="total-alert">
                All alerts <span>{alerts.length}</span>
              </div>
              {alertTypes.map((item) => (
                <div>
                  <div className="total-alert">
                    {item.label}{" "}
                    <span>{formatNumber(total[item.name] ?? 0)}</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Customer</th>
                    <th scope="col">Customer type</th>
                    <th scope="col">Severity</th>
                    <th scope="col">Alert type</th>
                    <th scope="col">Value</th>
                    <th scope="col">L/s</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.map((alert, index) => (
                    <tr key={alert.id}>
                      <td scope="col" className="customer-info customer-name">
                      <span>{getDisplayName(alert)}</span>
                      </td>
                      <td
                        scope="col"
                        className="customer-info customer-critical-class"
                      >
                        {alert.critical_class}
                      </td>
                      <td scope="col">{displaySeverity(alert)}</td>
                      <td scope="col">{displayAlertType(alert)}</td>
                      <td scope="col text-right">
                        {displayAlertValueInPercent(alert)}
                      </td>
                      <td scope="col text-right">{displayAlertValue(alert)}</td>
                      <td scope="col">{displayAlertTime(alert)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* List Alert */}
        </div>
      </div>
    </div>
  );
};

export default ScreenShotHw;


