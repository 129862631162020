import { propOr } from "ramda";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import { upperCaseFirstChar } from "src/main_app/utils/string_utils";
import Select from '@bhunjadi/react-select-v3';

const Select1 = ({
  controlId,
  className,
  name,
  label,
  value,
  title,
  items,
  onChange,
  disabled,
  itemId = "value",
  itemDisplay = "label",
  hasEmptyOption = false,
  children,
}) => (
  <Form.Group controlId={controlId} className={className}>
    <Form.Label>{label || upperCaseFirstChar(name)}</Form.Label>
    
    
    {/* {console.log('role user', items)} */}
    {/* {console.log('va,lue', value)} */}
    
    {itemId == "value" ?  (
      <Select
        value={{value: value, label: value}}
        onChange={onChange}
        isDisabled= {disabled}
        options={items}
      />
    ) : (
      <Select
        value={{id: value, name: title}}
        isDisabled= {disabled}
        onChange={onChange}
        getOptionLabel = {(option)=>option.name}
        getOptionValue = {(option)=>option.id}
        options={items}
      />
    )}
    
    {/* <Form.Control
      name={name}
      as="select"
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {hasEmptyOption && <option />}
      {items.map((item, index) => (
        <option key={index + ""} value={propOr("", itemId, item)}>
          {propOr("", itemDisplay, item)}
        </option>
      ))}
    </Form.Control> */}
    {children}
  </Form.Group>
);

export default Select1;
