import React, { useEffect } from "react";
import { propOr, head } from "ramda";
import { isNilOrEmpty } from "src/main_app/utils/common";
import { useTenantConfig } from "src/main_app/actions";
import { getCustomersDataLogin } from "src/main_app/services/apis";
import { authService } from "../../../base/services";

const HomePage = () => {

  const tenantConfig = useTenantConfig()
  const user = authService.getUser();
  const customerLogin = async (data) => {
    const response = await getCustomersDataLogin(data)
    const customerId = propOr([], 'data', response);
    document.location.href = `customer/${customerId}/overview`;
  }

  useEffect(() => {
    if (isNilOrEmpty(tenantConfig)) return;
    const subMenus = propOr([], "subMenus", tenantConfig);
    const defaultMenu = subMenus.find((i) => i.default) ?? head(subMenus);
    if (user.role == 'Customer') {
      customerLogin(user);
    } else {
      document.location.href = 
          tenantConfig.pathLandingPage 
          ? tenantConfig.pathLandingPage 
          : propOr("/", "to", defaultMenu);

      // document.location.href = propOr("/", "to", defaultMenu);

    }
  }, [tenantConfig]);

  return <div className="the-content"></div>;
};

export default HomePage;
