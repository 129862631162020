import {compose, either, pathEq, propOr, reduce, filter, map, concat} from 'ramda'

export const getCustomerFieldsConfig = compose(
  filter(
    either(
      pathEq(['field_data', 'table'], 'customers'),
      pathEq(['field_data', 'table'], 'customer_metas'),
    ),
  ),
  reduce(concat, []),
  map(propOr([], 'fields')),
)

export const getCustomerTaxonomiesConfig = compose(
  filter(
    pathEq(['field_data', 'table'], 'customers')
  ),
  reduce(concat, []),
  map(propOr([], 'fields')),
)
