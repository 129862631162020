import React from 'react'

import {
  StackedChart,
  ClusteredColumnChart
} from '../components'
import { DateRangePicker } from '../components/date_range_picker'
import PlansSetting from '../components/plans-setting/plans-setting'

const stackedColumnChartData = [
  {
    'month': 'July 2020',
    'large': 100,
    'major': 250,
    'school': 300,
  }, {
    'month': 'August 2020',
    'large': 200,
    'major': 100,
    'school': 150,
  }, {
    'month': 'September 2020',
    'large': 350,
    'major': 145,
    'school': 195,
  }]

const clusteredChartData = [
  {
    month: 'July 2020',
    rainfall: 80,
    usage: 110,
  },
  {
    month: 'August 2020',
    rainfall: 80,
    usage: 70,
  },
  {
    month: 'September 2020',
    rainfall: 125,
    usage: 98,
  },
]


const PlansPage = props => {
  return (
    <div className="the-content">
      <div className="page-heading ">
        <div className="container-fluid block-center-between">
          <h2 className="title mb-0">
            Planning
          </h2>
        </div>
      </div>
      <div className="container-fluid content">
        <div className="action-buttons block-center">
          <PlansSetting />
          <DateRangePicker className="ml-3">
            <input type="text" className="form-control" />
          </DateRangePicker>
        </div>
        <div className="row chart-box ">
          <div className="col-md-12 chart-box__col mb-5">
            <div className="card">
              <div className="card-header">Reports</div>
              <div className="card-body">
                <StackedChart
                  style={{
                    height: '370px',
                  }}
                  id="plan-page__xy-chart__01"
                  data={stackedColumnChartData}
                  info={{
                    category: 'month',
                    categoryText: '2020',
                    series: [
                      {
                        name: 'Large',
                        value: 'large',
                        color: '#2caaed',
                      },
                      {
                        name: 'Major',
                        value: 'major',
                        color: '#89d0f5',
                      },
                      {
                        name: 'School',
                        value: 'school',
                        color: '#c7ecff',
                      }
                    ],
                    radius: 10,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 chart-box__col mb-5">
            <div className="card">
              <div className="card-header">Rainfall vs Usage</div>
              <div className="card-body">
                <ClusteredColumnChart
                  style={{
                    height: '370px',
                  }}
                  id="plan-page__clustered-chart__01"
                  data={clusteredChartData}
                  info={{
                    category: 'month',
                    radius: 5,
                    series: [
                      {
                        name: 'Rainfall',
                        value: 'rainfall',
                        color: '#2caaed'
                      },
                      {
                        name: 'Usage',
                        value: 'usage',
                        color: '#89d0f5',
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansPage
