import { getTenantConfigData } from "src/main_app/utils/user_utils";
import { FETCH_TENANT_CONFIG } from "../constants";

let isFetched = false
const fetchTenantConfigAsync = () => (dispatch, getState) => {
  if (isFetched) return;
  isFetched = true
  getTenantConfigData().then(({ config }) =>
    dispatch({
      type: FETCH_TENANT_CONFIG,
      data: config,
    })
  );
};

export default fetchTenantConfigAsync;