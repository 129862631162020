// layouts
import {
  EmptyLayout,
  StartLayout,
  TvAppLayout,
} from '../layouts'

import SignInPage from '../scenes/sign-in'
import SignoutPage from '../scenes/sign-out'
import VerificationPage from '../scenes/verification'
import TvAppPage from '../scenes/tv-app'
import TvAppPageTest from '../scenes/tv-app-test'
import ScreenShotHw from '../scenes/screenshot-alert-hw'
import ScreenShotAveo from '../scenes/screenshot-alert-aveo'

const defaultRoutes = [
  {
    exact: true,
    path: '/sign-in',
    requireLogin: false,
    component: SignInPage,
    layout: StartLayout
  },
  {
    exact: true,
    path: '/verification',
    requireLogin: false,
    component: VerificationPage,
    layout: StartLayout
  },
  {
    exact: true,
    path: '/sign-out',
    requireLogin: true,
    component: SignoutPage,
    layout: EmptyLayout
  },
  {
    exact: true,
    path: '/tv-app',
    requireLogin: false,
    component: TvAppPage,
    layout: TvAppLayout
  },
  {
    exact: true,
    path: '/tv-app-test',
    requireLogin: false,
    component: TvAppPageTest,
    layout: TvAppLayout
  },
  {
    exact: true,
    path: '/screenshot-alert-hw',
    requireLogin: false,
    component: ScreenShotHw,
    layout: TvAppLayout
  },
  {
    exact: true,
    path: '/screenshot-alert-aveo',
    requireLogin: false,
    component: ScreenShotAveo,
    layout: TvAppLayout
  },
]

export default defaultRoutes

