import React from 'react'
import {
    Form,
} from 'react-bootstrap'
import { upperCaseFirstChar } from 'src/main_app/utils/string_utils'

const Textbox = ({
  controlId,
  name,
  label,
  type = 'text',
  placeholder,
  defaultValue,
  value,
  onChange,
  children,
  disabled,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label>{label || upperCaseFirstChar(name)}</Form.Label>
    <Form.Control
      name={name}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    {children}
  </Form.Group>
)

export default Textbox