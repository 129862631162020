const alertTypes = [
  { name: "base_flow", label: "Base flow", color: "#000" },
  { name: "night_flow", label: "Night flow", color: "#0BA2B4" },
  { name: "data_loss", label: "Data loss", color: "#000" },
  { name: "peak_flow", label: "Peak flow", color: "#008D4B" },
  { name: "water_quality", label: "Water quality", color: "#C76500" },
  { name: "step_change", label: "Step change", color: "#3422aa" },
  { name: "leak_alert", label: "Leak alert", color: "#3422aa" },
  { name: "signal_strength", label: "Signal strength", color: "#3422aa" },
];

export default alertTypes