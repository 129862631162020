
import { pathOr } from 'ramda'
import getWithToken from 'src/main_app/services/apis/base/get-with-token'
import { hasError, isServerResponseError } from '../common'

const getTaxonomyDetails = async (id) => {
  const result = await getWithToken(`/customer-taxonomy/${id}`)()

  return isServerResponseError(result) || hasError(result)
    ? {}
    : pathOr({}, ['data', 'data'], result)
}

export default getTaxonomyDetails
