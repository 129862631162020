import postWithToken from './base/post-with-token'

const addUser = ({
  first_name,
  last_name,
  email,
  roles,
}) => postWithToken('/user')(
  {
    first_name,
    last_name,
    email,
    roles,
  }
)

export default addUser