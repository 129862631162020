import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'
import { track } from "src/main_app/mixpanel";
import { sendMailUpdateAddress } from 'src/main_app/services/apis';
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";

const CustomerAddress = ({
  tenantConfig,
  showModal,
  handleActionsClose,
  user
}) => {
  if (!showModal) return null
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const { addToast } = useToasts();
  const [address, setAddress] = useState(null);
  const [field, setField] = useState({email: null, address: null});
  const [userMail, setUserMail] = useState(user.email)
  const params = useParams();

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (address == null) {
      formIsValid = false;
      errors["address"] = "The address field is required.";
    }
    if ((userMail == null) || (userMail === '')) {
      formIsValid = false;
      errors["email"] = "The email field is required.";
    }

    console.log('userMail', userMail)

    setField(errors);
    return formIsValid

  }

  const handleSubmit = async () => {
    if (handleValidation()) {
      const urlDetailCustomer = window.location.href;
      const data = {
        url: urlDetailCustomer,
        email_to: tenantConfig.addressMailTo,
        email_bcc: tenantConfig.addressMailBcc,
        address: address,
        customer_id: params.id,
        user_mail: userMail,
      }

      const res = await sendMailUpdateAddress(data);

      handleActionsClose()
      setField(null)
      addToast("Send email success", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    } else {
      return
    }
  }
  
  useEffect(() => {
    track("Contact with Administrator", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [])

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={"lg"}>
      <Modal.Header closeButton>
            <Modal.Title>Contact administrator to update address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-link-form" className="">
            <div className="form-group">
              <label>From<span style={{ color: "red" }}>*</span> :</label>
              <input type="text" className="form-control" value={userMail} onChange={(event) => setUserMail(event.target.value)} />
            </div>
            <span className='form-group' style={{ color: "red", position: "relative", bottom: "10px" }}>{field['email']}</span>
          <br/>
          <div className="form-group">
            <label>Correct address should be<span style={{ color: "red" }}>*</span> :</label>
            <textarea
              className="form-control"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </div>
          <span className='form-group' style={{ color: "red", position: "relative", bottom: "10px" }}>{field['address']}</span>
          <br/>
            <Button variant="primary" onClick={handleSubmit}>
                Send
            </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerAddress;

