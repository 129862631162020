import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { getCustomersTimelineNoteTypes } from 'src/main_app/services/apis'
import {
  deleteTimelineItem,
  updateNote,
} from '../../../../utils/customer_utils'
import { isEmpty, compose, andThen } from 'ramda'

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList'],
}

import { track } from "src/main_app/mixpanel";
import Select from '@bhunjadi/react-select-v3';
import { getTenantInfoFromLocal } from "src/main_app/services/local_storage/tenant-info";
  
const EditNote = ({
  id,
  itemData,
  label,
  customerId,
  showModal,
  handleActionsClose,
  fetchCustomerTimeline,
}) => {
  if (!showModal) return null
  useEffect(() => {
    track("Edit note modal", {
      tenant_id: tenantInfo.current.id,
      tenant_name: tenantInfo.current.name,
    });
  }, [])
  const tenantInfo = useRef(getTenantInfoFromLocal());
  const { addToast } = useToasts()
  const [nameType, setNameType] = useState(label)
  const [noteType, setNoteType] = useState(itemData.type)
  const [noteContent, setNoteContent] = useState(itemData.content)
  const [noteTypes, setNoteTypes] = useState([])
  const [error, setError] = useState(false)

  const responseProcess = (response, successMess, failMessage) => {
    addToast(response === 'success' ? successMess : failMessage, {
      appearance: response === 'success' ? 'success' : 'error',
      autoDismiss: true,
      autoDismissTimeout: 3000,
    })
    if (response === 'success' && fetchCustomerTimeline) {
      fetchCustomerTimeline(customerId)
    }
    setError(false)
    handleActionsClose()
  }

  const loadData = compose(andThen(setNoteTypes), getCustomersTimelineNoteTypes)

  useEffect(() => {
    loadData()
  }, [])

  const noteSubmitHandler = async () => {
    if (isEmpty(noteContent)) {
      addToast('Note content must not be empty', {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      })
      setError(true)
      return
    }
    const response = await updateNote(id, {
      type: noteType,
      content: noteContent,
    })
    responseProcess(response, 'Note updated successfully', 'Update note failed')
  }

  const onRemoveNote = async () => {
    if (!confirm('Are you sure you want to delete this item?')) return
    const response = await deleteTimelineItem(id)
    responseProcess(response, 'Note removed successfully', 'Remove note failed')
  }

  return (
    <Modal show={showModal} onHide={handleActionsClose} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Edit a note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="customer-add-note-form" className="">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Note type</label>
                <Select
                  defaultValue={{id:noteType, label:nameType}}
                  onChange={(event) => {
                    setNoteType(event.id + '')
                  }}
                  getOptionLabel ={(option)=>option.label}
                  getOptionValue ={(option)=>option.id}
                  options={noteTypes}
                />
              </div>
            </div>
          </div>
          <div className={`form-group ${error ? 'has-error' : ''}`}>
            <label>Note</label>
            <CKEditor
              editor={Editor}
              config={editorConfiguration}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    'height',
                    '200px',
                    editor.editing.view.document.getRoot(),
                  )
                  writer.setStyle(
                    'color',
                    '#000000',
                    editor.editing.view.document.getRoot(),
                  )
                })
                // console.log('Editor is ready to use!', editor)
              }}
              onChange={(event, editor) => {
                setNoteContent(editor.getData())
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
              data={noteContent}
            />
          </div>
          <div className="d-flex justify-content-between">
            <Button variant="primary" onClick={noteSubmitHandler}>
              UPDATE NOTE
            </Button>
            <Button variant="danger" onClick={onRemoveNote}>
              REMOVE
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default EditNote
