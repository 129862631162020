import { pathOr } from "ramda";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { checkPermission, useUserPermissions } from "src/main_app/actions";
import {
  getPermissions,
  getUserRoles,
  getRolePermissions,
  setUserRole,
} from "src/main_app/services/apis";

import "./roles-page.style.scss";

export const RolesPage = () => {
  const [selectedRole, setSelectedRole] = useState({
    id: -1,
  });
  const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [rolePermissions, setRolePermissions] = React.useState([]);

  const userPermissions = useUserPermissions();
  const canViewThisPage = useMemo(() => checkPermission(userPermissions, "roles-page", "canView"), [userPermissions]) 
  useEffect(() => {
    if (!canViewThisPage) {
      alert("You don't have permission to view this page");
      document.location.href = "/";
    }
  }, [canViewThisPage]);

  useEffect(() => {
    getUserRoles().then(({ data: res }) => {
      setRoles(res.data);
    });
    getPermissions().then(({ data: res }) => {
      setPermissions(res.data);
    });
    getRolePermissions().then(({ data: res }) => {
      setRolePermissions(res.data);
    });
  }, []);

  const onRemove = (role) => () => {
    if (confirm("Are you sure you want to delete this role?")) {
      console.log(role);
    }
  };

  const onAdd = () => {
    setSelectedRole({
      id: -1,
    });
    setIsUpdate(false);
    setIsShow(true);
  };

  const onEdit = (role) => () => {
    setSelectedRolePermissions(
      rolePermissions.filter((item) => item.roleId === role.id) ?? []
    );
    setSelectedRole(role);
    setIsUpdate(true);
    setIsShow(true);
  };

  const onSave = () => {
    setUserRole(selectedRole, selectedRolePermissions).then(({ data }) => {
      alert("Role saved successfully");
      document.location.reload();
    });
  };

  const onPermissionChange = (permission, permissionDetail) => () => {
    const selectedRolePermission = selectedRolePermissions.find(
      (item) => item.permissionId === permission.id
    ) ?? {
      roleId: selectedRole.id,
      permissionId: permission.id,
      meta: {},
    };
    const meta = selectedRolePermission.meta ?? {};
    const newSelectedRolePermission = {
      ...selectedRolePermission,
      meta: {
        ...meta,
        [permissionDetail]: !meta[permissionDetail],
      },
    };
    const newSelectedRolePermissions = [
      ...selectedRolePermissions.filter(
        (item) => item.permissionId !== permission.id
      ),
      newSelectedRolePermission,
    ];
    setSelectedRolePermissions(newSelectedRolePermissions);
  };

  const renderRolePermission = (role, permission, index2) => {
    const rolePermission = rolePermissions.find(
      (item) => item.roleId === role.id && item.permissionId === permission.id
    );

    const canView = pathOr(false, ["meta", "canView"], rolePermission);
    const canEdit = pathOr(false, ["meta", "canEdit"], rolePermission);
    const canDelete = pathOr(false, ["meta", "canDelete"], rolePermission);
    const canExecute = pathOr(false, ["meta", "canExecute"], rolePermission);

    return (
      <td scope="col" key={index2}>
        <div className="d-flex align-items-center">
          {canView ? (
            <i className="far fa-check-square"></i>
          ) : (
            <i className="far fa-square"></i>
          )}
          &nbsp;&nbsp; View
        </div>
        <div className="d-flex align-items-center">
          {canEdit ? (
            <i className="far fa-check-square"></i>
          ) : (
            <i className="far fa-square"></i>
          )}
          &nbsp;&nbsp; Edit
        </div>
        <div className="d-flex align-items-center">
          {canDelete ? (
            <i className="far fa-check-square"></i>
          ) : (
            <i className="far fa-square"></i>
          )}
          &nbsp;&nbsp; Delete
        </div>
        <div className="d-flex align-items-center">
          {canExecute ? (
            <i className="far fa-check-square"></i>
          ) : (
            <i className="far fa-square"></i>
          )}
          &nbsp;&nbsp; Execute
        </div>
      </td>
    );
  };

  const renderFormRolePermission = (permission, index) => {
    const selectedRolePermission =
      selectedRolePermissions.find(
        (item) =>
          item.roleId === selectedRole.id && item.permissionId === permission.id
      ) ?? {};
    const canView = pathOr(false, ["meta", "canView"], selectedRolePermission);
    const canEdit = pathOr(false, ["meta", "canEdit"], selectedRolePermission);
    const canDelete = pathOr(
      false,
      ["meta", "canDelete"],
      selectedRolePermission
    );
    const canExecute = pathOr(
      false,
      ["meta", "canExecute"],
      selectedRolePermission
    );

    return (
      <div className="form-group" key={index}>
        <label>{permission.name}</label>
        <div className="d-flex flex-wrap">
          <label className="checkbox checkbox--large mt-4 mr-3">
            <input
              type="checkbox"
              checked={canView}
              onChange={onPermissionChange(permission, "canView")}
            />
            <span className="ck"></span> View
          </label>
          <label className="checkbox checkbox--large mt-4 mr-3">
            <input
              type="checkbox"
              checked={canEdit}
              onChange={onPermissionChange(permission, "canEdit")}
            />
            <span className="ck"></span> Edit
          </label>
          <label className="checkbox checkbox--large mt-4 mr-3">
            <input
              type="checkbox"
              checked={canDelete}
              onChange={onPermissionChange(permission, "canDelete")}
            />
            <span className="ck"></span> Delete
          </label>
          <label className="checkbox checkbox--large mt-4 mr-3">
            <input
              type="checkbox"
              checked={canExecute}
              onChange={onPermissionChange(permission, "canExecute")}
            />
            <span className="ck"></span> Execute
          </label>
        </div>
      </div>
    );
  };

  return (userPermissions.length === 0 || !canViewThisPage) ? null :(
    <>
      <div className="the-content roles-page">
        {/* <div className="page-heading ">
          <div className="container-fluid">
            <h2 className="title mb-0">Roles</h2>
          </div>
        </div> */}
        <div className="container-fluid content">
          <div className="d-flex justify-content-end mb-4">
            <Button className="btn btn-default" onClick={onAdd}>
              Add Role
            </Button>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" width="13%">
                    <div className="d-flex">Name</div>
                  </th>
                  {permissions.map((permission, index) => (
                    <th scope="col" key={index}>
                      {permission.name}
                    </th>
                  ))}
                  <th scope="col" width="100">
                    <div className="d-flex">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => (
                  <tr key={index}>
                    <td>
                      {role.name}
                      {role.system_role && (
                        <>
                          <br />
                          <span className="badge bg-warning mt-3">System role</span>
                        </>
                      )}
                      {role.default_role && (
                        <>
                          <br />
                          <span className="badge bg-primary mt-3">Default role</span>
                        </>
                      )}
                    </td>
                    {permissions.map((permission, index2) =>
                      renderRolePermission(role, permission, index2)
                    )}
                    <td>
                      <a className="text-primary" onClick={onEdit(role)}>
                        Edit
                      </a>
                      {/* <br />
                      <br />
                      {!role.system_role && (
                        <a className="text-danger" onClick={onRemove(role)}>
                          Remove
                        </a>
                      )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={isShow} onHide={() => setIsShow(false)} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? "Edit" : "Add"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="mb-5">
            <div className="form-group">
              <label>Role name</label>
              <input
                type="text"
                className="form-control"
                value={selectedRole.name ?? ""}
                onChange={(event) =>
                  setSelectedRole({
                    ...selectedRole,
                    name: event.target.value,
                  })
                }
              />
            </div>
            {permissions.map(renderFormRolePermission)}
          </form>
          <Button variant="primary" onClick={onSave}>
            {isUpdate ? "SAVE" : "ADD"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
